// Users Add
import React, { useEffect, useState,useReducer, useRef} from 'react';
import { Formik } from 'formik';
import './users-list.scss';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import DesignationService from '../../services/designation.service';
import UsersService from '../../services/users.service';
import SetupAdd from '../setup/setup-add';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import SecurityService from '../security/security.service';
import { initialEnd } from '@syncfusion/ej2-react-grids';
import CheckDuplicate  from '../check-duplicate/check-duplicate'

const reducer = (states, action) => {
  console.log(states)
  const { type, payloads } = action;
  switch (type) {
    case 'Desgination_Value':
      return {...states , DesignationName:payloads};
    case 'Desgination_Error':
      return {...states , DesignationError:payloads};
    default:
      return states;
  }
};
export default function UsersAdd(props) {
  const userId = SecurityService.getLoggedInUserId();
  let DesignationRef = useRef(null)
  let initialstat = {
    DesignationName :  props.DesignationName,
    DesignationError: false,
  }
  const [value , setValue] = useReducer(reducer,initialstat)

var string
  const [designation, setDesignation] = useState([]);
  const [loadingDesignation, setDesignationLoading] = useState(false);
  const [designationID, setDesignationID] = useState(props.DesignationID)
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [password, setPassword] = useState()
  const [error, setError] = useState("");
  const [name, setName] = useState(props.UserName);
  const [email, setEmail] = useState(props.Email);
  const [googlePassword, setgooglePassword] = useState(props?.AdminEmailPassword);
  
  const useStyles = makeStyles((theme) => ({
    fromWrapper: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(8),
    },
  }));
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  useEffect(() => {
  setTimeout(() => {
    
  
    if( name != undefined && checkDuplicate == true){
      console.log(props)
     var check = false;
      if(props.isAdd == true){
        
        check = CheckDuplicate.withCombination(props.dataList,['UserName','Email'],{
          
         UserName:name
 
       })
      }else{
        let array = props.dataList.filter(obj => obj.UserID !== props.UserID)
        console.log(array)
        check = CheckDuplicate.withCombination(array,['ProductCategory','UserName'],{
          
         UserName:name
 
       })

      }
      console.log(check)
      
      if(!check){
        document.querySelector('#usersGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{
        document.querySelector('#usersGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
    }
   }
  }, 500);
  }, [name ]);

  useEffect(() => {
    setTimeout(() => {

      setCheckDuplicate(true)
      if(props.isAdd == false){

        document.querySelector('#usersGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }

    }, 400);
    getDesignation();
    // getYarnQuality();
    // getYarnType();
    // getHSCode();
    if(props.UserPassword !== undefined){
    
    const hexArray = props.UserPassword.match(/.{1,2}/g) || [];
const string = hexArray.map((hex) => String.fromCharCode(parseInt(hex, 16))).join('');
props.isAdd == false? 
setPassword(string)
:
setPassword('')
    }
  }, []);


  const getDesignation = async () => {
    setDesignationLoading(true);
    DesignationService.getAll()
      .then((response) => {
        setDesignation(response.data.recordset);
        setDesignationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDesignationLoading(false);
      });
  };

  const INITIAL_FORM_STATE = {
    UserID: '',
    UserName: '',
    UserPassword: '',
    IsAccountManager: '',
    IsExportManager: '',
    ResignationDate: '',
    DesignationID: '',
    Email:'',
    GooglePassword:'',
  };

  const FORM_VALIDATION = Yup.object().shape({
    UserID: Yup.string().required('Required'),
    UserName: Yup.string().required('Required'),
    // Email: Yup.string(),
    UserPassword: Yup.string().required('Required'),
    // DesignationID: Yup.string(),
  });

  const designationChange = (e) => {
    if (e?.e?.srcElement) {
      // setDesignationID(e?.itemData?.id)
      setValue({type:'Desgination_Value', payloads:e.value})
    }
    if (e?.e?.srcElement?.id) {
      setDesignationID(e?.itemData?.id)
    }
  }

  const fields: object = { text: 'value', value: 'id' };
  const ddlFields: object = { value: 'value' };

  const classes = useStyles();

  const minDate: Date = new Date(moment().startOf('day'));

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if (type !== '') {
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      }, 100)
    }
  };

  const header = () => {
    if (showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setShowAddNewDialog(false);
    switch (dialogType) {
      case 'Designation':
        getDesignation();
        break;
      default:
        break;
    }
  }

  const footerTemplate = () => {
    if (showAddNewDialog) {
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else {
      <h3 style={{ textAlign: 'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
      return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if (showAddNewDialog) {
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          />
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );
    }
    else {
      return (
        <></>
      );
    }
  };

  return (
    <div className='users-container'>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='heading'>
            <h3 style={{ textAlign: 'center', width: '100%' }}>Users - {props.isAdd?`Add`:`Edit`}</h3>
          </div>

        </Grid>
        <div className='App' id='dialog-target'>
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate={footerTemplate}
          />
        </div>

        <Grid item xs={12}>
          <Container>
            <div className={classes.fromWrapper}>
              <Formik
                initialValues={{
                  ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={async (values) => {
                  console.log('form values: ', values);
                  return new Promise((res) => {
                    UsersService.create(values).then(
                      (response) => {
                        console.log('response: ', response);
                      }
                    );
                    setTimeout(res, 2500);
                  });
                }}
              >
                {({ values, errors, isSubmitting }) => (
                  <div>
                    {/* <pre> {JSON.stringify({ values, errors }, null, 4)} </pre> */}
                    <Grid container spacing={2}>


                     
                      <Grid item xs={6}>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='User Name'
                          name='UserName'
                          value={name}
                          change={(e)=> setName(e.value)}
                          floatLabelType='Auto'
                        />
                      </Grid>

                      <Grid item xs={6}>
                      <TextBoxComponent
                          className='e-input'
                          type='password'
                          placeholder='User Password'
                          name='UserPassword'
                          
                          // value={password}
                          focus={()=>{
                            // setPassword('')
                            setError('')
                          }}
                          onChange={(e)=>{
                            setPassword(e.value)
                                                    console.log(e)
                          }}
                          blur={()=>{
                            console.log(password)
                            // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                            const pattern =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|(?=.*[@$!%*?&]))[A-Za-z\d@$!%*?&]{8,16}$/;
                            if(pattern.test(password) ){
                              console.log('ok')
                              setError('')
                            }else{
                              console.log('not ok')
                              setPassword('')
                              setError('please strong password!')
                            }
                          }}
                          created={()=>{
                            setTimeout(() => {
                              console.log('caa')
                            }, 220);
                          }}
                          // console.error();
                          floatLabelType='Auto'
                        />
                        <p id='error'>{error}</p>
                       
                      </Grid>

                      {/* <Grid item xs={6}>
                      <TextBoxComponent
                          className='e-input'
                          type='email'
                          placeholder='User Email'
                          name='Email'
                          floatLabelType='Auto'
                          value={email}
                          onChange={(e)=>{
                            const inputValue = e.value; // Assuming e.value contains the input value
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (emailRegex.test(e.value)) {
                              setEmail(e.value);
                            } else {
                            console.log('Invalid email address');
                            // setEmail(employeeName)
                            }
                            console.log(e)
                            setCheckDuplicate(true)
                            }}
                            
                                onBlur={()=>{
                                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  if(email == '' || !emailRegex.test(email)){ 
                                const emailInput = document.querySelector('input[name="Email"]');
                                      console.log(emailInput)
                                      emailInput.focus()
                                      emailInput.style.border = '2px solid red'
                                      setTimeout(() => {
                                        emailInput.style.border = '1px solid #c8c8c8'
                                        
                                      }, 400);
                                      
                                      }
                                    }}
                        />
                      </Grid> */}
                      {/* {
                      userId == 1 && props.isAdd ?
                      <Grid item xs={6}>
                        <TextBoxComponent
                          className='e-input'
                          type='password'
                          placeholder='Email Sender Password'
                          name='GooglePassword'
                          value={googlePassword}
                        
                          floatLabelType='Auto'
                        />
                      </Grid>: props.isAdd?    <TextBoxComponent
                          className='e-input'
                          type='hidden'
                          placeholder='Email Sender Password'
                          name='GooglePassword'
                          value={'-'}
                          /> : props.isAdd == false && props.AdminEmailPassword == '-'?
                         
                          <TextBoxComponent
                          className='e-input'
                          type='hidden'
                          placeholder='Email Sender Password'
                          name='GooglePassword'
                          value={'-'}
                          />
                        
                          :
                          <Grid item xs={6}>
                         <TextBoxComponent
                          className='e-input'
                          type='password'
                          placeholder='Email Sender Password'
                          name='GooglePassword'
                          value={googlePassword}
                        
                          floatLabelType='Auto'
                        />
                          </Grid>
                        } */}
                      {/* <Grid item xs={4}> */}
                        {/* <TextBoxComponent
                          className='e-input'
                          type='checkbox'
                          placeholder='Account Manager'
                          name='IsAccountManager'
                          floatLabelType='Auto'
                        /> */}
                        {/* <div className="checkbox-container">
                          <CheckBoxComponent
                            className='e-input'
                            label='Account Manager'
                            name='IsAccountManager'
                            floatLabelType='Auto'
                          />
                        </div> */}
                      {/* </Grid> */}

                      {/* <Grid item xs={4}> */}
                        {/* <TextBoxComponent
                          className='e-input'
                          type='checkbox'
                          placeholder='Export Manager'
                          name='IsExportManager'
                          floatLabelType='Auto'
                        /> */}
                        {/* <div className="checkbox-container">
                          <CheckBoxComponent
                            className='e-input'
                            label='Export Manager'
                            name='IsExportManager'
                            floatLabelType='Auto'
                          />
                        </div>
                      </Grid> */}

                      {/* <Grid item xs={4}>
                        <DatePickerComponent
                          id='ResignationDate'
                          placeholder='Enter Resignation Date'
                          floatLabelType='Auto'
                          min={minDate}
                          format='dd-MM-yy'
                        />
                      </Grid> */}

                      {/* <Grid item xs={6}>
                        {loadingDesignation ? (
                          <div>... Loading Designation </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='DesignationID'
                              dataSource={designation}
                              fields={ddlFields}
                              placeholder='Select Designation'
                              floatLabelType='Auto'
                              highlight={true}
                              value={value.DesignationName}
                              select={designationChange}
                              change={designationChange}
                              ref={DesignationRef}
                              blur={()=>{
                                 if (designationID === undefined || DesignationRef.current.value == null || DesignationRef.current.value == '')  {
                                  DesignationRef.current.element.focus()
                                  console.log(designationID, )
                                  console.log()
                                  if(value.DesignationName != ''){
                                  let resstr = designation.some(e=>e.value ==  value.DesignationName);
                                  console.log(resstr)
                                  if(resstr === false){
                                    setValue({type:'Desgination_Error', payloads:`${value.DesignationName} Value Not Find ` })
                                    setTimeout(() => {
                                      // setDesignationError('')
                                      setValue({type:'Desgination_Error', payloads:`` })
                                    }, 800);
                                  }
                                }
                                  // if(DesignationRef.current.value !== null || DesignationRef.current.value !== ''){
    
                                  //   setProductItemTypeError(`${DesignationRef.current.value } not matched `)
                                  // }
                                }
                              }}
                            />
                             <span className="errorSpan">
                          {value.DesignationError?value.DesignationError:null}
                          </span>
                            <TextBoxComponent
                              type='hidden'
                              name='DesignationID'
                              value={designationID}
                            />
                            <span className='add-new' onClick={() => onAddNewClick('Designation')}>
                              Add New
                            </span>
                          </div>
                        )}
                      </Grid> */}


                    </Grid>
                  </div>
                )}
              </Formik>
            </div>
          </Container>
        </Grid>
        <h3 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>
      </Grid>
    </div>
  );
}
