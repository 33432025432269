import http from "./http-common";

class DueDateService {
  
  getDueDate(SalesAgentID , CustomerID , CountryID) {
    // http://localhost:5001/api/v1/SalesRecord/list/01-JAN-23/31-DEC-23/7/18/12/1/5
    // FromDate ToDate SalesAgentID  CustomerID SupplierID CurrencyID SaleStatus
    return http.get(`/DueDate/list/${SalesAgentID}/${CustomerID}/${CountryID}`);
  }
  
}

export default new DueDateService();
