import http from '../../services/http-common';

class PIDuplicateService {
    getPIByPiid(id) {
    return http.get(`/PIDuplicate/pi/by/${id}`);
  }
  getPIDropdownList() {
    return http.get(`/PIDuplicate/duplicate/PIDropdown/list`);
  }
  addPIDuplicate(data) {
    return http.post(`/PIDuplicate/duplicate/add`, data);
  }
  getBalanceUnAllocatedAmount(date, currencyID, customerID) {
    return http.get(`/PIDuplicate/balanceUnAllocatedAmount/${date}/${currencyID}/${customerID}`);
  }
  addTransferUnAllocatedPayment(data) {
    return http.post(`/PIDuplicate/add`,data);
  }
  }
  export default new PIDuplicateService();
