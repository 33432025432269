import React, { useEffect, useState, useRef, useReducer } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
// import './PI.scss';
// import PIGrid from './pi-grid'
import HardDelete from '../hard-delete/hard-delete'
 
import './pi-view.scss'
import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CustomerService from '../../services/customer.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import PIService from '../../services/PI.service';
import EmployeeService from '../../services/employee.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { CheckBox } from '@syncfusion/ej2-react-buttons';
import TransferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';
import CurrencyService from '../../services/currency.service';
import UserService from '../../services/users.service'
import MessageService from '../../services/message.service'

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
  actionBegin,
  // columnSelectionComplete,
} from '@syncfusion/ej2-react-grids';
import { set } from 'date-fns';
// import { format } from 'date-fns';
// import { Receipt } from '@material-ui/icons';
// import is from 'date-fns/esm/locale/is/index.js';

let paymentTypeElem: HTMLElement;
let paymentTypeObj: AutoComplete;
let salesAgentStatusElem: HTMLElement;
let salesAgentStatusObj: AutoComplete;
let designationNameElem: HTMLElement;
let designationNameObj: AutoComplete;
let paymentVerificationStatusElem: HTMLElement;
let paymentVerificationStatusObj: AutoComplete;
let cRMStatusElem: HTMLElement;
let cRMStatusObj: AutoComplete;
var piRefValue = ''
var saleCheck = 0

const complexReducer = (state, action) => {
    console.log(state)
    console.log(action)
  switch (action.type) {
    case 'PIID':
      return {...state,PIID: action.PIID  };
      break;
    case 'PINo':
      return {...state,PINo: action.PINo  };
      break; 
    case 'PIReferenceNo':
      return {...state, PIReferenceNo: action.PIReferenceNo};
      break;
    case 'PIDate':
      return {...state, PIDate: action.PIDate};
      break;
    case 'SupplierName':
      return {
        ...state,
       
        SupplierName:action.SupplierName,
        SupplierID:action.SupplierID
       };
       break;
    case 'SupplierDataSource':
      return {
      
        ...state,
        LoadingSupplier:action.LoadingSupplier,
        SupplierDataSource: action.SupplierDataSource,
       };
       break;
       case 'CustomerName':
        return {
          ...state,
         
          CustomerName:action.CustomerName,
          CustomerID:action.CustomerID
          

         };
       break;
    case 'CustomerDataSource':
      return {
        ...state,
       
        LoadingCustomer:action.LoadingCustomer,
        CustomerDataSource: action.CustomerDataSource,
       };
       break;
    case 'EmployeeDataSource':
      return {
        ...state,
       
        LoadingEmployee:action.LoadingEmployee,
        EmployeeDataSource: action.EmployeeDataSource,
       };
       break;
    case 'CountryCityPortDataSource':
      return {
        ...state,
       
        LoadingCountryCityPort:action.LoadingCountryCityPort,
        CountryCityPortDataSource: action.CountryCityPortDataSource,
       };
       break;
    case 'ShipmentTypeDataSource':
      return {
        ...state,
       
        LoadingShipmentType:action.LoadingShipmentType,
        ShipmentTypeDataSource: action.ShipmentTypeDataSource,
       };
       break;
    case 'CurrencyNameDataSource':
      return {
        ...state,
       
        LoadingCurrencyName:action.LoadingCurrencyName,
        CurrencyNameDataSource: action.CurrencyNameDataSource,
       };
       break;
    case 'PurchaseSourceDataSource':
      return {
        ...state,
       
        LoadingPurchaseSource:action.LoadingPurchaseSource,
        PurchaseSourceDataSource: action.PurchaseSourceDataSource,
       };
       break;
    case 'TradeBoardLetterStatusDataSource':
      return {
        ...state,
       
        LoadingTradeBoardLetterStatus:action.LoadingTradeBoardLetterStatus,
        TradeBoardLetterStatusDataSource: action.TradeBoardLetterStatusDataSource,
       };
       break;
       case 'EmployeeName':
        return {
          ...state,
         
          EmployeeName:action.EmployeeName,
          EmployeeID:action.EmployeeID
         };
         break;
       case 'ConsigneeName':
        return {
          ...state,
          ConsigneeName:action.ConsigneeName
         };
         break;
       case 'ConfirmConsigneeName':
        return {
          ...state,
          ConfirmConsigneeName:action.ConfirmConsigneeName
         };
         break;
       case 'CountryCityPort':
        return {
          ...state,
          CountryCityPort:action.CountryCityPort,
          CountryCityPortID:action.CountryCityPortID
         };
         break;
       case 'ShipmentType':
        return {
          ...state,
          ShipmentType:action.ShipmentType,
          ShipmentTypeID:action.ShipmentTypeID
         };
         break;
       case 'CurrencyName':
        return {
          ...state,
          CurrencyName:action.CurrencyName,
          CurrencyID:action.CurrencyID
         };
         break;
       case 'CommissionAmountPerUnit':
        return {
          ...state,
          CommissionAmountPerUnit:action.CommissionAmountPerUnit
          
         };
         break;
       case 'ApproveCommissionAmountPerUnit':
        return {
          ...state,
          ApproveCommissionAmountPerUnit:action.ApproveCommissionAmountPerUnit
          
         };
         break;
       case 'AbsoluteCommissionAmount':
        return {
          ...state,
          AbsoluteCommissionAmount:action.AbsoluteCommissionAmount
          
         };
         break;
       case 'ApproveAbsoluteCommissionAmount':
        return {
          ...state,
          ApproveAbsoluteCommissionAmount:action.ApproveAbsoluteCommissionAmount
          
         };
         break;
       case 'BookAmount':
        return {
          ...state,
          BookAmount:action.BookAmount
          
         };
       case 'BookAmt':
        return {
          ...state,
          BookAmt:action.BookAmt
          
         };
         break;
       case 'PrimaryCommissionPercentOnAdjustment':
        return {
          ...state,
          PrimaryCommissionPercentOnAdjustment:action.PrimaryCommissionPercentOnAdjustment
          
         };
         break;
       case 'ApprovePrimaryCommissionPercentOnAdjustment':
        return {
          ...state,
          ApprovePrimaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment,
          ApproveSecondaryCommissionPercentOnAdjustment:action.ApprovePrimaryCommissionPercentOnAdjustment ? false : true
          
         };
         break;
       case 'SecondaryCommissionPercentOnAdjustment':
        return {
          ...state,
          SecondaryCommissionPercentOnAdjustment:action.SecondaryCommissionPercentOnAdjustment
          
         };
         break;
       case 'ApproveSecondaryCommissionPercentOnAdjustment':
        return {
          ...state,
          ApproveSecondaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment,
          ApprovePrimaryCommissionPercentOnAdjustment:action.ApproveSecondaryCommissionPercentOnAdjustment ? false: true,
         };
         break;
       case 'AdjustmentAmount':
        return {
          ...state,
          AdjustmentAmount:action.AdjustmentAmount
         };
         break;
       case 'BasePriceAgent':
        return {
          ...state,
          BasePriceAgent:action.BasePriceAgent
         };
       case 'BasePriceCompany':
        return {
          ...state,
          BasePriceCompany:action.BasePriceCompany
         };
       case 'ShowBasePriceCompany':
        return {
          ...state,
          IsShowBasePriceCompany:action.IsShowBasePriceCompany
         };
         break;
       case 'PurchaseSource':
        return {
          ...state,
          PurchaseSource:action.PurchaseSource,
          PurchaseSourceID:action.PurchaseSourceID
         };
         break;
       case 'TradeBoardLetterStatus':
        return {
          ...state,
          TradeBoardLetterStatus:action.TradeBoardLetterStatus,
          TradeBoardLetterStatusID:action.TradeBoardLetterStatusID
         };
         break;
       case 'Remarks':
        return {
          ...state,
          Remarks:action.Remarks,
         
         };
         break;
       case 'PaymentType':
        return {
          ...state,
          PaymentType:action.PaymentType,
          PaymentTypeID:action.PaymentTypeID,
         
         };
         break;
       case 'PaymentTypeDataSource':
        return {
          ...state,
          PaymentTypeDataSource:action.PaymentTypeDataSource
         };
         break;
      
       case 'ReceiptAmount':
        return {
          ...state,
          ReceiptAmount:action.ReceiptAmount
         };
         break;
         case 'SalesAgentStatus':
          return {
            ...state,
            SalesAgentStatus:action.SalesAgentStatus,
            SalesAgentStatusID:action.SalesAgentStatusID,
           
           };
           break;
         case 'SalesAgentStatusDataSource':
          return {
            ...state,
            SalesAgentStatusDataSource:action.SalesAgentStatusDataSource
           };
           break;
         case 'IsCRMDesignation':
          return {
            ...state,
            IsCRMDesignation:action.IsCRMDesignation
           };
         case 'IsSupervisorDesignation':
          return {
            ...state,
            IsSupervisorDesignation:action.IsSupervisorDesignation
           };
           break;
         case 'PaymentVerificationStatus':
          return {
            ...state,
            PaymentVerificationStatus:action.PaymentVerificationStatus
           };
           break;
         case 'DesignationNameDataSource':
          return {
            ...state,
            DesignationNameDataSource:action.DesignationNameDataSource
           };
           break;
         case 'CRMStatusDataSource':
          return {
            ...state,
            CRMStatusDataSource:action.CRMStatusDataSource
           };
           break;
         case 'ETA':
          return {
            ...state,
            ETA:action.ETA
           };
           break;
         case 'ETD':
          return {
            ...state,
            ETD:action.ETD
           };
           break;
           case 'DesignationName':
            return {
              ...state,
              DesignationName:action.DesignationName,
              DesignationID:action.DesignationID,
             
             };
             break;
           case 'CRMStatus':
            return {
              ...state,
              CRMStatus:action.CRMStatus,
              CRMStatusID:action.CRMStatusID,
             
             };
           case 'ReceiptMax':
            return {
              ...state,
              ReceiptMax:action.ReceiptMax,
              
             
             };
             break;
           case 'VoyageNo':
            return {
              ...state,
              VoyageNo:action.VoyageNo,
              
             
             };
             break;
             case 'BalanceUnAllocatedAmount':
        return {
          ...state,
         
          BalanceUnAllocatedAmount:action.BalanceUnAllocatedAmount,
      
        };
       break;
           case 'VesselName':
            return {
              ...state,
              VesselName:action.VesselName,
              
             
             };
             break;
           case 'CurrencySymbol':
            return {
              ...state,
              CurrencySymbol:action.CurrencySymbol,
              
             
             };
             break;
           case 'PaymentVerificationStatusDate':
            return {
              ...state,
              PaymentVerificationStatusDate:action.PaymentVerificationStatusDate,
              
             
             };
             break;
           case 'PaymentDetailDataSource':
            return {
              ...state,
              PaymentDetailDataSource:action.PaymentDetailDataSource,
              
             
             };
             break;
           case 'InitialPaymentPercent':
            return {
              ...state,
              InitialPaymentPercent:action.InitialPaymentPercent,
              
             
             };
             break;
           case 'InitialPaymentToleranceAmount':
            return {
              ...state,
              InitialPaymentToleranceAmount:action.InitialPaymentToleranceAmount,
              
             
             };
             break;
           case 'InitialPaymentToleranceAmount':
            return {
              ...state,
              InitialPaymentToleranceAmount:action.InitialPaymentToleranceAmount,
              
             
             };
           case 'GetInitialPaymentToleranceAmount':
            return {
              ...state,
              GetInitialPaymentToleranceAmount:action.GetInitialPaymentToleranceAmount,
              
             
             };
             break;
    default:
      return state;
  }
};
// let dataSoureGrid =[]
var storeInitialPaymentToleranceAmount = 0
var balanceUnAllocatedAmount = 0;
var PaymentTypeName = '';
var CheckReceiptMax =  0
var  IsMaxReceiptAmt = false
var  IsMaxShortAmt = false
var GenerateBookAmount = 0;
var temAmt = 0
var IsAddActionType =  false
var IsEditActionType =  false
var tempReceiptDate = false
var isCrmStatusEdit = false
let messageGrid: Grid;
let ProformaInvoiceDetailGrid: Grid
var  whenDestory = []
var holdBookAmt = 0
var initialPaymentToleranceAmount = 0
export default function PIAdd(props) {
var  isCrm =    props.type == "CRM" ? true : false;
  console.log('props',props)

  let PaymentDetailDataSourceTry = []

  const [state, dispatch] = useReducer(complexReducer, { 
    PIID:props.PIID,
    PIReferenceNo:props.PIReferenceNo ? props.PIReferenceNo : '',
     PINo: props.PINo ? props.PINo :'-',
     PIDate:new Date(moment()),
     LoadingCustomer:true,
     CustomerDataSource: [],
    LoadingSupplier:true,
    SupplierDataSource: [],
    LoadingEmployee:true,
    EmployeeDataSource: [],
    LoadingCountryCityPort:true,
    CountryCityPortDataSource: [],
    LoadingShipmentType:true,
    ShipmentTypeDataSource: [],
    LoadingCurrencyName:true,
    CurrencyNameDataSource: [],
    LoadingPurchaseSource:true,
    PurchaseSourceDataSource: [],
    LoadingTradeBoardLetterStatus:true,
    TradeBoardLetterStatusDataSource: [],
    PaymentVerificationStatusDataSource:[
      {id:1, value: 'Pending Verification'},
      {id:2, value: 'Verified'},
      {id:3, value: 'InValid'}
    ],
    SupplierRef: useRef(null),
    CustomerRef: useRef(null),
    EmployeeRef: useRef(null),
    CountryCityPortRef: useRef(null),
    ShipmentTypeRef: useRef(null),
    CurrencyNameRef: useRef(null),
    PurchaseSourceRef: useRef(null),
    TradeBoardLetterStatusRef: useRef(null),
    SupplierName: props.SupplierName ? props.SupplierName :'',
    CustomerName: props.CustomerName ? props.CustomerName :'',
    EmployeeName:props.SalesAgentName ? props.SalesAgentName :'',
    SupplierID:props.SupplierID ? props.SupplierID :'',
    CustomerID:props.CustomerID ? props.CustomerID :'',
    EmployeeID:props.SalesAgentID ? props.SalesAgentID :'',
    ConsigneeName: props.ConsigneeName ? props.ConsigneeName :'-',
    ConfirmConsigneeName: props.ConfirmConsigneeName == undefined  ?  false : props.ConfirmConsigneeName,
    CountryCityPort:props.CountryCityPort ? props.CountryCityPort : '',
    CountryCityPortID:props.CountryCityPortID ? props.CountryCityPortID : '',
    ShipmentType: props.ShipmentType ? props.ShipmentType :'CNF',
    ShipmentTypeID: props.ShipmentTypeID  ? props.ShipmentTypeID : '1',
    CurrencyName: props.CurrencyName ? props.CurrencyName :'',
    CurrencySymbol: props.CurrencySymbol ? props.CurrencySymbol :'',
    CurrencyID:props.CurrencyID ? props.CurrencyID : '',
    CommissionAmountPerUnit: props.CommissionAmountPerUnit ? props.CommissionAmountPerUnit : '0',
    ApproveCommissionAmountPerUnit:props.ApproveCommissionAmountPerUnit ? props.ApproveCommissionAmountPerUnit :false,
    AbsoluteCommissionAmount:props.AbsoluteCommissionAmount ? props.AbsoluteCommissionAmount : '0',
    ApproveAbsoluteCommissionAmount:props.ApproveAbsoluteCommissionAmount? props.ApproveAbsoluteCommissionAmount :false,
    BookAmount: props.BookAmount ?props.BookAmount :'0',
    PrimaryCommissionPercentOnAdjustment:props.PrimaryCommissionPercentOnAdjustment ? props.PrimaryCommissionPercentOnAdjustment:0,
    ApprovePrimaryCommissionPercentOnAdjustment:false,
    SecondaryCommissionPercentOnAdjustment: props.SecondaryCommissionPercentOnAdjustment ? props.SecondaryCommissionPercentOnAdjustment : '0',
    ApproveSecondaryCommissionPercentOnAdjustment: props.ApproveSecondaryCommissionPercentOnAdjustment ? props.ApproveSecondaryCommissionPercentOnAdjustment :false,
    AdjustmentAmount:props.AdjustmentAmount ? props.AdjustmentAmount :0,
    BasePriceAgent:props.BasePriceAgent ? props.BasePriceAgent :0,
    BasePriceCompany: props.BasePriceCompany ? props.BasePriceCompany :0,
    IsShowBasePriceCompany:false,
    PurchaseSource: props.PurchaseSource ? props.PurchaseSource :'',
    PurchaseSourceID: props.PurchaseSourceID ? props.PurchaseSourceID :'',
    TradeBoardLetterStatus:props.TradeBoardLetterStatus ? props.TradeBoardLetterStatus :'',
    TradeBoardLetterStatusID: props.TradeBoardLetterStatusID ? props.TradeBoardLetterStatusID : '',
    Remarks:props.Remarks ? props.Remarks :'-',
    VesselName: props.VesselName ? props.VesselName :'-',
    VoyageNo:props.VoyageNo ? props.VoyageNo :'-',
    ETA: props.ETA != null? new Date(props.ETA) : '',
    ETD: props.ETD  != null? new Date(props.ETD) :  '',
    PaymentDetailDataSource:[],
    PaymentType:'',
    PaymentTypeID:'',
    PaymentTypeDataSource:[],
    ShortAmountRef:useRef(null),
    SalesAgentStatus:props.SalesAgentStatus  ? props.SalesAgentStatus :'',
    SalesAgentStatusID:props.SalesAgentStatusID ? props.SalesAgentStatusID :'',
    SalesAgentStatusDataSource:[],
    SalesAgentStatusDate: props.SalesAgentStatusDate  ?  props.SalesAgentStatusDate  : new Date(moment()),
    IsCRMDesignation:props.type == "CRM" ? true : false,
    IsSupervisorDesignation:props.type == "Supervisor" ? true : false,
    ShortAmount: props.ShortAmount ? props.ShortAmount : 0,
    PaymentVerificationStatus:false,
    PaymentVerificationStatusID:1,
    // PaymentVerificationStatusDate:new Date(moment()),
    PaymentVerificationStatusDate:null,
    DesignationNameDataSource:[],
    DesignationName:'',
    DesignationID:null,
    CRMStatusDataSource:[],
    CRMStatus:props.CRMStatus ? props.CRMStatus :'',
    CRMStatusID:props.CRMStatusID ? props.CRMStatusID : '',
    CRMStatusDate: props.CRMStatusDate ? props.CRMStatusDate : isCrm ? new Date(moment()) :  null,
    BalanceUnAllocatedAmount:null,
    ReceiptMax:100,
    InitialPaymentPercent: 0 ,
    InitialPaymentToleranceAmount: 0,
    GetInitialPaymentToleranceAmount: 0,
    BookAmt:props.BookAmount ? props.BookAmount : 0

    });
console.log(state)
  const [irtiza, setirtiza] = useState(10);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [piData, setPiData] = useState([]);
  const [dialogType, setDialogType] = useState('');
 
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  const [dataSoureGrid, setDataSoureGrid] = useState([]);
  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();
  const [message, setMessage] = useState([]);

let receiptRef = useRef(null)
let receiptDateRef = useRef(null)

useEffect(() => {
  // getEmployeeName( props.CustomerID )
  // getCustomerNameForConsigneeName(props.CustomerID )
  // getCountryCityPortUsingCustomerID(props.CustomerID )
  // getCurrency(props.CustomerID )
}, []);
    useEffect(() => {
      
      return () => {
        console.log('irtiza hassan rajput',whenDestory)
        
        let receiptArray = whenDestory.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ReceiptAmount }}).filter(item => item !== undefined)
        let shortArray = whenDestory.map(res=> { if(res.PaymentVerificationStatus != 'INVALID'){ return res.ShortAmount }}).filter(item => item !== undefined)
        console.log(receiptArray)
        console.log(shortArray)
        const sumValue = receiptArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const shortArrayValue = shortArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        // let valueBookAmt = document.getElementById('BookAmount').value;
        var valueBookAmt =  state.BookAmount;
        console.log(sumValue)
        
        var sumGenerateBookAmout = sumValue - shortArrayValue
        console.log(sumGenerateBookAmout)
        console.log(holdBookAmt)
        let bookAmountGEN =   initialPaymentToleranceAmount - holdBookAmt; 
        console.log(initialPaymentToleranceAmount)
        console.log(bookAmountGEN)
        let resultOfSums = holdBookAmt == sumGenerateBookAmout || sumGenerateBookAmout  ==  bookAmountGEN;
        console.log(resultOfSums)
        // props.popup(resultOfSums)
          //  if(resultOfSums ==  true && props.type == "CRM"){
            //  console.log(resultOfSums)
            //  let isHaveRelease = whenDestory.some((e)=>{e.SalesAgentStatus == "Release"  })
            //  let isHaveSurrenderBL = whenDestory.some((e)=>{e.SalesAgentStatus == "Surrender BL" })
            //  if(i)
            //  console.log(whenDestory)
             
            //  console.log(props.type)

            //  props.popup('Release')
          // }else{
          //    props.popup(resultOfSums)

          //  }
        // let generateBookAmount = 0
        // if (toString(valueBookAmt).includes(',')) {
        // console.log(valueBookAmt)
        //   // Remove the comma and convert it to a number
        //    let resultOfSums = Number(toString(valueBookAmt).replace(/,/g, '')) == sumGenerateBookAmout;
        //    console.log(resultOfSums)

        //   // generateBookAmount = Number(valueBookAmt.replace(/,/g, '')) - sumGenerateBookAmout;
        // } else {
        //   // Convert the string to a number and subtract
        //    let resultOfSums = Number(valueBookAmt) == sumGenerateBookAmout;
        //    console.log(resultOfSums)
        //   // generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
        // }
        // let generateBookAmount = Number(valueBookAmt) - sumGenerateBookAmout;
      //   console.log(shortArrayValue)
      //   console.log(generateBookAmount)
      // console.log( generateBookAmount -  shortArrayValue)
        // CheckReceiptMax = generateBookAmount  -  shortArrayValue ;
        // GenerateBookAmount =  args.ReceiptAmount ? args.ReceiptAmount : 0;
        // console.log(ProformaInvoiceDetailGrid.dataSource)
        // }
        // temAmt = args.ReceiptAmount ==  undefined ? 0 : args.ReceiptAmount
        // if(IsEditActionType == true){
//         var checkRecp = 0 ;
// console.log(shortArrayValue)
//         console.log(generateBookAmount)
// if (shortArrayValue >= 0) {
// // If shortArrayValue is non-negative, add it to generateBookAmount
// checkRecp = generateBookAmount + Math.abs(shortArrayValue);
// } else {
// // If shortArrayValue is negative, subtract its absolute value from generateBookAmount
// console.log(checkRecp)
// checkRecp = generateBookAmount + Math.abs(shortArrayValue);
// }
      };
    }, []);

    useEffect(() => {
      console.log(state.SupplierID)
      console.log(state.CurrencyID)
      if(state.SupplierID != '' && state.EmployeeID != ''){
        // getPrimaryCommissionPercentOnAdjustment(state.SupplierID ,state.EmployeeID)
      }
      if(state.SupplierID != ''  && state.EmployeeID != ''){
        console.log(state.SupplierID)
        console.log(state.CurrencyID)
        // getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,state.SupplierID ,state.EmployeeID)
      // return  getCommissionAmountPerUnit(1 ,state.SupplierID ,state.EmployeeID)

      }
    }, [state.SupplierID ,state.CurrencyID ,state.EmployeeID]);
    useEffect(()=>{
      setTimeout(()=>{
        console.log(state.InitialPaymentToleranceAmount)
        return storeInitialPaymentToleranceAmount =  state.InitialPaymentToleranceAmount;
      },1000)
    },[state.InitialPaymentToleranceAmount])
    // useEffect(() => {
    //   console.log(state.SupplierID)
    //   console.log(state.CurrencyID)
    //   if(state.CurrencyID != '' && state.CustomerID != '' && state.PIDate != ''){
    //     getBalanceUnAllocatedAmount(state.PIDate,state.CurrencyID,state.CustomerID)
        
    //   }
      
    // }, [state.CurrencyID ,state.CustomerID, state.PIDate]);


  const textBoxChange = (e) => {
    console.log(e)
    dispatch({ type: e.target.name , [e.target.name]: e?.value })
   
};

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'SupplierName':
        dispatch({ type: 'SupplierName' ,SupplierName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'SupplierName' , 
            SupplierName: e?.itemData?.value ? e?.itemData?.value : '',
            SupplierID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                  // getCommissionAmountPerUnit(1,e?.itemData?.id,state.EmployeeID)
                  // getSecondaryCommissionPercentOnAdjustment(state.CurrencyID ,e?.itemData?.id ,state.EmployeeID)
                    
                  break;
      case 'CustomerName':
        dispatch({ type: 'CustomerName' ,CustomerName: e?.itemData?.value ? e?.itemData?.value : ''})

        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CustomerName' , 
            CustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            CustomerID: e?.itemData?.id ? e?.itemData?.id : '',
           
                  })
                  // EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
                  // EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
                  // getEmployeeName( e?.itemData?.id )
                  // getCustomerNameForConsigneeName(e?.itemData?.id )
                  // getCountryCityPortUsingCustomerID(e?.itemData?.id )
                  // getCurrency(e?.itemData?.id )
                }
                  break;
      case 'EmployeeName':
        dispatch({ type: 'EmployeeName' ,EmployeeName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'EmployeeName' , 
            EmployeeName: e?.itemData?.value ? e?.itemData?.value : '',
            EmployeeID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'CountryCityPort':
        dispatch({ type: 'CountryCityPort' ,CountryCityPort: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CountryCityPort' , 
            CountryCityPort: e?.itemData?.value ? e?.itemData?.value : '',
            CountryCityPortID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'ShipmentType':
        dispatch({ type: 'ShipmentType' ,ShipmentType: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'ShipmentType' , 
            ShipmentType: e?.itemData?.value ? e?.itemData?.value : '',
            ShipmentTypeID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      case 'CurrencyName':
        dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CurrencyName' , 
            CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
            CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                  // getCommissionAmountPerUnit(1,state.SupplierID,state.EmployeeID)
                  // getSecondaryCommissionPercentOnAdjustment(e?.itemData?.id ,state.SupplierID,state.EmployeeID)
                  setTimeout(() => {
                    CurrencyService.getCurrencySymbolForDD().then(res=>{
                        let symbolObj = res.data.recordset.filter(ress=>{ return ress.id == e?.itemData?.id});
                        console.log(symbolObj)
                        dispatch({type:`CurrencySymbol` , CurrencySymbol: symbolObj[0].value})
                      })
                    }, 200);
                  setTimeout(()=>{
                    PIService.getInitialPaymentPercent(state.CustomerID , e?.itemData?.id).then(res=>{
                        console.log(res)
                    dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: res.data.recordset[0].InitialPaymentPercent ?  res.data.recordset[0].InitialPaymentPercent : 0})
                    dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount: res.data.recordset[0].InitialPaymentToleranceAmount ?  res.data.recordset[0].InitialPaymentToleranceAmount : 0})
                    initialPaymentToleranceAmount = res.data.recordset[0].FinalPaymentToleranceAmount;
                    dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: 0})
                  }).catch((e)=>{
                    dispatch({type:`InitialPaymentToleranceAmount` , InitialPaymentToleranceAmount:  0})
                    dispatch({type:`InitialPaymentPercent` , InitialPaymentPercent: 0})
                    dispatch({type:`GetInitialPaymentToleranceAmount` , GetInitialPaymentToleranceAmount: 0})

                    })
                  },1000)
                    break;
      case 'PurchaseSource':
        dispatch({ type: 'PurchaseSource' ,PurchaseSource: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'PurchaseSource' , 
            PurchaseSource: e?.itemData?.value ? e?.itemData?.value : '',
            PurchaseSourceID: e?.itemData?.id ? e?.itemData?.id : ''
                  })
                  if(e?.itemData?.value ==  'AUCTION'){
                    dispatch({ 
                      type: 'CommissionAmountPerUnit' , 
                      CommissionAmountPerUnit: 0
                    })
                  }
                }
                    break;
      case 'TradeBoardLetterStatus':
        dispatch({ type: 'TradeBoardLetterStatus' ,TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'TradeBoardLetterStatus' , 
            TradeBoardLetterStatus: e?.itemData?.value ? e?.itemData?.value : '',
            TradeBoardLetterStatusID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      
            default:
        return state;
    }
   
   
};
const userId = SecurityService.getLoggedInUserId();
// const [isCrm, isCrm] = useState(false);
  var printByUserID2 = 0
     useEffect(() => {
      if(props.PIID != ''){
          setTimeout(() => {
            getPaymentDetailForGrid(props.PIID)
            getMassage(props.PiID)
            
          }, 500);
      }
      MessageService.getEmployeeByUserID(userId).then((res)=>{
        console.log(userId)
        console.log(res)
      printByUserID2 = res.data.recordset[0].EmployeeID;
     }).catch((e)=>{
      console.log(e)
     })
     }, []);
     const getMassage = async (id) => {
      console.log(props.PiID)
      console.log(printByUserID2)
      MessageService.getMessageList(id ,props.PiID == 0 ? printByUserID2 : 0)
        .then((response) => {
          console.log('get all yarn count', response)
          setMessage(response.data.recordset);
        })
        .catch((e) => {
          console.log(e);
        });
    };
  
const [pPaymentDetailDataSource, setpPaymentDetailDataSource] = useState([]);
  const getPaymentDetailForGrid = async (id) => {
    PIService.getPaymentDetail(id)
      .then((response) => {
        console.log('get Payment Detail response', response.data.recordset);
        whenDestory = response.data.recordset;
        setpPaymentDetailDataSource(response.data.recordset)
  // return       dispatch({ type: `PaymentDetailDataSource` ,  PaymentDetailDataSource:[...response.data.recordset]})
  // setDataSoureGrid( response.data.recordset)
  // ProformaInvoiceDetailGrid.dataSource = [...response.data.recordset]
      })
      .catch((e) => {
        console.log(e);
        // dispatch({ type: `PaymentDetailDataSource`  , PaymentDetailDataSource:[]})
        // setDataSoureGrid( [])
      });
  };
  const checkIsCRMDesignation = async (type: string) => {
    // PIService.getAllEmployee()
    UserService.getAll()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        console.log(userId)
        // let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId && val.DesignationID == 2).length == 1 ? true : false;
        let isCRMDesignation = response.data.recordset.filter((val)=> val.UserID == userId).length == 1 ? true : false;
        console.log(isCRMDesignation)       
        // dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:props.isAdd ? false : isCRMDesignation})
        isCrm = isCRMDesignation;
        dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:isCRMDesignation})
              })
      .catch((e) => {
        console.log(e);
        isCrm = false;

        dispatch({ type: `IsCRMDesignation` ,  IsCRMDesignation:false})
      });
  };
  
  const checkIsSupervisorDesignation = async () => {
    // PIService.getAllEmployee()
    UserService.getAll()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        console.log(userId)
        let isSupervisorDesignation = response.data.recordset.filter((val)=> val.UserID == userId ).length == 1 ? true : false;
        console.log(isSupervisorDesignation)       
        // dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:props.isAdd ? false : isSupervisorDesignation})
        dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:isSupervisorDesignation})
              })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `IsSupervisorDesignation` ,  IsSupervisorDesignation:false})
      });
  };
  
useEffect(() => {
  setTimeout(() => {
    document.getElementById('_dialog-content').scrollTop = '0'
  }, 2000);
}, []);
  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      // case 'ProductCategory':
      //   // getProductCategory();
      // break;
      // case 'ProductDesc':
      //   // getProductDesc();
      // break;
      // case 'UnitOfMeasurement':
      //   // getUOMs();
      // break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<p style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</p>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }


let productDescRef = useRef(null)
      let uomRef = useRef(null)
      
  const minDate: Date = new Date(moment().startOf('day'));
  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
    mode: 'Normal',
    // allowEditing: SecurityService.canUserAccess('ProductEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
  }
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    {
      text: "Delete", tooltipText: "Delete", id: "ProformaInvoiceDetailGrid_delete", disabled:false
    },
   "Update",
    "Cancel",
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];
   const toolbarClick = (args: ClickEventArgs) => {
    console.log(args)
    if (ProformaInvoiceDetailGrid && args.item.properties.id === "ProformaInvoiceDetailGrid_add") {
      IsAddActionType =  true;
    }
    if (ProformaInvoiceDetailGrid && args.item.properties.id === "ProformaInvoiceDetailGrid_edit") {
      IsEditActionType =  true;
    }
    if (ProformaInvoiceDetailGrid && args.item.properties.id === 'ProformaInvoiceDetailGrid_delete') {
      console.log('toolbar Delete');
      if (ProformaInvoiceDetailGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', ProformaInvoiceDetailGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProformaInvoice_PaymentDetail`,
          ColumnName:`ProformaInvoicePaymentDetailID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        console.log('delete: ', ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`ProformaInvoice_PaymentDetail`,
          ColumnName:`ProformaInvoicePaymentDetailID`,
          RowID:ProformaInvoiceDetailGrid.getSelectedRecords()[0]?.ProformaInvoicePaymentDetailID,
          SelectedRow:true,
          SelectedRowData:ProformaInvoiceDetailGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    }
  }
  const sortingOptions: SortSettingsModel = {
    // columns: [{ field: 'ProformaInvoicePaymentDetailID', direction: 'Descending' }]
    columns: [{ field: 'ProformaInvoicePaymentDetailID', direction: 'Ascending' }]
  }

  
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
  const [salesAgentStatus, setSalesAgentStatus] = useState('');
  const [salesAgentStatusID, setSalesAgentStatusID] = useState('');
 
  const [crmStatusID, setCrmStatusID] = useState(null);
  const [crmStatus, setCrmStatus] = useState(null);
  const [crmStatusDate, setCrmStatusDate] = useState(null);
  const actionComplete = (args: DialogEditEventArgs) => {
    console.log("data: ", args);
    console.log("data: ", args);
    console.log("data: ",  salesAgentStatusObj )
    if (args.form) {                     
      console.log("PaymentVerificationStatus: ", state.PaymentVerificationStatusDate);
      console.log("PaymentVerificationStatusID: ", state.PaymentVerificationStatusID);
      console.log("data: ", state);
      console.log("data: ", args);
      console.log("data: ",  crmStatusID  )
      console.log("data: ",  crmStatus  )
      console.log("data: ",  crmStatusDate  )
      console.log("data: ",  salesAgentStatusID  )
      console.log("data: ",  salesAgentStatus  )
      // if (args.requestType === "beginEdit" || args.requestType === "add"  &&  state.SalesAgentStatusID  != '' &&  state.SalesAgentStatusID  != undefined &&  state.SalesAgentStatusID  != null) {
      if (args.requestType === "beginEdit" || args.requestType === "add"  ) {
        console.log(props.type)
        if(props.type != 'CRM'){
        console.log('attaching blur event complete', document.getElementById('ShortAmount'));
        document
          .getElementById("ShortAmount")
          ?.addEventListener("blur", (e) => {
          
            if (
              saleCheck != 0
          ) {
            console.log('crm not blur')
            setTimeout(() => ProformaInvoiceDetailGrid?.endEdit(), 200);
          
          }
          });
      }else if(props.type == 'CRM'){
        
        document
          .getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus")
          ?.addEventListener("blur", (e) => {
          
            if (
              saleCheck != 0
          ) {
            console.log('crm blur')
            setTimeout(() => ProformaInvoiceDetailGrid?.endEdit(), 200);
          
          }
          });
      }
      }
      console.log("data: ", args);
    
    // if(args.requestType ==  'save' || args.requestType == 'cancel'){
      //   setTimeout(() => {
        
        //   return  getPaymentDetailForGrid(1006)
        //   }, 1000);
    // }
    if(args.requestType ==  'save'){ 
      
      setTimeout(() => {
        dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:null})
        
        // document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        document.getElementById('error-1').style.display ='none'
        document.getElementById('error-2').style.display ='none'
      }, 200);
      setTimeout(() => {
        // ProformaInvoiceDetailGrid.refresh()
        console.log(state.PIID)
        if(state.PIID != undefined){

          getPaymentDetailForGrid(state.PIID)
        }
        
        // 0document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 1000);
    }
      
    if (args.requestType === 'save' && args.action ==  'add') {
      IsAddActionType = false
      console.log(args)
      const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const curtDate = `${year}-${month}-${day}`;
//       const inputDate = state.PaymentVerificationStatusDate;
// const parts = inputDate.split('/');
// const formattedDateAddDash = parts[2] + '-' + parts[1] + '-' + parts[0];

// console.log(formattedDateAddDash);
      let data = {
      PIID:state.PIID,
      PINo:state.PINo,	
      PIDate:moment(state.PIDate).format('DD-MM-YYYY'),	
      SupplierID:state.SupplierID,
      CommissionAmountPerUnit:state.CommissionAmountPerUnit,	
      ApproveCommissionAmountPerUnit:state.ApproveCommissionAmountPerUnit,	
      AbsoluteCommissionAmount:state.AbsoluteCommissionAmount,	
      ApproveAbsoluteCommissionAmount:state.ApproveAbsoluteCommissionAmount,	
      CustomerID:state.CustomerID,	
      SalesAgentID:state.EmployeeID,	
      ConsigneeName:state.ConsigneeName,	
      ConfirmConsigneeName:state.ConfirmConsigneeName,
      CountryCityPortID:state.CountryCityPortID,	
      ShipmentTypeID:state.ShipmentTypeID,	
      BookAmount:state.BookAmount,	
      CurrencyID:state.CurrencyID,	
      PrimaryCommissionPercentOnAdjustment:state.PrimaryCommissionPercentOnAdjustment,	
      ApprovePrimaryCommissionPercentOnAdjustment:state.ApprovePrimaryCommissionPercentOnAdjustment,	
      SecondaryCommissionPercentOnAdjustment:state.SecondaryCommissionPercentOnAdjustment,	
      ApproveSecondaryCommissionPercentOnAdjustment:state.ApproveSecondaryCommissionPercentOnAdjustment,	
      AdjustmentAmount:state.AdjustmentAmount,	
      BasePriceAgent:state.BasePriceAgent,	
      BasePriceCompany:state.BasePriceCompany,	
      PurchaseSourceID:state.PurchaseSourceID,	
      TradeBoardLetterStatusID:state.TradeBoardLetterStatusID,	
      Remarks:state.Remarks,	
      CreationDate:state.CreationDate,	
      CreatedByUserID:state.CreatedByUserID,	
      VesselName:state.VesselName,	
      VoyageNo:state.VoyageNo,	
      ETD:state.ETD != '' ?state.ETD : null,	
      ETA:state.ETA != '' ? state.ETA : null,	
      PIReferenceNo:state.PIReferenceNo,
      ShortAmount:state.ShortAmount,
      ...args.data,
      ReceiptDate:moment(args.data.ReceiptDate).format('DD-MM-YYYY'),	

      PaymentTypeID: state.PaymentTypeID,
      // SalesAgentStatusID: state.SalesAgentStatusID,
      SalesAgentStatusID: null,

      PaymentVerificationStatus:args.data.PaymentVerificationStatusID == 2 ? true : args.data.PaymentVerificationStatusID == 3 ? false : null,
      // PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      PaymentVerificationStatusDate:state.PaymentVerificationStatusDate != null ? curtDate : null,
      CreationDate:new Date(moment()),
      CreatedByUserID:userId,
      DesignationName:null,
      CRMStatusDate:args.data.CRMStatusDate ? args.data.CRMStatusDate : null ,
      CRMStatus:args.data.CRMStatus ? args.data.CRMStatus : null ,
      CRMID:args.data.CRMID ? args.data.CRMID : null ,
      CRMID:state.DesignationID,
      CRMStatus:crmStatus,
      CRMStatusID:null,
      // CRMID:userId,

      // CRMStatusID:crmStatusID,
      CRMStatusDate:crmStatusDate ,

    }
    console.log(data)
    PIService.addProformaInvoice(data).then((res)=>{
      console.log(res)
      //  whenDestory = ProformaInvoiceDetailGrid.dataSource
      getPaymentDetailForGrid(res.data.params.id)
      dispatch({ type: `PIID`  , PIID:res.data.params.id})
      ProformaInvoiceDetailGrid.refresh()
      setTimeout(() => {
        return document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
      }, 300);

    }).catch((e)=>{
      console.log(e)
      if(ProformaInvoiceDetailGrid.dataSource.length <= 0){
       document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
      }
    })
  }
  if (args.requestType === 'save' && args.action ==  'edit') {
    IsEditActionType = false
    const inputDate = state.PaymentVerificationStatusDate;
  
// const parts = inputDate.split('/');
// const formattedDateAddDash = parts[2] + '-' + parts[1] + '-' + parts[0];

// console.log(formattedDateAddDash);

    let data = {
      PIID:state.PIID,
        ...args.data,
      PaymentVerificationStatus:state.PaymentVerificationStatus,
      // PaymentVerificationStatusDate:formattedDateAddDash,
      PaymentVerificationStatusDate:state.PaymentVerificationStatusDate,
      PaymentTypeID: state.PaymentTypeID,
      // SalesAgentStatusID : state.SalesAgentStatusID,
      SalesAgentStatusID : null,
      CRMID:args.data.DesignationID,
      CreationDate:new Date(moment()),
      UpdatedByUserID:userId,
      DesignationName:null,
      ReceiptDate:moment(args.data.ReceiptDate).format('DD-MM-YYYY'),	

      CRMStatusDate:args.data.CRMStatusDate ? args.data.CRMStatusDate : null ,
      CRMStatus:args.data.CRMStatus ? args.data.CRMStatus : null ,
      // CRMID:args.data.CRMID ? args.data.CRMID : null ,
      // CRMStatusID:args.data.CRMStatusID ? args.data.CRMStatusID : null ,
      CRMID:state.DesignationID,
      // CRMID:userId,w
      // CRMStatusID:crmStatusID,
      CRMStatusID:null,
      CRMStatus:crmStatus,
      CRMStatusDate:crmStatusDate ,
   }
    console.log(data)
    PIService.updatePI(args.data.ProformaInvoicePaymentDetailID,data).then((res)=>{
      // whenDestory = ProformaInvoiceDetailGrid.dataSource
      
      getPaymentDetailForGrid(res.data.params.id)
      dispatch({ type: `PIID`  , PIID:res.data.params.id})

    })

  }
      // if (args.requestType === "beginEdit" || args.requestType === "add") {
      //   console.log('attaching blur event complete', document.getElementById('productDetailGridQuantityPerSet'));
      //   document
      //     .getElementById("productDetailGridQuantityPerSet")
      //     ?.addEventListener("blur", (e) => {
      //     let strr =   document
      //     .getElementById("productDetailGridQuantityPerSet").value
      //       if(strr > 0){

      //       setTimeout(() => productDetailGrid?.endEdit(), 200);
      //       }else{
      //         setTimeout(() =>  document
      //         .getElementById("productDetailGridQuantityPerSet").focus(),150)
      //       }
            
      //     });
      // }
    }
    }


    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////       params
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

  const paymentTypeParams: IEditCell = {
    create: () => {
      paymentTypeElem = document.createElement('input');
      return paymentTypeElem;
    },
    destroy: () => {
      paymentTypeObj?.destroy();
    },
    read: () => {
      return paymentTypeObj?.text;
    },
    write: (args) => {
      paymentTypeObj = new AutoComplete({
        created : async   (e) => {
        
          dispatch({ type: `PaymentType` ,  PaymentTypeID: args.rowData.PaymentTypeID})
          if( args.rowData?.PaymentTypeID == "1"){
          // getBalanceUnAllocatedAmount()
        }else{
          dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:null})
        }
        },
        select: (e) => {
          console.log('yes',args)
          console.log(e.itemData)
          // console.log(BalanceUnAllocatedAmount)
          if(e.itemData?.id == "1"){
            console.log('yes')
            // getBalanceUnAllocatedAmount()
          }else{
            dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:null})
          }
          console.log('PaymentTypeID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
          args.rowData.PaymentTypeID = e?.itemData?.id
          args.rowData.PaymentType = e?.itemData?.value
          PaymentTypeName = e?.itemData?.value

           
        },
        change:(e)=>{
          let resstr = paymentTypeObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `PaymentType` ,  PaymentTypeID: 0})
            args.rowData.PaymentTypeID =0
          }else{
            dispatch({ type: `PaymentType` ,  PaymentTypeID: e?.itemData?.id ,PaymentType:e?.itemData?.value })
            args.rowData.PaymentTypeID = e?.itemData?.id
            args.rowData.PaymentType = e?.itemData?.value
            PaymentTypeName = e?.itemData?.value

  
          }},        
        blur:(e)=>{
          console.log(state.PaymentTypeID)
          console.log(paymentTypeObj);
          if (
              paymentTypeObj?.itemData?.id == undefined
          ||  paymentTypeObj?.itemData?.id == '' 
          ||  paymentTypeObj?.itemData?.id == 0 
          ) {
              if(addNewClicked == false){
              paymentTypeObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridPaymentType").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.PaymentTypeDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Payment Type',
        highlight: true,
        value: args.rowData.PaymentType
      });
      paymentTypeObj?.appendTo(paymentTypeElem);
    }
  };

  const salesAgentStatusParams: IEditCell = {
    create: () => {
      salesAgentStatusElem = document.createElement('input');
      return salesAgentStatusElem;
    },
    destroy: () => {
      salesAgentStatusObj?.destroy();
    },
    read: () => {
      return salesAgentStatusObj?.text;
    },
    write: (args) => {
      salesAgentStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: args.rowData.SalesAgentStatusID})
        },
        select: (e) => {
          console.log('SalesAgentStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
          args.rowData.SalesAgentStatusID = e?.itemData?.id
        },
        change:(e)=>{
          let resstr = salesAgentStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 0})
            args.rowData.SalesAgentStatusID =0
            saleCheck = 0
          }else{
            dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: e?.itemData?.id ,SalesAgentStatus:e?.itemData?.value })
            args.rowData.SalesAgentStatusID = e?.itemData?.id
            saleCheck = e?.itemData?.id
  
          }},        
        blur:(e)=>{

          console.log(state.SalesAgentStatusID)
          console.log(salesAgentStatusObj);
          if (
              salesAgentStatusObj?.itemData?.id == undefined
          ||  salesAgentStatusObj?.itemData?.id == '' 
          ||  salesAgentStatusObj?.itemData?.id == 0 
          ||  salesAgentStatusObj.itemData?.value == ''
          ) {
              if(addNewClicked == false){
              salesAgentStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridSalesAgentStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }

              },
        dataSource:state.SalesAgentStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Sales Agent Status',
        highlight: true,
        value: args.rowData.SalesAgentStatus
      });
      salesAgentStatusObj?.appendTo(salesAgentStatusElem);
    }
  };

  const paymentVerificationStatusParams: IEditCell = {
    create: () => {
      paymentVerificationStatusElem = document.createElement('input');
      return paymentVerificationStatusElem;
    },
    destroy: () => {
      paymentVerificationStatusObj?.destroy();
    },
    read: () => {
      return paymentVerificationStatusObj?.text;
    },
    write: (args) => {
      paymentVerificationStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: args.rowData.PaymentVerificationStatusID ,PaymentVerificationStatus:  args.rowData.PaymentVerificationStatusID == 3 ? false :  args.rowData.PaymentVerificationStatusID == 2 ? true : null   })
        },
        select: (e) => {
          console.log('PaymentVerificationStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: e?.itemData?.id , PaymentVerificationStatus: e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null   })
          args.rowData.PaymentVerificationStatusID = e?.itemData?.id
          args.rowData.PaymentVerificationStatus = e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null  
          // console.log( new Date(moment().format('DD-MM-YY')))
          // console.log( new Date(moment().format('dd/MM/YY')))
          // console.log( moment().format('DD/MM/YY'))
          
        },
        change:(e)=>{
          let resstr = paymentVerificationStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: 0})
            args.rowData.PaymentVerificationStatusID = 0
            saleCheck = 0
            document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = null
          args.rowData.PaymentVerificationStatusDate = null
          dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:null })
          dispatch({ type: `DesignationName` ,  DesignationID: ''  ,DesignationName:null })
        document.getElementById('ProformaInvoiceDetailGridCRMName').value = null

        }else{
            dispatch({ type: `PaymentVerificationStatus` ,  PaymentVerificationStatusID: e?.itemData?.id ,PaymentVerificationStatus:e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null   })
            args.rowData.PaymentVerificationStatusID = e?.itemData?.id
            args.rowData.PaymentVerificationStatus = e?.itemData?.id == 3 ? false : e?.itemData?.id == 2 ? true : null  
            saleCheck = e?.itemData?.id
          //   const currentDate = new Date();
          // const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          // const day = currentDate.getDate();
          // const year = currentDate.getFullYear();
          // const formattedDate = `${year}-${month}-${day}`;
          // console.log(formattedDate)
          // document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = formattedDate
          // args.rowData.PaymentVerificationStatusDate = formattedDate
          // dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:formattedDate })
          
          if( e?.itemData?.id == 2 ||  e?.itemData?.id == 3  ){
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const formattedDate = `${year}-${month}-${day}`;
          console.log(formattedDate)
          document.getElementById('ProformaInvoiceDetailGridPaymentVerificationStatusDate').value = formattedDate
          args.rowData.PaymentVerificationStatusDate = formattedDate
          dispatch({ type: `PaymentVerificationStatusDate` ,  PaymentVerificationStatusDate:formattedDate })
          // getAllEmployeeUserName()

          }
          if( e?.itemData?.id == 2 ){
            isCrmStatusEdit = false;

            console.log(document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value)
            let salesAgentStatusValue =  document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value;
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();
            var formattedDate2 = `${year}-${month}-${day}`;
            console.log(formattedDate2)
              document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value =  formattedDate2
              document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  null
              args.rowData.CRMStatusDate = formattedDate2
                setCrmStatusDate(formattedDate2)
            // if(salesAgentStatusValue ==  'Release' || salesAgentStatusValue == 'Surrender BL'){
              
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 5,CRMStatus: 'Valid Payment Complete' })
            //   args.rowData.CRMStatusID = 5
            //   args.rowData.CRMStatus = 'Valid Payment Complete'
            //   setCrmStatus('Valid Payment Complete')
            //   setCrmStatusID(5)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   args.rowData.CRMStatusDate = formattedDate2
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            
            // }else if(salesAgentStatusValue ==  'BL Hold'){
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 4,CRMStatus: 'Valid Sold' })
            //   args.rowData.CRMStatusID = 4
            //   args.rowData.CRMStatus = 'Valid Sold'
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  'Valid Sold'
            //   setCrmStatus('Valid Sold')
            //   setCrmStatusDate(`${year}-${month}-${day}`)
            //   setCrmStatusID(4)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   // document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate
            //                 args.rowData.CRMStatusDate = formattedDate2
            // }else if (salesAgentStatusValue == 'Booking-Partial Payment'){
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            //   dispatch({ type: `CRMStatus` ,  CRMStatusID: 1,CRMStatus: 'Pending Payment' })
            //   args.rowData.CRMStatusID = 1
            //   args.rowData.CRMStatus = 'Pending Payment'
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatus').value =  'Pending Payment'
            //   setCrmStatus('Pending Payment')
            //   setCrmStatusDate(`${year}-${month}-${day}`)
            //                 setCrmStatusID(1)
            //   document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate2
            // }

            // console.log(salesAgentStatus)
            // console.log(salesAgentStatusID)
            // console.log(state.SalesAgentStatusID)
            // console.log(state.SalesAgentStatus)


          }else{
            isCrmStatusEdit = true
          }
          }},        
        blur:(e)=>{

          console.log(state.PaymentVerificationStatusID)
          console.log(paymentVerificationStatusObj);
          if (
              paymentVerificationStatusObj?.itemData?.id == undefined
          ||  paymentVerificationStatusObj?.itemData?.id == '' 
          ||  paymentVerificationStatusObj?.itemData?.id == 0 
          // ||  paymentVerificationStatusObj.itemData?.value == ''
          ) {
              if(addNewClicked == false){
              paymentVerificationStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridPaymentVerificationStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }

              },
        // dataSource:isCrm == true ? state.PaymentVerificationStatusDataSource: [],
        dataSource:state.PaymentVerificationStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Payment Verification',
        highlight: true,
        enabled:{isCrm},
        
        value: args.rowData.PaymentVerificationStatus
        
      });
      paymentVerificationStatusObj?.appendTo(paymentVerificationStatusElem);
    }
  };
  const designationNameParams: IEditCell = {
    create: () => {
      designationNameElem = document.createElement('input');
      return designationNameElem;
    },
    destroy: () => {
      designationNameObj?.destroy();
    },
    read: () => {
      return designationNameObj?.text;
    },
    write: (args) => {
      designationNameObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `DesignationName` ,  DesignationID: args.rowData.DesignationID})
        },
        select: (e) => {
          console.log('DesignationID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
          args.rowData.DesignationID = e?.itemData?.id
        },
        change:(e)=>{
          let resstr = designationNameObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `DesignationName` ,  DesignationID: 0})
            args.rowData.DesignationID =0
          }else{
            dispatch({ type: `DesignationName` ,  DesignationID: e?.itemData?.id ,DesignationName:e?.itemData?.value })
            args.rowData.DesignationID = e?.itemData?.id
  
          }},        
        blur:(e)=>{
          console.log(state.DesignationID)
          console.log(designationNameObj);
          if (
              designationNameObj.itemData.id == undefined
          ||  designationNameObj.itemData.id == '' 
          ||  designationNameObj.itemData.id == 0 
          ) {
              if(addNewClicked == false){
              designationNameObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridCRMName").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.DesignationNameDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Select CRM',
        highlight: true,
        enabled:isCrm,
        value: args.rowData.CRMName
      });
      designationNameObj?.appendTo(designationNameElem);
    }
  };
  const cRMStatusParams: IEditCell = {
    create: () => {
      cRMStatusElem = document.createElement('input');
      return cRMStatusElem;
    },
    destroy: () => {
      cRMStatusObj?.destroy();
    },
    read: () => {
      return cRMStatusObj?.text;
    },
    write: (args) => {
      cRMStatusObj = new AutoComplete({
        created : async   (e) => {
        
        dispatch({ type: `CRMStatus` ,  CRMStatusID: args.rowData.CRMStatusID})
        },
        select: (e) => {
          console.log('CRMStatusID - e?.itemData?.id :', e?.itemData?.id)
          dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
          args.rowData.CRMStatusID = e?.itemData?.id
        },
        change:(e)=>{

          let resstr = cRMStatusObj.dataSource.some(s=>s.value ==  e.value);
          console.log(resstr)
          if(resstr === false){
            dispatch({ type: `CRMStatus` ,  CRMStatusID: 0})
            args.rowData.CRMStatusID =0
            document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = null
          args.rowData.CRMStatusDate = null
          }else{
            dispatch({ type: `CRMStatus` ,  CRMStatusID: e?.itemData?.id ,CRMStatus:e?.itemData?.value })
            args.rowData.CRMStatusID = e?.itemData?.id
            setCrmStatus(e?.itemData?.value)
              setCrmStatusID(e?.itemData?.id)
          const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // Adding 1 to get the correct month.
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          console.log(formattedDate)
          document.getElementById('ProformaInvoiceDetailGridCRMStatusDate').value = formattedDate
          args.rowData.CRMStatusDate = formattedDate
          }},        
        blur:(e)=>{
          console.log(state.CRMStatusID)
          console.log(cRMStatusObj);
          if (
              cRMStatusObj?.itemData?.id == undefined
          ||  cRMStatusObj?.itemData?.id == '' 
          ||  cRMStatusObj?.itemData?.id == 0 
          ) {
              if(addNewClicked == false){
              cRMStatusObj.element.focus()
              document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='3px solid red'
              setTimeout(() => {
              document.getElementById("ProformaInvoiceDetailGridCRMStatus").parentElement.style.border ='1px solid #c8c8c8'
                  }, 1000);
                  }
                }
              },
        dataSource:state.CRMStatusDataSource,
        fields: { id: "id", value: "value" },
        floatLabelType: 'Never',
        placeholder: 'Select CRM Status',
        highlight: true,
        // enabled:isCrm,
        value: args.rowData.CRMStatus
      });
      cRMStatusObj?.appendTo(cRMStatusElem);
    }
  };
  const actionBegin = (e)=>{
    console.log(e)
    // PaymentVerificationStatus
    // "VERIFIED"
    
    if (e.requestType == "beginEdit" && props.type == 'SalesAgent') {
      if(e.rowData.PaymentVerificationStatus == "VERIFIED"  ||  e.rowData.PaymentVerificationStatus == "INVALID"){

        e.cancel = true; // Prevent the editing process from starting
        alert('YOU ARE NOT ELIGIBLE TO EDIT THIS ROW')
      }
    }
    // if(e.requestType = "refresh"){
    //   getPaymentDetailForGrid(props.PIID)
    // }
  }
// const PIGrid = ()=>(

// )
// const updateDataSource = ()=>{
//   return getPaymentDetailForGrid(1006)
// }
let  PInoRef = useRef(null)
let  PiNoRefRef = useRef(null)
const deleteDialog = useRef();
const [showDialogOfProduct, setShowDialogOfProduct] = useState(false)
const deleteButtons = [
  {
    buttonModel: {
      content: 'Release',
      cssClass: 'e-flat',
      isPrimary: true,
    },
    click: (e) => {
      deleteDialog.current.hide();
      document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Release';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      setSalesAgentStatus('Release')
      setSalesAgentStatusID(4)
      dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 4 ,SalesAgentStatus:'Release' })
     
      // setTimeout(() => {
        // console.log(e);
        
      //   console.log('jd')
      //   // setProductInputValue(undefined)
      //   // document.getElementById('ProductInput').value =''
      //   setProductInputValue('')
      //   setProductDropdownID(0)
      //   // document.getElementById('Product').
      //   // productInput.current.focus()
      // },300);
      // setTimeout(  () => {

      //   let el =  document.getElementById('ProductInput')
      //   // console.(
      //   // productInput.current.focus()
      //   if(el != null){
      //   return el.focus();}

      // },700)
        // console.log(productInput)
        // setProductInputChecker(true)

      // deleteRow();

    },
  },
  {
    buttonModel: {
      content: 'Surrender BL',
      cssClass: 'e-flat',
    },
    click: () => {
      deleteDialog.current.hide();
      document.getElementById('ProformaInvoiceDetailGridSalesAgentStatus').value = 'Surrender BL';
      // document.getElementById('ProformaInvoiceDetailGridSalesAgentStatusID').value = '2';
      // dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 2 ,SalesAgentStatus:'SalesAgentStatus' })
      setSalesAgentStatus('Surrender BL')
      setSalesAgentStatusID(5)
      dispatch({ type: `SalesAgentStatus` ,  SalesAgentStatusID: 5 ,SalesAgentStatus:'Surrender BL' })

      // props.showResult(false)
    },
  },
];
 

const deleteDialogClose = ()=>{
  setShowDialogOfProduct(false)
  if(isCrm){

    setTimeout(() => {
      
      document.getElementById('ShortAmount').focus()
  }, 600);
    }else{
      setTimeout(() => {
      
        // document.getElementById('ShortAmount').focus()
        ProformaInvoiceDetailGrid.endEdit()
    }, 600);
    }
}
  return (
    <div className='product-Finish-size-add-container'
     onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13 || e.keyCode == 27  ||  e.code == "Escape"){
        e.stopPropagation()
    }
    }}
     onKeyUp={(e)=>{
      console.log(e)
      if(e.keyCode == 13 || e.keyCode == 27  ||  e.code == "Escape"){
        e.stopPropagation()
    }
    }}
    
    >
      <div  onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13 || e.keyCode == 27  ||  e.code == "Escape"){
        e.stopPropagation()
    }
    }}
     onKeyUp={(e)=>{
      console.log(e)
      if(e.keyCode == 13 || e.keyCode == 27  ||  e.code == "Escape"){
        e.stopPropagation()
    }
    }}>
        {/* <div>
          <div className='heading'>
            <p>PI - {props.isAdd?`Add`:`Edit`}</p>
          </div>
        </div> */}
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>

                <div className="row">
                  <div className="col-sm-4">
                <table>
                  <tr className='tr-view-pi'>
                      <th className='ths'>PINO</th>
                      <td>{props.PINo}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                      <th className='ths'>Supplier Name </th>
                      <td>{props.SupplierName}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                      <th className='ths'>Consignee Name </th>
                      <td>{props.ConsigneeName}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                      <th className='ths'>Shipment Type </th>
                      <td>{props.ShipmentType}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                  
                  <th className='ths'>Adjustment Amount </th>
                  <td>{Number(props.AdjustmentAmount).toLocaleString()}</td>
                </tr>
                  <tr className='tr-view-pi'>
                  
                  <th className='ths'>Purchase Source </th>
                  <td>{props.PurchaseSource}</td>
                </tr>
                  <tr className='tr-view-pi'>
                  
                  <th className='ths'>Vessel Name </th>
                  <td>{props.VesselName}</td>
                </tr>
                  <tr className='tr-view-pi'>
                  
                  <th className='ths'>ETD Date </th>
                  <td>{props.ETD == null ?  '' :moment(props.ETD).format('DD-MM-YYYY')}</td>
                </tr>
                
                       {/* <tr className='tr-view-pi'>
                      <th className='ths'>Base Price Company</th>
                      <td>{Number(props.BasePriceCompany).toLocaleString()}</td>
                  </tr> */}
              </table>
              </div>
                  
                    <div className="col-sm-4">
                  <table>
                                  <tr className='tr-view-pi'>
                      <th className='ths'>PI Reference No </th>
                      <td>{props.PIReferenceNo}</td>
                  </tr>
                                  <tr className='tr-view-pi'>
                      <th className='ths'>Customer Name </th>
                      <td>{props.CustomerName}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                               
                      <th className='ths'>Confirm Consignee Name </th>
                      <td>{props.ConfirmConsigneeName == true ? "YES" : "NO"}</td>
                  </tr>
                  <tr className='tr-view-pi'>
  
                      <th className='ths'>Currency Name </th>
                      <td>{props.CurrencyName}</td>
                  </tr>
                  {/* <tr className='tr-view-pi'>
                      <th className='ths'>Commission Amount Per Unit </th>
                      <td>{Number(props.CommissionAmountPerUnit).toLocaleString()}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                      <th className='ths'>Primary Commission Percent On Adjustment </th>
                      <td>{state.PrimaryCommissionPercentOnAdjustment}</td>
                  </tr> */}
                  <tr className='tr-view-pi'>
                      <th className='ths'>Trade Board Letter Status </th>
                      <td>{state.TradeBoardLetterStatus}</td>
                  </tr>
                  <tr className='tr-view-pi'>
                      <th className='ths'>Voyage No</th>
                      <td>{state.VoyageNo}</td>
                  </tr>
                  {/* <tr className='tr-view-pi'>
                      <th className='ths'>Base Price Agent</th>
                      <td>{Number(state.BasePriceAgent).toLocaleString()}</td>
                  </tr> */}
                 
                 <tr className='tr-view-pi'>
                      <th className='ths'>ETA Date</th>
                      <td>{props.ETA == null ? "" :moment(props.ETA).format('DD-MM-YYYY')}</td>
                  </tr>
              </table>
              </div>
                              
                    <div className="col-sm-4">
                  <table>
                       <tr className='tr-view-pi'>
                      <th className='ths'>PI Date : </th>
                      <td>{state.PIDate == null ? '':moment(state.PIDate).format('DD-MM-YYYY')}</td>
                  </tr>
                       <tr className='tr-view-pi'>
                      <th className='ths'>Employee Name  : </th>
                      <td>{props.SalesAgentName}</td>
                  </tr>
                       <tr className='tr-view-pi'>
                      <th className='ths'>Country City Port  : </th>
                      <td>{props.CountryCityPort}</td>
                  </tr>
                       <tr className='tr-view-pi'>
                      <th className='ths'>Book Amount: </th>
                      <td>{Number(props.BookAmount).toLocaleString()}</td>
                  </tr>
                       <tr className='tr-view-pi'>
                      <th className='ths'>Absolute Commission Amount: </th>
                      <td>{props.AbsoluteCommissionAmount}</td>
                  </tr>
                       <tr className='tr-view-pi'>
                      <th className='ths'>Secondary Commission Percent On Adjustment: </th>
                      <td>{Number(props.SecondaryCommissionPercentOnAdjustment).toLocaleString()}</td>
                  </tr>
                  
                       <tr className='tr-view-pi'>
                      <th className='ths'>Remarks</th>
                      <td>{props.Remarks}</td>
                  </tr>

              </table>
              </div>
              </div>
              
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>
      {/* if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            } */}
                            <div>
       <div style={{display:'flex',justifyContent:'space-around'}}>
       {state.BalanceUnAllocatedAmount  != null? <h4 id='Account-amt'> ACCOUNT BALANCE : {state.CurrencySymbol} {state.BalanceUnAllocatedAmount}</h4>:<h4 id='Account-amt' style={{visibility:'hidden'}}>...</h4>}
          
       </div>
       </div>
{/* <PIGrid /> */}

{/* <button onClick={(e) =>{ e.preventDefault(); updateDataSource()}}>Update Data</button> */}

 <div>
 
      
<GridComponent
      ref={(grid) => (ProformaInvoiceDetailGrid = grid)}
      id='ProformaInvoiceDetailGrid'
      dataSource={pPaymentDetailDataSource}
      // dataSource={[]}
      // editSettings={editSettings}
      // toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true,  }}
      // allowPaging={true}
      // filterSettings={filterOptions}
      sortSettings={sortingOptions}
      // allowPaging={true}
      allowSorting={false}
      
      allowPdfExport={false}
      allowExcelExport={false}
      //toolbarClick={toolbarClick}
      // allowFiltering={true}
      // showColumnChooser={true}
      // allowReordering={true}
      // visible={false}
      allowTextWrap={true}
      // actionComplete={actionComplete}
      actionBegin={actionBegin}
      // toolbarClick={toolbarClick}
      >
      <ColumnsDirective>
      <ColumnDirective
      field='ProformaInvoicePaymentDetailID'
      visible={false}

      />
        <ColumnDirective
          headerText='Receipt Date'
          field='ReceiptDate'
          width='110'
          headerTextAlign='CENTER'
          textAlign='right'
         editType='datepickeredit'
        //  ref={receiptDateRef}

        //  format={formatOptions}
        type='date'
            format='dd/MM/yy'
            
            />
        <ColumnDirective
          headerText='ID'
          field='PaymentTypeID'
          visible={false}
        />
        <ColumnDirective
          headerText='Payment Type'
          field='PaymentType'
          width='130'
          textAlign='Left'
          edit={paymentTypeParams}
          editType='dropdownedit'
        />
        <ColumnDirective
          headerText='Payment Ref No'
          field='PaymentReferenceNo'
          width='110'
          defaultValue='-'
          headerTextAlign='Left'
        />
        <ColumnDirective
          headerText='Payment Sender'
          field='PaymentSenderName'
          defaultValue='-'
          width='110'
          headerTextAlign='Left'
        />
        <ColumnDirective
          defaultValue='-'
          headerText='Payment Desc'
          field='PaymentDescription'
          width='110'
          headerTextAlign='Left'
        />
        <ColumnDirective
          headerText='Receipt Amount'
          field='ReceiptAmount'
          width='120'
          textAlign='right'
          edit={{ params :{showSpinButton:false,format:'n0'}}}
          editType='numericedit'
          format='n0'
          headerTextAlign='right'
          
          />
      
      
         <ColumnDirective
          headerText='Short Amount'
          field='ShortAmount'
          ref={state.ShortAmountRef}
          width='100'
          allowEditing={isCrm}
          defaultValue={0}
          edit={{ params :{showSpinButton:false,format:'n0',min:0}}}
          // editType='numericedit'
          min={0}
          headerTextAlign='Center'
          textAlign='right'
          
        /> 
        <ColumnDirective
          headerText='ID'
          field='SalesAgentStatusID'
          visible={false}
        />
        <ColumnDirective
          headerText='Sales Agent Status'
          field='SalesAgentStatus'
          width='200'
          textAlign='Left'
          // edit={salesAgentStatusParams}
          allowEditing={false}
          // editType='dropdownedit'
        />
          <ColumnDirective
          headerText='Sales Agent Status Date'
          field='SalesAgentStatusDate'
          width='135'
          allowEditing={false}
          defaultValue={state.SalesAgentStatusDate}
          headerTextAlign='Right'
          editType='datepickeredit'
          // format={formatOptions}
          textAlign='right'
          type='date'
            format='dd/MM/yy'
        />

{/* { isCrm == true ?  */}
          {/* <ColumnDirective
          headerText='Payment Verification Status'
          field='PaymentVerificationStatus'
          width='150'
          allowEditing={isCrm}
          headerTextAlign='Center'
          editTemplate={(args)=>{
            console.log(args)
            return (
                <label className="checkbox-container">
                <input
                  type='checkbox'
                  className={`checkbox ${props.PaymentVerificationStatus ? 'yellow': ''}`}
                  id={`checkbox_${props.CostingProductRowID}`}
                  checked={props.PaymentVerificationStatus}
                  readOnly={!isCrm}
                  disabled={!isCrm}
                  
                  onChange={(e) => {
                    dispatch({ type: 'PaymentVerificationStatus' ,PaymentVerificationStatus: e.target.checked})
                    console.log(e, props)
                  }}
                />
              <span className="checkmark"></span>
              </label>
            )
          }}
          /> */}
          <ColumnDirective
          headerText='Payment Verification Status'
          field='PaymentVerificationStatus'
          width='150'
          allowEditing={isCrm}
          headerTextAlign='Center'
          textAlign='Left'
          // visible={isCrm}
          edit={paymentVerificationStatusParams}
          editType='dropdownedit'
          />
          {/* // :null} */}
          
       {/* {isCrm == true ?  */}
        <ColumnDirective
          headerText='Payment Verification Status Date'
          field='PaymentVerificationStatusDate'
          width='145'
          
          textAlign='right'
          type='date'
            format='dd/MM/yy'
          allowEditing={false}
          // defaultValue={state.PaymentVerificationStatusDate}
          headerTextAlign='Center'
          //  format={formatOptions}
          // editType='datepickeredit'
          // type='date'
              // format='dd/MM/yy'
        />
        {/* :<></>} */}
        <ColumnDirective
          headerText='ID'
          field='DesignationID'
          visible={false}
        />
        {/* {isCrm == true ? */}
        <ColumnDirective
          headerText='CRM'
          field='CRMName'
          allowEditing={false}
        
          width='140'
          textAlign='Center'
          // edit={designationNameParams}
          // editType='dropdownedit'
        />
        {/* :<></>} */}
        <ColumnDirective
          headerText='ID'
          field='CRMStatusID'
          visible={false}
        />
      {/* {isCrm == true ?  */}

        <ColumnDirective
        //  allowEditing={isCrmStatusEdit}
         allowEditing={false}
         headerText='CRM Status' 
          field='CRMStatus'
          // visible={isCrm}


          width='140'
          
          textAlign='Center'
          // edit={cRMStatusParams}
          // editType='dropdownedit'
        />
        {/* :<></>} */}
        {/* {isCrm == true ?  */}
         <ColumnDirective
          headerText='CRM Status Date'
          field='CRMStatusDate'
          width='145'
          textAlign='right'
          type='date'
            format='dd/MM/yy'
          // allowEditing={false}
          // defaultValue={state.CRMStatusDate}
          allowEditing={false}
          headerTextAlign='Center'
          // editType='datepickeredit'
          //  format={formatOptions}
          // allowEditing={false}
          // type='date'
              // format='dd/MM/yy'
              
        />
        
         {/* : <></>} */}
      </ColumnsDirective>
    <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    format={'n0'}

                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    format={'n0'}

                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              {/* <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='ReceiptAmount'
                    type='Sum'
                    // groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='ShortAmount'
                    type='Sum'
                    // groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              */}
           </AggregatesDirective> 
      <Inject
          services={[Edit, Toolbar, Page, Sort, Filter, Aggregate, Reorder]}
      />
    </GridComponent>

    </div>

    <br/>
    <div>
      <h4 className='view-pi-messages'>Messages</h4>
    <GridComponent
    ref={(grid) => (messageGrid = grid)}
    id='messageGrid'
    dataSource={message}
    // editSettings={editSettings}
    // toolbar={toolbarOptions}
    pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 12 }}
    // filterSettings={filterOptions}
    // groupSettings={groupOptions}
    // actionComplete={actionComplete}
    allowTextWrap='true'
    // commandClick={commandClick}

    // allowPaging={true}
    allowSorting={true}
    // allowPdfExport={true}
    // toolbarClick={toolbarClick}
    // allowPdfExport={true}
    // allowExcelExport={true}
    // allowFiltering={true}
    // allowGrouping={true}/
    // showColumnChooser={true}
    // allowReordering={true}
    // allowResizing={true}
    // sortSettings={sortingOptions}
  //  dataBound={databound}

  //  rowSelected={
  //   (args)=>{
  //     console.log(args.data)
  //     selectedRows = args.data
      
  //               setTimeout(() => {
  //       console.log('ss')
  //     }, 200);
  //   }
  // }
  rowSelected={
    (args)=>{
      console.log(args.data)
      // selectedRows = args.data
      // let data = {
      //   PIMessageID:args.data.PIMessageID,
      //   MessageReadDate:new Date()
      // }
      // MessageService.messageReadUpdate(data).then((res)=>{
      //   console.log(res)
      //   setTimeout(() => {
      //     getMassage(piID)
      //   }, 3000);
      //   // messageGrid.refresh()
        
      // })

      
                setTimeout(() => {
                  console.log(args.data)
                  
      }, 3000);
    }
    }

  >
  
    <ColumnsDirective>
    {/* <ColumnDirective
                headerText='Commands'
                width='150'
                commands={commands}
                textAlign='center'
                headerTextAlign='center'
              /> */}
    <ColumnDirective
        headerText='Serial 
        No'
        field='SerialNo'
        width='130'
        headerTextAlign='Center'
        textAlign='Right'
      />
     <ColumnDirective
        headerText='PI NO'
        field='PINo'
        width='130'
        headerTextAlign='Center'
        textAlign='Left'
      />
     <ColumnDirective
        headerText='Date'
        field='MessageDate'
        width='135'
        type='date'
        format={formatOptions}
      //  format={'dd/MM/yy'}
            /> 
      <ColumnDirective
        headerText='Employee Name'
        field='EmployeeName'
        width='130'
        headerTextAlign='Center'
        textAlign='left'
      
      />
      <ColumnDirective
        headerText='To Employees'
        field='ToEmployees'
        width='150'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      <ColumnDirective
        headerText='Message '
        field='MessageComment'
        width='120'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      <ColumnDirective
        headerText='To Employees With Read Date '
        field='ToEmployeesWithReadDate'
        width='170'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      {/* <ColumnDirective
        headerText='Message
        Read 
        Date'
        field='MessageReadDate'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        type='date'
        format={formatOptions}
        
      />  */}
      {/* <ColumnDirective
        headerText='Message Status '
        field='PIMessageStatus'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
         template={(props) =>{ 
            
            console.log(props)
            return (
            <div className={props.PIMessageStatus == "UNREAD" ? `MessageUnRead` : ''} >
        {props.PIMessageStatus}
            </div>
            // <label className="checkbox-container">

            // <input
            //  type='checkbox'

            //   className={`checkbox ${props.ApprovedProduct ? 'yellow': ''}`}
            //   id={`checkbox_${props.CostingProductRowID}`}
            //    checked={props.ApprovedProduct}
               
            //     onChange={(e) => handleCheckboxChange(e, props)} 
            //   />
            // <span className="checkmark"></span>
            // </label>
          )}} 
        
      />
     */}
    </ColumnsDirective>

          <Inject
      services={[
        Edit,
        Toolbar,
        Page,
        Sort,
        PdfExport,
        ExcelExport,
        Filter,
        Group,
        Aggregate,
        Freeze,
        ColumnChooser,
        Reorder,
        Resize,
        CommandColumn,
      ]}
    />
  </GridComponent>
    </div>
        {/* <PIGrid actionComplete={actionComplete} /> */}
    </div>
  )
  
}








