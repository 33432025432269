import React, { useEffect, useState } from 'react';
import UsersService from '../../services/users.service';
import './users-list.scss';
import HardDelete from '../hard-delete/hard-delete';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import UsersAdd from './users-add';
// import Buffer from 'buffer';
// import YarnPurchaseContractEdit from './yarn-purchase-contract-edit';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import YarnContractDeliveryScheduleList from '../yarn-contract-delivery-schedule/yarn-contract-delivery-schedule-list';
import moment from 'moment';
import SecurityService from '../security/security.service';
let usersGrid: Grid;
let datas = []
export default function UsersList(props) {
  console.log(props)
  const [users, setUsers] = useState([]);
  const [domainEmails, setDomainEmails] = useState('');
  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();
  // const [showDialog, setShowDialog] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  const userId = SecurityService.getLoggedInUserId();
  const userName = SecurityService.getLoggedInUser();
  console.log(userName)

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (usersGrid && args.item.properties.id === 'usersGrid_PDFExport') {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_users_information.pdf`
      };
      console.log('clicked on pdf export');
      usersGrid.pdfExport(exportProperties);
    }
    if (usersGrid && args.item.properties.id === 'usersGrid_excelexport') {
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_users_information.xlsx`
      };
      usersGrid.excelExport(exportProperties);
    }
    if (usersGrid && args.item.properties.id === 'usersGrid_columnchooser') {
    }
    if (usersGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete', usersGrid.getSelectedRecords()[0].UserID);
      if (usersGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', usersGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`Users`,
          ColumnName:`UserID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else if(usersGrid.getSelectedRecords()[0].UserID != 1 ) {
        console.log('delete: ', usersGrid.getSelectedRecords()[0].UserID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`Users`,
          ColumnName:`UserID`,
          RowID:usersGrid.getSelectedRecords()[0].UserID,
          SelectedRow:true,
          DeleteSecurity:`UsersDelete`,
          UserID:userId
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
    // if (
    //   usersGrid &&
    //   args.item.properties.id === 'usersGrid_Delivery Schedule'
    // ) {
    //   console.log('clicked delivery');
    // }
  };

  useEffect(() => {
    const type = getRoute()[1];
    getUsers();
    let listData = {
      TableName:'CompanySetup',
      Columns:['DomainEmail']
    }
    getListDynamic(listData)                                                         
  }, []);

  const getUsers = async (type: string) => {
    UsersService.getAll()
      .then((response) => {
        console.log(response)
        datas =  response.data.recordset;
        let data = response.data.recordset;
      
        setUsers(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getListDynamic = async (listdata) => {
    UsersService.getThreeInputsForList(listdata)
      .then((response) => {
        console.log(response)
       setDomainEmails(response.data.recordset[0].DomainEmail)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addUsers = () => {
    props.history.push('/Users/Add');
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
    text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess(`UsersDelete`)
    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const databound = () => {
    if (usersGrid) {
      usersGrid.autoFitColumns();
    }
  }

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ?
      (
        <UsersAdd {...props} dataList={datas}> </UsersAdd>
      ) :
      (
        <UsersAdd {...props}  dataList={datas}> </UsersAdd>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess(`UsersEdit`),
    allowAdding: SecurityService.canUserAccess(`UsersAdd`),
    allowDeleting: SecurityService.canUserAccess(`UsersDelete`),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    headerTemplate: '<p></p>'
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'notequal',
    //     value: true
    //   }
    // ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  // const header = () => {
  //   return <div>Yarn Contract Delivery Schedule</div>;
  // };

  //   const contentTemplate = () => {
  //   return (
  //     <div>
  //       <YarnContractDeliveryScheduleList
  //         {...selectedRow}
  //       ></YarnContractDeliveryScheduleList>
  //     </div>
  //   );
  // };

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      usersGrid?.autoFitColumns();
    }
    if (args.form) {

      if(args.requestType == 'save'){
        setTimeout(() => {
          getUsers()
        }, 500);
      }
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        console.log('e: ', args.form.ej2_instances[0]);
        /** Add Validation Rules */
       args.form.ej2_instances[0].addRules('UserName', { required: true });
       args.form.ej2_instances[0].addRules('UserPassword', { required: true });
      //  args.form.ej2_instances[0].addRules('DesignationID', { required: true });

      }
      if (args.requestType === 'save' && args.action == 'add') {
        console.log('save data,', args.data);
      
      
       
     
        console.log(`${args.data.Email}@${domainEmails}`);
     let data = {
          
            UserName:args.data.UserName,
            UserPassword:args.data.UserPassword,
            DesignationID:null,
            Email:null,
           IsTempPassword:0,
          CreatedByUserID:1,
          AdminEmailPassword:args.data.GooglePassword ? args.data.GooglePassword : '-'
          
        }
        console.log(data)
        UsersService.add(data).then((response) => {
          console.log('add response: ', response);
          getUsers();
          usersGrid.refresh()
          

        });
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        console.log('save data,', args.data);
     let data = {
         
            UserID:args.data.UserID,
            UserName:args.data.UserName,
            UserPassword:args.data.UserPassword,
            DesignationID:args.data.DesignationID,
            Email:args.data.Email,
           IsTempPassword:0,
           UserID:1,
          AdminEmailPassword:args.data.GooglePassword

         
        }
        usersGrid.refresh()
        UsersService.edit(data,args.data.UserID).then((response) => {
          console.log('add response: ', response);
          // const type = getRoute()[1];
          getUsers();
          usersGrid.refresh()
          // let data = response.?data.recordset;
         
          //   //console.log(v)
          //   (v['IsAccountManager']) ? v['IsAccountManager'] = "Yes" : v['IsAccountManager'] = "No";
          //   (v['IsExportManager']) ? v['IsExportManager'] = "Yes" : v['IsExportManager'] = "No"
          //   return v
          // })
          // setUsers(data);
          // const type = getRoute()[1];
          // getUsers(type);

        });
      }

    }

  };

  // const commandClick = (args: CommandClickEventArgs) => {
  //   setShowDialog(false);
  //   console.log('command click args: ', args);
  //   setSelectedRow(args.rowData);
  //   setShowDialog(true);
  // };

  // const commands: CommandModel[] = [
  //   {
  //     buttonOption: {
  //       content: 'Delivery Schedule',
  //       cssClass: 'e-flat',
  //     },
  //   },
  // ];

  // const dialogClose = () => {
  //   this.buttonInstance.style.display = 'inline-block';
  //   setShowDialog(false);
  // };

  // const openDialog = () => {
  //   setShowDialog(true);
  // };

  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  let refreshColumn =(columnName) =>{
    if (usersGrid) {
      usersGrid.columns.forEach(column => {
        if (column.field === columnName) {
          usersGrid.refreshColumns(column);
        }
      });
    }
  }
  const handleCheckboxChange = async (e,data) => {
    // console.log(data.C)
    console.log(e,data)
    let dataS = {
      UserID:data.UserID,
      DeletionDate : e.target.checked == true? `${new Date()}`: null,
      DeletedByUserName : userName,
      DeletedByUserID : e.target.checked == true? userId: null,
      IsDeleted:e.target.checked == true? 1: 0
    }
   UsersService.UserIsDeletedService(dataS,data.UserID).then((res)=>{
    
    console.log(datas)
    let dataFin = datas.map((Res)=>{
      if(data.UserID == Res.UserID){
        console.log(Res)
        if(Res.IsDeleted == true){
          Res.IsDeleted = false;
         //  Res.CRMID = 0;
         //  Res.CRMName = null
         //  Res.PaymentVerificationStatusDate = null
         Res.DeletionDate = new Date()
         Res.DeletedByUserName = userName
         Res.DeletedByUserID = userId
         refreshColumn('IsDeleted')
         refreshColumn('DeletionDate')
          refreshColumn('DeletedByUserName')
         
          Res.DeletionDate = new Date()
         //  Res.IsDeleted = true
          refreshColumn('IsDeleted')
          refreshColumn('DeletionDate')
          Res.DeletedByUserName = 'NULL'
           refreshColumn('DeletedByUserName')
       }else{
        //  Res.IsDeleted = true;
        //  Res.CRMID = userId
        //  Res.CRMName = printedByUserName
         
        Res.IsDeleted = true;
        //  Res.CRMID = 0;
        //  Res.CRMName = null
        //  Res.PaymentVerificationStatusDate = null
        Res.DeletionDate = new Date()
        Res.DeletedByUserName = userName
        Res.DeletedByUserID = userId
        refreshColumn('IsDeleted')
        refreshColumn('DeletionDate')
         refreshColumn('DeletedByUserName')
        
       
          
        }
       }
     })
     console.log(dataFin)
 
   })
 };
  return (
    (props.isDialog) ? (<>
    {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        const type = getRoute()[1];
        getUsers(type);
        setTimeout(() => {
          
          usersGrid.refresh()
        }, 200);
      // getProductCutSize(props.path.substring(1));

                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
          <GridComponent
            ref={(grid) => (usersGrid = grid)}
            id='usersGrid'
            dataSource={users}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 4, pageSizes: true }}
            filterSettings={filterOptions}
            groupSettings={groupOptions}
            actionComplete={actionComplete}
            allowTextWrap='true'
            // commandClick={commandClick}

            allowPaging={true}
            allowSorting={true}
            allowPdfExport={true}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            allowFiltering={true}
            allowGrouping={true}
            showColumnChooser={true}
            allowReordering={true}
            allowResizing={true}
            // dataBound={databound}
            actionBegin={(e)=>{
                console.log(e)
            }}
            sortSettings={sortingOptions}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                headerText='Commands'
                width='170'
                commands={commands}
                textAlign='Left'
              /> */}

              {/* <ColumnDirective
                headerText='ID'
                field='UserID'
                width='90'
                textAlign='right'
                headerTextAlign='Center'
              /> */}
              <ColumnDirective
                headerText='Serial No'
                field='SerialNo'
                width='90'
                textAlign='right'
                headerTextAlign='Center'
              />

              {/* <ColumnDirective
                headerText='Order Date'
                field='OrderDate'
                width='200'
                
                type='date'
                format='dd/MM/yy'
              /> */}
            
              <ColumnDirective
                headerText='User Name'
                field='UserName'
                width='160'
                textAlign='Left'
                headerTextAlign='Center'
              />
              {/* <ColumnDirective
                headerText='User Password'
                field='UserPassword'
                headerTextAlign='Center'
                width='160'
                type='password'
                template={(value)=>{
                  console.log(value)
                  return '*'.repeat(8)
                }}
              /> */}
               {/* <ColumnDirective
                headerText='Email'
                field='Email'
                headerTextAlign='Center'
                width='200'
                textAlign='Left'
              />
              
              <ColumnDirective
                headerText='Designation Name'
                field='DesignationName'
                headerTextAlign='Center'
                width='170'
              /> */}
              {/* <ColumnDirective
                headerText='Account Status'
                field='IsDeleted'
                headerTextAlign='Center'
                width='140'
                template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              /> */}
                 <ColumnDirective 
        field='IsDeleted'
         headerText='InActive'
          width='170' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'
               className={`checkbox ${props.IsDeleted ? 'yellow': ''}`}
                id={`checkbox_${props.UserID}`}
                 checked={props.IsDeleted
                 }
                 
                  onChange={(e) => handleCheckboxChange(e, props)} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            />
              {/* <ColumnDirective
                headerText='InActive 
                 Date'
                field='DeletionDate'
                headerTextAlign='Center'
                width='135'
                type='date'
                format={formatOptions}
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />
               <ColumnDirective
                headerText='InActive
                  User Name'
                field='DeletedByUserName'
                headerTextAlign='Center'
                width='145'
                textAlign='left'
                
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />  */}
              {/* <ColumnDirective
                headerText='Bags Ordered'
                field='OrderBags'
                width='150'
              />
              <ColumnDirective
                headerText='Designation Name'
                field='Designation Name'
                width='150'
              />
              <ColumnDirective
                headerText='Procurement Type'
                field='ProcurementType'
                width='150'
              />
              <ColumnDirective
                headerText='Start Date'
                field='ContractStartDate'
                width='150'
                type='date'
              />
              <ColumnDirective
                headerText='End Date'
                field='ContractEndDate'
                width='150'
                type='date'
              />
              <ColumnDirective
                headerText='Currency'
                field='CurrencyName'
                width='140'
              />
              <ColumnDirective
                headerText='Foreign Currency Rate'
                field='ForeignCurrencyRate'
                width='200'
              />
              <ColumnDirective
                headerText='Foreign Currency Amount'
                field='ForeignCurrencyAmount'
                width='200'
              />
              <ColumnDirective
                headerText='Contract Exchange Rate'
                field='ContractExchangeRate'
                width='200'
              />
              <ColumnDirective
                headerText='Bags Received'
                field='ReceivedBags'
                width='140'
              />
              <ColumnDirective
                headerText='Bags Returned'
                field='ReturnedBags'
                width='140'
              />
              <ColumnDirective
                headerText='Tax %'
                field='TaxPercent'
                width='100'
              />
              <ColumnDirective
                headerText='Tax Type'
                field='TaxType'
                width='150'
              /> */}
           
            </ColumnsDirective>

            {/* <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='OrderBags'
                    type='Sum'
                    footerTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='OrderBags'
                    type='Sum'
                    groupFooterTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective> */}

            <Inject
              services={[
                Edit,
                Toolbar,
                Page,
                Sort,
                PdfExport,
                ExcelExport,
                Filter,
                Group,
                Aggregate,
                Freeze,
                ColumnChooser,
                Reorder,
                Resize,
                // CommandColumn,
              ]}
            />
          </GridComponent>

    </>) : (
      <>
    <div className="content-wrapper">
      <div>
        <div>
          <h3>Users - List</h3>
          <div>
            {/* <ButtonComponent onClick={addYarnContract}>Add </ButtonComponent> */}
            {/* <button
                className='m-3 btn btn-sm btn-primary'
                onClick={this.addYarnContract}
              >
                Add
              </button> */}
          </div>
          {/* <div>
              <ReactDataGrid columns={columns} rows={rows} editor={true} />
            </div> */}

          {/* <div className='e-statustext'>
                  Select column name to toggle visibility
                </div> */}
          {/* <ToolbarComponent
                  id='toolbar'
                  ref={(toolbar) => (this.ToolbarInstance = toolbar)}
                  onClick={toolBarClick(this)}
                >
                  <ItemsDirective>
                    <ItemDirective text='Order ID' />
                    <ItemDirective text='Customer Name' />
                    <ItemDirective text='Freight' />
                    <ItemDirective text='Order Date' />
                    <ItemDirective text='Shipped Date' />
                    <ItemDirective text='Ship Country' />
                  </ItemsDirective>
                </ToolbarComponent> */}
          {/* <div className='App' id='dialog-target'>
            <DialogComponent
              width='600px'
              target='#dialog-target'
              visible={showDialog}
              close={dialogClose}
              content={contentTemplate}
              isModal={true}
              showCloseIcon={true}
              header={header}
              
            />
          </div> */}
   {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        const type = getRoute()[1];
        getUsers(type);
        setTimeout(() => {
          
          usersGrid.refresh()
        }, 200);
      // getProductCutSize(props.path.substring(1));

                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
          <GridComponent
            ref={(grid) => (usersGrid = grid)}
            id='usersGrid'
            dataSource={users}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 4, pageSizes: true }}
            filterSettings={filterOptions}
            groupSettings={groupOptions}
            actionComplete={actionComplete}
            allowTextWrap='true'
            // commandClick={commandClick}

            allowPaging={true}
            allowSorting={true}
            allowPdfExport={true}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            allowFiltering={true}
            allowGrouping={true}
            showColumnChooser={true}
            allowReordering={true}
            allowResizing={true}
            // dataBound={databound}
            sortSettings={sortingOptions}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                headerText='Commands'
                width='170'
                commands={commands}
                textAlign='Left'
              /> */}

              {/* <ColumnDirective
                headerText='ID'
                field='UserID'
                width='90'
                textAlign='right'
                headerTextAlign='Center'
              /> */}
              <ColumnDirective
                headerText='Serial No'
                field='SerialNo'
                width='90'
                textAlign='right'
                headerTextAlign='Center'
              />

              {/* <ColumnDirective
                headerText='Order Date'
                field='OrderDate'
                width='200'
                
                type='date'
                format='dd/MM/yy'
              /> */}
            
              <ColumnDirective
                headerText='User Name'
                field='UserName'
                width='160'
                textAlign='Left'
                headerTextAlign='Center'
              />
              {/* <ColumnDirective
                headerText='User Password'
                field='UserPassword'
                headerTextAlign='Center'
                width='160'
                type='password'
                template={(value)=>{
                  console.log(value)
                  return '*'.repeat(8)
                }}
              /> */}
               {/* <ColumnDirective
                headerText='Email'
                field='Email'
                headerTextAlign='Center'
                width='200'
                textAlign='Left'
              />
              
              <ColumnDirective
                headerText='Designation Name'
                field='DesignationName'
                headerTextAlign='Center'
                width='170'
              /> */}
              {/* <ColumnDirective
                headerText='Account Status'
                field='IsDeleted'
                headerTextAlign='Center'
                width='140'
                template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              /> */}
                 <ColumnDirective 
        field='IsDeleted'
         headerText='InActive'
          width='170' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'
               className={`checkbox ${props.IsDeleted ? 'yellow': ''}`}
                id={`checkbox_${props.UserID}`}
                 checked={props.IsDeleted
                 }
                 
                  onChange={(e) => handleCheckboxChange(e, props)} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            />
              {/* <ColumnDirective
                headerText='InActive 
                 Date'
                field='DeletionDate'
                headerTextAlign='Center'
                width='135'
                type='date'
                format={formatOptions}
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />
               <ColumnDirective
                headerText='InActive
                  User Name'
                field='DeletedByUserName'
                headerTextAlign='Center'
                width='145'
                textAlign='left'
                
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />  */}
              {/* <ColumnDirective
                headerText='Bags Ordered'
                field='OrderBags'
                width='150'
              />
              <ColumnDirective
                headerText='Designation Name'
                field='Designation Name'
                width='150'
              />
              <ColumnDirective
                headerText='Procurement Type'
                field='ProcurementType'
                width='150'
              />
              <ColumnDirective
                headerText='Start Date'
                field='ContractStartDate'
                width='150'
                type='date'
              />
              <ColumnDirective
                headerText='End Date'
                field='ContractEndDate'
                width='150'
                type='date'
              />
              <ColumnDirective
                headerText='Currency'
                field='CurrencyName'
                width='140'
              />
              <ColumnDirective
                headerText='Foreign Currency Rate'
                field='ForeignCurrencyRate'
                width='200'
              />
              <ColumnDirective
                headerText='Foreign Currency Amount'
                field='ForeignCurrencyAmount'
                width='200'
              />
              <ColumnDirective
                headerText='Contract Exchange Rate'
                field='ContractExchangeRate'
                width='200'
              />
              <ColumnDirective
                headerText='Bags Received'
                field='ReceivedBags'
                width='140'
              />
              <ColumnDirective
                headerText='Bags Returned'
                field='ReturnedBags'
                width='140'
              />
              <ColumnDirective
                headerText='Tax %'
                field='TaxPercent'
                width='100'
              />
              <ColumnDirective
                headerText='Tax Type'
                field='TaxType'
                width='150'
              /> */}
           
            </ColumnsDirective>

            {/* <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='OrderBags'
                    type='Sum'
                    footerTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='OrderBags'
                    type='Sum'
                    groupFooterTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective> */}

            <Inject
              services={[
                Edit,
                Toolbar,
                Page,
                Sort,
                PdfExport,
                ExcelExport,
                Filter,
                Group,
                Aggregate,
                Freeze,
                ColumnChooser,
                Reorder,
                Resize,
                // CommandColumn,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
    </>
  ));
}
