import http from "./http-common";

class SalesRecordService {
  
  getSalesRecord(FromDate , ToDate ,SalesAgentID , CustomerID , SupplierID , CurrencyID , SaleStatus) {
    // http://localhost:5001/api/v1/SalesRecord/list/01-JAN-23/31-DEC-23/7/18/12/1/5
    // FromDate ToDate SalesAgentID  CustomerID SupplierID CurrencyID SaleStatus
    return http.get(`/SalesRecord/list/${FromDate}/${ToDate}/${SalesAgentID}/${CustomerID}/${SupplierID}/${CurrencyID}/${SaleStatus}`);
  }
  
}

export default new SalesRecordService();
