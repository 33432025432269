import http from './http-common';

class CommissionCalculatorService {
  getList(id1, id2,  status) {
    return http.get(`/AdjustmentCalculator/list/${id1}/${id2}/${status}`);
  }
  updateApproveAbsoluteCommissionAmount(data) {
    return http.put(`/CommissionCalculator/ApproveAbsoluteCommissionAmount`,data);
  }
  updateApproveCommissionAmountPerUnit(data) {
    return http.put(`/CommissionCalculator/ApproveCommissionAmountPerUnit`,data);
  }
  updateFinalCommissionAmountPerUnitApproved(data) {
    return http.put(`/CommissionCalculator/FinalCommissionAmountPerUnitApproved`,data);
  }
  updateRemarks(data) {
    return http.put(`/CommissionCalculator/Remarks`,data);
  }
  update(data) {
    return http.put(`/AdjustmentCalculator/update`,data);
  }

//   ApproveAbsoluteCommissionAmount
// FinalCommissionAmountPerUnitApproved
// ApproveCommissionAmountPerUnit
  // customerDropDown() {
  //   return http.get(`//customer/dropdown`);
  // }
  // getBalanceUnAllocatedAmount(date, currencyID, customerID) {
  //   return http.get(`/TransferUnAllocatedPayment/balanceUnAllocatedAmount/'${date}'/${currencyID}/${customerID}`);
  // }
  // addTransferUnAllocatedPayment(data) {
  //   return http.post(`/TransferUnAllocatedPayment/add`,data);
  // }
  }
  export default new CommissionCalculatorService();
