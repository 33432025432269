import React, { useEffect, useState, useRef, useReducer } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import './receive-unAllocated-payment.scss';

import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import CustomerService from '../../services/customer.service'
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import PIService from '../../services/PI.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { CheckBox } from '@syncfusion/ej2-react-buttons';
import ReceiveUnAllocatedPaymentService from '../../services/receive-unAllocated-payment.service';


import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { format } from 'date-fns';
import transferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';
var BalanceUnAllocatedAmounts = null
const complexReducer = (state, action) => {
    console.log(state)
    console.log(action)
  switch (action.type) {
       case 'CustomerName':
        return {
          ...state,
         
          CustomerName:action.CustomerName,
          CustomerID:action.CustomerID,
        };
       break;
    case 'CustomerDataSource':
      return {
        ...state,
       
        LoadingCustomer:action.LoadingCustomer,
        CustomerDataSource: action.CustomerDataSource,
       };
       break;
    case 'PaymentTypeDataSource':
      return {
        ...state,
       
        LoadingPaymentType:action.LoadingPaymentType,
        PaymentTypeDataSource: action.PaymentTypeDataSource,
       };
       break;
       case 'PaymentType':
           return {
             ...state,
             PaymentType:action.PaymentType,
             PaymentTypeID:action.PaymentTypeID
            };
            break;
    case 'CurrencyNameDataSource':
      return {
        ...state,
       
        LoadingCurrencyName:action.LoadingCurrencyName,
        CurrencyNameDataSource: action.CurrencyNameDataSource,
       };
       break;
    case 'CurrencyName':
        return {
          ...state,
          CurrencyName:action.CurrencyName,
          CurrencyID:action.CurrencyID
         };
         break;
       
       case 'Remarks':
        return {
          ...state,
          Remarks:action.Remarks,
         
         };
       case 'BalanceUnAllocatedAmount':
        return {
          ...state,
          BalanceUnAllocatedAmount:action.BalanceUnAllocatedAmount,
         
         };
         
             default:
               return state;
    }
  };
  var creditAmt = 0
  var debitAmt = 0
  export default function PIAdd(props) {
    // let divs = document.querySelector('ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper')
    useEffect(() => {
      
      return () => {
        console.log(props)
        
        // document.querySelector('ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper').addEventListener('keyDown',(e)=>{
        //   console.log(e)
        //   e.stopPropagation()
        // })
       
      };
    }, []);
    
    const [state, dispatch] = useReducer(complexReducer, { 
      ReceiptDate:new Date(moment()),
      CustomerDataSource: [],
      LoadingCustomer:true,
      CustomerID:'',
      CustomerName:'',
      CustomerRef: useRef(null),
      PaymentTypeDataSource:[],
      LoadingPaymentType:true,
      PaymentTypeRef: useRef(null),
      PaymentTypeID:'',
      PaymentType:'',
      ReferenceNo:'',
      SenderName:'',
      Description:'',
      DebitAmount: 0,
      CreditAmount:0,

      LoadingCurrencyName:true,
      CurrencyNameDataSource: [],
      CurrencyName:'',
      CurrencyID:'',
      CurrencyNameRef: useRef(null),
      BalanceUnAllocatedAmount:null,
      ReceiveAmount: 0,
      Remarks : '-'
    });
console.log(state)
  
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
 
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');


  const  removeComma = (str) => {
    // Check if the string contains a comma
    if (str.includes(',')) {
      // If yes, remove the comma and parse the string as an integer or float
      var stringWithoutComma = str.replace(/,/g, '');
      return parseFloat(stringWithoutComma); // or parseInt(stringWithoutComma, 10) for integer
    } else {
      // If no comma, return the original value
      return parseFloat(str); // or parseInt(str, 10) for integer
    }
  }
  
  const textBoxChange = (e) => {
    console.log(e)
    // if(e.target.name == 'DebitAmount'){
      
    //   debitAmt = e.value
    // }else if(e.target.name == 'CreditAmount'){
    //   creditAmt = e.value
    // }
    if(e.target.name != 'DebitAmount' || e.target.name == 'CreditAmount'){
      
      dispatch({ type: e.target.name , [e.target.name]: e?.value })
    }
    let RemoveeComma = removeComma(state.BalanceUnAllocatedAmount)
    console.log(RemoveeComma)
    console.log(e?.value > RemoveeComma)
    if(e.target.name == 'DebitAmount' && e?.value > RemoveeComma){
    
      document.querySelector('#ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
    }else {
      document.querySelector('#ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
  
    }
    console.log(e.target.name == 'DebitAmount' && props.isSaleAgent != 'SalesAgent')
   if(e.target.name == 'DebitAmount' && props.isSaleAgent != 'SalesAgent' ){
    // console.log(creditAmt)
    if( creditAmt != 0 ){
      alert('EITHER DEBIT OR CREDIT CAN CONTAIN VALUES')
      // document.getElementById('DebitAmount').style.border = '1px solid red'
      debitAmt = 0
      creditAmt = 0
      document.getElementById('DebitAmount').value = 0
      document.getElementById('CreditAmount').value = 0
      dispatch({ type: 'CreditAmount' , CreditAmount: 0})
      dispatch({ type: 'DebitAmount' , DebitAmount:0 })
    }else{

      document.getElementById('DebitAmount').style.border = '1px solid #eee'
      debitAmt = e.value
       dispatch({ type: 'DebitAmount' , DebitAmount: e.value })
    }
  }else  if(e.target.name == 'DebitAmount' & props.isSaleAgent == 'SalesAgent'){
    document.getElementById('DebitAmount').style.border = '1px solid #eee'
    debitAmt = e.value
     dispatch({ type: 'DebitAmount' , DebitAmount: e.value })

   }


   if(e.target.name == 'CreditAmount' && props.isSaleAgent != 'SalesAgent' ){
    // console.log(creditAmt)
    if(debitAmt != 0){
      alert('EITHER DEBIT OR CREDIT CAN CONTAIN VALUES')
      // document.getElementById('CreditAmount').style.border = '1px solid red'
      debitAmt = 0
      creditAmt = 0
      document.getElementById('DebitAmount').value = 0
      document.getElementById('CreditAmount').value = 0
      dispatch({ type: 'CreditAmount' , CreditAmount: 0})
      dispatch({ type: 'DebitAmount' , DebitAmount:0 })
      document.getElementById('DebitAmount').focus()
    }else{

      document.getElementById('CreditAmount').style.border = '1px solid #eee'
      creditAmt = e.value
       dispatch({ type: 'CreditAmount' , CreditAmount: e.value })
    }
  }else  if(e.target.name == 'CreditAmount' & props.isSaleAgent == 'SalesAgent'){
    document.getElementById('CreditAmount').style.border = '1px solid #eee'
    creditAmt = e.value
     dispatch({ type: 'CreditAmount' , CreditAmount: e.value })

   }
   
};

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'CustomerName':
        dispatch({ type: 'CustomerName' ,CustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CustomerName' , 
            CustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            CustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                })
                getCurrency(e?.itemData?.id )

                  }
                  break;
      case 'PaymentType':
        dispatch({ type: 'PaymentType' ,PaymentType: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'PaymentType' , 
            PaymentType: e?.itemData?.value ? e?.itemData?.value : '',
            PaymentTypeID: e?.itemData?.id ? e?.itemData?.id : '',
                })
                  }
                  break;
      case 'ToCustomerName':
        dispatch({ type: 'ToCustomerName' ,ToCustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'ToCustomerName' , 
            ToCustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            ToCustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                 })
                 setTimeout(() => {
                  
                  getBalanceUnAllocatedAmount()
                }, 200);        
                }
                  break;
      
      case 'CurrencyName':
        dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CurrencyName' , 
            CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
            CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                  setTimeout(()=>{return getBalanceUnAllocatedAmount()}, 200)
                    break;
      
      
            default:
        return state;
    }
   
   
};
const userId = SecurityService.getLoggedInUserId();
  // useEffect(() => {
  // setTimeout(() => {
    
  
  //   if(state.PINo  != '' && state.PIReferenceNo != ''  && checkDuplicate == true){
  //     console.log(props.dataList)
  //     console.log()
  //     var check = false
  //     if(props.isAdd ==  true){

  //        check = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
  //         PINo:state.PINo,
  //         PIReferenceNo:state.PIReferenceNo,
          
  //          })
  //     }
     
  //     // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
  //     console.log(check)
  //     // setCheckDuplicate(check)
  //     if(!check ){
  //       setCheckDuplicateValue('')
  //       if(state.PaymentDetailDataSource.length > 0){document.querySelector('#ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';}
  //     }else{

  //       setCheckDuplicateValue('Duplicate Value Detected!')
  //       document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        
  //   }
  //  }
  // }, 500);
  // }, [state ,state ]);
useEffect(() => {
    
  getPaymentType()
  
    getCustomer()
    if(props.isAdd ==  false){

      // getCurrency(props.CustomerID)
    }
    
  }, []);

  
  const getPaymentType = async () => {
    SetupService.getForList('PaymentType')
      .then((response) => {
        dispatch({ type: `PaymentTypeDataSource` ,  PaymentTypeDataSource:response.data.recordset,LoadingPaymentType:false})
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `PaymentTypeDataSource` ,  PaymentTypeDataSource:[],LoadingPaymentType:false})

      });
  };
  // useEffect(() => {
  //   console.log('update CC')
  //   if(state.CurrencyID != undefined && state.CustomerID != undefined){

  //     return getBalanceUnAllocatedAmount()
  //   }ReceiptDate
  
  // }, [state.ReceiptDate , state.CurrencyID]);
  const getBalanceUnAllocatedAmount = async () => {
    console.log(state.ReceiptDate)
    if(state.CustomerID != undefined && state.CurrencyID != undefined){
      console.log( state.CustomerID,     state.CurrencyID,     state.ReceiptDate  )
    ReceiveUnAllocatedPaymentService.getBalanceUnAllocatedAmount(moment(state.ReceiptDate ).format('DD-MMM-YY'),state.CurrencyID, state.CustomerID)
      .then((response) => {
        console.log('getBalanceUnAllocatedAmount', response.data.recordset);
        dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:response.data.recordset[0].BalanceAmount.toLocaleString()})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:''})
      });
    }
  };
  
useEffect(() => {
  console.log('update CC')
  getBalanceUnAllocatedAmount()

}, [state.ReceiptDate , state.CurrencyID]);
  
  const getCustomer = async (type: string) => {
    
    PIService.getCustomerForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:response.data.recordset,LoadingCustomer:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };
  // const getCurrency = async (type: string) => {
  //   PIService.getCurrencyForDD()
  //     .then((response) => {
  //       console.log('productFinishSize get response', response.data.recordset);
  //       dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:response.data.recordset,LoadingCurrencyName:false})

  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       dispatch({ type: `CurrencyNameDataSource`  , CurrencyNameDataSource:[],LoadingCurrencyName:false})
  //     });
  // };
  
  const getCurrency = async (id) => {
    CustomerService.getCurrencyUsingCustomerID(id)
          .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:response.data.recordset,LoadingCurrencyName:false})
        

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CurrencyNameDataSource`  , CurrencyNameDataSource:[],LoadingCurrencyName:false})
      });
  };

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      // case 'ProductCategory':
      //   // getProductCategory();
      // break;
      // case 'ProductDesc':
      //   // getProductDesc();
      // break;
      // case 'UnitOfMeasurement':
      //   // getUOMs();
      // break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }


let productDescRef = useRef(null)
      let uomRef = useRef(null)
      
  const minDate: Date = new Date(moment().startOf('day'));

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: false,
    mode:'Normal'
    // allowEditing: SecurityService.canUserAccess('ProductEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
  }
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    //  'Edit',
   
    "Update",
    "Cancel",
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];

  
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
  // 	CustomerID	PaymentTypeID	ReferenceNo	SenderName	Description	DebitAmount	CreditAmount	CurrencyID
  return (
    <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13 || e.keyCode == 27){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Receive UnAllocated Payment - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        
        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
            <br/>
                <div className="col-sm-4">
                    <mat-form-field>
                    <DatePickerComponent
                          id='ReceiptDate'
                          placeholder='Receipt date'
                          floatLabelType='Auto'
                          min={minDate}
                          name='ReceiptDate'
                          format='dd-MM-yy'
                          value={state.ReceiptDate}
                         
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCustomer ? (
                        <div>...  Loading Customer </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={state.CustomerDataSource}
                            placeholder="Select  Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>AutoCompleteChange(e,'CustomerName')}
                            change={(e)=>AutoCompleteChange(e,'CustomerName')}
                            value={state.CustomerName}
                            ref={state.CustomerRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CustomerID)
                              console.log('dfdfblur',state.CustomerName)
                              if (state.CustomerID  == 0  || state.CustomerRef.current.value == null || state.CustomerRef.current.value == '' || state.CustomerID == undefined || state.CustomerID == null) {
                               if(state.CustomerName != ''){
                                 let resstr = state.CustomerDataSource.some(e=>e.value ==  state.CustomerName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.Customer.CustomerName} Value Not Find ` )
                                  //  document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CustomerName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CustomerRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={state.CustomerID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  {/* <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingPaymentType ? (
                        <div>...  Loading PaymentType </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="PaymentType" 
                            dataSource={state.PaymentTypeDataSource}
                            placeholder="Select  Payment Type" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="PaymentType"
                            select={(e)=>AutoCompleteChange(e,'PaymentType')}
                            change={(e)=>AutoCompleteChange(e,'PaymentType')}
                            value={state.PaymentType}
                            ref={state.PaymentTypeRef}
                            blur={()=>{
                              console.log('dfdfblur',state.PaymentTypeID)
                              console.log('dfdfblur',state.PaymentType)
                              if (state.PaymentTypeID  == 0  || state.PaymentTypeRef.current.value == null || state.PaymentTypeRef.current.value == '' || state.PaymentTypeID == undefined || state.PaymentTypeID == null) {
                               if(state.PaymentType != ''){
                                 let resstr = state.PaymentTypeDataSource.some(e=>e.value ==  state.PaymentType);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.PaymentType.PaymentType} Value Not Find ` )
                                  //  document.getElementById("PaymentType").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("PaymentType").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.PaymentTypeRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='PaymentTypeID'
                            floatLabelType='Auto'
                            value={state.PaymentTypeID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div> */}
                  <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Reference No'
                        name='ReferenceNo'
                        floatLabelType='Auto'
                        value={state.ReferenceNo}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                  </div> 
                  <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Sender Name'
                        name='SenderName'
                        floatLabelType='Auto'
                        value={state.SenderName}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                  </div> 
                  <div className="col-sm-4">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Description'
                        name='Description'
                        floatLabelType='Auto'
                        value={state.Description}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                  </div> 
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCurrencyName ? (
                         <TextBoxComponent
                         type='text'
                         placeholder='Select Currency Name'
                         floatLabelType='Auto' 
                         highlight={true}
                 
                       />
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CurrencyName" 
                            dataSource={state.CurrencyNameDataSource}
                            placeholder="Select Currency Name" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CurrencyName"
                            select={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            change={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            value={state.CurrencyName}
                            ref={state.CurrencyNameRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CurrencyID)
                              console.log('dfdfblur',state.CurrencyName)
                              if (state.CurrencyID  == 0  || state.CurrencyNameRef.current.value == null || state.CurrencyNameRef.current.value == '' || state.CurrencyID == undefined || state.CurrencyID == null) {
                               if(state.CurrencyName != ''){
                                 let resstr = state.CurrencyNameDataSource.some(e=>e.value ==  state.CurrencyName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.CurrencyName.CurrencyName} Value Not Find ` )
                                  //  document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CurrencyNameRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CurrencyID'
                            floatLabelType='Auto'
                            value={state.CurrencyID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-4">
                  {state.BalanceUnAllocatedAmount  ?(
                  
                  <>  <h2 className='account-amount'>   ACCOUNT AMOUNT : {state.BalanceUnAllocatedAmount}</h2></> 
                      
                  
                  )
                  :<> </>}
                  </div> 
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Debit Amount'
                        id='DebitAmount'
                        disabled={props.isSaleAgent == 'SalesAgent'}
                        name='DebitAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={state.DebitAmount}
                        onChange={textBoxChange}
                        onFocus={(e)=>{
                          // e.value = 0
      dispatch({ type: 'DebitAmount' , DebitAmount:0 })
      setTimeout(() => {
        
        return   document.getElementById('DebitAmount').value = 0
      }, 200);
                          
                        }}

                        
                      />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Credit Amount'
                        id='CreditAmount'
                        name='CreditAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={state.CreditAmount}
                        onChange={textBoxChange}

                        focus={(e)=>{
                          console.log(e)
                          dispatch({ type: 'CreditAmount' , CreditAmount: 0})
        setTimeout(() => {
          
          return document.getElementById('CreditAmount').value = 0
        }, 200);
                          
                        }}
                      />
                    </mat-form-field>
                  </div>
                  
                  <br/>
                  <div className="col-sm-12">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Remarks'
                        name='Remarks'
                        floatLabelType='Auto'
                        value={state.Remarks}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                  </div> 
                  
                 
                  <br/>
                  <br/>
                  </div>

                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
      </div>
      {/* if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            } */}
      </div>
  )
  
}
