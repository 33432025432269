import React from 'react';
import {Link} from 'react-router-dom';
import securityService from '../security/security.service';

export default function ProtectedSubmenu(props)
{        
    let {displayValue, names , type ,icon} = props

    const canAccess = Object.values(names).map(res=>{
            
            return  securityService.canUserAccess(res);
        })

    console.log('props: ', canAccess.includes(true));
   

    switch (true) {
        case canAccess.includes(true) === true &&  type === 'submenu' :
            return (
        
                <a href="#" className="nav-link">
                  <i className={` fas fa-list-ul ${icon}`} />
                  <p>
                 {displayValue}
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>

          );
        case canAccess.includes(true) === true &&  type === 'mainmenu':
            return (
        
            <a href="#" className="nav-link active">

                 <i className={`nav-icon  ${icon}`}></i>
            <p>
            {displayValue} 
            <i className="fas fa-angle-left right" />

            </p>
            </a> 
                    );
                    
        default: return(
            <></>
        )
            break;
    }
    
}

