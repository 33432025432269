import React, { useEffect, useState , useRef } from 'react';
// import CommissionCalculatorService from '../../services/product.service';
import SecurityService from '../security/security.service';
import PIService from '../../services/PI.service';
import SaleSummaryService from '../../services/sales-summary.service';
import './sales-summary.scss';
import HardDelete from '../hard-delete/hard-delete';
// import CheckDuplicate  from '../check-duplicate/check-duplicate';
// import AuditTableInput from '../audit-table/audit-table';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExportProperfties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import TransferUnAllocatedPaymentAdd from './Transfer-unAllocated-payment-add';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
// import {image} from './image';
// import logoUrl from './logo192.png';
import CommissionCalculatorService from '../../services/commission-calculator.service';
import EmployeeService from '../../services/employee.service';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';
import CurrencyAdd from '../currency/currency-add';
import supplierService from '../../services/supplier.service';
import currencyService from '../../services/currency.service';
import { useMemo } from 'react';
// import { dataBind } from 'jodit/types/core/helpers';
var selectedRows = []
export default function SalesSummaryList(props) {
 
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [salesAgent, setSalesAgent] = useState('');
  const [loadingSalesAgent, setLoadingSalesAgent] = useState('');
  const [salesAgentID, setSalesAgentID] = useState(0);
  const [salesAgentValue, setSalesAgentValue] = useState('');

  const [customerDataSource, setCustomerDataSource] = useState([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customerID, setCustomerID] = useState(0);
  const [customerName, setCustomerName] = useState('');
  
  const [supplierDataSource, setSupplierDataSource] = useState([]);
  const [loadingSupplier, setLoadingSupplier] = useState(false);
  const [supplierID, setSupplierID] = useState(0);
  const [supplierName, setSupplierName] = useState('');
  
  const [currencyDataSource, setCurrencyDataSource] = useState([]);
  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [currencyID, setCurrencyID] = useState(0);
  const [currencyName, setCurrencyName] = useState('');
  
  const [saleStatusDataSource, setSaleStatusDataSource] = useState([]);
  const [loadingSaleStatus, setLoadingSaleStatus] = useState(false);
  const [saleStatusID, setSaleStatusID] = useState(0);
  const [salesStatusName, setSaleStatusName] = useState('');
var data  = [];
var isCustomer = false;
useEffect(() =>{
  if(customerID == 1){
isCustomer = true;
  }else{
    isCustomer = false;
  }
},[customerID])
  // useEffect(() => {
   
  //   // getSalesRecord(data)
  // }, [fromDate , toDate , salesAgentID , customerID , supplierID , currencyID , saleStatusID]);
// var img =  imagess;
 var backupCommissionCalculatorDataSource = []

  const [SalesRecordDataSource, setSalesRecordDataSource] = useState([]);
  const [isCommissionCalculator, setIsCommissionCalculator] = useState(false);
  
  
  const [showDropDown, setShowDropDown] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  // const [deletingProps, setDeletingProps] = useState();
  // const [showDialog, setShowDialog] = useState(false);
  // const [showDialogs, setShowDialogs] = useState(false);
  // const [isDuplicate, setIsDuplicate] = useState(false);
  const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();
  const [proccessedVal, setProccessedVal] = useState('');
  // const [showDialogEmail, setShowDialogEmail] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  let SalesRecordGrid: Grid;
  const ddlFields: object = { value: 'value' };

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (args?.item?.properties?.id === 'Cancel') {
  // console.log(userSecurityItems);
  // to reload the grid
  // setUserSecurityItems([]);
  // getUserSecurityItems(userID);

  // SalesRecordGrid?.refresh();
  // SalesRecordGrid?.refreshDataSource();
      SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      if(isCommissionCalculator == true){
        getSalesRecord();
      }else{
        getUnProcessedCommissionCalculator();
      }
    }
    if (args?.item?.properties?.id === 'Update') {
      console.log(SalesRecordGrid?.getCurrentViewRecords());
      console.log("Update")
      CommissionCalculatorService.update(SalesRecordGrid.getCurrentViewRecords()).then((res)=>{
        console.log(res)
        SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
        if(isCommissionCalculator == true){
          getSalesRecord();
        }else{
          getUnProcessedCommissionCalculator();
        }
      }).catch((e)=>{
        console.log(e)
  // SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      })
      SalesRecordGrid?.editModule?.batchSave();
  // console.log(SalesRecordGrid?.getCurrentViewRecords());
  // SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_PDFExport'
    ) {
      // const exportProperties: PdfExportProperties = {
      //   header: { 
      //     contents: [ 
      //       {
      //         position: { x: 10, y: 30 },
      //         size: { width: 120, height: 80 }, // Set the width and height of the image
      //         type: "Image",
      //         src:image // Replace with the URL of your image
      //       },
      //       { 
      //         position: { x:420 , y: 50 }, 
      //         style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DALAL INDUSTRIES`
              
      //       },
      //       { 
      //         position: { x:430 , y: 80 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `PRODUCT FINISH SIZE`
              
      //       },          
      //       { 
      //         position: { x:830 , y: 90 }, 
      //         style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //         type: "Text", 
      //         value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
              
      //       }
          
      //       ]
      //      ,
      //      fromTop: 0, 
      //       height: 130 
      //   },
      //    footer: {
      //               contents: [
      //                   {
      //                       format: 'Page {$current} of {$total}',
      //                       pageNumberType: 'Northwind Traders',
      //                       position: { x: 900, y: 25 },
      //                       style: {
      //                           fontSize: 15,
      //                           textBrushColor: '#000000'
      //                       },
      //                       type: 'PageNumber'
      //                   },
      //                   { 
      //                     position: { x:430 , y: 25 }, 
      //                     style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
      //                     type: "Text", 
      //                     value: `Printed By: ${printedByUserName}`
                          
      //                   }
      //               ],
      //               fromBottom: 20,
      //               height: 60
      //           },
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.pdf`
      // };
      // console.log('clicked on pdf export');
      // SalesRecordGrid.pdfExport(exportProperties);
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_sales-summary-records.pdf`
    };
    console.log('clicked on pdf export');
    
      
       SalesRecordGrid.pdfExport(exportProperties);

    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_sales-summary-records.xlsx`
    };
    SalesRecordGrid.excelExport(exportProperties);
      // const exportProperties: ExcelExportProperties = {
        
      //   pageOrientation: 'Landscape',
      //   fileName: `${moment().format('YYYYMMDD')}_TransferUnAllocatedPaymentGrid_information.xlsx`,
      //   footer: {
      //     footerRows: 2,
      //     rows: [
      //         { cells: [{ colSpan: 4, value: `Printed By: ${printedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
      //     ]
      // },
      // header: {
      //     headerRows: 4,
      //     rows: [
      //         { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "VOMS", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: "Proforma Invoice", style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
      //         { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
      //     ]
      // },

      // };
      // // const customizeHeader = ({ element }) => {
      // //   // Insert the logo in the header
      // //   const logoImage = new Image();
      // //   logoImage.src = image;
      // //   logoImage.width = 100; // Adjust the width as per your requirement
      // //   logoImage.height = 50; // Adjust the height as per your requirement
    
      // //   // Add the logo to the header cell
      // //   const headerCell = element.sheet.rows[0].cells[0];
      // //   headerCell.appendChild(logoImage);
    
      // //   // Merge cells for the logo
      // //   element.sheet.merge(headerCell.rowIndex, headerCell.columnIndex, headerCell.rowIndex + 3, headerCell.columnIndex + 1);
      // // };
      
      // SalesRecordGrid.excelExport(exportProperties);
      // // SalesRecordGrid.beforeDataBound(customizeHeader)
      
    }
    if (
      SalesRecordGrid &&
      args.item.properties.id === 'SalesRecordGrid_columnchooser'
    ) {
    }
    if (SalesRecordGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (SalesRecordGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', SalesRecordGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProductFinishSize`,
          ColumnName:`ProductFinishSizeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        // setDeletingProps(obj)
        // setShowDialogs(true)
       
      } else {
        console.log('delete: ', SalesRecordGrid.getSelectedRecords()[0]?.TransferID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`TransferUnAllocatedPayment`,
          ColumnName:`TransferID`,
          RowID:SalesRecordGrid.getSelectedRecords()[0]?.TransferID,
          SelectedRow:true,
          SelectedRowData:{
            ...SalesRecordGrid.getSelectedRecords()[0]
          }
          
          }
        //   setDeletingProps(obj)
        
        // // deleteDialog.current.show();
        // setShowDialogs(true)
      }
    } 
  };

  useEffect(() => {
    // getSalesRecord();

    SecurityService.getUserAccessList(userId).then((res)=>{
      console.log(res)
      console.log(res.data.recordset[0])
      setPrintedByUserName(res.data.recordset[0].UserName)
      setPrintedByEmail(res.data.recordset[0].Email)
    });
    getCustomer()
    getSalesAgent()
    getSupplier()
    getCurrency()
    getCrmStatus()
  }, []);

  const [setShow, setSetShow] = useState(false);
  const getSalesRecord = async () => {
    setSetShow(true)
    // '${req.params.FromDate}' , '${req.params.ToDate}' , ${req.params.SalesAgentID} , ${req.params.CustomerID} , ${req.params.SupplierID} , ${req.params.CurrencyID} , ${req.params.SaleStatus}
    const toDateget = new Date(toDate);
    const formattedToDate = moment(toDateget).format('DD-MMM-YY');
      const fromDateget = new Date(fromDate);
    const formattedFromDate = moment(fromDateget).format('DD-MMM-YY');
      let  data = {
        FromDate : formattedFromDate,
        ToDate   : formattedToDate,
        SalesAgentID : salesAgentID,
        CustomerID : customerID,
        ReportType : customerName == '' ||  customerName == undefined ? 'null' : customerName,
        SupplierID : supplierID,
        CurrencyID : currencyID,
        SaleStatus : saleStatusID
      }
      console.log(data)
    SaleSummaryService.getSalesSummary(data.FromDate, data.ToDate , data.ReportType , data.SalesAgentID , data.SupplierID , data.CurrencyID , data.SaleStatus)
      .then((response) => {
        console.log('getSalesRecord get response', response.data.recordset);
      setIsCommissionCalculator(true)
        setSalesRecordDataSource(response.data.recordset);
        backupCommissionCalculatorDataSource =  response.data.recordset;
      })
      .catch((e) => {
        console.log(e);
        setSalesRecordDataSource([]);
      });
  };

  const getUnProcessedCommissionCalculator = async () => {
    // :customerid/:salesAgentID
    setProccessedVal('- UNPROCESSED')
    console.log(customerID)
    console.log(salesAgentID)
    CommissionCalculatorService.getList(customerID, salesAgentID, 'UnProcessed')
      .then((response) => {
        console.log('getUnProcessedCommissionCalculator get response', response.data.recordset);
        setIsCommissionCalculator(false)
        setSalesRecordDataSource(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSalesAgent = async () => {
    setLoadingSalesAgent(true);
    EmployeeService.getEmployeeForList()
      .then((response) => {
        console.log('SalesAgent get response', response.data.recordset);
        let data = response.data.recordset.filter((Res)=>{return Res.DesignationID == 3})
        let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSalesAgent(mapData);
        setLoadingSalesAgent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
 
  const getCurrency = async () => {
    setLoadingCurrency(true);
    currencyService.getCurrencyForDD()
      .then((response) => {
        console.log('SalesAgent get response', response.data.recordset);
        // let data = response.data.recordset.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setCurrencyDataSource(response.data.recordset);
        setLoadingCurrency(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
 
  const getCrmStatus = async () => {
    setLoadingSaleStatus(true);
    PIService.getCRMStatusForDD()
      .then((response) => {
        console.log('SalesAgent get response', response.data.recordset);
        // let data = response.data.recordset.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSaleStatusDataSource(response.data.recordset);
        setLoadingSaleStatus(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSupplier = async () => {
    setLoadingSupplier(true);
    supplierService.getSupplierForDD()
      .then((response) => {
        console.log('SalesAgent get response', response.data.recordset);
        // let data = response.data.recordset.filter((Res)=>{return Res.DesignationID == 3})
        // let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSupplierDataSource(response.data.recordset);
        setLoadingSupplier(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCustomer = async (type: string) => {
    PIService.getCustomerForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);

        setCustomerDataSource(response.data.recordset)
        setLoadingCustomer(false)
        // dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:response.data.recordset,LoadingCustomer:false})
        
      })
      .catch((e) => {
        console.log(e);
        setCustomerDataSource([])
        setLoadingCustomer(true)
        // dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    // 'Add',
    // 'Edit',
    // {
    //   // text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('ProductFinishSizeDelete')    },
    //   text: "Delete", tooltipText: "Delete", id: "Delete", disabled:false},
    // 'Update',
    // 'Cancel',
    'PDFExport',
    'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
     {
      text: "Cancel", tooltipText: "Cancel", id: "Cancel", disabled: true
    },
    {
      text: "Update", tooltipText: "Update", id: "Update", disabled: true
    }
  ];

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ? (
      // <div dataList={SalesRecordDataSource} {...props}> </div>
      // <TransferUnAllocatedPaymentAdd dataList={SalesRecordDataSource} {...props}> </TransferUnAllocatedPaymentAdd>
      <></>
      
    ) :
      (
        // <PIAdd dataList={productFinishSize} {...props}> </PIAdd>
        <></>
      );
  };

  const editSettings: EditSettingsModel = {
    // allowEditing: SecurityService.canUserAccess('CommissionCalculatorPerUnitEdit'),
    // allowAdding: SecurityService.canUserAccess('CommissionCalculatorPerUnitAdd'),
    // allowDeleting: SecurityService.canUserAccess('CommissionCalculatorPerUnitDelete'),
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          // mode: 'Dialog',
          // showDeleteConfirmDialog: true,
          // template: dialogTemplate,
    // allowEditing: true,
    mode: 'dialog',
    showConfirmDialog: false      
    // headerTemplate: '<p></p>',
    // footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
    // columns:[{field:'CurrencyName',  direction: 'Descending' }]
    // columns:['CurrencyName']
  };


  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();
   
  // console.log(userName)
  
  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    console.log('data: ',  SalesRecordGrid.getCurrentViewRecords());
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      SalesRecordGrid?.autoFitColumns();
    }
    if(args.requestType == "batchsave"){
      console.log('436')
    
    }
    if (args.form) {
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      // if (args.requestType === 'save'){
      //   console.log(args.data)
      //   const data = {
      //     CreatedByUserID : userId,
      //     ...args.data,
      //     TransferDate: moment(args.data.TransferDate).format('YYYY-MM-DD')
      //   }
      //   CommissionCalculatorService.addTransferUnAllocatedPayment(data)
      //     .then((response) => {
      //   getSalesRecord()
      //     })
      // }
    //   if (args.requestType === 'save'  && args.action   === 'aSSdd') {
    //     const data = {
    //       UserId: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     console.log('args: ', data)
    //     // delete productFinishSize[0]

      
    //   CommissionCalculatorService.addProductFinishSize(data)
    //   .then((response) => {
    //     let AddData = {
    //       FinishDrop: args.data.FinishDrop,
    //       FinishLength: args.data.FinishLength,
    //       FinishSize: args.data.FinishSize,
    //       FinishWidth: args.data.FinishWidth,
    //       ProductDesc: args.data.ProductDesc,
    //       UnitOfMeasurement: args.data.UOM
          
    //               }
    //     AuditTableInput.auditAdd(`ProductFinishSize`,`ProductFinishSize`,`ProductFinishSizeID`,AddData)

    //     setSalesRecordDataSource(response?.data?.recordset)});
  
    // }
       
    //   if (args.requestType === 'save'  && args.action   === 'ediSt') {
    //     const data = {
    //       UserID: userId,
    //       ...args.data,
    //       ProductCategoryID:1
    //     };
    //     console.log('args: ', data)
    //     CommissionCalculatorService.updateProductFinshSize(args.data.ProductFinishSizeID,data)
    //       .then((response) => {
    //         let EditData = {
    //           FinishDrop: args.data.FinishDrop,
    //           FinishLength: args.data.FinishLength,
    //           FinishSize: args.data.FinishSize,
    //           FinishWidth: args.data.FinishWidth,
    //           ProductDesc: args.data.ProductDesc,
    //           UnitOfMeasurement: args.data.UnitOfMeasurement
              
    //                   }
    //                   let totalColumns = Object.keys(EditData);
    //                   console.log(totalColumns)
    //                   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`ProductFinishSize`,args.data.ProductFinishSizeID)
    //         getSalesRecord();
    //         console.log(response)});
    //   }

    }

  };
  const databound = () =>
  {
      if (SalesRecordGrid) {
        SalesRecordGrid.autoFitColumns();
      }
  }

  // exec spProformaInvoiceForList


  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email',
        cssClass: 'e-flat',
        isPrimary: true,
      },
    }
  
  ];
//   const commandClick = (args: CommandClickEventArgs) => {
//     if (args.commandColumn.buttonOption.id === 'email') {
//       setShowDialogEmail(false);
//       console.log('command click args: ', args.commandColumn.buttonOption.id);
//       setSelectedRow(args.rowData);
//       setShowDialogEmail(true);
//     }
//   }
//   const emailHeader = () => {
//     return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
//   };

//   const contentEmailTemplate = () => {
//     if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
//     return (
//       <div>
//         <EmailEditorPopup
//           {...selectedRow}
//           loggedInUser={printedByEmail}
//           isPreview={false}
//         ></EmailEditorPopup>
//       </div>
//     )
//   }else{
//  return( 
//     <div className='returnlist'>
//       {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
//     </div>
//  )
//   }
//   };
//   const emailDialogClose = () => {
//     this.buttonInstance.style.display = 'inline-block';
//     setShowDialogEmail(false);
//   };
let refreshColumn =(columnName) =>{
  if (SalesRecordGrid) {
    SalesRecordGrid.columns.forEach(column => {
      if (column.field === columnName) {
        SalesRecordGrid.refreshColumns(column);
      }
    });
  }
}
const handleCheckboxChange = (e,data) => {
   console.log(e)
   console.log(data)

   
  CommissionCalculatorService.updateApproveCommissionAmountPerUnit(data.PIID,userId).then((res)=>{

    let dataFin = SalesRecordGrid.map((Res)=>{
      if(data.PIID == Res.PIID){
        console.log(Res)
        if(Res.PaymentVerificationStatus == true){
          Res.PaymentVerificationStatus = false;
          Res.CRMID = 0;
          Res.CRMName = null
          Res.PaymentVerificationStatusDate = null
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
 
        }else{
          Res.PaymentVerificationStatus = true;
          Res.CRMID = userId
          Res.CRMName = printedByUserName
          Res.PaymentVerificationStatusDate = new Date()
          refreshColumn('PaymentVerificationStatus')
          refreshColumn('CRMName')
          refreshColumn('PaymentVerificationStatusDate')
        }
      }
    })
    console.log(dataFin)

  })
};

let RemarksUseRef =  useRef(null)
let FinalCommissionAmountPerUnitApprovedUseRef =  useRef(null)
  const SalesRecordGridComponent = useMemo(()=>{ return ({ restrictPageSize = 12 }) => (


    <GridComponent
      ref={(grid) => (SalesRecordGrid = grid)}
      id='SalesRecordGrid'
      dataSource={SalesRecordDataSource}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
    
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      // commandClick={commandClick}
      // dataBound={databound}
      // created={() => {
      //   SalesRecordGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
      // }}
      rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
          
                    setTimeout(() => {
            console.log('ss')
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='PI ID'
          field='PIID'
          allowEditing={false}
          width='90'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        {/* SerialNo	
MonthYearPIDate	
SalesAgentName	
PIID	
	
PIReferenceNo	
SupplierName	
CustomerName	
CurrencyName	
BookAmount	
TotalReceiptAmount	
AdjustmentAmount	
CRMStatus	 */}

{/* 	
	
	
	
	
 */}


        <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          allowEditing={false}
          width='120'
          headerTextAlign='CENTER'
          textAlign='right'
        />
        <ColumnDirective
          headerText='Agent'
          field='SalesAgentName'
          allowEditing={false}
          width='120'
          headerTextAlign='CENTER'
          textAlign='left'
        />
        {
          customerID  == 1 ? 
          <ColumnDirective
            headerText='Customer'
            headerTextAlign='CENTER'    
            field='CustomerName'
            allowEditing={false}
            width='155'
            textAlign='left'
          /> :
          <ColumnDirective
            headerText='Supplier'
            headerTextAlign='CENTER'    
            field='SupplierName'
            allowEditing={false}
            width='160'
            textAlign='left'
          />

        }
        <ColumnDirective
          headerText="Currency"
          headerTextAlign='CENTER'    
          field='CurrencyName'
          allowEditing={false}
          width='165'
          textAlign='left'
          // format={formatOptions}
          // type='date'
          
        />
         {/* <ColumnDirective
          headerText='CRM Status'
          headerTextAlign='CENTER'    
          field='CRMStatus'
          allowEditing={false}
          width='150'
          textAlign='left'
          format='n0'
        /> */}
        <ColumnDirective
          headerText='No. Of Units'
          headerTextAlign='CENTER'    
          field='NoOfPI'
          allowEditing={false}
          width='155'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Booking Amount'
          headerTextAlign='CENTER'    
          field='TotalBookAmount'
          allowEditing={false}
          width='165'
          textAlign='right'
          format='n0'

        />
        <ColumnDirective
          headerText='Adjustment Amount'
          headerTextAlign='CENTER'    
          field='TotalAdjustmentAmount'
          allowEditing={false}
          width='170'
          textAlign='right'
          format='n0'
        />
          {/* <ColumnDirective
          headerText='CRM Status'
          headerTextAlign='CENTER'    
          field='CRMStatus'
          allowEditing={false}
          width='155'
          textAlign='left'
          format='n0'
        /> */}
      </ColumnsDirective>
       <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='TotalAdjustmentAmounts'
                    type='Sum'
                    // footerTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='TotalBookAmounts'
                    type='Sum'
                    format={'n0'}
                    // footerTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='TotalAdjustmentAmounts'
                    type='Sum'
                    // groupFooterTemplate={footerSum}
                    format={'n0'}
                  />
                  <AggregateColumnDirective
                    field='TotalBookAmounts'
                    type='Sum'
                    format={'n0'}
                    // groupFooterTemplate={footerSum}
                  />
                </AggregateColumnsDirective>
             
              </AggregateDirective> 
            </AggregatesDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          CommandColumn,
        ]}
      />
    </GridComponent>
  )},[SalesRecordDataSource , setShow == true]);

  return (
    (props.isDialog) ? (
      <div>
        {/* <div className='drop-downs-div'> */}
        <div className='drop-downs-div'>
          <div className='required'>
        <DatePickerComponent
              id='FromDate1'
              placeholder='Select From Date'
              floatLabelType='Auto'
              // min={minDate}
              name='FromDate1'
              format='MMM-yy'
              width={150}
              value={fromDate}
            change={(e)=>{
            console.log(e.value)
            setFromDate(e.value)
           }}
            />
            </div>
            <div className='required'>
          
        <DatePickerComponent
              id='ToDate1'
              placeholder='Select To Date'
              floatLabelType='Auto'
              // min={minDate}
              name='ToDate1'
              format='MMM-yy'
              width={150}
              value={toDate}
            change={(e)=>{
            console.log(e.value)
            setToDate(e.value)
           }}
            />
            </div>
            <div className='required'>
            {loadingCustomer ? (
                        <div>... Loading  Report Type</div>
                      ) : (
                        <div className='required'>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={[{id: 1 , value: 'Customer'}, {id: 2 , value: 'Supplier'}]}
                            placeholder="Select Report Type" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
            </div>
           {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  console.log('SalesAgent change: ', e?.itemData?.id);
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}

              



            
                                                <button id='get-data-btn' onClick={getSalesRecord}> Get Sales Summary</button>


                        {/* </div>: <></>} */}
  
                        </div>
            
       {setShow == true  ? <SalesRecordGridComponent restrictPageSize={5} /> : null}
      </div>
    )
      :
      (
        <div className="content-wrapper">
            
          <div>
            
            <div>
            
              <h3> Sales Summary - List </h3>
              
        <div className='drop-downss-div'>
          <div className='required'>
          <DatePickerComponent
              id='FromDate2'
              placeholder='Select From Date'
              floatLabelType='Auto'
              // min={minDate}
              // type='nu'
              name='FromDate2'
              format='dd-MM-yy'
              width={150}
              value={fromDate}
            change={(e)=>{
            console.log(e.value)
            setFromDate(e.value)
           }}
            />
            </div>
          <div className='required'>
        <DatePickerComponent
              id='ToDate2'
              placeholder='Select To Date'
              floatLabelType='Auto'
              // min={minDate}
              name='ToDate2'
              format='dd-MM-yy'
              width={150}
              value={toDate}
            change={(e)=>{
            console.log(e.value)
            setToDate(e.value)
           }}
            />
            </div>
            <div className='required'>
            {loadingCustomer ? (
                        <div>... Loading  Report Type</div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CustomerName" 
                            dataSource={[{id: 1 , value: 'Customer'}, {id: 2 , value: 'Supplier'}]}
                            placeholder="Select Report Type" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CustomerName"
                            select={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            change={(e)=>{
                                setCustomerName(e?.itemData?.value ? e?.itemData?.value : '');
                              setCustomerID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setCustomerID(e?.itemData?.id);
                              }
                            }}
                            value={customerName}
                            // ref={CustomerRef}
                            
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CustomerID'
                            floatLabelType='Auto'
                            value={customerID}
                          />
                          {/* <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span> */}
                        </div>
                      )}
          </div>
           {loadingSalesAgent ? (
                          <div>... Loading Sales Agent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                              setSalesAgentID(0);
                              if (e?.e?.srcElement?.id) {
                                console.log('SalesAgent change: ', e?.itemData?.id);
                                setSalesAgentID(e?.itemData?.id);
                              }
                              }}
                              change={(e)=>{
                                setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
                                setSalesAgentID(0);
                                if (e?.e?.srcElement?.id) {
                                  console.log('SalesAgent change: ', e?.itemData?.id);
                                  setSalesAgentID(e?.itemData?.id);
                                }
                              }}
                              value={salesAgentValue}
                              />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            {/* <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span> */}
                          </div>
                        )}

              


            
                                                <button id='get-data-btn' disabled={customerName == '' ? true : false} onClick={getSalesRecord}> Get Sales Summary</button>
                                                {/* <button id='get-data-btn' onClick={getUnProcessedCommissionCalculator}> Get UnProcessed Commission</button> */}

                        </div>
{/*               
              <div>
              </div> */}
              <div>

              {setShow == true  ? <SalesRecordGridComponent /> : null}
              </div>
            </div>
          </div>
          
        </div>
      )
  );
}
