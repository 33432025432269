import http from "./http-common";

class EmployeeService {
  
  getEmployeeForList() {
    return http.get('/employee/list');
  }

  getEmployeeForDD() {
    return http.get('/employee/dropdown');
  }

  addEmployee(data) {
    return http.post('/employee/add', data);
  }

  addEmployeeSupplierWisePerUnitCommissionDetail(data) {
    return http.post('/employee/SupplierWisePerUnitCommissionDetail/add', data);
  }

  addEmployeeSupplierWisePrimaryCommissionDetail(data) {
    return http.post('/employee/SupplierWisePrimaryCommissionDetail/add', data);
  }

  addEmployeeSupplierWiseSecondaryCommissionDetail(data) {
    return http.post('/employee/SupplierWiseSecondaryCommissionDetail/add', data);
  }
  addEmployeeSupplierWisePerUnitCommissionDetailGrid(data) {
    return http.post('/employee/SupplierWisePerUnitCommissionDetailGrid/add', data);
  }

  addEmployeeSupplierWisePrimaryCommissionDetailGrid(data) {
    return http.post('/employee/SupplierWisePrimaryCommissionDetailGrid/add', data);
  }

  addEmployeeSupplierWiseSecondaryCommissionDetailGrid(data) {
    return http.post('/employee/SupplierWiseSecondaryCommissionDetailGrid/add', data);
  }
  getEmployeeSupplierWisePerUnitCommissionDetail(id) {
    return http.get(`/employee/EmployeeSupplierWisePerUnitCommissionDetail/${id}`);
  }
  getEmployeeSupplierWisePrimaryCommissionDetail(id) {
    return http.get(`/employee/EmployeeSupplierWisePrimaryCommissionDetail/${id}`);
  }
  getEmployeeSupplierWiseSecondaryCommissionDetail(id) {
    return http.get(`/employee/EmployeeSupplierWiseSecondaryCommissionDetail/${id}`);
  }
  updateEmployeeSupplierWisePerUnitCommissionDetail(data) {
    return http.put(`/employee/Update/EmployeeSupplierWisePerUnitCommissionDetail`,data);
  }
  updateEmployeeSupplierWisePrimaryCommissionDetail(data) {
    return http.put(`/employee/Update/EmployeeSupplierWisePrimaryCommissionDetail`,data);
  }
  updateEmployeeSupplierWiseSecondaryCommissionDetail(data) {
    return http.put(`/employee/Update/EmployeeSupplierWiseSecondaryCommissionDetail`,data);
  }

  delete(EmployeeID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/employee/${EmployeeID}`)
  }

  updateEmployee(id,data) {
    return http.put(`/employee/update/employee/${id}`,data);
  }
  EmployeeIsDeletedService(id ,dataS){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/employee/changeIsDeleted/${id} `,dataS)
  }
  SaleAgentIDByUserID(id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.get(`/employee/SaleAgentID/${id} `)
  }
  getUserForEmployeeInput(){
    return http.get(`/employee/user/list/ForEmployeeInput`)
  }
}

export default new EmployeeService();
