import { React } from 'react';
import AccessDenied from '../security/access-denied/access-denied'
import securityService from '../security/security.service'
import SetupList from '../setup/setup-list';
import CountryCityPortList from "../country-city-port/country-city-port-list";
import CurrencyList from "../currency/currency-list";
import CustomerList from "../customer/customer-list";
import EmployeeList from '../employee/employee-list';
import SupplierList from "../supplier/supplier-list";
import UsersList from "../users/users-list";
import UserReplicateList from "../user-replicate/user-replicate";
import EmailEditor from "../email-editor/email-editor-list";
import PIList from '../PI/PI-list'
import PIMessageList from '../messages/messages-list'
import ReceiveUnAllocatedPaymentList from '../receive-unAllocated-payment/receive-unAllocated-payment-list'
import TransferUnAllocatedPaymentList from '../transfer-unAllocated-payment/Transfer-unAllocated-payment-list';
import UploadSupplierList from '../upload-supplier/upload-supplier-list';
import CommissionCalculatorPerUnitList from '../commission-calculator/commission-calculator-list';
import CommissionReportList from '../commission-details/commission-details-list';
import SalesRecordList from '../sales-record/sales-record-list';
import SalesSummaryList from '../sales-summary/sales-summary-list'; 
import DueDateList from '../due-date/due-date-list';
import LedgerList from '../ledger/ledger-list';

import DuplicatePIList from '../pi-duplicate/pi-duplicate';

export default function SecureComponent(props) {
    const canUserAccess = securityService.canUserAccess(props.name);
    if (!canUserAccess) {
        return <AccessDenied {...props} />
    }
    else {
        switch (props.name) {
            case 'CommissionReportList':
                return <CommissionReportList/>
            case 'CityList':
                return <SetupList />
            case 'CountryCityPortList':
                return <CountryCityPortList/>
            case 'CountryList':
                return <SetupList />
            case 'CurrencyList':
                return <CurrencyList/>
            // case 'CustomerList':
            //     return <CustomerList/>
            case 'CustomerList':
                return <CustomerList isCallSaleAgent={false} />
            case 'CustomerList-SalesAgent':
                return <CustomerList isCallSaleAgent={true} />
            case 'DesignationList':
                return <SetupList />
            case 'EmployeeList':
                return <EmployeeList/>
            case 'PaymentTypeList':
                return <SetupList />
            case 'PortList':
                return <SetupList />
            case 'PurchaseSourceList':
                return <SetupList />
            case 'ShipmentTypeList':
                return <SetupList />
            case 'SupplierList':
                return <SupplierList/>
            case 'TradeBoardLetterStatusList':
                return <SetupList/>
            case 'UsersList':
                return <UsersList/>
            case 'UserReplicateList':
                return <UserReplicateList/>
            case 'EmailList':
                return <EmailEditor/>
            case 'PISupervisorList':
                return <PIList />
            case 'PISalesAgentList':
                return <PIList />
            case 'PICRMList':
                return <PIList />
            case 'PIMessageList':
                return <PIMessageList />
            case 'ReceiveUnAllocatedPaymentSalesAgentList':
                return <ReceiveUnAllocatedPaymentList />
            case 'ReceiveUnAllocatedPaymentCRMList':
                return <ReceiveUnAllocatedPaymentList />
            case 'TransferUnAllocatedPaymentList':
                return <TransferUnAllocatedPaymentList />
            case 'UploadSupplierList':
                return <UploadSupplierList />
            case 'CommissionCalculatorPerUnitList':
                return <CommissionCalculatorPerUnitList />
                case 'SaleRecordList':
                    return <SalesRecordList />
                case 'SaleSummaryList':
                    return <SalesSummaryList />
                case 'CommissionReportList':
                return <CommissionReportList />
                case 'DueDateReportList':
                    return <DueDateList />
                case 'CustomerLedgerList':
                    return <LedgerList />
                case 'DuplicatePIList':
                    return <DuplicatePIList />
                default:

        break;
        }
    }
}
