import React, { useEffect, useState } from 'react';
import EmployeeService from '../../services/employee.service';
import SecurityService from '../security/security.service';
import './employee.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import EmployeeAdd from './employee-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
var selectedRows = []

export default function EmployeeList(props) {
  const [employee, setEmployee] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  let employeeGrid: Grid;

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      employeeGrid &&
      args.item.properties.id === 'EmployeeGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Employee_information.pdf`
      };
      console.log('clicked on pdf export');
      employeeGrid.pdfExport(exportProperties);
    }
    if (
      employeeGrid &&
      args.item.properties.id === 'EmployeeGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Employee_information.xlsx`
      };
      employeeGrid.excelExport(exportProperties);
    }
    if (
      employeeGrid &&
      args.item.properties.id === 'EmployeeGrid_columnchooser'
    ) {
    }
    if (employeeGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (employeeGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', employeeGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`Employee`,
          ColumnName:`EmployeeID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        console.log('delete: ', employeeGrid.getSelectedRecords()[0]?.EmployeeID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`Employee`,
          ColumnName:`EmployeeID`,
          RowID:employeeGrid.getSelectedRecords()[0]?.EmployeeID,
          SelectedRow:true,
          SelectedRowData:employeeGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    getEmployee();
  }, []);

  const getEmployee = async (type: string) => {
    EmployeeService.getEmployeeForList()
      .then((response) => {
        console.log('Employee get response', response.data.recordset);
        setEmployee(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('EmployeeDelete')    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ? (
      <EmployeeAdd dataList={employee} {...props}> </EmployeeAdd>
      // <div></div>
    ) :
      (
         <EmployeeAdd dataList={employee} {...props}> </EmployeeAdd>
        // <div></div>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess('EmployeeEdit'),
    allowAdding: SecurityService.canUserAccess('EmployeeAdd'),
    allowDeleting: SecurityService.canUserAccess('EmployeeDelete'),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      employeeGrid?.autoFitColumns();
    }
    if (args.form) {
      if(args.requestType ==  'save'){
        setTimeout(() => {
    return   getEmployee();

        }, 400);
      }
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'  && args.action   === 'add') {
        const data = {
          ...args.data,
          CommissionPercent:0,
          UserID:args.data.UserID,
          CreatedByUserID:userId,
        };
        console.log('args: ', data)
        delete employee[0]

      
      EmployeeService.addEmployee(data)
      .then((response) => {
        AuditTableInput.auditAdd(`Employee`,`Employee`,`EmployeeID`,data)

        setEmployee(response?.data?.recordset)}).catch(()=>{
          employeeGrid.refresh()
          // setTimeout(() => {
            
          //   EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
          // }, 300);
        })
  
    }
       
      if (args.requestType === 'save'  && args.action   === 'edit') {
        const data = {
          // UserID: userId,
          ...args.data,
          CommissionPercent:0,
          UserID:args.data.UserID,
 CreatedByUserID:userId,
        };
        console.log('args: ', data)
        EmployeeService.updateEmployee(args.data.EmployeeID,data)
          .then((response) => {
            let totalColumns = Object.keys(args.data);
            console.log(totalColumns)
            AuditTableInput.auditEdit(totalColumns,selectedRows,args.data,`Employee`,args.data.EmployeeID)
            getEmployee();
            console.log(response)}).catch(()=>{
              employeeGrid.refresh()
              // setTimeout(() => {
                
              //   EmployeeSupplierWisePerUnitCommissionDetailGrid.addRecord()
              // }, 300);
            })
      }

    }

  };
  const databound = () =>
  {
      if (employeeGrid) {
        employeeGrid.autoFitColumns();
      }
  }
  let refreshColumn =(columnName) =>{
    if (employeeGrid) {
      employeeGrid.columns.forEach(column => {
        if (column.field === columnName) {
          employeeGrid.refreshColumns(column);
        }
      });
    }
  }
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const userName = SecurityService.getLoggedInUser();
  const handleCheckboxChange = (e,data) => {
    // console.log(data.C)
    let dataS = {
      UserID:data.UserID,
      DeletionDate : e.target.checked == true? `${new Date()}`: null,
      DeletedByUserName : userName,
      DeletedByUserID : e.target.checked == true? userId: null,
      IsDeleted:e.target.checked == true? 1: 0
    }
    EmployeeService.EmployeeIsDeletedService(data.EmployeeID,dataS).then((res)=>{
 
      let dataFin = employee.map((Res)=>{
        if(data.EmployeeID == Res.EmployeeID){
          console.log(Res)
          if(Res.IsDeleted == true){
            Res.IsDeleted = false;
           //  Res.CRMID = 0;
           //  Res.CRMName = null
           //  Res.PaymentVerificationStatusDate = null
           Res.DeletionDate = new Date()
           Res.DeletedByUserName = userName
           Res.DeletedByUserID = userId
           refreshColumn('IsDeleted')
           refreshColumn('DeletionDate')
            refreshColumn('DeletedByUserName')
           
            Res.DeletionDate = new Date()
           //  Res.IsDeleted = true
            refreshColumn('IsDeleted')
            refreshColumn('DeletionDate')
            Res.DeletedByUserName = 'NULL'
             refreshColumn('DeletedByUserName')
         }else{
          //  Res.IsDeleted = true;
          //  Res.CRMID = userId
          //  Res.CRMName = printedByUserName
           
          Res.IsDeleted = true;
          //  Res.CRMID = 0;
          //  Res.CRMName = null
          //  Res.PaymentVerificationStatusDate = null
          Res.DeletionDate = new Date()
          Res.DeletedByUserName = userName
          Res.DeletedByUserID = userId
          refreshColumn('IsDeleted')
          refreshColumn('DeletionDate')
           refreshColumn('DeletedByUserName')
          
         
            
          }
       }
     })
     console.log(dataFin)
 
   })
 };

  const EmployeeGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (employeeGrid = grid)}
      id='EmployeeGrid'
      dataSource={employee}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      dataBound={databound}
      rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
          
                    setTimeout(() => {
            console.log('args.data', args.data)
          }, 200);
        }
      }
    >
      <ColumnsDirective>
      <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        />
      
         <ColumnDirective
          headerText='Employee Name'
          headerTextAlign='CENTER'    
          field='EmployeeName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Employee Email'
          headerTextAlign='CENTER'    
          field='EmployeeEmail'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo1'
          headerTextAlign='CENTER'    
          field='ContactNo1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo2'
          headerTextAlign='CENTER'    
          field='ContactNo2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='ContactNo3'
          headerTextAlign='CENTER'    
          field='ContactNo3'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address1'
          headerTextAlign='CENTER'    
          field='Address1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address2'
          headerTextAlign='CENTER'    
          field='Address2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='CitizenShip No'
          headerTextAlign='CENTER'    
          field='CitizenShipNo'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Country Name'
          headerTextAlign='CENTER'    
          field='CountryName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='City Name'
          headerTextAlign='CENTER'    
          field='CityName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Designation Name'
          headerTextAlign='CENTER'    
          field='DesignationName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Commission Percent'
          headerTextAlign='CENTER'    
          field='CommissionPercent'
          width='150'
          textAlign='Left'
        />
        {/* <ColumnDirective
          headerText='Is Deleted'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='IsDeleted'
          width='150'
        /> */}
        <ColumnDirective 
        field='IsDeleted'
         headerText='InActive'
          width='170' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'

                className={`checkbox ${props.IsDeleted ? 'yellow': ''}`}
                id={`checkbox_${props.SerialNo}`}
                 checked={props.IsDeleted}
                 
                  onChange={(e) => handleCheckboxChange(e, props)} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            />
        {/* <ColumnDirective
                headerText='InActive
                 Date'
                field='DeletionDate'
                headerTextAlign='Center'
                width='135'
                type='date'
                format={formatOptions}
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />
               <ColumnDirective
                headerText='InActive
                 User Name'
                field='DeletedByUserName'
                headerTextAlign='Center'
                width='145'
                textAlign='left'
                
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />  */}
     
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          // CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getEmployee()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <EmployeeGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Employee - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getEmployee()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <EmployeeGridComponent />
            </div>
          </div>
        </div>
      )
  );
}
