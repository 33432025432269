import React , {useState ,useEffect , useRef}from 'react';
import { TextBoxComponent , NumericTextBoxComponent} from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button } from '@material-ui/core';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import MessageService from '../../services/message.service';
import PIService from '../../services/PI.service';
import PIDuplicateService from './PI-duplicate.service';
import SecurityService from '../security/security.service';
import './pi-duplicate.scss';

let generatedPiid = 0
const PiDuplicate = () => {
    const ddlFields: object = { value: "value" };
    let proformaInvoiceRef = useRef(null)
    const [proformaInvoiceDataSource, setProformaInvoiceDataSource] = useState([]);
    const [loadingProformaInvoice, setLoadingProformaInvoice] = useState(false);
    const [proformaInvoiceID, setProformaInvoiceID] = useState(0);
    const [proformaInvoiceValue, setProformaInvoiceValue] = useState('');
    
    let piNoRef = useRef(null)
    const [pINo, setPINo] = useState('');
    const [dataList, setDataList] = useState([]);
    
    let piNoReferenceRef = useRef(null)
    const [pIReferenceNo, setPIReferenceNo] = useState('');
    const [checkDuplicate, setCheckDuplicate] = useState(false);
    
    const [consigneeName, setConsigneeName] = useState('');
    
    let shipmentTypeRef = useRef(null)
    const [loadingShipmentType, setLoadingShipmentType] = useState(false);
    const [shipmentTypeDataSource, setShipmentTypeDataSource] = useState([]);
    const [shipmentTypeID, setShipmentTypeID] = useState(0);
    const [shipmentTypeValue, setShipmentTypeValue] = useState('');
    
    const [bookAmount , setBookAmount] = useState(0);
 
    const [receiveAmount , setReceiveAmount] = useState(0);
    
    let purchaseSourceRef = useRef(null)
    const [loadingPurchaseSource, setLoadingPurchaseSource] = useState(false);
    const [purchaseSourceDataSource, setPurchaseSourceDataSource] = useState([]);
    const [purchaseSourceID,setPurchaseSourceID] = useState(0);
    const [purchaseSourceValue, setPurchaseSourceValue] = useState('');
    
    const [initialPaymentPercent, setInitialPaymentPercent] = useState(0);
    
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        if(pIReferenceNo != '' && checkDuplicate == true){
      let resultcheck = CheckDuplicate.withOutCombination(dataList,['PINo','PIReferenceNo'],{
        PIReferenceNo:pIReferenceNo
        })
         console.log(resultcheck)
         if(resultcheck == true){
          setDisabled(true)
          piNoReferenceRef.current.element.focus()
           document.getElementById('PIReferenceNo').style.border = '2px solid red'
           document.getElementById('duplicate-div').style.transform = 'scale(1)'
           setTimeout(() => {
             document.getElementById('PIReferenceNo').style.border = '1px solid #eee'
             
           }, 2000);
          }else{
            document.getElementById('duplicate-div').style.transform = 'scale(0)'
            setDisabled(false)
         }
        }
      }, 500);
    }, [pIReferenceNo , proformaInvoiceID]);
    
    useEffect(() => {
      setTimeout(() => {
        setCheckDuplicate(true)
        proformaInvoiceRef.current.element.focus()
      }, 500);
      getProformaInvoiceDropDownList()
      getProformaInvoice()
      getShipmentType()
      getPurchaseSource()
    }, []);

    const getProformaInvoiceByPiid = async (id) => {
      PIDuplicateService.getPIByPiid(id)
        .then((response) => {
          console.log('ProductFinishSize get response', response.data.recordset);
          setPINo(response.data.recordset[0].PINo)
          setPIReferenceNo(response.data.recordset[0].PIReferenceNo)
          setConsigneeName(response.data.recordset[0].ConsigneeName)
          setShipmentTypeID(response.data.recordset[0].ShipmentTypeID)
          setShipmentTypeValue(response.data.recordset[0].ShipmentType)
          setPurchaseSourceID(response.data.recordset[0].PurchaseSourceID)
          setPurchaseSourceValue(response.data.recordset[0].PurchaseSource)
          setBookAmount(response.data.recordset[0].BookAmount)
          piNoRef.current.element.focus()
          piNoRef.current.element.select()
          getIntialValue(response.data.recordset[0].CustomerID ,response.data.recordset[0].CurrencyID )
        })
        .catch((e) => {
          console.log(e);
        });
    };
    
    const getIntialValue = async (CustomerID , CurrencyID) => {
      PIService.getInitialPaymentPercent(CustomerID , CurrencyID).then(res=>{
        console.log(res)
        setInitialPaymentPercent(res.data.recordset[0].InitialPaymentPercent  ? res.data.recordset[0].InitialPaymentPercent : 0)
  }).catch((e)=>{
    setInitialPaymentPercent(0)
    })
    }

    const getProformaInvoice = async () => {
      PIService.getList()
        .then((response) => {
          console.log('ProductFinishSize get response', response.data.recordset);
          const data = response.data.recordset.map((v, k) => {
            v.SalesAgentStatusDate =  v.SalesAgentStatusDate == null ? null : new Date(v.SalesAgentStatusDate.split("T", 1)[0]);
            v.ETA = v.ETA == null ? null :  new Date(v.ETA.split("T", 1)[0]);
            v.ETD =  v.ETD == null ? null : new Date(v.ETD.split("T", 1)[0]);
            v.CreationDate = v.CreationDate == null ? null :  new Date(v.CreationDate.split("T", 1)[0]);
            v.CRMStatusDate = v.CRMStatusDate == null ? null :  new Date(v.CRMStatusDate.split("T", 1)[0]);
            v.PaymentVerificationStatusDate = v.PaymentVerificationStatusDate == null ? null :   new Date(v.PaymentVerificationStatusDate.split("T", 1)[0]);
            // v.ConfirmConsigneeName = v.ConfirmConsigneeName ? 'Yes' : 'No';
           return v;
          });
          setDataList(data);
          
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const getProformaInvoiceDropDownList = async () => {
      setLoadingProformaInvoice(true)
      PIDuplicateService.getPIDropdownList()
        .then((response) => {
          console.log('get all pi', response)
          setLoadingProformaInvoice(false)
          setProformaInvoiceDataSource(response.data.recordset);
  
        })
        .catch((e) => {
          setLoadingProformaInvoice(false)
          console.log(e);
        });
    };

    const getShipmentType = async () => {
      setLoadingShipmentType(true)
      PIService.getShipmentTypeForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        setLoadingShipmentType(false)
        setShipmentTypeDataSource(response.data.recordset)
        
      })
      .catch((e) => {
        console.log(e);
        setLoadingShipmentType(false)
          
        });
    };

    const getPurchaseSource = async (type: string) => {
      setLoadingPurchaseSource(true)
      PIService.getPurchaseSourceForDD()
        .then((response) => {
          console.log('productFinishSize get response', response.data.recordset);
          setPurchaseSourceDataSource(response.data.recordset)
          setLoadingPurchaseSource(false)
        })
        .catch((e) => {
          console.log(e);
          setLoadingPurchaseSource(false)
          });
    };
    // DROPDOWNS  INPUTS BLUR FUNCITONS
    const handleProductDescBlur = (id, ref, value, elementId, productArray) => {
      if (id === 0 || ref.current.value == null || ref.current.value === '' || id === undefined || id === null) {
        if (value !== '') {
          const isValueInProductDesc = productArray.some((e) => e.value === value);
          if (!isValueInProductDesc) {
            const elem = document.getElementById(elementId).parentElement.parentElement.firstChild;
            elem.style.border = '3px solid red';
            setTimeout(() => {
              elem.style.border = '1px solid #c8c8c8';
            }, 1000);
          }
        }
        
          ref.current.element.focus();
        
      }
    };

    // CHANGE FUNCTIONS
        const proformaInvoiceChange = (e)=>{
            setProformaInvoiceID(e.itemData.id ? e.itemData.id : 0)
            setProformaInvoiceValue(e.itemData.value ? e.itemData.value : '')
            if(e.itemData.id){
              getProformaInvoiceByPiid(e.itemData.id)
            }
        }

        const shipmentTypeChange = (e)=>{
            setShipmentTypeID(e.itemData.id ? e.itemData.id : 0)
            setShipmentTypeValue(e.itemData.value ? e.itemData.value : '')
        }
        const purchaseSourceChange = (e)=>{
          setPurchaseSourceID(e.itemData.id ? e.itemData.id : 0)
            setPurchaseSourceValue(e.itemData.value ? e.itemData.value : '')
        }

        // DUPLICATE PI FUNCTION
        const duplicatePiFunc = ()=>{
          console.log(initialPaymentPercent)
          console.log(bookAmount)
          console.log(receiveAmount)
          let bookAmountGEN = bookAmount - initialPaymentPercent ; 
          let resultOfSums = bookAmount == receiveAmount || receiveAmount  ==  bookAmountGEN;
        
        let  data = {
          PIID:proformaInvoiceID,
          PINo:pINo,
          PIReferenceNo:pIReferenceNo,
          ConsigneeName:consigneeName,
          ShipmentTypeID:shipmentTypeID,
          PurchaseSourceID:purchaseSourceID,
          BookAmount:bookAmount,
          ReceiptAmount:receiveAmount,
          CreatedByUserID:SecurityService.getLoggedInUserId()
        }
        PIDuplicateService.addPIDuplicate(data).then((res)=>{

          console.log(res)
          generatedPiid = res.data.params.id;

          console.log(resultOfSums)
          if(resultOfSums == true){
            setShowDialogOfProduct(true)
          }else{
            // updateProformaInvoice(res.data.params.id , 'undefined')
            alert('PI DUPLICATED SUCCESSFULLY!')
            setTimeout(() => {
              
              getProformaInvoiceByPiid(proformaInvoiceID)
            }, 200);
          }
          getProformaInvoiceDropDownList()
        }).catch((e)=>{
          getProformaInvoiceDropDownList()
        })
        }
      
      
  const updateProformaInvoice = async (id,value) => {
        PIService.updatingSp(id,value).then((response) => {
          alert('PI DUPLICATED SUCCESSFULLY!')
            })
            .catch((e) => {
              console.log(e);
            });
        };
  
  const deleteDialog = useRef();
  const [showDialogOfProduct, setShowDialogOfProduct] = useState(false)
  const deleteButtons = [
          {
            buttonModel: {
              content: 'Release',
              cssClass: 'e-flat',
              isPrimary: true,
            },
            click: (e) => {
              deleteDialog.current.hide();
              console.log(generatedPiid)
              if(generatedPiid != ''){
         
               updateProformaInvoice(generatedPiid, 'Release')
               setTimeout(() => {
              
              getProformaInvoiceByPiid(proformaInvoiceID)
            }, 200);
              }
              
            },
          },
          {
            buttonModel: {
              content: 'Surrender BL',
              cssClass: 'e-flat',
            },
            click: () => {
              deleteDialog.current.hide();
                    if(generatedPiid != ''){
         
               updateProformaInvoice(generatedPiid, 'Surrender BL')
               setTimeout(() => {
              
              getProformaInvoiceByPiid(proformaInvoiceID)
            }, 200);
             }
              
            },
          },
        ];
         
        const deleteDialogClose = ()=>{
          setShowDialogOfProduct(false)
  }
    return (
        <div className='content-wrapper'>
          <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={showDialogOfProduct}
                close={deleteDialogClose}
                content={() => (<div>DO WANT TO TO RELEAES OF SURRENDER BL?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              </div>
           
             <p className='lists-headings'>PI - Duplicate</p>
             <div className="col-sm-6 pi-div ">
                  {loadingProformaInvoice ? (
                    <div>... loading PI </div>
                  ) : (
                    <div>
                      <AutoCompleteComponent
                        id="ProformaInvoice"
                        dataSource={proformaInvoiceDataSource}
                        placeholder="Select PI"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={proformaInvoiceRef}
                        select={proformaInvoiceChange}
                        value={proformaInvoiceValue}
                        change={proformaInvoiceChange}
                        blur={() => handleProductDescBlur(
                          proformaInvoiceID,
                          proformaInvoiceRef,
                          proformaInvoiceValue,
                          "ProformaInvoice",
                          proformaInvoiceDataSource
                           )}
                      />
                      <TextBoxComponent
                        name="ProformaInvoiceID"
                        value={proformaInvoiceID}
                        type="hidden"
                      />
                    </div>
                  )}
                </div>
                <div className='content-div-pi-duplicate'>
              {proformaInvoiceID == 0 ? <div className='please-select-pi'>Please Select PI </div> :(  <div className='row'>
                  <div className='col-sm-4'>
                  <TextBoxComponent
                        className='e-input'
                        placeholder='PINo'
                        name='PINo'
                        floatLabelType='Auto'
                        id='PINo'
                        value={pINo}
                        ref={piNoRef}
                        onChange={(e)=>setPINo(e.value)}
                        blur={
                          ()=>{
                             console.log(dataList)
                             console.log(pINo)
                           let resultcheck = CheckDuplicate.withOutCombination(dataList,['PINo','PIReferenceNo'],{
                            PINo:pINo
                             })
                             console.log(resultcheck)
                             if(resultcheck == true){
                              setDisabled(true)
                               piNoRef.current.element.focus()
                               document.getElementById('PINo').style.border = '2px solid red'
                               document.getElementById('duplicate-div').style.transform = 'scale(1)'
                               setTimeout(() => {
                                 document.getElementById('PINo').style.border = '1px solid #eee'
                                }, 2000);
                              }else{
                                setDisabled(false)
                               document.getElementById('duplicate-div').style.transform = 'scale(0)'
                               piNoReferenceRef.current.element.focus()
                            }
                          }
                        }
                        />
                  </div>
                  <div className='col-sm-8'>
                  <TextBoxComponent
                        className='e-input'
                        placeholder='Reference No'
                        name='PIReferenceNo'
                        floatLabelType='Auto'
                        value={pIReferenceNo}
                        multiline
                        id={'PIReferenceNo'}
                        ref={piNoReferenceRef}
                        onChange={(e)=>{
                          setPIReferenceNo(e.value)
                if(e.value == ''){
                        piNoReferenceRef.current.element.focus()

                          }
                        }}
                        focus={(e)=>{
                          piNoReferenceRef.current.element.select()
                          let resultcheck = CheckDuplicate.withOutCombination(dataList,['PINo','PIReferenceNo'],{
                            PIReferenceNo:pIReferenceNo
                            })
                             console.log(resultcheck)
                             if(resultcheck == true){
                              setDisabled(true)
                              piNoReferenceRef.current.element.focus()
                               document.getElementById('PIReferenceNo').style.border = '2px solid red'
                               document.getElementById('duplicate-div').style.transform = 'scale(1)'
                               setTimeout(() => {
                                 document.getElementById('PIReferenceNo').style.border = '1px solid #eee'
                                 
                               }, 2000);
                              }else{
                                setDisabled(false)
                              }
                        }}


                      />
                      </div>
                  <div className='col-sm-4'>
                  <TextBoxComponent
                        className='e-input'
                        placeholder='Consignee Name'
                        name='ConsigneeName'
                        floatLabelType='Auto'
                        value={consigneeName}
                        onChange={(e)=>setConsigneeName(e.value)}
                      />
                  </div>
                  <div className='col-sm-4'>
                  {loadingShipmentType ? (
                    <div>... Loading Shipment Type </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="ShipmentType" 
                            dataSource={shipmentTypeDataSource}
                            placeholder="Select Shipment Type" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="ShipmentType"
                            select={shipmentTypeChange}
                            change={shipmentTypeChange}
                            value={shipmentTypeValue}
                            ref={shipmentTypeRef}
                            blur={() => handleProductDescBlur(
                              shipmentTypeID,
                              shipmentTypeRef,
                              shipmentTypeValue,
                              "ShipmentType",
                              shipmentTypeDataSource
                               )}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='ShipmentTypeID'
                            floatLabelType='Auto'
                            value={shipmentTypeID}
                          />
                          </div>
                      )}
                  </div>
                  <div className='col-sm-4'>
                  <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Book Amount'
                        id='BookAmount'
                        name='BookAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={bookAmount}
             
                        onChange={(e)=>{
                          // dispatch({ type: `BookAmt` , BookAmt: e?.value })  
                          setBookAmount(e.value)
                          }}
                        blur={()=>{
                          if(bookAmount  <= 0 ){
                            document.getElementById('BookAmount').style.border = '1px solid red'
                          }else{
                            document.getElementById('BookAmount').style.border = '1px solid #eee'

                          }
                        }}
                        
                      />
                  </div>
                  <div className='col-sm-4'>
                  {loadingPurchaseSource ? (
                    <div>... Loading Purchase Source </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="PurchaseSource" 
                            dataSource={purchaseSourceDataSource}
                            placeholder="Select Purchase Source " 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="PurchaseSource"
                            select={purchaseSourceChange}
                            change={purchaseSourceChange}
                            value={purchaseSourceValue}
                            ref={purchaseSourceRef}
                            blur={() => handleProductDescBlur(
                              purchaseSourceID,
                              purchaseSourceRef,
                              purchaseSourceValue,
                              "PurchaseSource",
                              purchaseSourceDataSource
                               )}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='PurchaseSourceID'
                            floatLabelType='Auto'
                            value={purchaseSourceID}
                          />
                          </div>
                      )}
                  </div>
                  <div className='col-sm-4'>
                  <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Receive Amount'
                        id='ReceiveAmount'
                        name='ReceiveAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        value={receiveAmount}
                        onChange={(e)=>{
                          setReceiveAmount(e.value)
                          }}
                        
                      />
                  </div>
                  <div className='col-sm-4 Duplicate-PI-button'>
                    <Button disabled={disabled} onClick={duplicatePiFunc} >Duplicate Pi</Button>
                  </div>
                </div>
                  ) } 

                <div id='duplicate-div'> Duplicate Value Detected!</div>
    </div>
        </div>
    );
}

export default PiDuplicate;
