import React, { useEffect, useState, useRef } from 'react';
import CurrencyService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import './currency.scss';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import currencyService from '../../services/currency.service';

export default function CurrencyAdd(props) {
  const [currency, setCurrency] = useState([]);
  // const [loadingProductCategory, setLoadingProductCategory] = useState(true);
  // const [productCategory, setProductCategory] = useState([]);
  // const [productCategoryID, setProductCategoryID] = useState(props.ProductCategoryID);
  // const [loadingUOMs, setLoadingUOMs] = useState(true);
  // const [uoms, setUOMs] = useState([]);
  // const [uomID, setUOMID] = useState(props.UnitOfMeasurementID);
  // const [uomValue, setUomValue] = useState(props.UnitOfMeasurement);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  // const [ProductDesc, setProductDesc] = useState('');
  // const [loadingProductDesc, setLoadingProductDesc] = useState('');
  // const [productDescID, setProductDescID] = useState(props.ProductDescID);
  // const [productDescValue, setProductDescValue] = useState(props.ProductDesc);
  const [currencyName, setCurrencyName] = useState(props?.CurrencyName);
  const [currencySymbol, setCurrencySymbol] = useState(props?.CurrencySymbol);
  // const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  useEffect(() => {
  setTimeout(() => {
    
  
    if(currencyName != undefined && currencySymbol != undefined && checkDuplicate == true){
      console.log(props.dataList)
      console.log()
      let check = CheckDuplicate.withCombination(props.dataList,['CurrencyName','CurrencySymbol'],{
        CurrencyName:currencyName,
          CurrencySymbol:currencySymbol})
     
      // props.dataList,['FinishDrop','FinishLength','FinishWidth','ProductDesc','UnitOfMeasurement'],)
      console.log(check)
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#CurrencyGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#CurrencyGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [currencyName,currencySymbol]);

  useEffect(() => {
   setTimeout(() => {
    setCheckDuplicate(true)

   }, 200);
  
    // getCurrency();
    // getProductCategory();
    // getUOMs();
    // getProductDesc()
  }, []);

  const getCurrency = async (type: string) => {
    currencyService.getCurrencyForList()
      .then((response) => {
        console.log('currency get response', response.data.recordset);
        setCurrency(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const getProductCategory = async (type: string) => {
  //   setLoadingProductCategory(true);
  //   SetupService.getAll('ProductCategory')
  //     .then((response) => {
  //       console.log('ProductCategory get response', response.data.recordset);
  //       setProductCategory(response.data.recordset);
  //       setLoadingProductCategory(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  // const getProductDesc = async (type: string) => {
  //   setLoadingProductDesc(true);
  //   SetupService.getAll('ProductDesc')
  //     .then((response) => {
  //       console.log('ProductDesc get response', response.data.recordset);
  //       setProductDesc(response.data.recordset);
  //       setLoadingProductDesc(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // const getUOMs = async (type: string) => {
  //   setLoadingUOMs(true);
  //   SetupService.getAll('UnitOfMeasurement')
  //     .then((response) => {
  //       console.log('uoms get response', response.data.recordset);
  //       setUOMs(response.data.recordset);
  //       setLoadingUOMs(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    // setAddNewClicked(false)
    // setShowAddNewDialog(false);
    // switch(dialogType){
    //   case 'ProductCategory':
    //     getProductCategory();
    //   break;
    //   case 'ProductDesc':
    //     getProductDesc();
    //   break;
    //   case 'UnitOfMeasurement':
    //     getUOMs();
    //   break;
    //   default:
    //     break;
    // } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Currency - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }
// const [uomValue, setUomValue] = useState(props.UnitOfMeasurement);
  // const uomChange = (e) => {
  //   setUomValue(e?.itemData?.value ? e?.itemData?.value : '')
  //   if(e?.e?.srcElement?.id)  {
  //     console.log('uom change: ', e?.itemData?.id);
  //       setUOMID(e?.itemData?.id);
  //     }
  //   };

  //   const productCategoryChange = (e) => {
  //     if(e?.e?.srcElement?.id)  {
  //       console.log('uom change: ', e?.itemData?.id);
  //         setProductCategoryID(e?.itemData?.id);
  //       }
  //     };
  //   const productDescChange = (e) => {
  //       console.log('dfdf' ,e)
  //       setProductDescValue(e?.itemData?.value  ? e?.itemData?.value :"")
  //       setProductDescID(0)
  //     if(e?.e?.srcElement?.id)  {
  //       console.log('uom change: ', e?.itemData?.id);
  //         setProductDescID(e?.itemData?.id);
  //       }
  //     };


let productDescRef = useRef(null)
      let uomRef = useRef(null)
  return (
    <div className='currency-input' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Currency - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        <div>
          <div>
            <mat-card>
              <mat-card-content>
                {/* <div className="row"> */}
                <div className="col-sm-12">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Currency Name'
                        name='CurrencyName'
                        floatLabelType='Auto'
                        value={currencyName}
                        onChange={(e)=>{
                          console.log(e)
                          setCheckDuplicate(true)
                          setCurrencyName(e.value)}}
                      />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-12">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Currency Symbol'
                        name='CurrencySymbol'
                        floatLabelType='Auto'
                        value={currencySymbol}
                        onChange={(e)=>{
                          console.log(e)
                          setCurrencySymbol(e.value)}}
                        
                      />
                    </mat-form-field>
                  {/* </div> */}

                 
                  <br/>
                  <br/>
                  {/* <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='number'
                        placeholder='Finish Drop'
                        name='FinishDrop'
                        floatLabelType='Auto'
                        value={finishDrop}
                        onChange={(e)=>{
                          console.log(e)
                          setFinishDrop(e.value)}}
                       
                      />
                    </mat-form-field>
                  </div> */}
                  <br/>
                  <br/>
                   <div className="col-sm-6">
                 {/*   <mat-form-field>
                      {loadingProductCategory ? (
                        <div>... Loading Product Category </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="Category" 
                            dataSource={productCategory}
                            placeholder="Select Category" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={productCategoryChange}
                            change={productCategoryChange}
                            value={props.ProductCategory}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='Category Id'
                            name='ProductCategoryID'
                            floatLabelType='Auto'
                            value={productCategoryID}

                          />
                          <span className='add-new' onClick={()=>onAddNewClick('ProductCategory')}>
                          {AddNewSecurity('ProductCategory')}
                              </span>
                        </div>
                      )}
                    </mat-form-field> */}
                    {/* <mat-form-field>
                      { loadingProductDesc? (
                        <div>... Loading Product Desc </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id="ProductDesc" 
                            dataSource={ProductDesc}
                            placeholder="Select Product Desc" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={productDescChange}
                            ref={productDescRef}
                            change={productDescChange}
                            value={productDescValue}
                            blur={()=>{
                              console.log('dfdfblur',productDescID)
                              if (productDescID  == 0  || productDescRef.current.value == null || productDescRef.current.value == '' || productDescID == undefined || productDescID == null) {
                               if(productDescValue != ''){
                                 let resstr = ProductDesc.some(e=>e.value ==  productDescValue);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${productDescValue} Value Not Find ` )
                                   document.getElementById("ProductDesc").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("ProductDesc").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               if (addNewClicked == false){
                                productDescRef.current.element.focus()
                            
                               }
                             }
                           }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='ProductDescID'
                            floatLabelType='Auto'
                            value={productDescID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('ProductDesc')}>
                          {AddNewSecurity('ProductDesc')}                         
                              </span>
                        </div>
                      )}
                    </mat-form-field> */}
                  {/* </div>
                  <div className="col-sm-6">
                    <mat-form-field>
                      {loadingUOMs ? (
                        <div>... Loading UOM </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="UOM" 
                            dataSource={uoms}
                            placeholder="Select Unit Of Measurement" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            select={uomChange}
                            change={uomChange}
                            value={uomValue}
                            ref={uomRef}
                            blur={()=>{
                              console.log('dfdfblur',uomID)
                              if (uomID  == 0  || uomRef.current.value == null || uomRef.current.value == '' || uomID == undefined || uomID == null) {
                               if(uomValue != ''){
                                 let resstr = uoms.some(e=>e.value ==  uomValue);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${uomValue} Value Not Find ` )
                                   document.getElementById("UOM").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("UOM").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                uomRef.current.element.focus()
                   
                               }
                             }
                             if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#CurrencyGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            }
                            }
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='UnitOfMeasurementID'
                            floatLabelType='Auto'
                            value={uomID}
                          />
                          <span className='add-new' onClick={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurement')}
                              </span>
                        </div>
                      )}
                    </mat-form-field> */}
                    
                  </div>
                </div>
                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>
<h3 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3>

    </div>
  )
}
