import React, { useEffect, useState, useRef ,useReducer } from 'react';
import SetupService from '../../services/setup.service';
import './customer.scss';
import PIService from '../../services/PI.service';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import CustomerService from '../../services/customer.service';
import CurrencyService from '../../services/currency.service';
import EmployeeList from '../employee/employee-list';
import EmployeeService from '../../services/employee.service';

let currencyElem: HTMLElement;
let currencyObj: AutoComplete;
let CustomerDetailGrid: Grid;
const complexReducer = (state, action) => {
  console.log(state)
  console.log(action)
switch (action.type) {
  
  case 'CountryCityPortDataSource':
    return {
      ...state,
     
      LoadingCountryCityPort:action.LoadingCountryCityPort,
      CountryCityPortDataSource: action.CountryCityPortDataSource,
     };
     break;
  
     case 'CountryCityPort':
      return {
        ...state,
        CountryCityPort:action.CountryCityPort,
        CountryCityPortID:action.CountryCityPortID
       };
       break;
  
  default:
    return state;
}
};

export default function CustomerAdd(props) {
  console.log(props)
  const [state, dispatch] = useReducer(complexReducer, { 
    LoadingCountryCityPort:true,
    CountryCityPortDataSource: [],
    CountryCityPortRef: useRef(null),
    CountryCityPort:props.CountryCityPort,
    CountryCityPortID:props.CountryCityPortID,
  })
  const [customer, setCustomer] = useState([]);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [country, setCountry] = useState('');
  const [loadingCountry, setLoadingCountry] = useState('');
  const [countryID, setCountryID] = useState(props.CountryID);
  const [countryValue, setCountryValue] = useState(props.country);
  const [city, setCity] = useState('');
  const [loadingCity, setLoadingCity] = useState('');
  const [cityID, setCityID] = useState(props.CityID);
  const [cityValue, setCityValue] = useState(props.city);
  const [port, setPort] = useState('');
  const [loadingPort, setLoadingPort] = useState('');
  const [portID, setPortID] = useState(props.PortID);
  const [portValue, setPortValue] = useState(props.port);
  const [salesAgent, setSalesAgent] = useState('');
  const [loadingSalesAgent, setLoadingSalesAgent] = useState('');
  const [salesAgentID, setSalesAgentID] = useState(props.SalesAgentID);
  const [salesAgentValue, setSalesAgentValue] = useState(props.SalesAgentName);

  const [customerName, setCustomerName] = useState(props?.CustomerName);
  const [customerEmail, setCustomerEmail] = useState(props?.CustomerEmail);
  const [contactNo1, setContactNo1] = useState(props?.ContactNo1 ? props?.ContactNo1 : '');
  const [CheckContact1, setCheckContact1] = useState(false);
  const [contactNo2, setContactNo2] = useState(props?.ContactNo2 ? props?.ContactNo2 : '');
  const [CheckContact2, setCheckContact2] = useState(false);
  const [contactNo3, setContactNo3] = useState(props?.ContactNo3 ? props?.ContactNo3 : '');
  const [CheckContact3, setCheckContact3] = useState(false);
  const [address1, setAddress1] = useState(props?.Address1 ? props?.Address1 : '-');
  const [address2, setAddress2] = useState(props?.Address2 ? props?.Address2 : '-');
  // const [port, setPort] = useState('');
  // const [loadingPort, setLoadingPort] = useState('');
  // const [portID, setPortID] = useState(props.PortID);
  // const [portValue, setPortValue] = useState(props.port);
  const [currency, setCurrency] = useState([]);
  const [currencyID, setCurrencyID] = useState(props.CurrencyID);

  // const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  useEffect(() => {
  setTimeout(() => {
    
  
    if( customerEmail != undefined  && checkDuplicate == true){
      console.log(props.dataList)
      console.log()
      // let check = CheckDuplicate.withCombination(props.dataList,['CustomerEmail'],{
        
      //   CustomerEmail: customerEmail,
      //   // ContactNo1: contactNo1,
      //   // ContactNo2: contactNo2,
      //   // ContactNo3: contactNo3,
      //   // Address1: address1,
      //   // Address2: address2,
      //   // Country: countryValue,
      //   // City: cityValue,
      // })
     
      // props.dataList,['Country', 'City', 'Port'],)
      var check = false;
      if(props.isAdd == true){
        
        check = CheckDuplicate.withOutCombination(props.dataList,['CustomerEmail'],{
          CustomerEmail: customerEmail,
 
       })
      }else{
        let array = props.dataList.filter(obj => obj.CustomerID !== props.CustomerID)

        check = CheckDuplicate.withOutCombination(array,['CustomerEmail'],{
          CustomerEmail: customerEmail,
 
       })

      }
      console.log(check)
      
    //   if(!check){
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
    //     setCheckDuplicateValue('')
    //   }else{
    //     document.querySelector('#StitchingStyleGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
    //     setCheckDuplicateValue('Duplicate Value Detected!')
    // }
      console.log(check)
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [customerEmail]);

  useEffect(() => {

    setTimeout(() => {
      setCheckDuplicate(true)
    },300)
    if(props.isAdd == false){
     setTimeout(() => {
      
       document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
     }, 400);
    }
    if(props.CustomerID != undefined){
      console.log(props.CustomerID )
      getAllCustomerDetail(props.CustomerID);
    }
  }, []);

  const getAllCustomerDetail = async (id) => {
    CustomerService.getAllCustomerDetail(id)
      .then((response) => {
        console.log('Customer get response', response.data.recordset);
        setCustomer(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       customerName != undefined &&
  //       customerEmail != undefined &&
  //       contactNo1 != undefined &&
  //       contactNo2 != undefined &&
  //       contactNo3 != undefined &&
  //       address1 != undefined &&
  //       address2 != undefined &&
  //       countryID != undefined &&
  //       cityID != undefined &&
  //       salesAgentID != undefined &&
  //       portID != undefined &&
  //       checkDuplicate == true
  //     ) {
  //       console.log(props.dataList);
  //       console.log();
  //       let check = CheckDuplicate.withCombination(
  //         props.dataList,
  //         [
  //           'CustomerName',
  //           'CustomerEmail',
  //           'ContactNo1',
  //           'ContactNo2',
  //           'ContactNo3',
  //           'Address1',
  //           'Address2',
  //           'Country',
  //           'City',
  //           'Port',
  //           'SalesAgent',
  //         ],
  //         {
  //           CustomerName: customerName,
  //           CustomerEmail: customerEmail,
  //           ContactNo1: contactNo1,
  //           ContactNo2: contactNo2,
  //           ContactNo3: contactNo3,
  //           Address1: address1,
  //           Address2: address2,
  //           Country: countryValue,
  //           City: cityValue,
  //           Port: portValue,
  //           SalesAgent: salesAgentValue,
  //         }
  //       );

  //       // props.dataList,['Country', 'City', 'Port'],)
  //       console.log(check);
  //       // setCheckDuplicate(check)
  //       if (!check) {
  //         document.querySelector(
  //           '#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat'
  //         ).style.visibility = 'visible';
  //         setCheckDuplicateValue('');
  //       } else {
  //         document.querySelector(
  //           '#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat'
  //         ).style.visibility = 'hidden';
  //         setCheckDuplicateValue('Duplicate Value Detected!');
  //       }
  //     }
  //   }, 500);
  // }, [
  //   customerName,
  //   customerEmail,
  //   contactNo1,
  //   contactNo2,
  //   contactNo3,
  //   address1,
  //   address2,
  //   countryID,
  //   cityID,
  //   salesAgentID,
  // ]);

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'CountryCityPort':
        dispatch({ type: 'CountryCityPort' ,CountryCityPort: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CountryCityPort' , 
            CountryCityPort: e?.itemData?.value ? e?.itemData?.value : '',
            CountryCityPortID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
            default:
        return state;
    }
  }
   

  useEffect(() => {
    getCountry();
    getCity();
    getPort();
    getSalesAgent();
    getCurrency();
    // getPort()
    getCountryCityPort()
  }, []);
  const getCountryCityPort = async (type: string) => {
    PIService.getCountryCityPortForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CountryCityPortDataSource` ,  CountryCityPortDataSource:response.data.recordset,LoadingCountryCityPort:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CountryCityPortDataSource`  , CountryCityPortDataSource:[],LoadingCountryCityPort:false})
      });
  };
  const getCustomer = async (type: string) => {
    CustomerService
      .getCustomerForList()
      .then((response) => {
        console.log('customer get response', response.data.recordset);
        setCustomer(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCountry = async (type: string) => {
    setLoadingCountry(true);
    SetupService.getAll('Country')
      .then((response) => {
        console.log('Country get response', response.data.recordset);
        setCountry(response.data.recordset);
        setLoadingCountry(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCity = async (type: string) => {
    setLoadingCity(true);
    SetupService.getAll('City')
      .then((response) => {
        console.log('City get response', response.data.recordset);
        setCity(response.data.recordset);
        setLoadingCity(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPort = async (type: string) => {
    setLoadingPort(true);
    SetupService.getAll('Port')
      .then((response) => {
        console.log('Port get response', response.data.recordset);
        setPort(response.data.recordset);
        setLoadingPort(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCurrency = async () => {
    CurrencyService.getCurrencyForDD()
      .then((response) => {
        console.log('CurrencyName get response', response.data.recordset);
        setCurrency(response.data.recordset);
        // companies = [...response.data.recordset];
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSalesAgent = async () => {
    setLoadingSalesAgent(true);
    EmployeeService.getEmployeeForList()
      .then((response) => {
        console.log('SalesAgent get response', response.data.recordset);
        let data = response.data.recordset.filter((Res)=>{return Res.DesignationID == 3})
        let mapData =  data.map(e=>{return {id:e.EmployeeID , value:e.EmployeeName}})
        setSalesAgent(mapData);
        setLoadingSalesAgent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const getPort = async (type: string) => {
  //   setLoadingPort(true);
  //   SetupService.getAll('Port')
  //     .then((response) => {
  //       console.log('Port get response', response.data.recordset);
  //       setPort(response.data.recordset);
  //       setLoadingPort(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    'Update',
    'Cancel',
];

const filterOptions: FilterSettingsModel = {
    type: 'Excel',
};

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    // TODO: Ask Asif Bhai, if this needs security
    allowAdding: true,
    // allowDeleting: true,
    mode: 'Normal',
    // showDeleteConfirmDialog: true,
};

  const ddlFields: object = { value: 'value' };

  const actionComplete = (args: DialogEditEventArgs) => {
    if (args.form) {
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          document.getElementById('CustomerDetailGridFinalPaymentToleranceAmount').addEventListener('keydown', (e) => {
              console.log('blurred');
              if (e.key === 'Tab') {
                  setTimeout(() => document.getElementById('CustomerDetailGridFinalPaymentToleranceAmount') && CustomerDetailGrid?.endEdit(), 200);
              }
          })
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        // updateCustomerDetail
        console.log(args)

        console.log(currencyID)
        let data ={...args.data,CurrencyID:currencyID,CustomerPaymentDetailID:args.data.CustomerPaymentDetailID }
        console.log(data)
        CustomerService.updateCustomerDetail(data)
        .then((response) => {
          getAllCustomerDetail(props.CustomerID)
        
          console.log('CustomerDetail  add: ', response);
          if(response.status == 200){
            document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
            setCheckDuplicateValue('')
          }
        })
        .catch((e) => {
          console.log(e);
          CustomerDetailGrid.refresh()
        });
      }
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd == false) {
        const data = {
          CustomerName: customerName,
            CustomerEmail: customerEmail,
            ContactNo1: contactNo1,
            ContactNo2: contactNo2,
            ContactNo3: contactNo3,
            Address1: address1,
            Address2: address2,
            CountryID: countryID,
            CityID: cityID,
            PortID: portID,
            SalesAgentID: salesAgentID,
            CurrencyID: currencyID,
            CountryCityPortID:state.CountryCityPortID,
            InitialPaymentPercent: args.data.InitialPaymentPercent,
            InitialPaymentToleranceAmount: args.data.InitialPaymentToleranceAmount,
            FinalPaymentToleranceAmount: args.data.FinalPaymentToleranceAmount,
            UserID: 1,
            IsDeleted: 0,
            CustomerID:props.CustomerID
        };

        console.log('data: ', data);
        CustomerService.CustomerPaymentDetail(data)
          .then((response) => {
            getAllCustomerDetail(response.data.CustomerID)
            setTimeout(() => {
              CustomerDetailGrid.addRecord()
            }, 400);
            console.log('CustomerDetail  add: ', response);
            // if(response.status == 200){
            //   // document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
            //   // setCheckDuplicateValue('')
            // }
            // CustomerDetailGrid?.addRecord();
          })
          .catch((e) => {
            console.log(e);
            // CustomerDetailGrid.refresh()
            getAllCustomerDetail(props.CustomerID)

          });
      }
      if (args.requestType === 'save' && args.action == 'add' && props.isAdd == true) {
        console.log('arg add: ', args.data);
        // {
        //     RejectionCategoryID: categoryID,
        //     ApplicableFromDate: applicableDate,
        //     CreationDate: moment().format('YYYY-MM-DD'),
        //     CreatedByUserID: SecurityService.getLoggedInUserId(),
        //     IsDeleted: 0,
        //     CurrencyName: currencyID,
        //     SaleRate: args.data.Rate,
        //     RejectionSubCategoryID: subCategoryID,
        //     CompanyID: customerID
        // }c
        // const TaxPer = args.data.TaxPercent;
        // const excheck = args.data.ExcludingTaxAmount
        //   ? args.data.ExcludingTaxAmount
        //   : ExcludingTaxAmt;
        // const taxAmount = excheck + excheck * (TaxPer / 100);
        // const ExcludingTaxAmt = args.data.Quantity * args.data.Rate;
        // const taxcheck = taxAmount ? taxAmount : args.data.TaxAmount;
        const data = {
          CustomerName: customerName,
            CustomerEmail: customerEmail,
            ContactNo1: contactNo1,
            ContactNo2: contactNo2,
            ContactNo3: contactNo3,
            Address1: address1,
            Address2: address2,
            CountryID: countryID,
            CityID: cityID,
            PortID: portID,
            SalesAgentID: salesAgentID,
            CurrencyID: currencyID,
            CountryCityPortID:state.CountryCityPortID,
            InitialPaymentPercent: args.data.InitialPaymentPercent,
            InitialPaymentToleranceAmount: args.data.InitialPaymentToleranceAmount,
            FinalPaymentToleranceAmount: args.data.FinalPaymentToleranceAmount,
            UserID: 1,
            IsDeleted: 0,
        };

        console.log('data: ', data);
        CustomerService.addCustomer(data)
          .then((response) => {
            getAllCustomerDetail(response.data.CustomerID)
            setTimeout(() => {
              CustomerDetailGrid.addRecord()
            }, 400);
            console.log('CustomerDetail  add: ', response);
            if(response.status == 200){
              document.querySelector('#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
              setCheckDuplicateValue('')
            }
            // CustomerDetailGrid?.addRecord();
          })
          .catch((e) => {
            console.log(e);
            // CustomerDetailGrid.refresh()
            getAllCustomerDetail(props.CustomerID)

          });
      }
    }
  };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if (type !== '') {
      setAddNewClicked(true);
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      }, 100);
    }
  };

  const header = () => {
    if (showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(' ')} </div>;
    } else {
      return <></>;
    }
  };

  const onClose = () => {
    setAddNewClicked(false);
    setShowAddNewDialog(false);
    switch (dialogType) {
      case 'Country':
        getCountry();
        break;
      case 'City':
        getCity();
        break;
      case 'Port':
        getPort();
        break;
      case 'Employee':
        getSalesAgent();
        break;
      default:
        break;
    }
  };

  const footerTemplate = () => {
    if (showAddNewDialog) {
      return (
        <div>
          <button
            id='btnSave'
            className='e-control e-btn e-primary'
            data-ripple='true'
            onClick={onClose}>
            Close
          </button>
        </div>
      );
    } else {
      <h3 style={{ textAlign: 'center', width: '80%' }}>Customer - Add</h3>;
      return <></>;
    }
  };

  // const contentTemplate = (props) => {
  //   console.log('props: ', props);
  //   if (showAddNewDialog) {
  //     console.log('dialog template');
  //     return (
  //       <div>
  //         <SetupAdd type={dialogType} isDialog={true} />
  //         {/* <SetupAdd onChange={value => setWord(value)}*/}
  //       </div>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const contentTemplate = () => {
    if (showAddNewDialog) {
      console.log('dialog template');
      if (dialogType === 'Employee') {
        return <EmployeeList isDialog={true} />;
      } else {
        return (
          <div>
            <SetupAdd type={dialogType} isDialog={true} />
            {/* <SetupAdd onChange={value => setWord(value)}*/}
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  const AddNewSecurity = (addNewType) => {
    var res = '';
    switch (SecurityService.canUserAccess(`${addNewType}List`)) {
      case true:
        //('true')
        return (res = 'ADD NEW');
      default:
        break;
    }
    return res;
  };

  const countryChange = (e) => {
    console.log('dfdf', e);
    setCountryValue(e?.itemData?.value ? e?.itemData?.value : '');
    setCountryID(0);
    if (e?.e?.srcElement?.id) {
      console.log('Country change: ', e?.itemData?.id);
      setCountryID(e?.itemData?.id);
    }
  };

  const cityChange = (e) => {
    console.log('dfdf', e);
    setCityValue(e?.itemData?.value ? e?.itemData?.value : '');
    setCityID(0);
    if (e?.e?.srcElement?.id) {
      console.log('City change: ', e?.itemData?.id);
      setCityID(e?.itemData?.id);
    }
  };

  const salesAgentChange = (e) => {
    console.log('dfdf', e);
    setSalesAgentValue(e?.itemData?.value ? e?.itemData?.value : '');
    setSalesAgentID(0);
    if (e?.e?.srcElement?.id) {
      console.log('SalesAgent change: ', e?.itemData?.id);
      setSalesAgentID(e?.itemData?.id);
    }
  };

  const portChange = (e) => {
    console.log('dfdf', e);
    setPortValue(e?.itemData?.value ? e?.itemData?.value : '');
    setPortID(0);
    if (e?.e?.srcElement?.id) {
      console.log('Port change: ', e?.itemData?.id);
      setPortID(e?.itemData?.id);
    }
  };

  const currencyParams: IEditCell = {
    create: () => {
      currencyElem = document.createElement('input');
      return currencyElem;
    },
    destroy: () => {
      currencyObj?.destroy();
    },
    read: () => {
      return currencyObj?.text;
    },
    write: (args) => {
      currencyObj = new AutoComplete({
        created: () => {
          if(args.rowData){
            setCurrencyID(args.rowData.CurrencyID)
            }
        },
        select: (e) => {
          args.rowData.CurrencyID =  e.itemData.id
          console.log(e.itemData.id);
          //TODO: Set id
          //const column = projectionEditGrid?.getColumnByField("FabricQualityID");
          //console.log(column);
          setCurrencyID(e.itemData.id);
        },
        dataSource: currency,
        fields: { value: 'value' },
        floatLabelType: 'Never',
        placeholder: 'Choose a Currency',
        highlight: true,
        value: args.rowData.CurrencyName
      });
      currencyObj.appendTo(currencyElem);
    },
  };

  let countryRef = useRef(null);
  let cityRef = useRef(null);
  let portRef = useRef(null);
  let salesAgentRef = useRef(null);
  // let portRef = useRef(null)

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>;
};

var twoDPEditParams: IEditCell = {
    params: {
      decimals: 2,
      format: "N2",
      validateDecimalOnType: true,
      // min: 0
    }
  };
  return (
    <div>
      <div
        className=''
        onKeyDown={(e) => {
          console.log(e);
          if (e.keyCode == 13) {
            e.stopPropagation();
          }
        }}>
        <div>
          <div>
            <div className='heading'>
              <h3>Customer - {props.isAdd ? `Add` : `Edit`}</h3>
            </div>
          </div>
          <div className='App' id='targetElement'>
            <DialogComponent
              width='800px'
              visible={showAddNewDialog}
              close={showAddNewDialog}
              content={contentTemplate}
              isModal={true}
              header={header}
              footerTemplate={footerTemplate}
            />
          </div>

          <div>
            <div>
              <mat-card>
                <mat-card-content>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Customer Name'
                          name='CustomerName'
                          floatLabelType='Auto'
                          value={customerName}
                          onChange={(e) => {
                            console.log(e);
                            setCheckDuplicate(true);
                            setCustomerName(e.value);
                          }}
                        />
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='email'
                          placeholder='Customer Email'
                          name='CustomerEmail'
                          floatLabelType='Auto'
                          value={customerEmail}
                          onChange={(e)=>{
                            const inputValue = e.value; // Assuming e.value contains the input value
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (emailRegex.test(e.value)) {
                              setCustomerEmail(e.value);
                            } else {
                            console.log('Invalid email address');
                            // setCustomerEmail(employeeName)
                            }
                            console.log(e)
                            setCheckDuplicate(true)
                            }}
                            
                                onBlur={()=>{
                                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  if(customerEmail == '' || !emailRegex.test(customerEmail)){ 
                                const emailInput = document.querySelector('input[name="CustomerEmail"]');
                                      console.log(emailInput)
                                      emailInput.focus()
                                      emailInput.style.border = '2px solid red'
                                      setTimeout(() => {
                                        emailInput.style.border = '1px solid #c8c8c8'
                                        
                                      }, 400);
                                      
                                      }
                                    }}
                                    // disabled={props.isAdd? false:   true}
                        />
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Contact No 1'
                          name='ContactNo1'
                          floatLabelType='Auto'
                          value={contactNo1}
                          onChange={(e)=>{
                            
                            const inputValue = e.value; // Assuming e.value contains the input value
                            const emailRegex = /^[0-9+]+$/;
                            if (emailRegex.test(inputValue)) {
                              setContactNo1(inputValue);
                              setCheckContact1(false)
                            } else {
                              setCheckContact1(true)
                              console.log('Invalid email address');
                            const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                            // if(contactNo1 != ''){contactNo1Input.value = contactNo1; }else{
                            //   contactNo1Input.value = ''
                            //   setContactNo1('')
                            //   setCheckContact1(true)
                            // }
                            contactNo1Input.focus()
  
                            contactNo1Input.style.border = '2px solid red'
                            setTimeout(() => {
                              contactNo1Input.style.border = '1px solid #c8c8c8'
                              
                            }, 400);
                            }
                            console.log(e)
                            setCheckDuplicate(true)
                            }}
                            onBlur={()=>{
                              if(contactNo1 == ''){
                            const contactNo1Input = document.querySelector('input[name="ContactNo1"]');
                                  console.log(contactNo1Input)
                                  contactNo1Input.focus()
                                  setCheckContact1(true)
                                  contactNo1Input.style.border = '2px solid red'
                                  setTimeout(() => {
                                    contactNo1Input.style.border = '1px solid #c8c8c8'
                                    
                                  }, 400);
                                  
                                  }else{
                                    setCheckContact1(false)
                                  }
                                }}
                        />
                        {
                           CheckContact1 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Contact No 2'
                          name='ContactNo2'
                          floatLabelType='Auto'
                          value={contactNo2}
                          onChange={(e)=>{
                            const inputValue = e.value; // Assuming e.value contains the input value
                            const emailRegex = /^[0-9+]+$/;
                            if (emailRegex.test(inputValue)) {
                              setContactNo2(inputValue);
                              setCheckContact2(false)
                            } else {
                            console.log('Invalid email address');
                            const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                            // if(contactNo2 != ''){contactNo2Input.value = contactNo2; }else{
                            //   contactNo2Input.value = ''
                            //   setCheckContact2(true)

                            //   setContactNo2('')
                            // }
                            contactNo2Input.focus()
                            setCheckContact2(true)

                            contactNo2Input.style.border = '2px solid red'
                            setTimeout(() => {
                              contactNo2Input.style.border = '1px solid #c8c8c8'
                              
                            }, 400);
                            }
                            console.log(e)
                            setCheckDuplicate(true)
                            }}
                            onBlur={()=>{
                              if(contactNo2 == ''){
                            const contactNo2Input = document.querySelector('input[name="ContactNo2"]');
                            contactNo2Input.focus()
                        setCheckContact2(true)

                            contactNo2Input.style.border = '2px solid red'
                            setTimeout(() => {
                              contactNo2Input.style.border = '1px solid #c8c8c8'
                              
                            }, 400);
                                  }
                                }}
                        />
                            {
                           CheckContact2 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Contact No 3'
                          name='ContactNo3'
                          floatLabelType='Auto'
                          value={contactNo3}
                          onChange={(e)=>{
                            const inputValue = e.value; // Assuming e.value contains the input value
                            const emailRegex = /^[0-9+]+$/;
                            if (emailRegex.test(inputValue)) {
                              setContactNo3(inputValue);
                              setCheckContact3(false)
                            } else {
                            console.log('Invalid email address');
                            const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                            // if(contactNo3 != ''){contactNo3Input.value = contactNo3; }else{
                            //   contactNo3Input.value = ''
                            //   setCheckContact3(true)

                            //   setContactNo3('')
                            // }
                            contactNo3Input.focus()
                            setCheckContact3(true)

                            contactNo3Input.style.border = '2px solid red'
                            setTimeout(() => {
                              contactNo3Input.style.border = '1px solid #c8c8c8'
                              
                            }, 400);
                            }
                            console.log(e)
                            setCheckDuplicate(true)
                            }}
                            onBlur={()=>{
                              if(contactNo3 == ''){
                            const contactNo3Input = document.querySelector('input[name="ContactNo3"]');
                            contactNo3Input.focus()
                        setCheckContact3(true)

                            contactNo3Input.style.border = '2px solid red'
                            setTimeout(() => {
                              contactNo3Input.style.border = '1px solid #c8c8c8'
                              
                            }, 400);
                                  }
                                }}
                        />
                        {
                           CheckContact3 == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Only + Symbol and Numbers are allowed
                        </div></div>:null
                        }

                                               </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Address 1'
                          name='Address1'
                          floatLabelType='Auto'
                          value={address1}
                          onChange={(e) => {
                            console.log(e);
                            setCheckDuplicate(true);
                            setAddress1(e.value);
                          }}
                        />
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        <TextBoxComponent
                          className='e-input'
                          type='text'
                          placeholder='Address 2'
                          name='Address2'
                          floatLabelType='Auto'
                          value={address2}
                          onChange={(e) => {
                            console.log(e);
                            setCheckDuplicate(true);
                            setAddress2(e.value);
                          }}
                        />
                      </mat-form-field>
                    </div>
                    <div className="col-sm-6">
                    <mat-form-field>
                      {state.LoadingCountryCityPort ? (
                        <div>... Loading Country City Port </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CountryCityPort" 
                            dataSource={state.CountryCityPortDataSource}
                            placeholder="Select Country City Port" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CountryCityPort"
                            select={(e)=>AutoCompleteChange(e,'CountryCityPort')}
                            change={(e)=>AutoCompleteChange(e,'CountryCityPort')}
                            value={state.CountryCityPort}
                            ref={state.CountryCityPortRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CountryCityPortID)
                              console.log('dfdfblur',state.CountryCityPort)
                              if (state.CountryCityPortID  == 0  || state.CountryCityPortRef.current.value == null || state.CountryCityPortRef.current.value == '' || state.CountryCityPortID == undefined || state.CountryCityPortID == null) {
                               if(state.CountryCityPort != ''){
                                 let resstr = state.CountryCityPortDataSource.some(e=>e.value ==  state.CountryCityPort);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.CountryCityPort.CountryCityPort} Value Not Find ` )
                                   document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CountryCityPort").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CountryCityPortRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CountryCityPortID'
                            floatLabelType='Auto'
                            value={state.CountryCityPortID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>

                    {/* <div className='col-sm-6'>
                      <mat-form-field>
                        {loadingCountry ? (
                          <div>... Loading Country </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Country'
                              dataSource={country}
                              placeholder='Select Country'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={countryChange}
                              ref={countryRef}
                              change={countryChange}
                              value={countryValue}
                              blur={() => {
                                console.log('dfdfblur', countryID);
                                if (
                                  countryID == 0 ||
                                  countryRef.current.value == null ||
                                  countryRef.current.value == '' ||
                                  countryID == undefined ||
                                  countryID == null
                                ) {
                                  if (countryValue != '') {
                                    let resstr = country.some(
                                      (e) => e.value == countryValue
                                    );
                                    console.log(resstr);
                                    if (resstr === false) {
                                      //  setProductGroupErorr(`${countryValue} Value Not Find ` )
                                      document.getElementById(
                                        'Country'
                                      ).parentElement.parentElement.firstChild.style.border =
                                        '3px solid red';
                                      setTimeout(() => {
                                        //  setProductItemTypeError('')
                                        document.getElementById(
                                          'Country'
                                        ).parentElement.parentElement.firstChild.style.border =
                                          '1px solid #c8c8c8';
                                      }, 1000);
                                    }
                                  }
                                  if (addNewClicked == false) {
                                    countryRef.current.element.focus();
                                  }
                                }
                              }}
                            />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='Country Id'
                              name='CountryID'
                              floatLabelType='Auto'
                              value={countryID}
                            />
                            <span
                              className='add-new'
                              onClick={() => onAddNewClick('Country')}>
                              {AddNewSecurity('Country')}
                            </span>
                          </div>
                        )}
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        {loadingCity ? (
                          <div>... Loading City </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='City'
                              dataSource={city}
                              placeholder='Select City'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={cityChange}
                              ref={cityRef}
                              change={cityChange}
                              value={cityValue}
                              blur={() => {
                                console.log('dfdfblur', cityID);
                                if (
                                  cityID == 0 ||
                                  cityRef.current.value == null ||
                                  cityRef.current.value == '' ||
                                  cityID == undefined ||
                                  cityID == null
                                ) {
                                  if (cityValue != '') {
                                    let resstr = city.some(
                                      (e) => e.value == cityValue
                                    );
                                    console.log(resstr);
                                    if (resstr === false) {
                                      //  setProductGroupErorr(`${cityValue} Value Not Find ` )
                                      document.getElementById(
                                        'City'
                                      ).parentElement.parentElement.firstChild.style.border =
                                        '3px solid red';
                                      setTimeout(() => {
                                        //  setProductItemTypeError('')
                                        document.getElementById(
                                          'City'
                                        ).parentElement.parentElement.firstChild.style.border =
                                          '1px solid #c8c8c8';
                                      }, 1000);
                                    }
                                  }
                                  if (addNewClicked == false) {
                                    cityRef.current.element.focus();
                                  }
                                }
                              }}
                            />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='City Id'
                              name='CityID'
                              floatLabelType='Auto'
                              value={cityID}
                            />
                            <span
                              className='add-new'
                              onClick={() => onAddNewClick('City')}>
                              {AddNewSecurity('City')}
                            </span>
                          </div>
                        )}
                      </mat-form-field>
                    </div>

                    <div className='col-sm-6'>
                      <mat-form-field>
                        {loadingPort ? (
                          <div>... Loading Port </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Port'
                              dataSource={port}
                              placeholder='Select Port'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={portChange}
                              ref={portRef}
                              change={portChange}
                              value={portValue}
                              blur={() => {
                                console.log('dfdfblur', portID);
                                if (
                                  portID == 0 ||
                                  portRef.current.value == null ||
                                  portRef.current.value == '' ||
                                  portID == undefined ||
                                  portID == null
                                ) {
                                  if (portValue != '') {
                                    let resstr = port.some(
                                      (e) => e.value == portValue
                                    );
                                    console.log(resstr);
                                    if (resstr === false) {
                                      //  setProductGroupErorr(`${portValue} Value Not Find ` )
                                      document.getElementById(
                                        'Port'
                                      ).parentElement.parentElement.firstChild.style.border =
                                        '3px solid red';
                                      setTimeout(() => {
                                        //  setProductItemTypeError('')
                                        document.getElementById(
                                          'Port'
                                        ).parentElement.parentElement.firstChild.style.border =
                                          '1px solid #c8c8c8';
                                      }, 1000);
                                    }
                                  }
                                  if (addNewClicked == false) {
                                    portRef.current.element.focus();
                                  }
                                }
                              }}
                            />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='Port Id'
                              name='PortID'
                              floatLabelType='Auto'
                              value={portID}
                            />
                            <span
                              className='add-new'
                              onClick={() => onAddNewClick('Port')}>
                              {AddNewSecurity('Port')}
                            </span>
                          </div>
                        )}
                      </mat-form-field>
                    </div> */}

                    <div className='col-sm-6'>
                      <mat-form-field>
                        {loadingSalesAgent ? (
                          <div>... Loading SalesAgent </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='SalesAgent'
                              dataSource={salesAgent}
                              placeholder='Select Sales Agent'
                              fields={ddlFields}
                              floatLabelType='Auto'
                              highlight={true}
                              select={salesAgentChange}
                              ref={salesAgentRef}
                              change={salesAgentChange}
                              value={salesAgentValue}
                              blur={() => CustomerDetailGrid.addRecord()}
                              // blur={() => {
                              //   console.log('dfdfblur', salesAgentID);
                              //   if (
                              //     salesAgentID == 0 ||
                              //     salesAgentRef.current.value == null ||
                              //     salesAgentRef.current.value == '' ||
                              //     salesAgentID == undefined ||
                              //     salesAgentID == null
                              //   ) {
                              //     if (salesAgentValue != '') {
                              //       let resstr = salesAgent.some(
                              //         (e) => e.value == salesAgentValue
                              //       );
                              //       console.log(resstr);
                              //       if (resstr === false) {
                              //         //  setProductGroupErorr(`${salesAgentValue} Value Not Find ` )
                              //         document.getElementById(
                              //           'SalesAgent'
                              //         ).parentElement.parentElement.firstChild.style.border =
                              //           '3px solid red';
                              //         setTimeout(() => {
                              //           //  setProductItemTypeError('')
                              //           document.getElementById(
                              //             'SalesAgent'
                              //           ).parentElement.parentElement.firstChild.style.border =
                              //             '1px solid #c8c8c8';
                              //         }, 1000);
                              //       }
                              //     }
                                  
                              //     if (addNewClicked == false) {
                              //       salesAgentRef.current.element.focus();
                              //     }
                              //   }
                              // }}
                            />

                            <TextBoxComponent
                              className='e-input'
                              type='hidden'
                              placeholder='SalesAgent Id'
                              name='SalesAgentID'
                              floatLabelType='Auto'
                              value={salesAgentID}
                            />
                            <span
                              className='add-new'
                              onClick={() => onAddNewClick('Employee')}>
                              {AddNewSecurity('Employee')}
                            </span>
                          </div>
                        )}
                      </mat-form-field>
                    </div>
                    <div className='col-sm-6'>
                    <h3
        className='duplicate '
        style={checkDuplicateValue != '' ? { transform: 'scale(1)' } : {}}>
        {checkDuplicateValue}
      </h3>
                      </div>
                  </div>
                  <br />
                </mat-card-content>
                <mat-card-action></mat-card-action>
              </mat-card>
            </div>
          </div>
        </div>

        <div className='add-grid'>
          <GridComponent
            ref={(grid) => (CustomerDetailGrid = grid)}
            id='CustomerDetailGrid'
            dataSource={customer}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 4, pageSizes: true }}
            filterSettings={filterOptions}
            actionComplete={actionComplete}
            allowPaging={true}
            allowSorting={true}
            
            allowPdfExport={true}
            allowExcelExport={true}
            //toolbarClick={toolbarClick}
            allowFiltering={true}
            showColumnChooser={true}
            allowReordering={true}
            visible={false}
            allowTextWrap={true}
            //actionBegin={actionBegin}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerTextAlign='center'
                headerText='Currency Name'
                field='CurrencyName'
                width='150'
                editType='dropdownedit'
                edit={currencyParams}
                textAlign='Left'
              />
              <ColumnDirective
                headerTextAlign='center'
                headerText='CurrencyID'
                field='CurrencyID'
                width='150'
              visible={false}
              />
              	
	
	
              <ColumnDirective
                headerText='ID'
                field='CustomerPaymentDetailID'
                // format='n2'
                width='150'
                textAlign='Right'
                // editType='numericedit'
                visible={false}
                // edit={twoDPEditParams}
                headerTextAlign='center'
                //valueAccessor={thousandSeperator}
              />
              <ColumnDirective
                headerText='Initial
                 Payment %'
                field='InitialPaymentPercent'
                format='n2'
                width='150'
                textAlign='Right'
                editType='numericedit'
                edit={twoDPEditParams}
                headerTextAlign='center'
                //valueAccessor={thousandSeperator}
              />
              <ColumnDirective
                headerText='Initial Payment 
                Tolerance'
                field='InitialPaymentToleranceAmount'
                format='n2'
                width='160'
                textAlign='Right'
                editType='numericedit'
                edit={twoDPEditParams}
                headerTextAlign='center'
                //valueAccessor={thousandSeperator}
              />
              <ColumnDirective
                headerTextAlign='center'
                headerText='Final Payment 
                Tolerance '
                field='FinalPaymentToleranceAmount'
                width='160'
                format='n2'
                textAlign='Right'
                editType='numericedit'
                
                //defaultValue={0}
                // edit={numEditParams}
                //valueAccessor={thousandSeperator}
              />
            </ColumnsDirective>
            <Inject
              services={[Edit, Toolbar, Page, Sort, Filter, Aggregate, Reorder]}
            />
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='Quantity'
                    type='Sum'
                    footerTemplate={footerSum}
                    // valueAccessor={thousandSeperator}
                  />

                  <AggregateColumnDirective
                    field='TaxPercent'
                    type='Sum'
                    footerTemplate={footerSum}
                    // valueAccessor={thousandSeperator}
                  />

                  <AggregateColumnDirective
                    field='ExcludingTaxAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                    // valueAccessor={thousandSeperator}
                  />

                  <AggregateColumnDirective
                    field='TaxAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                    // valueAccessor={thousandSeperator}
                  />

                  <AggregateColumnDirective
                    field='TotalAmount'
                    type='Sum'
                    footerTemplate={footerSum}
                    // valueAccessor={thousandSeperator}
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
          </GridComponent>
        </div>
      </div>

      
    </div>
  );
}
