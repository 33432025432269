import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './app/store/index'
// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/scss/mdb-pro.scss';
// import '../node_modules/@syncfusion/ej2-base/styles/material.scss';
// import '../node_modules/@syncfusion/ej2-buttons/styles/material.scss';

ReactDOM.render(
<Provider store={store}>
<App />
</Provider>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
