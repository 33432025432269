import React, { useEffect, useState ,useRef} from 'react';
import CustomerService from '../../services/customer.service';
import SecurityService from '../security/security.service';
import './customer.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';
import CustomerReplicate from '../replicate-customer/replicate-customer'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import CustomerAdd from './customer-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import employeeService from '../../services/employee.service';
var selectedRows = []

var storedEmployeeID = 0
export default function CustomerList(props) {
  console.log(props)
  const [customer, setCustomer] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  // const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showDialogs, setShowDialogs] = useState();
const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
const [dialogWarningVisibility, setDialogWarningVisibility] = useState(false);
const [warningMessage, setWarningMessage] = useState('');
const deleteDialog = useRef();
const warningDialog = useRef();

const deleteButtons = [
  {
    buttonModel: {
      content: 'OK',
      cssClass: 'e-flat',
      isPrimary: true,
    },
    click: (e) => {
      deleteDialog.current.hide();
      console.log(e);
      // const data = {
      //   TableName:props.TableName,
      //   ConditionalColumn: props.ColumnName,
      //   ConditionalSign: '=',
      //   ConditionalValue:props.RowID,
      //   Columns:'*'
      // }
      // MultipleInputService.getMultipleInputByCondition(data).then((res)=>{
      //   console.log(res)
        deleteRow();
      // })
    },
  },
  {
    buttonModel: {
      content: 'Cancel',
      cssClass: 'e-flat',
    },
    click: () => {
      deleteDialog.current.hide();
      props.showResult(false)
    },
  },
];

const warningButtons = [
  {
    buttonModel: {
      content: 'OK',
      cssClass: 'e-flat',
      isPrimary: true,
    },
    click: () => {
      warningDialog.current.hide();
      getCustomer(storedEmployeeID)
      // if(props.SelectedRow){
      //   props.showResult(false)
      // }else{
      //   props.showResult(true)
      // }
      // console.log('iritza')
      // setTimeout(() => {
      //   if (!(warningMessage.includes('access denied'))) {
      //     // record was succesfully deleted
      //     const type = getRoute()[1];
      //     getYarnContracts(type);
      //     // console.log("record was succesfully deleted");
      //   }
      // }, 500);

    },
  },
];

function deleteDialogClose() {
  setDeleteDialogVisibility(false);
  }

function warningDialogClose() {
  setDialogWarningVisibility(false);
  if (!(warningMessage.includes('access denied'))) {
    
}
}
const deleteRow = ()=>{
  console.log(selectedRows)
  CustomerService.deleteCustomer(selectedRows[0].CustomerID).then(async (response) => {
    // setDialogWarningVisibility(false);
    // console.log("Message is about to set to ", response?.data?.recordset[0]?.Message);
    setWarningMessage(`RECORD(S) DELETED SUCESSFULLY`);
    // console.log("Message is set");
    warningDialog.current.show();
    // AuditTable.auditDelete(props.TableName,props.RowID ,props.SelectedRowData)

  
    // if (!(response?.data?.recordset[0]?.Message.includes('access denied'))) {
    //   // record was succesfully deleted
    //   const type = getRoute()[1];
    //   await getYarnContracts(type);
    //   // console.log("record was succesfully deleted");
    // }
    // console.log("Dialog Turned on");
  })
  .catch((e) => {
    // setWarningMessage('Error: ');
    // setDialogWarningVisibility(true);
    console.log('Error: ', e);
    setWarningMessage(`THIS RECORD CANNOT BE DELETED`);
    // console.log("Message is set");
    warningDialog.current.show();
    
  });

}

useEffect(() => {
  console.log(props.isCallSaleAgent)
  if(props.isCallSaleAgent == true){

    employeeService.SaleAgentIDByUserID(userID).then((res)=>{
      console.log(res)
      storedEmployeeID =  res.data.recordset[0].EmployeeID
      getCustomer(res.data.recordset[0].EmployeeID);
    })
  }else{
    getCustomer(0);
  }

}, []);

  let customerGrid: Grid;
  const [showDialogReplicate, setShowDialogReplicate] = useState(false);
  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      customerGrid &&
      args.item.properties.id === 'CustomerGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Customer_information.pdf`
      };
      console.log('clicked on pdf export');
      customerGrid.pdfExport(exportProperties);
    }
    if (
      customerGrid &&
      args.item.properties.id === 'CustomerGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Customer_information.xlsx`
      };
      customerGrid.excelExport(exportProperties);
    }
    if (
      customerGrid &&
      args.item.properties.id === 'CustomerGrid_columnchooser'
    ) {
    }
    if (customerGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (customerGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', customerGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`Customer`,
          ColumnName:`CustomerID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        selectedRows = customerGrid.getSelectedRecords()

        // console.log('delete: ', customerGrid.getSelectedRecords()[0]?.CustomerID)
        // let obj={
        //   TableName:``,
        //   ColumnName:``,
        //   RowID:customerGrid.getSelectedRecords()[0]?.CustomerID,
        //   SelectedRow:true,
        //   SelectedRowData:customerGrid.getSelectedRecords()[0]
          
        //   }
        //   setDeletingProps(obj)
        
        // // deleteDialog.current.show();
        // setShowDialogs(true)
        deleteDialog.current.show()
      }
    }
    console.log(customerGrid.getSelectedRecords()) 
    if (args.item.properties.id == "Replicate" && customerGrid.getSelectedRecords().length > 0)  {
      selectedRows = customerGrid.getSelectedRecords()
      setTimeout(() => {
        
        setShowDialogReplicate(true)
      }, 1000);
    }else if( args.item.properties.id == "Replicate" &&  customerGrid.getSelectedRecords().length == 0){
      window.alert('PLEASE SELECT ROW TO REPLICATE CUSTOMER')
    }
  };

  
  useEffect(() => {
    
  }, []);

  const getCustomer = async (id) => {
    
    CustomerService.getCustomerForList(id)
      .then((response) => {
        console.log('Customer get response', response.data.recordset);
        setCustomer(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('CustomerDelete')    },
      'Update',
      'Cancel',
      {
        text: "Customer Replicate", tooltipText: "Replicate", id: "Replicate", disabled:!SecurityService.canUserAccess('CustomerReplicate')    },
    
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ? (
      <CustomerAdd {...props} dataList={customer}> </CustomerAdd>
    ) 
    :
    (
        <CustomerAdd {...props} dataList={customer}> </CustomerAdd>
    );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess('CustomerEdit'),
    allowAdding: SecurityService.canUserAccess('CustomerAdd'),
    allowDeleting: SecurityService.canUserAccess('CustomerDelete'),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userID = SecurityService.getLoggedInUserId();

  const actionComplete = (args: DialogEditEventArgs) => {
    if (args?.requestType === 'columnstate') {
        console.log('adjusting column');
        customerGrid?.autoFitColumns();
    }
    if (args?.form) {
        if (args?.requestType === 'beginEdit' || args?.requestType === 'add') {
            console.log('e: ', args?.form?.ej2_instances[0]);
        }
        if (args?.requestType === 'save' || args?.requestType === 'cancel') {
            console.log('args: ', args);
            getCustomer(storedEmployeeID);
            customerGrid?.refresh();
            // YarnGodownTransactionsService.create(args.data).then((response) => {
            //   console.log('response: ', response);
            //   const data = response.data.recordset.map((v,k) => {
            //     v.TransactionDate = new Date(v.TransactionDate.split('T',1)[0])
            //     v.ApprovedByDate = new Date(v.ApprovedByDate.split('T',1)[0])
            //     return v
            //   });
            //   setYarnGodownTransactions(data);
            // });

        }

      //   if (args.requestType === 'save'  && args.action   === 'add') {
      //     const data = {
      //       UserID: userID,
      //       ...args.data,
      //     };
      //     console.log('args: ', data)
       
  
        
      //   CustomerService.addCustomer(data)
      //   .then((response) => {
      //     console.log(response)
      //     // AuditTableInput.auditAdd(`Customer`,`Customer`,`CustomerID`,data)
      //     // setCustomer(response?.data?.recordset)
      //   });
    
      // }
         
        if (args.requestType === 'save'  && args.action === 'edit') {
          const data = {
            UserID: userID,
            ...args.data,
          };
          console.log('args: ', data)
          CustomerService.updateCustomer(args.data.CustomerID,data)
            .then((response) => {
              // let totalColumns = Object.keys(args.data);
              // console.log(totalColumns)
              // AuditTableInput.auditEdit(totalColumns,selectedRows,args.data,`Customer`,args.data.CustomerID)
              getCustomer(storedEmployeeID);
              console.log(response)});
        }
    }
};

  // const actionComplete = (args: DialogEditEventArgs) => {
  //   console.log('data: ', args);
  //   if (args?.requestType === 'columnstate') {
  //     console.log('adjusting column');
  //     customerGrid?.autoFitColumns();
  //   }
  //   if (args?.form) {
  //     console.log('data: ', args);
  //     if (args.requestType === 'beginEdit' || args.requestType === 'add') {
  //       console.log('e: ', args?.form?.ej2_instances[0]);
  //     }
      

  //   }

  // };
  const databound = () =>
  {
      if (customerGrid) {
        customerGrid.autoFitColumns();
      }
  }
  let refreshColumn =(columnName) =>{
    if (customerGrid) {
      customerGrid.columns.forEach(column => {
        if (column.field === columnName) {
          customerGrid.refreshColumns(column);
        }
      });
    }
  }
  const userId = SecurityService.getLoggedInUserId();
  const userName = SecurityService.getLoggedInUser()
  const handleCheckboxChange = (e,data) => {
    // console.log(data.C)
    console.log(e,data)
    let dataS = {
      CustomerID:data.CustomerID,
      DeletionDate : e.target.checked == true? `${new Date()}`: null,
      DeletedByUserName : userName,
      DeletedByUserID : e.target.checked == true? userId: null,
      IsDeleted:e.target.checked == true? 1: 0
    }
   CustomerService.CustomerIsDeletedService(dataS,data.CustomerID).then((res)=>{
    console.log(customer)
     let dataFin = customer.map((Res)=>{
       if(data.CustomerID == Res.CustomerID){
         console.log(Res)
         if(Res.IsDeleted == true){
           Res.IsDeleted = false;
          //  Res.CRMID = 0;
          //  Res.CRMName = null
          //  Res.PaymentVerificationStatusDate = null
          Res.DeletionDate = new Date()
          Res.DeletedByUserName = userName
          Res.DeletedByUserID = userId
          refreshColumn('IsDeleted')
          refreshColumn('DeletionDate')
           refreshColumn('DeletedByUserName')
          
           Res.DeletionDate = new Date()
          //  Res.IsDeleted = true
           refreshColumn('IsDeleted')
           refreshColumn('DeletionDate')
           Res.DeletedByUserName = 'NULL'
            refreshColumn('DeletedByUserName')
        }else{
         //  Res.IsDeleted = true;
         //  Res.CRMID = userId
         //  Res.CRMName = printedByUserName
          
         Res.IsDeleted = true;
         //  Res.CRMID = 0;
         //  Res.CRMName = null
         //  Res.PaymentVerificationStatusDate = null
         Res.DeletionDate = new Date()
         Res.DeletedByUserName = userName
         Res.DeletedByUserID = userId
         refreshColumn('IsDeleted')
         refreshColumn('DeletionDate')
          refreshColumn('DeletedByUserName')
         
        
           
         }
       }
     })
     console.log(dataFin)
 
   })
 };
 const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const CustomerGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (customerGrid = grid)}
      id='CustomerGrid'
      dataSource={customer}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      dataBound={databound}
      rowSelected={
        (args)=>{
          console.log(args.data)
          
          return selectedRows = [args.data]
          
                    setTimeout(() => {
            console.log('args.data', args.data)
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        />
      
         <ColumnDirective
          headerText='Customer Name'
          headerTextAlign='CENTER'    
          field='CustomerName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Customer Email'
          headerTextAlign='CENTER'    
          field='CustomerEmail'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Contact No 1'
          headerTextAlign='CENTER'    
          field='ContactNo1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Contact No 2'
          headerTextAlign='CENTER'    
          field='ContactNo2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Contact No 3'
          headerTextAlign='CENTER'    
          field='ContactNo3'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address 1'
          headerTextAlign='CENTER'    
          field='Address1'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Address 2'
          headerTextAlign='CENTER'    
          field='Address2'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Country Name'
          headerTextAlign='CENTER'    
          field='CountryName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='City Name'
          headerTextAlign='CENTER'    
          field='CityName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Port Name'
          headerTextAlign='CENTER'    
          field='PortName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Sales Agent Name'
          headerTextAlign='CENTER'    
          field='SalesAgentName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Designation Name'
          headerTextAlign='CENTER'    
          field='DesignationName'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective
          headerText='Commission %'
          headerTextAlign='CENTER'    
          field='CommissionPercent'
          width='150'
          textAlign='Left'
        />
        <ColumnDirective 
        field='IsDeleted'
         headerText='InActive'
          width='170' 
          textAlign='center'
            template={(props) => (
              <label className="checkbox-container">
              <input
               type='checkbox'

                className={`checkbox ${props.IsDeleted ? 'yellow': ''}`}
                id={`checkbox_${props.SerialNo}`}
                 checked={props.IsDeleted}
                 
                  onChange={(e) => handleCheckboxChange(e, props)} 
                />
              <span className="checkmark"></span>
              </label>
            )} 
            
            />
         {/* <ColumnDirective
                headerText='InActive
                 Date'
                field='DeletionDate'
                headerTextAlign='Center'
                width='135'
                type='date'
                format={formatOptions}
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />
               <ColumnDirective
                headerText='InActive
                 User Name'
                field='DeletedByUserName'
                headerTextAlign='Center'
                width='145'
                textAlign='left'
                
                // template={(rowData) => rowData['IsDeleted'] ? 'In Active' : 'Active'}
              />  */}
        
        {/* <ColumnDirective
          headerText='Is Deleted'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='IsDeleted'
          width='150'
        /> */}
        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          // CommandColumn,
        ]}
      />
    </GridComponent>
  );

  const replicateHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1?'Replicate Cutomer':null}</div>;
  };


  const contentReplicateTemplate = () => {
    // console.log(customerGrid.getSelectedRecords())
    // setTimeout(() => {
    //   selectedRows =[]
    // }, 1500);
    console.log(selectedRows)
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1 || showDialogReplicate == true ) {
    return (
      <div>
        <CustomerReplicate
          {...selectedRows[0]}
          dataList={customer}
          
        ></CustomerReplicate>
      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };

  const replicateDialogClose = () => {
    // this.buttonInstance.style.display = 'inline-block';
    getCustomer(storedEmployeeID);

    setShowDialogReplicate(false);
  };
  return (
    (props.isDialog) ? (
      <div>
         <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogReplicate}
              close={replicateDialogClose}
              //open={openReturnDialog}
              content={contentReplicateTemplate}
              isModal={true}
              showCloseIcon={true}
              header={replicateHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
           <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={deleteDialogVisibility}
                close={deleteDialogClose}
                content={() => (<div>Are you sure you want to delete?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              <DialogComponent
                ref={warningDialog}
                width={400}
                visible={dialogWarningVisibility}
                close={warningDialogClose}
                content={() => (<div>{warningMessage}</div>)}
                isModal={true}
                header='Warning'
                buttons={warningButtons}
              // footerTemplate={footerTemplate}
              />
            </div>
                 {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCustomer( storedEmployeeID)
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
        <CustomerGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Customer  {props.isCallSaleAgent == true ? `Sale Agent`:''} - List</h3>
             
              <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogReplicate}
              close={replicateDialogClose}
              //open={openReturnDialog}
              content={contentReplicateTemplate}
              isModal={true}
              showCloseIcon={true}
              header={replicateHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
              <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={deleteDialogVisibility}
                close={deleteDialogClose}
                content={() => (<div>Are you sure you want to delete?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              <DialogComponent
                ref={warningDialog}
                width={400}
                visible={dialogWarningVisibility}
                close={warningDialogClose}
                content={() => (<div>{warningMessage}</div>)}
                isModal={true}
                header='Warning'
                buttons={warningButtons}
              // footerTemplate={footerTemplate}
              />
            </div>
              {/* {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCustomer( storedEmployeeID)
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null} */}
              <div>
              </div>
              <CustomerGridComponent />
            </div>
          </div>
        </div>
      )
  );
}
