import http from "./http-common";

class CurrencyService {
  
  getCurrencyForList() {
    return http.get('/currency/list');
  }

  getCurrencyForDD() {
    return http.get('/currency/dropdown');
  }
  getCurrencySymbolForDD() {
    return http.get('/currency/symbol/dropdown');
  }

  addCurrency(data) {
    return http.post('/currency/add', data);
  }

  delete(ProductID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/currency/${ProductID}`)
  }

  updateCurrency(id,data) {
    return http.put(`/currency/${id}`,data);
  }

}

export default new CurrencyService();
