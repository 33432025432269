import React, { useEffect, useRef,  useState , useMemo } from 'react';
import MessageService from '../../services/message.service';
import SecurityService from '../security/security.service';
import EmailEditorPopup from '../PI/email-editor-popup';
import {
    GridComponent,
    Inject,
    Toolbar,
    ToolbarItems,
    Edit,
    EditSettingsModel,
    Page,
    Sort,
    PdfExport,
    PdfExportProperties,
    ExcelExport,
    Grid,
    Resize,
    FilterSettingsModel,
    Filter,
    Group,
    GroupSettingsModel,
    AggregateColumnDirective,
    ColumnDirective,
    ColumnsDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    Aggregate,
    Freeze,
    ColumnChooser,
    Reorder,
    DialogEditEventArgs,
    CommandColumn,
    CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
// import AccessoryTransferAdd from './full-yarn-count-add';
import CommonService from '../../services/common.service';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import ChartOfAccount from './chart-of-account';
// import AccessoryPurchaseTransactionsReturnList from './accessory-purchase-transaction-return-list';
import moment from 'moment';
import EmailEditorService from '../../services/email-editor.service';
import HardDelete from '../hard-delete/hard-delete';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import './email-editor.scss'
import PIView from '../pi-view/pi-view';

import PIService from '../../services/PI.service';
// import { useMemo } from 'react';

let emailEditorGrid: Grid;
var employeeEmail = ''
var isShowGrid = false;
export default function EmailEditor(props) {
    const [emailEditorDataSource, setEmailEditorDataSource] = useState([]);
    const [message, setMessage] = useState([]);
  const [loadingPi, setLoadingPi] = useState(false);
    const [pi, setPi] = useState([]);
  const [piValue, setPIValue] = useState('');
  const [piID, setPiID] = useState(0);
    const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
    const [dialogWarningVisibility, setDialogWarningVisibility] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const deleteDialog = useRef();
    const warningDialog = useRef();
  
    const [showDialogs, setShowDialogs] = useState();
    const [deletingProps, setDeletingProps] = useState();
    const [deletingID, setDeletingID] = useState(0);
    const [showDialogEmail, setShowDialogEmail] = useState(false);
    const [showDialogView, setShowDialogView] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [printedByUserName, setPrintedByUserName] = useState();
  const [printedByEmail, setPrintedByEmail] = useState();

  const [piprops, setPiprops] = useState([]);
  const [piData, setPiData] = useState([]);
    const toolbarClick = (args: ClickEventArgs) => {
        console.log('args.item.properties :', args.item.properties);
        if (
            emailEditorGrid &&
            args.item.properties.id === 'EmailEditorGrid_PDFExport'
        ) {
            const exportProperties: PdfExportProperties = {
                pageOrientation: 'Landscape',
                fileName: `${moment().format('YYYYMMDD')}_email_information.pdf`
            };
            console.log('clicked on pdf export');
            emailEditorGrid.pdfExport(exportProperties);
        }
        if (
            emailEditorGrid &&
            args.item.properties.id === 'EmailEditorGrid_excelexport'
        ) {
            const exportProperties: PdfExportProperties = {
                pageOrientation: 'Landscape',
                fileName: `${moment().format('YYYYMMDD')}_email_information.xlsx`
            };
            emailEditorGrid.excelExport(exportProperties);
        }
        if (
            emailEditorGrid &&
            args.item.properties.id === 'EmailEditorGrid_columnchooser'
        ) {
        }
        if (emailEditorGrid && args.item.properties.id === 'Delete') {
          console.log('toolbar Delete');
          if (emailEditorGrid.selectedRowIndex === -1) {
            // no rows selected
            console.log('Delete ', emailEditorGrid.getSelectedRecords()[0]);
            // setDialogWarningVisibility(false);
            let obj={
              TableName:`FullYarnCount`,
              ColumnName:`FullYarnCountID`,
              RowID:undefined,
              SelectedRow:false
              
            }
            setDeletingProps(obj)
            // setShowDialogs(true)
            setWarningMessage("Please select a row first!");
            warningDialog.current.show();
    
          } else {
            console.log('delete: ', emailEditorGrid.getSelectedRecords())
            
            // deleteDialog.current.hide();
            let obj={
              TableName:`ChartOfAccount`,
              ColumnName:`AccountID`,
              RowID:emailEditorGrid.getSelectedRecords()[0]['AccountID'],
              SelectedRow:true,
              SelectedRowData:{
                AccountCode: emailEditorGrid.getSelectedRecords()[0].AccountCode,
                AccountTitleShort:  emailEditorGrid.getSelectedRecords()[0].AccountTitleShort,
              
          }
    
              
        }
              setDeletingProps(obj)
            
            // deleteDialog.current.show();
            setShowDialogs(true)
          }
        } 
    };

    useEffect(() => {
        // getEmailEditor();
        SecurityService.getUserAccessList(userId).then((res)=>{
          console.log(res)
          console.log(res.data.recordset[0])
          setPrintedByUserName(res.data.recordset[0].UserName)
          setPrintedByEmail(res.data.recordset[0].Email)
         });
         getPiDropDown()
        // localStorage.removeItem('gridAccessoryDisplayGrid');
        SecurityService.getEmployeeEmailByUserID(userId).then((res)=>{
          console.log(res)
            employeeEmail = res.data.recordset[0].EmployeeEmail;
            // getEmailEditor(0, res.data.recordset[0].EmployeeEmail)
        })
        isShowGrid = false
    }, []);
    
  const getProformaInvoice = async (id) => {
    console.log(id)
    PIService.getList()    
      .then((response) => {
        console.log('ProductFinishSize get response', response.data.recordset);
        let filterData = response.data.recordset.filter(res =>{ if( res.PIID == id){
          return res
        }})
        console.log(filterData)
        setPiData(response.data.recordset)
        setPiprops(filterData[0])
        // setPI(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

    const getPiDropDown = async () => {
      MessageService.getPIDropdownList()
        .then((response) => {
          console.log('get all pi', response)
          setPi(response.data.recordset);
  
        })
        .catch((e) => {
          console.log(e);
        });
    };
  
    const getEmailEditor = async (PiID) => {
        EmailEditorService.getEmailEditorList(PiID  , employeeEmail)
            .then((response) => {
              console.log(response.data.recordset)
              setEmailEditorDataSource( response.data.recordset);
              // let data = response.data.recordset.filter(res => res.PIID == PiID)
              // console.log(data)
              // setEmailEditorDataSource( data);
              // setEmailEditorDataSource( data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const toolbarOptions: ToolbarItems[] = [
        // 'Add',
  //  'Edit',
    // {
    //   text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess('AccessoryTransferDelete')
    // },
    // 'Delete',
        // 'Update',
        // 'Cancel',
        'PDFExport',
        'ExcelExport',
        'Search',
        'ColumnChooser',
    ];

    const dialogTemplate = (props) => {
        console.log('props: ', props);
        return props.isAdd ? (
            
            // <ChartOfAccount dataList={emailEditorDataSource} {...props} > </ChartOfAccount>
            <div></div>
        ) :
            (
                <div></div>

                // <ChartOfAccount dataList={emailEditorDataSource} {...props} > </ChartOfAccount>
            );
    };

    const editSettings: EditSettingsModel = {
        allowEditing:SecurityService.canUserAccess('EmailEdit'),
        allowAdding: SecurityService.canUserAccess('EmailAdd'),
        allowDeleting: SecurityService.canUserAccess('EmailDelete'),
        mode: 'Dialog',
        showDeleteConfirmDialog: true,
        template: dialogTemplate,
        headerTemplate: '<p></p>',
    };

    const filterOptions: FilterSettingsModel = {
        type: 'Excel',
        // columns: [
        //     {
        //         field: 'IsDeleted',
        //         operator: 'notequal',
        //         value: 'True'
        //     }
        // ]
    };

    const groupOptions: GroupSettingsModel = {
        showDroopArea: true,
    };

    const sortingOptions: SortSettingsModel = {
        columns: [{ field: 'PIEmailID', direction: 'Descending' }]
    }

    const footerSum = (e: any) => {
        return <span> Sum: {e.Sum}</span>;
    };

    const userId = SecurityService.getLoggedInUserId();

    const actionComplete = (args: DialogEditEventArgs) => {
        console.log('data: ', args);
        if (args?.requestType === 'columnstate') {
            console.log('adjusting column');
            emailEditorGrid?.autoFitColumns();
        }
        if (args.form) {
            console.log('data: ', args);
            if (args.requestType === 'beginEdit' || args.requestType === 'add') {
                // console.log('e: ', args.form.ej2_instances[0]);
                // /** Add Validation Rules */
                // args.form.ej2_instances[0].addRules('YarnCountID', { required: true });
                // args.form.ej2_instances[0].addRules('YarnThread', { required: true, });
                // args.form.ej2_instances[0].addRules('YarnQualityID', { required: true });
                // args.form.ej2_instances[0].addRules('YarnTypeID', { required: true });
                // args.form.ej2_instances[0].addRules('HSCodeID', { required: true, });

            }
            if (args.requestType === 'save' && args.data.ActionType ==  'Add') {
              console.log(args.data)
                const data = {
                   AccountCode:args.data.AccountCode
                  ,AccountTitle:args.data.AccountTitle
                  ,AccountTitleShort:args.data.AccountTitle
                  ,Transactional:args.data.Transactional == false ? '0':'1'
                  ,Inactive:args.data.Inactive == false ? '0':'1'
                  ,AccountTypeID:args.data.AccountTypeID
                  ,OpeningBalance:args.data.OpeningBalance
                  ,CreditDays:args.data.CreditDays
                  ,CreditAmountLimit:args.data.CreditAmountLimit
                  ,ContactPerson:args.data.ContactPerson == undefined ? '-' : args.data.ContactPerson
                  ,OfficeAddress:args.data.OfficeAddress == undefined ? '-' : args.data.OfficeAddress
                  ,LandlineNumber:args.data.LandlineNumber == undefined ? '-' : args.data.LandlineNumber
                  ,CellNumber:args.data.CellNumber == undefined ? '-' : args.data.CellNumber
                  ,NTN:args.data.NTN == undefined ? '-' : args.data.NTN
                  ,STRN:args.data.STRN == undefined ? '-' :args.data.STRN
                  ,EmailAddress:args.data.EmailAddress == undefined ? '-' : args.data.EmailAddress
                  ,AccountCategoryID:args.data.AccountCategoryID
                  ,Aging:args.data.Aging == false ? '0':'1'
                 ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
                    CreatedByUserID: userId,
                    TransactionDate: moment(args.data.TransactionDate).format('YYYY-MM-DD')
                }
                console.log('args: ', data)
                EmailEditorService?.create(data)
                    .then((response) => {
                       
                      getEmailEditor(piID)
                        emailEditorGrid.refresh();
                    });

            }
            if (args.requestType === 'save' && args.data.ActionType ==  'Edit') {
              console.log(args.data)
                const data = {
                   AccountCode:args.data.AccountCode
                  ,AccountTitle:args.data.AccountTitle
                  ,AccountTitleShort:args.data.AccountTitle
                  ,Transactional:args.data.Transactional == false ? '0':'1'
                  ,Inactive:args.data.Inactive == false ? '0':'1'
                  ,AccountTypeID:args.data.AccountTypeID
                  ,OpeningBalance:args.data.OpeningBalance
                  ,CreditDays:args.data.CreditDays
                  ,CreditAmountLimit:args.data.CreditAmountLimit
                  ,ContactPerson:args.data.ContactPerson == undefined ? '-' : args.data.ContactPerson
                  ,OfficeAddress:args.data.OfficeAddress == undefined ? '-' : args.data.OfficeAddress
                  ,LandlineNumber:args.data.LandlineNumber == undefined ? '-' : args.data.LandlineNumber
                  ,CellNumber:args.data.CellNumber == undefined ? '-' : args.data.CellNumber
                  ,NTN:args.data.NTN == undefined ? '-' : args.data.NTN
                  ,STRN:args.data.STRN == undefined ? '-' :args.data.STRN
                  ,EmailAddress:args.data.EmailAddress == undefined ? '-' : args.data.EmailAddress
                  ,AccountCategoryID:args.data.AccountCategoryID
                  ,Aging:args.data.Aging == false ? '0':'1'
                 ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
                    CreatedByUserID: userId,
                    TransactionDate: moment(args.data.TransactionDate).format('YYYY-MM-DD')
                }
                console.log('args: ', data)
                EmailEditorService.update(data)
                    .then((response) => {
                       
                      getEmailEditor(piID)
                        emailEditorGrid.refresh();
                    });

            }

        }

    };

    const formatOptions = { type: 'date', format: 'dd/MM/yy' }
    const deleteRow = () => {
        CommonService.delete(userId, 'AccessoryTransferDelete', 'AccessoryAdjustTransferTransactions', 'AccessoryAdjustTransferTransactionID', deletingID, 1)
          .then(async (response) => {
            // setDialogWarningVisibility(false);
            // console.log("Message is about to set to ", response?.data?.recordset[0]?.Message);
            setWarningMessage(response?.data?.recordset[0]?.Message);
            // console.log("Message is set");
            warningDialog.current.show();
            // if (!(response?.data?.recordset[0]?.Message.includes('access denied'))) {
            //   // record was succesfully deleted
            //   const type = getRoute()[1];
            //   await getYarnContracts(type);
            //   // console.log("record was succesfully deleted");
            // }
            // console.log("Dialog Turned on");
          })
          .catch((e) => {
            // setWarningMessage('Error: ');
            // setDialogWarningVisibility(true);
            console.log('Error: ', e);
          });;
      };
    
      const deleteButtons = [
        {
          buttonModel: {
            content: 'OK',
            cssClass: 'e-flat',
            isPrimary: true,
          },
          click: (e) => {
            deleteDialog.current.hide();
            console.log(e);
            deleteRow();
          },
        },
        {
          buttonModel: {
            content: 'Cancel',
            cssClass: 'e-flat',
          },
          click: () => {
            deleteDialog.current.hide();
          },
        },
      ];
    
      const warningButtons = [
        {
          buttonModel: {
            content: 'OK',
            cssClass: 'e-flat',
            isPrimary: true,
          },
          click: () => {
            warningDialog.current.hide();
            // setTimeout(() => {
            //   if (!(warningMessage.includes('access denied'))) {
            //     // record was succesfully deleted
            //     const type = getRoute()[1];
            //     getYarnContracts(type);
            //     // console.log("record was succesfully deleted");
            //   }
            // }, 500);
    
          },
        },
      ];
    
      function deleteDialogClose() {
        setDeleteDialogVisibility(false);
      }
    
      function warningDialogClose() {
        setDialogWarningVisibility(false);
        if (!(warningMessage.includes('access denied'))) {
          // record was succesfully deleted
        //   const type = getRoute()[1];
                      getEmailEditor(piID)
        //   getYarnSaleTransactions(type);
          // console.log("record was succesfully deleted");
        }
      }

      const databound = () =>
  {
      if (emailEditorGrid) {
        emailEditorGrid.autoFitColumns();
      }
  }
  // const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  
  const commands: CommandModel[] = [
    {
      buttonOption: {
        id: 'email',
        content: 'Email Preview',
        cssClass: 'e-flat',
        isPrimary: true,
      }
       
    }
    // ,{
    //   buttonOption :{
    //     id: 'view-pi',
    //     content: 'View PI',
    //     cssClass: 'e-flat',
    //     isPrimary: true,
    //   }
    // }
  
  ];
  const commandClick = (args: CommandClickEventArgs) => {
    if (args.commandColumn.buttonOption.id === 'email') {
      setShowDialogEmail(false);
      console.log('command click args: ', args.commandColumn.buttonOption.id);
      setSelectedRow(args.rowData);
      setShowDialogEmail(true);
    }else if (args.commandColumn.buttonOption.id === 'view-pi'){
      setShowDialogView(false);
      console.log('command click args: ', args.commandColumn.buttonOption.id);
      setSelectedRow(args.rowData);
    if(args.rowData.PIID !=  undefined){

      getProformaInvoice(args.rowData.PIID)
    }
      setShowDialogView(true);
    }
  }
  const emailHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'Yarn Transactions Import Expenses':null}</div>;
  };
  const viewHeader = () => {
    return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'View PI':null}</div>;
  };

  const contentViewTemplate = () => {
    console.log(selectedRow)
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1 || showDialogView == true){
    return (
      <div>
         <PIView dataList={piData}  userName={printedByUserName} type={"Supervision"} isPreview={true}  isAdd={false}  {...piprops}> </PIView>
          
      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };
  const contentEmailTemplate = () => {
    console.log(selectedRow)
    if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1 || showDialogEmail == true){
    return (
      <div>
        <EmailEditorPopup
          {...selectedRow}
          loggedInUser={printedByEmail}
          isPreview={true}
          
        ></EmailEditorPopup>
      </div>
    )
  }else{
 return( 
    <div className='returnlist'>
      {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
    </div>
 )
  }
  };
  const emailDialogClose = () => {
    this.buttonInstance.style.display = 'inline-block';
    setShowDialogEmail(false);
  };
  const viewDialogClose = () => {
    setShowDialogView(false);
    // this.buttonInstance.style.display = 'inline-block';
  };

  const viewPiClicked = () =>{
   
    console.log(piID)
    if(piID == 0 || piID == undefined){
       window.alert('PLEASE SELECT ROW TO VIEW PI')
    }else{
      getProformaInvoice(piID)
      // let filterRowByid = emailEditorGrid.dataSource.filter((res)=>{res.PIID ==  piID})
      // console.log(filterRowByid)
      // if(filterRowByid.length > 0){
        
      //   setSelectedRow(filterRowByid);
        setShowDialogView(true);
      // }{
      //   window.alert('PLEASE SELECT ANOTHER PI')
      // }
    }
    // if(args.rowData.PIID !=  undefined){

    //   getProformaInvoice(args.rowData.PIID)
    // }
  }

    const EmailEditorGridComponent = useMemo(()=>{
      
      return ({ restrictPageSize = 12 }) => (
        <GridComponent
            ref={(grid) => (emailEditorGrid = grid)}
            id='EmailEditorGrid'
            dataSource={emailEditorDataSource}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
            filterSettings={filterOptions}
            groupSettings={groupOptions}
            actionComplete={actionComplete}
            allowTextWrap='true'
            allowPaging={true}
            allowSorting={true}
            allowPdfExport={true}
            allowExcelExport={true}
            allowFiltering={true}
            toolbarClick={toolbarClick}
            allowGrouping={true}
            showColumnChooser={true}
            allowReordering={true}
            allowResizing={true}
            sortSettings={sortingOptions}
            commandClick={commandClick}
            // dataBound={databound}
        >
            <ColumnsDirective>
            <ColumnDirective
                headerText='Commands'
                width='200'
                commands={commands}
                textAlign='center'
                headerTextAlign='center'
              />
              
            {/* 
            <ColumnDirective
                headerText="ID"
                field="PIEmailID"
                width="90"
                textAlign="Right"0
                headerTextAlign="center"
              />
            */}
            <ColumnDirective
                headerText="Serial No"
                field="SerialNo"
                width="120"
                textAlign="Right"
                headerTextAlign="center"
              /> 

              <ColumnDirective
                headerText="Email Subject"
                field="EmailSubject"
                width="150"
                
                textAlign="left"
                headerTextAlign="center"
                
              />
              <ColumnDirective
                headerText="To Email"
                field="ToEmailAddress"
                width="200"
                textAlign="Left"
                // textAlign="Right"
                headerTextAlign="center"
              />
              <ColumnDirective
                headerText="From Email "
                field="FromEmailAddress"
                width="200"
                textAlign="Left"
                // textAlign="Right"
                headerTextAlign="center"
              />
              <ColumnDirective
                headerText="CC Email "
                field="CCEmailAddress"
                width="200"
                textAlign="left"
                headerTextAlign="center"
              />
              
              <ColumnDirective
                headerText="Email Date"
                field="EmailDate"
                width="150"
                textAlign="right"
                format={formatOptions}
                type='date'

                headerTextAlign="center"
              />
              <ColumnDirective
                headerText="Has Attachments"
                field="HasAttachments"
                width="180"
                textAlign="left"
                headerTextAlign="center"
              />
         
            </ColumnsDirective>
            
            <Inject
                services={[
                    Edit,
                    Toolbar,
                    Page,
                    Sort,
                    PdfExport,
                    ExcelExport,
                    Filter,
                    Group,
                    Aggregate,
                    Freeze,
                    ColumnChooser,
                    Reorder,
                    Resize,
                    CommandColumn,
                ]}
            />
        </GridComponent>
)},[emailEditorDataSource , piID]);
    let piRef = useRef(null)
    const ddlFields: object = { value: "value" };
    const piChange = (e) => {
      setPIValue(e?.itemData?.value ? e?.itemData?.value :'');
      if (e?.e?.srcElement?.id) {
        console.log("piChange: ", e?.itemData?.id);
        // setCountryID(e?.itemData?.id);
        setPiID(e?.itemData?.id);
        // getEmailEditor(e?.itemData?.id);
      }else{
        setPiID(0);

      }
      // else {
      //   setMessage([]);
      //   setPiID(0)
      // }
    };
    return (
        (props.isDialog) ? (
            <div>
              <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogEmail}
              close={emailDialogClose}
              //open={openReturnDialog}
              content={contentEmailTemplate}
              isModal={true}
              showCloseIcon={true}
              header={emailHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
               {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );

                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        setTimeout(() => {
          
          // yarnCountGrid.refresh()
          getEmailEditor(piID)
          

        }, 200);
      // getProductCutSize(props.path.substring(1));

                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
                   <div className="col-sm-4  pi-div">
                  {loadingPi ? (
                    <div>... loading PI </div>
                  ) : (
                    <div>
                      <AutoCompleteComponent
                        id="pi"
                        dataSource={pi}
                        placeholder="Select PI"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={piRef}
                        select={piChange}
                        value={piValue}
                        change={piChange}
                        blur={()=>{
                          console.log(piRef.current)
                           if (piID == 0  || piRef.current.value == null || piRef.current.value == '' || piID == undefined ) {
                            // piRef.current.element.focus()
                            if(piValue != ''){
                            let resstr = pi.some(e=>e.value ==  piValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("pi").parentElement.parentElement.firstChild)
                              document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }}
                        

                      />
                      <TextBoxComponent
                        name="piID"
                        value={piID}
                        type="hidden"
                      />
                    </div>
                  )}

                <button onClick={(e)=>{
                  console.log(piID)
                  setTimeout(() => {
                    
                    isShowGrid = true
                  }, 500);
                  getEmailEditor(piID)
                }}>Emails</button>
                <button onClick={viewPiClicked}>View PI</button>
                </div>
        <EmailEditorGridComponent restrictPageSize={5} />

              
            </div>
        )
            :
            (
                <div className="content-wrapper">
                   {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );

                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        setTimeout(() => {
          
          // yarnCountGrid.refresh()
          getEmailEditor(piID)
          

        }, 200);
      // getProductCutSize(props.path.substring(1));

                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
                    <div>
                    <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogEmail}
              close={viewDialogClose}
              //open={openReturnDialog}
              content={contentEmailTemplate}
              isModal={true}
              className='pi-view'
              showCloseIcon={true}
              header={emailHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
                    <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogView}
              close={viewDialogClose}
              //open={openReturnDialog}
              content={contentViewTemplate}
              isModal={true}
              showCloseIcon={true}
              header={viewHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
                        <div>
                            <h3>Emails - List</h3>
                            <div>
          <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={deleteDialogVisibility}
                close={deleteDialogClose}
                content={() => (<div>Are you sure you want to delete?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              <DialogComponent
                ref={warningDialog}
                width={400}
                visible={dialogWarningVisibility}
                close={warningDialogClose}
                content={() => (<div>{warningMessage}</div>)}
                isModal={true}
                header='Warning'
                buttons={warningButtons}
              // footerTemplate={footerTemplate}
              />
          </div>
                            <div>
                            </div>
                            <div className="col-sm-4 pi-div">
                  {loadingPi ? (
                    <div>... loading PI </div>
                  ) : (
                    <div>
                      <AutoCompleteComponent
                        id="pi"
                        dataSource={pi}
                        placeholder="Select PI"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={piRef}
                        select={piChange}
                        value={piValue}
                        change={piChange}
                        blur={()=>{
                          console.log(piRef.current)
                           if (piID == 0  || piRef.current.value == null || piRef.current.value == '' || piID == undefined ) {
                            // piRef.current.element.focus()
                            if(piValue != ''){
                            let resstr = pi.some(e=>e.value ==  piValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("pi").parentElement.parentElement.firstChild)
                              document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }}
                        

                      />
                      <TextBoxComponent
                        name="piID"
                        value={piID}
                        type="hidden"
                      />
                    </div>


                  )}
                  <button onClick={(e)=>{
                  console.log(piID)
                   return getEmailEditor(piID)
                  // setTimeout(() => {
                    
                  //   isShowGrid = true
                  // }, 500);
                }}>Emails</button>
                                  <button onClick={viewPiClicked}>View PI</button>

                </div>
        {emailEditorDataSource.length > 0 ? <EmailEditorGridComponent restrictPageSize={5} />
:null}

                        </div>
                    </div>
                </div>
            )
    );
}
