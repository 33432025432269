import http from "./http-common";

class SupplierService {
  
  getSupplierForList() {
    return http.get('/supplier/list');
  }
  
  getSupplierForDD() {
    return http.get('/supplier/dropdown');
  }

  addSupplier(data) {
    return http.post('/supplier/add', data);
  }

  delete(SupplierID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/supplier/${SupplierID}`)
  }

  updateSupplier(id,data) {
    return http.put(`/supplier/${id}`,data);
  }
  CustomerIsDeletedService(dataS ,id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/supplier/changeIsDeleted/${id}`,dataS)
  }
}

export default new SupplierService();
