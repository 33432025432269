import http from './http-common';

class DesignationService {
  getAll() {
    return http.get('/designation');
  }

  create(data) {
    return http.post(`/designation/add`, data);
  }
}

export default new DesignationService();
