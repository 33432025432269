import React, { useEffect, useState, useRef, useReducer } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import './receive-unAllocated-payment.scss';

import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import PIService from '../../services/PI.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { CheckBox } from '@syncfusion/ej2-react-buttons';
import ReceiveUnAllocatedPaymentService from '../../services/receive-unAllocated-payment.service';


import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { format } from 'date-fns';
import employeeService from '../../services/employee.service';
let holdRemarks = ''
let holdCRM = ''
let employeeID = ''
let employeeName = ''
const complexReducer = (state, action) => {
    console.log(state)
    console.log(action)
  switch (action.type) {
       case 'PaymentVerificationStatus':
        return {
          ...state,
         
          PaymentVerificationStatus:action.PaymentVerificationStatus,
          PaymentVerificationStatusDate:action.PaymentVerificationStatus == true ?  new Date(moment()) : null,
          CRMName:action.PaymentVerificationStatus == false && holdRemarks == state.Remarks ? holdCRM : employeeName,
          CRMID:action.PaymentVerificationStatus == false && holdRemarks == state.Remarks ? holdCRM : employeeID
        };
       break;
    case 'Remarks':
      return {
        ...state,
        Remarks:action.Remarks,
        CRMName:employeeName,
        CRMID: employeeID
        };
       break;
             default:
               return state;
    }
  };
  export default function PIAdd(props) {
      console.log(props)
    const [state, dispatch] = useReducer(complexReducer, { 
      PaymentVerificationStatus:props.PaymentVerificationStatus,
      Remarks : props.Remarks,
      CRMName : props.CRMName ? props.CRMName  : "",
      PaymentVerificationStatusDate : props.PaymentVerificationStatusDate ? props.PaymentVerificationStatusDate : null,
    });
console.log(state)
  
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
 
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');

useEffect(() => {
  console.log(SecurityService.getLoggedInUserId())
  getEmployeeID(userId)
  setTimeout(() => {
    holdRemarks =  props.Remarks
    holdCRM =  props.CRMName
  }, 300);
}, []);

// let userId = SecurityService.getLoggedInUserId();
const getEmployeeID = ( id = userId) => {

  ReceiveUnAllocatedPaymentService.getEmployeeByUserID(id).then((res)=>{
      console.log(res)
      employeeID = res.data.recordset[0].EmployeeID
      employeeName = res.data.recordset[0].EmployeeName
  }).catch((e) => {
    console.log(e)
  })

}

  const textBoxChange = (e) => {
    console.log(e)
    dispatch({ type: e.target.name , [e.target.name]: e?.value })
   
};

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'CustomerName':
        dispatch({ type: 'CustomerName' ,CustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CustomerName' , 
            CustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            CustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                })
                  }
                  break;
      case 'PaymentType':
        dispatch({ type: 'PaymentType' ,PaymentType: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'PaymentType' , 
            PaymentType: e?.itemData?.value ? e?.itemData?.value : '',
            PaymentTypeID: e?.itemData?.id ? e?.itemData?.id : '',
                })
                  }
                  break;
      case 'ToCustomerName':
        dispatch({ type: 'ToCustomerName' ,ToCustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'ToCustomerName' , 
            ToCustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            ToCustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                 })
              
                }
                  break;
      
      case 'CurrencyName':
        dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CurrencyName' , 
            CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
            CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      
      
            default:
        return state;
    }
   
   
};
const userId = SecurityService.getLoggedInUserId();
  // useEffect(() => {
  // setTimeout(() => {
    
  
  //   if(state.PINo  != '' && state.PIReferenceNo != ''  && checkDuplicate == true){
  //     console.log(props.dataList)
  //     console.log()
  //     var check = false
  //     if(props.isAdd ==  true){

  //        check = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
  //         PINo:state.PINo,
  //         PIReferenceNo:state.PIReferenceNo,
          
  //          })
  //     }
     
  //     // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
  //     console.log(check)
  //     // setCheckDuplicate(check)
  //     if(!check ){
  //       setCheckDuplicateValue('')
  //       if(state.PaymentDetailDataSource.length > 0){document.querySelector('#ReceiveUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';}
  //     }else{

  //       setCheckDuplicateValue('Duplicate Value Detected!')
  //       document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        
  //   }
  //  }
  // }, 500);
  // }, [state ,state ]);
  

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      // case 'ProductCategory':
      //   // getProductCategory();
      // break;
      // case 'ProductDesc':
      //   // getProductDesc();
      // break;
      // case 'UnitOfMeasurement':
      //   // getUOMs();
      // break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }


let productDescRef = useRef(null)
      let uomRef = useRef(null)
      
  const minDate: Date = new Date(moment().startOf('day'));

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: false,
    mode:'Normal'
    // allowEditing: SecurityService.canUserAccess('ProductEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
  }
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    //  'Edit',
   
    "Update",
    "Cancel",
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];

  
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
  // 	CustomerID	PaymentTypeID	ReferenceNo	SenderName	Description	DebitAmount	CreditAmount	CurrencyID
  return (
    <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Receive UnAllocated Payment - Approve</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        
        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
            <br/>
                <div className="col-sm-6">
                    <mat-form-field>
                    <DatePickerComponent
                          id='PaymentVerificationStatusDate'
                          placeholder='Payment Verification Status date'
                          floatLabelType='Auto'
                          // min={minDate}
                          name='PaymentVerificationStatusDate'
                          format='dd-MM-yy'
                          value={state.PaymentVerificationStatusDate}
                          // disabled={true}
                          enabled={false}
                          readOnly={true}
                          readonly={true}
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-6 checkbox-PaymentVerificationStatus">
                    <mat-form-field>
                      <CheckBoxComponent 
                      name='PaymentVerificationStatus'
                      id='PaymentVerificationStatus'
                      checked= {state.PaymentVerificationStatus}
                      label='Payment Verification Status'
                      change={(e)=>{
                        console.log(e)
                        dispatch({ type: 'PaymentVerificationStatus' ,PaymentVerificationStatus:e.checked})

                      }}
                      />
                      <TextBoxComponent
                        className='e-input'
                        type='hidden'
                        placeholder='PaymentVerificationStatus'
                        name='PaymentVerification'
                        floatLabelType='Auto'
                        value={state.PaymentVerificationStatus}
                        readOnly={true}
                        readonly={true}
                        enabled={false}
                        // onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                    
                  </div>
                  <div className="col-sm-6">
                    <mat-form-field>
                    <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='CRMName'
                        name='CRMName'
                        floatLabelType='Auto'
                        value={state.CRMName}
                        readOnly={true}
                        readonly={true}
                        enabled={false}
                        onChange={textBoxChange}
                       
                        />
                    <TextBoxComponent
                        className='e-input'
                        type='hidden'
                        placeholder='CRMID'
                        name='CRMID'
                        floatLabelType='Auto'
                        value={state.CRMID}
                        readOnly={true}
                        readonly={true}
                        enabled={false}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                    
                  </div>
                  
                  <br/>
                  <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='Remarks'
                        name='Remarks'
                        floatLabelType='Auto'
                        value={state.Remarks}
                        onChange={textBoxChange}
                       
                        />
                    </mat-form-field>
                  </div> 
                  
                 
                  <br/>
                  <br/>
                  </div>

                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
      </div>
      {/* if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            } */}
      </div>
  )
  
}
