import { createSlice } from '@reduxjs/toolkit'

const listTemplate =  createSlice({
    name: 'listTemplate',
    initialState:{
        gridColumnsListWithDetails: [],
        gridSortingOptionsDetails: {},
        printedByUserName: ''

    },
    reducers:{
        gridColumnsList(state , action){
            state.gridColumnsListWithDetails = action.payload
            console.log('action' ,action)
            console.log('state' ,state)
        },
        
        gridSortingOptions(state , action){
            state.gridSortingOptionsDetails = action.payload
            console.log('action' ,action)
            console.log('state' ,state)
        },
        getprintedByUserName(state , action){
            state.printedByUserName = action.payload
            console.log('action' ,action)
            console.log('state' ,state)
        }
        
    }
 
})
export const { gridColumnsList , gridSortingOptions, getprintedByUserName} = listTemplate.actions
export default listTemplate.reducer