import React, { useEffect, useState } from 'react';
import CurrencyService from '../../services/currency.service';
import SecurityService from '../security/security.service';
import './currency.scss';
import HardDelete from '../hard-delete/hard-delete';
import CheckDuplicate  from '../check-duplicate/check-duplicate';
import AuditTableInput from '../audit-table/audit-table';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import CurrencyAdd from './currency-add';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
var selectedRows = []

export default function CurrencyList(props) {
  const [currency, setCurrency] = useState([]);
  const [deletingProps, setDeletingProps] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogs, setShowDialogs] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  let currencyGrid: Grid;

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      currencyGrid &&
      args.item.properties.id === 'CurrencyGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Currency_information.pdf`
      };
      console.log('clicked on pdf export');
      currencyGrid.pdfExport(exportProperties);
    }
    if (
      currencyGrid &&
      args.item.properties.id === 'CurrencyGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_Currency_information.xlsx`
      };
      currencyGrid.excelExport(exportProperties);
    }
    if (
      currencyGrid &&
      args.item.properties.id === 'CurrencyGrid_columnchooser'
    ) {
    }
    if (currencyGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (currencyGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', currencyGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`Currency`,
          ColumnName:`CurrencyID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        console.log('delete: ', currencyGrid.getSelectedRecords()[0]?.CurrencyID)
        // deleteDialog.current.hide();
        let obj={
          TableName:`Currency`,
          ColumnName:`CurrencyID`,
          RowID:currencyGrid.getSelectedRecords()[0]?.CurrencyID,
          SelectedRow:true,
          SelectedRowData:currencyGrid.getSelectedRecords()[0]
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
  };

  
  useEffect(() => {
    getCurrency();
  }, []);

  const getCurrency = async (type: string) => {
    CurrencyService.getCurrencyForList()
      .then((response) => {
        console.log('Currency get response', response.data.recordset);
        setCurrency(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled:!SecurityService.canUserAccess('CurrencyDelete')    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ? (
      <CurrencyAdd dataList={currency} {...props}> </CurrencyAdd>
    ) :
      (
        <CurrencyAdd dataList={currency} {...props}> </CurrencyAdd>
      );
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess('CurrencyEdit'),
    allowAdding: SecurityService.canUserAccess('CurrencyAdd'),
    allowDeleting: SecurityService.canUserAccess('CurrencyDelete'),
    mode: 'Dialog',
    showDeleteConfirmDialog: true,
    template: dialogTemplate,
    
    // headerTemplate: '<p></p>',
    footerTemplate:''
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  //   columns: [
  //     {
  //       field: 'IsDeleted',
  //       operator: 'notequal',
  //       value: 'True'
  //     }
  //   ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'SerialNo', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };

  const userId = SecurityService.getLoggedInUserId();

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      currencyGrid?.autoFitColumns();
    }
    if (args.form) {
      console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {

      }
      if (args.requestType === 'save'  && args.action   === 'add') {
        const data = {
          UserID: userId,
          ...args.data,
        };
        console.log('args: ', data)
        delete currency[0]

      
      CurrencyService.addCurrency(data)
      .then((response) => {
        AuditTableInput.auditAdd(`Currency`,`Currency`,`CurrencyID`,data)

        setCurrency(response?.data?.recordset)});
  
    }
       
      if (args.requestType === 'save'  && args.action   === 'edit') {
        const data = {
          UserID: userId,
          ...args.data,
        };
        console.log('args: ', data)
        CurrencyService.updateCurrency(args.data.CurrencyID,data)
          .then((response) => {
            let totalColumns = Object.keys(args.data);
            console.log(totalColumns)
            AuditTableInput.auditEdit(totalColumns,selectedRows,args.data,`Currency`,args.data.CurrencyID)
            getCurrency();
            console.log(response)});
      }

    }

  };
  const databound = () =>
  {
      if (currencyGrid) {
        currencyGrid.autoFitColumns();
      }
  }
  const CurrencyGridComponent = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (currencyGrid = grid)}
      id='CurrencyGrid'
      dataSource={currency}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      groupSettings={groupOptions}
      actionComplete={actionComplete}
      allowTextWrap='true'
      allowPaging={true}
      allowSorting={true}
      allowPdfExport={true}
      allowExcelExport={true}
      toolbarClick={toolbarClick}
      allowFiltering={true}
      allowGrouping={true}
      showColumnChooser={true}
      allowReordering={true}
      allowResizing={true}
      sortSettings={sortingOptions}
      dataBound={databound}
      rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
          
                    setTimeout(() => {
            console.log('args.data', args.data)
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        {/* <ColumnDirective
          headerText='ID'
          field='CurrencyID'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        /> */}
        <ColumnDirective
          headerText='Serial No'
          field='SerialNo'
          width='150'
          headerTextAlign='CENTER'
          textAlign='right'
        />
        <ColumnDirective
          headerText='Currency Name'
          headerTextAlign='CENTER'    
          field='CurrencyName'
          width='150'
          textAlign='Left'
        />
         <ColumnDirective
          headerText='Currency Symbol'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='CurrencySymbol'
          width='100'
        /> 


        {/* <ColumnDirective
          headerText='Is Deleted'
          textAlign='Left'
          headerTextAlign='CENTER'
          field='IsDeleted'
          width='150'
        /> */}
        
      </ColumnsDirective>

      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          PdfExport,
          ExcelExport,
          Filter,
          Group,
          Aggregate,
          Freeze,
          ColumnChooser,
          Reorder,
          Resize,
          // CommandColumn,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div>
                 {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCurrency()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <CurrencyGridComponent restrictPageSize={5} />
      </div>
    )
      :
      (
        <div className="content-wrapper">
          <div>
            {isDuplicate ? <CheckDuplicate.CheckDuplicateUI />: null}
            <div>
              <h3>Currency - List</h3>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );
                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        getCurrency()
                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
              <div>
              </div>
              <CurrencyGridComponent />
            </div>
          </div>
        </div>
      )
  );
}
