import http from './http-common';

class CommissionDetailsService {
  getList(monthYearPIDate, customerid, salesAgentID, PIID, supplierID, purchaseSourceID, commissionStatus) {
    return http.get(`/commissionDetails/list/${monthYearPIDate}/${customerid}/${salesAgentID}/${PIID}/${supplierID}/${purchaseSourceID}/${commissionStatus}`);
  }
  getCommissionStatusForDD() {
    return http.get(`/commissionDetails/commissionStatus/dropdown`);
  }

  getPIIDForDD() {
    return http.get(`/commissionDetails/PIID/dropdown`);
  }
//   updateApproveAbsoluteCommissionAmount(data) {
//     return http.put(`/CommissionCalculator/ApproveAbsoluteCommissionAmount`,data);
//   }
//   updateApproveCommissionAmountPerUnit(data) {
//     return http.put(`/CommissionCalculator/ApproveCommissionAmountPerUnit`,data);
//   }
//   updateFinalCommissionAmountPerUnitApproved(data) {
//     return http.put(`/CommissionCalculator/FinalCommissionAmountPerUnitApproved`,data);
//   }
//   updateRemarks(data) {
//     return http.put(`/CommissionCalculator/Remarks`,data);
//   }

// //   ApproveAbsoluteCommissionAmount
// // FinalCommissionAmountPerUnitApproved
// // ApproveCommissionAmountPerUnit
//   // customerDropDown() {
//   //   return http.get(`//customer/dropdown`);
//   // }
//   // getBalanceUnAllocatedAmount(date, currencyID, customerID) {
//   //   return http.get(`/TransferUnAllocatedPayment/balanceUnAllocatedAmount/'${date}'/${currencyID}/${customerID}`);
//   // }
//   // addTransferUnAllocatedPayment(data) {
//   //   return http.post(`/TransferUnAllocatedPayment/add`,data);
//   // }
  }
  export default new CommissionDetailsService();
