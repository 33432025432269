import http from './http-common';

class PIService {
  getList() {
    return http.get(`/PI/list`);
  }
  getSupplierForDD() {
    return http.get(`/PI/supplier/dropdown`);
  }
  getCustomerForDD() {
    return http.get(`/PI/customer/dropdown`);
  }
  getSaleAgentForDD() {
    return http.get(`/PI/employee/dropdown`);
  }
  getCountryCityPortForDD() {
    return http.get(`/PI/countryCityPort/dropdown`);
  }
  getShipmentTypeForDD() {
    return http.get(`/PI/shipmentType/dropdown`);
  }
  getCurrencyForDD() {
    return http.get(`/PI/currency/dropdown`);
  }
  getAllEmployee() {
    return http.get(`/PI/employee`);
  }
  getPurchaseSourceForDD() {
    return http.get(`/PI/purchaseSource/dropdown`);
  }
  getTradeBoardLetterStatusForDD() {
    return http.get(`/PI/tradeBoardLetterStatus/dropdown`);
  }
  getPaymentTypeForDD() {
    return http.get(`/PI/paymentType/dropdown`);
  }
  getSalesAgentStatusForDD() {
    return http.get(`/PI/salesAgentStatus/dropdown`);
  }
  getDesignationForDD() {
    return http.get(`/PI/designation/dropdown`);
  }
  getCRMStatusForDD() {
    return http.get(`/PI/CRMStatus/dropdown`);
  }
  addProformaInvoice(data) {
    return http.post(`/PI/insert/proformaInvoice` , data);
  }
  addProformaInvoiceGrid(data) {
    return http.post(`/PI/insert/proformaInvoiceGrid` , data);
  }

  create(data) {
    console.log('create data: ', data);
    return http.post(`/chartOfAccount/add/`, data);
  }
  update(data) {
    console.log('create data: ', data);
    return http.put(`/chartOfAccount/update`, data);
  }
  getPaymentDetail(id) {
    console.log('create id:', id);
    return http.get(`/PI/getPaymentDetail/${id}`);
  }
  getEmployeeName(id) {
    console.log('CUSTOMER ID: ', id);
    return http.get(`/PI/getEmployeeName/${id}`);
  }
  getCommissionAmountPerUnit(currencyID,supplierID,employeeID) {
    // console.log('CUSTOMER ID: ', id);
    return http.get(`/PI/getCommissionAmountPerUnit/${currencyID}/${supplierID}/${employeeID}`);
  }
  getSecondaryCommissionPercentOnAdjustment(currencyID,supplierID,employeeID) {
    // console.log('CUSTOMER ID: ', id);
    return http.get(`/PI/getSecondaryCommissionPercentOnAdjustment/${currencyID}/${supplierID}/${employeeID}`);
  }
  
  getPrimaryCommissionPercentOnAdjustment(supplierID,employeeID) {
    // console.log('CUSTOMER ID: ', id);
    return http.get(`/PI/getPrimaryCommissionPercentOnAdjustment/${supplierID}/${employeeID}`);
  }
updatePI(id, data) {
    // console.log('CUSTOMER ID: ', id);
    return http.put(`/PI/editPaymentDetail/${id}`,data);
  }
PIEdit( data) {
    // console.log('CUSTOMER ID: ', id);
    return http.put(`/PI/editPI`,data);
  }
  getInitialPaymentPercent(customerID , currencyID) {
    console.log('CUSTOMER ID: ', customerID);
    console.log('CURRENCY ID: ', currencyID);
    return http.get(`/PI/InitialPaymentPercent/${customerID}/${currencyID}`);
  }
  updatingSp(id , value) {
    
    return http.get(`/PI/update/sp/${id}/${value}`);
  }
  getToEmails() {
    return http.get(`/PI/toEmails/list`);
  }
  deletePi(piid) {
    console.log(piid)
    return http.delete(`/PI/delete/pi/${piid}`);
  }
  getPIBalance(piid) {
    console.log(piid)
    return http.get(`/PI/PIBalance/${piid}`);
  }
  uploadPIPdf(data) {
    console.log(data)
    return http.post(`/PI/Upload/Pdf`, data);
  }
  
  getPIPDFFiles(id) {
    console.log(id)
    return http.get(`/PI/PIPDFFiles/${id}`);
  }
  deleteAllPdfByPIID(id,year) {
    console.log(year)
    return http.put(`/PI/deleteAllPdfByPIID/${id}/${year}`);
  }
  deletePDFFilebyPIPDFID(data) {
    console.log(data)
    return http.put(`/PI/deletePDFFilebyPIPDFID`,data);
  }
  
}
  export default new PIService();

  