import http from './http-common';

class MessageService {
  // emailData(emailData) {
  //   return http.post(`/EmailEditor/`,emailData ,{
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       }},
  //   )
  // }
  getMessageList(idOne , employeeID) {

    console.log(`/Message/list/byEmployee/${idOne}/${employeeID}`)
    return http.get(`/Message/list/byEmployee/${idOne}/${employeeID}`);
  }
  getMessageDashboardList(id , status ,  employeeID) {
    return http.get(`/Message/dashboard/list/${id}/${status}/${employeeID}`);
  }
  getPIDropdownList() {
    return http.get(`/Message/pi/dropdown/list`);
  }
  getEmployeeByUserID(id) {
    return http.get(`/Message/getEmployeeByUserID/${id}`);
  }
  insert(data) {
    return http.post(`/Message/insert`,data);
  }
  messageReadUpdate(data){
    return http.post('/Message/message/read' , data)
  }
  // getEmailFiles(id) {
  // return http.get(`/EmailEditor/files/${id}`);
  // }  


}
  export default new MessageService();
