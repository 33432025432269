import { Data } from "@syncfusion/ej2-react-grids";
import http from "./http-common";

class CustomerService {
  
  getCustomerForList(id) {
    return http.get(`/customer/list/customer/${id}`);
  }
  
  getAllCustomerDetail(id) {
    return http.get(`/customer/detail/list/${id}`);
  }

  addCustomer(data) {
    return http.post('/customer/add', data);
  }
  updateCustomerDetail(data) {
    return http.put('/customer/update/detail', data);
  }

  delete(CustomerID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/customer/${CustomerID}`)
  }
  CustomerIsDeletedService(id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/customer/changeIsDeleted/${id}`)
  }
  CustomerIsDeletedService(data,id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/customer/changeIsDeleted/${id}`,data)
  }

  updateCustomer(id,data) {
    return http.put(`/customer/${id}`,data);
  }
  deleteCustomer(id) {
    return http.delete(`/customer/delete/${id}`);
  }
  getCustomerNameForConsigneeName(id) {
    return http.get(`/customer/consigneeName/${id}`);
  }
  getCountryCityPortUsingCustomerID(id) {
    return http.get(`/customer/CountryCityPort/${id}`);
  }
  getCurrencyUsingCustomerID(id) {
    return http.get(`/customer/Currency/${id}`);
  }
  insertCustomer(data) {
    return http.post(`/customer/insert/`,data);
  }
  CustomerPaymentDetail(data) {
    return http.post(`/customer/insert/CustomerPaymentDetail`,data);
  }

}

export default new CustomerService();
