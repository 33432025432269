import React, { useEffect, useState, useRef, useReducer } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import './Transfer-unAllocated-payment.scss';

import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import { DatePicker } from 'formik-material-ui-pickers';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import PIService from '../../services/PI.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { CheckBox } from '@syncfusion/ej2-react-buttons';
import TransferUnAllocatedPaymentService from '../../services/transfer-unAllocated-payment.service';


import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { format } from 'date-fns';

const complexReducer = (state, action) => {
    console.log(state)
    console.log(action)
  switch (action.type) {
       case 'ToCustomerName':
        return {
          ...state,
         
          ToCustomerName:action.ToCustomerName,
          ToCustomerID:action.ToCustomerID,
        };
       break;
       case 'BalanceUnAllocatedAmount':
        return {
          ...state,
         
          BalanceUnAllocatedAmount:action.BalanceUnAllocatedAmount,
      
        };
       break;
       case 'FromCustomerName':
        return {
          ...state,
         
          FromCustomerName:action.FromCustomerName,
          FromCustomerID:action.FromCustomerID,
        };
       break;
    case 'CustomerDataSource':
      return {
        ...state,
       
        LoadingCustomer:action.LoadingCustomer,
        CustomerDataSource: action.CustomerDataSource,
       };
       break;
    case 'CurrencyNameDataSource':
      return {
        ...state,
       
        LoadingCurrencyName:action.LoadingCurrencyName,
        CurrencyNameDataSource: action.CurrencyNameDataSource,
       };
       break;
    case 'CurrencyName':
        return {
          ...state,
          CurrencyName:action.CurrencyName,
          CurrencyID:action.CurrencyID
         };
         break;
       
       case 'Remarks':
        return {
          ...state,
          Remarks:action.Remarks,
         
         };
       case 'TransferDate':
        return {
          ...state,
          TransferDate:action.TransferDate,
         
         };
         
             default:
               return state;
    }
  };
  export default function PIAdd(props) {
    
    const [state, dispatch] = useReducer(complexReducer, { 
      TransferDate:new Date(moment()),
      LoadingCurrencyName:true,
      CurrencyNameDataSource: [],
      CurrencyName:'',
      CurrencyID:'',
      CurrencyNameRef: useRef(null),
      CustomerDataSource: [],
      ToLoadingCustomer:true,
      ToCustomerID:'',
      ToCustomerName:'',
      ToCustomerRef: useRef(null),
      FromLoadingCustomer:true,
      FromCustomerID:'',
      FromCustomerName:'',
      FromCustomerRef: useRef(null),
      BalanceUnAllocatedAmount:'',
      TransferAmount: 0,
      Remarks : '-'
    });
console.log(state)
  
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
 
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');

useEffect(() => {
  console.log('update CC')
  getBalanceUnAllocatedAmount()

}, [state.TransferDate , state.CurrencyID]);

  const textBoxChange = (e) => {
    console.log(e)
    dispatch({ type: e.target.name , [e.target.name]: e?.value })
    console.log(e?.value > state.BalanceUnAllocatedAmount)
  if(e.target.name == 'TransferAmount' && e?.value > state.BalanceUnAllocatedAmount){
    
    document.querySelector('#TransferUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
  }else {
    document.querySelector('#TransferUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';

  }
   
};

  const AutoCompleteChange = (e,name) => {
    console.log(e,name)
    switch (name) {
      case 'FromCustomerName':
        dispatch({ type: 'FromCustomerName' ,FromCustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'FromCustomerName' , 
            FromCustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            FromCustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                 })
                setTimeout(() => {
                  
                  getBalanceUnAllocatedAmount()
                }, 200);
                }
                  break;
      case 'ToCustomerName':
        dispatch({ type: 'ToCustomerName' ,ToCustomerName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'ToCustomerName' , 
            ToCustomerName: e?.itemData?.value ? e?.itemData?.value : '',
            ToCustomerID: e?.itemData?.id ? e?.itemData?.id : '',
                 })
              
                }
                  break;
      
      case 'CurrencyName':
        dispatch({ type: 'CurrencyName' ,CurrencyName: e?.itemData?.value ? e?.itemData?.value : ''})
        if(e?.e?.srcElement?.id)  {
          console.log('uom change: ', );
          dispatch({ 
            type: 'CurrencyName' , 
            CurrencyName: e?.itemData?.value ? e?.itemData?.value : '',
            CurrencyID: e?.itemData?.id ? e?.itemData?.id : ''
                  })}
                    break;
      
      
            default:
        return state;
    }
   
   
};
const userId = SecurityService.getLoggedInUserId();
  // useEffect(() => {
  // setTimeout(() => {
    
  
  //   if(state.PINo  != '' && state.PIReferenceNo != ''  && checkDuplicate == true){
  //     console.log(props.dataList)
  //     console.log()
  //     var check = false
  //     if(props.isAdd ==  true){

  //        check = CheckDuplicate.withOutCombination(props.dataList,['PINo','PIReferenceNo'],{
  //         PINo:state.PINo,
  //         PIReferenceNo:state.PIReferenceNo,
          
  //          })
  //     }
     
  //     // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
  //     console.log(check)
  //     // setCheckDuplicate(check)
  //     if(!check ){
  //       setCheckDuplicateValue('')
  //       if(state.PaymentDetailDataSource.length > 0){document.querySelector('#TransferUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';}
  //     }else{

  //       setCheckDuplicateValue('Duplicate Value Detected!')
  //       document.querySelector('#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        
  //   }
  //  }
  // }, 500);
  // }, [state ,state ]);
useEffect(() => {
    
  
  
    getCustomer()
     getCurrency()
    
  }, []);

  
  const getBalanceUnAllocatedAmount = async () => {
    console.log( 'state.ToCustomerID,     state.CurrencyID,     state.TransferDate ' )
    console.log(state.TransferDate)
    TransferUnAllocatedPaymentService.getBalanceUnAllocatedAmount(moment(state.TransferDate ).format('DD-MMM-YY'),state.CurrencyID, state.FromCustomerID)
      .then((response) => {
        console.log('getBalanceUnAllocatedAmount', response.data.recordset);
        dispatch({ type: `BalanceUnAllocatedAmount` ,  BalanceUnAllocatedAmount:response.data.recordset[0].BalanceAmount.toLocaleString()})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `BalanceUnAllocatedAmount`  , BalanceUnAllocatedAmount:''})
      });
  };
  
  const getCustomer = async (type: string) => {
    
    // TransferUnAllocatedPaymentService.customerDropDown()
    PIService.getCustomerForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CustomerDataSource` ,  CustomerDataSource:response.data.recordset,LoadingCustomer:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CustomerDataSource`  , CustomerDataSource:[],LoadingCustomer:false})
      });
  };
  const getCurrency = async (type: string) => {
    PIService.getCurrencyForDD()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        dispatch({ type: `CurrencyNameDataSource` ,  CurrencyNameDataSource:response.data.recordset,LoadingCurrencyName:false})

      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: `CurrencyNameDataSource`  , CurrencyNameDataSource:[],LoadingCurrencyName:false})
      });
  };
  
  

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      // case 'ProductCategory':
      //   // getProductCategory();
      // break;
      // case 'ProductDesc':
      //   // getProductDesc();
      // break;
      // case 'UnitOfMeasurement':
      //   // getUOMs();
      // break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }


let productDescRef = useRef(null)
      let uomRef = useRef(null)
      
  const minDate: Date = new Date(moment().startOf('day'));

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: false,
    mode:'Normal'
    // allowEditing: SecurityService.canUserAccess('ProductEdit'),
    // allowAdding: SecurityService.canUserAccess('ProductAdd'),
    // allowDeleting: SecurityService.canUserAccess('ProductDelete'),
  }
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    //  'Edit',
   
    "Update",
    "Cancel",
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
  ];

  
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
 
  
  return (
    <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Transfer UnAllocated Payment - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>

        
        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
            <br/>
                <div className="col-sm-4">
                    <mat-form-field>
                    <DatePickerComponent
                          id='TransferDate'
                          placeholder='Transfer date'
                          floatLabelType='Auto'
                          min={minDate}
                          name='TransferDate'
                          format='dd-MM-yy'
                          value={state.TransferDate}
                          onChange={textBoxChange}
                          // value={moment().add(0, 'd').format('DD-MM-YY')}
                        />
                    </mat-form-field>
                  </div>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.LoadingCurrencyName ? (
                        <div>... Loading Currency Name </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="CurrencyName" 
                            dataSource={state.CurrencyNameDataSource}
                            placeholder="Select Currency Name" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="CurrencyName"
                            select={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            change={(e)=>AutoCompleteChange(e,'CurrencyName')}
                            value={state.CurrencyName}
                            ref={state.CurrencyNameRef}
                            blur={()=>{
                              console.log('dfdfblur',state.CurrencyID)
                              console.log('dfdfblur',state.CurrencyName)
                              if (state.CurrencyID  == 0  || state.CurrencyNameRef.current.value == null || state.CurrencyNameRef.current.value == '' || state.CurrencyID == undefined || state.CurrencyID == null) {
                               if(state.CurrencyName != ''){
                                 let resstr = state.CurrencyNameDataSource.some(e=>e.value ==  state.CurrencyName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.CurrencyName.CurrencyName} Value Not Find ` )
                                   document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("CurrencyName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.CurrencyNameRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='CurrencyID'
                            floatLabelType='Auto'
                            value={state.CurrencyID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  <br/>
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.fromLoadingCustomer ? (
                        <div>... From Loading Customer </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="FromCustomerName" 
                            dataSource={state.CustomerDataSource}
                            placeholder="Select From Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="FromCustomerName"
                            select={(e)=>AutoCompleteChange(e,'FromCustomerName')}
                            change={(e)=>AutoCompleteChange(e,'FromCustomerName')}
                            value={state.FromCustomerName}
                            ref={state.FromCustomerRef}
                            blur={()=>{
                              console.log('dfdfblur',state.FromCustomerID)
                              console.log('dfdfblur',state.FromCustomerName)
                              if (state.FromCustomerID  == 0  || state.FromCustomerRef.current.value == null || state.FromCustomerRef.current.value == '' || state.FromCustomerID == undefined || state.FromCustomerID == null) {
                               if(state.FromCustomerName != ''){
                                 let resstr = state.CustomerDataSource.some(e=>e.value ==  state.FromCustomerName);
                                 console.log(resstr)
                                 if(resstr === false){
                                  //  setProductGroupErorr(`${state.Customer.FromCustomerName} Value Not Find ` )
                                   document.getElementById("FromCustomerName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("FromCustomerName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.FromCustomerRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='FromCustomerID'
                            floatLabelType='Auto'
                            value={state.FromCustomerID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  
                 
                
                  <br/>
                  
                  <div className="col-sm-4">
                    <mat-form-field>
                      {state.toLoadingCustomer ? (
                        <div>... To Loading Customer </div>
                      ) : (
                        <div>
                         <AutoCompleteComponent
                            id="ToCustomerName" 
                            dataSource={state.CustomerDataSource}
                            placeholder="Select To Customer" 
                            fields={ddlFields}
                            floatLabelType='Auto' 
                            highlight={true}
                            name="ToCustomerName"
                            select={(e)=>AutoCompleteChange(e,'ToCustomerName')}
                            change={(e)=>AutoCompleteChange(e,'ToCustomerName')}
                            value={state.ToCustomerName}
                            ref={state.ToCustomerRef}
                            blur={()=>{
                              console.log('dfdfblur',state.ToCustomerID)
                              console.log('dfdfblur',state.ToCustomerName)
                              if (state.ToCustomerID  == 0  || state.ToCustomerRef.current.value == null || state.ToCustomerRef.current.value == '' || state.ToCustomerID == undefined || state.ToCustomerID == null) {
                               if(state.ToCustomerName != ''){
                                 let resstr = state.CustomerDataSource.some(e=>e.value ==  state.ToCustomerName);
                                 console.log(resstr)
                                 if(resstr == false){
                                  //  setProductGroupErorr(`${state.Customer.ToCustomerName} Value Not Find ` )
                                   document.getElementById("ToCustomerName").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                   setTimeout(() => {
                                    //  setProductItemTypeError('')
                                     document.getElementById("ToCustomerName").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
   
                                   }, 1000);
                                 }
                               }
                               
                               if (addNewClicked == false){
                                state.ToCustomerRef.current.element.focus()
                   
                               }
                             }}
                          
                          }
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            placeholder='UOM Id'
                            name='ToCustomerID'
                            floatLabelType='Auto'
                            value={state.ToCustomerID}
                          />
                          <span className='add-new' onClack={()=>onAddNewClick('UnitOfMeasurement')}>
                          {AddNewSecurity('UnitOfMeasurementss')}
                              </span>
                        </div>
                      )}
                    </mat-form-field>
                    
                  </div>
                  
                  <div className="col-sm-4">
                    <mat-form-field>
                      <NumericTextBoxComponent
                        className='e-input'
                        placeholder='Transfer Amount'
                        id='TransferAmount'
                        name='TransferAmount'
                        floatLabelType='Auto'
                        format={'N0'}
                        showSpinButton={false}
                        min={0}
                        value={state.TransferAmount}
                        onChange={textBoxChange}
                        
                      />
                    </mat-form-field>
                  </div>
                  {state.BalanceUnAllocatedAmount ?(
                  <div className="col-sm-4">
                    <mat-form-field>
                     <h2 className='account-amount'>   ACCOUNT AMOUNT : {state.BalanceUnAllocatedAmount}</h2>
                      {/* <TextBoxComponent
                        className='e-input'
                        type='text'
                        placeholder='BALANCE AMOUNT'
                        name='BalanceUnAllocatedAmount'
                        floatLabelType='Auto'
                        value={state.BalanceUnAllocatedAmount}
                        disabled={true}
                        /> */}
                    </mat-form-field>
                  </div> ):null }
                  <div className="col-sm-12">
                    <mat-form-field>
                      <TextBoxComponent 
                        className='e-input'
                        type='text'
                        placeholder='Remarks'
                        id='Remarks'
                        name='Remarks'
                        floatLabelType='Auto'
                        value={state.Remarks}
                        onChange={textBoxChange}
                        
                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  <br/>
                  </div>

                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
      </div>
      {/* if(checkDuplicateValue == ''){
                              setTimeout(()=>document.querySelector('#ProductFinishSizeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').focus(),600);

                            } */}
      </div>
  )
  
}
