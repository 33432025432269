import http from './http-common';

class YarnContractService {
  getAll(type) {
    console.log('type: ', type);
    return http.get(`/yarncontract/${type}`);
  }

  get(type, id) {
    return http.get(`/yarncontract/${type}/${id}`);
  }

  getDetails(type, id) {
    return http.get(`/yarncontract/details/${type}/${id}`);
  }

  getForList() {
    return http.get(`/yarnContract/yarnContractForList`);
  }

  getInfoForList(type) {
    return http.get(`/yarnContract/yarnContractInfoForList/${type}`);
  }

  getForListByType(type) {
    return http.get(`/yarnContract/yarnContractForList/${type}`);
  }

  getForIssue() {
    return http.get(`/yarnContract/yarnContractForIssue`);
  }

  create(type, data) {
    return http.post(`/yarncontract/${type}`, data);
  }

  update(type, data) {
    return http.put(`/yarncontract/${type}/update`, data);
  }

  delete(type, id) {
    return http.delete(`/yarncontract/${type}/${id}`);
  }

  getDeliverySchedule(id) {
    return http.get(`/yarncontractdeliveryschedule/${id}`);
  }

  updateValues(id, data){
    return http.put(`/yarnContract/edit/${id}`, data)
  }
}

export default new YarnContractService();
