import React, { useEffect, useState, useRef } from 'react';
import ProductService from '../../services/product.service';
import SetupService from '../../services/setup.service';
import PIService from '../../services/PI.service';
// import './product-finish-size.scss';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SecurityService from '../security/security.service';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import Select from 'react-select';
import moment from 'moment';

export default function MessageAdd(props) {
  // var  = []
  // var toEmailsValue = []
  console.log(props)
  const [messageDate, setMessageDate] = useState(new Date());
  const [from, setFrom] = useState(props.From);
  const [fromID, setFromID] = useState(props.FromID);
  const [toEmails, setToEmails] = useState([]);
  const [tomenuIsOpen, setToMenuIsOpen] = useState(false);
  const [messageComment, setMessageComment] = useState('');
  const [toEmailsValue, setToEmailsValue] = useState('');
  const [selectedToEmails, setSelectedToEmails] = useState('');


  const [loadingProductCategory, setLoadingProductCategory] = useState(true);
  const [productCategory, setProductCategory] = useState([]);
  const [productCategoryID, setProductCategoryID] = useState(props.ProductCategoryID);
  const [loadingUOMs, setLoadingUOMs] = useState(true);
  const [uoms, setUOMs] = useState([]);
  const [uomID, setUOMID] = useState(props.UnitOfMeasurementID);
  const [uomValue, setUomValue] = useState(props.UnitOfMeasurement);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [ProductDesc, setProductDesc] = useState('');
  const [loadingProductDesc, setLoadingProductDesc] = useState('');
  const [productDescID, setProductDescID] = useState(props.ProductDescID);
  const [productDescValue, setProductDescValue] = useState(props.ProductDesc);
  const [finishWidth, setFinishWidth] = useState(props?.FinishWidth);
  const [finishLength, setFinishLength] = useState(props?.FinishLength);
  const [finishDrop, setFinishDrop] = useState(props?.FinishDrop);
  const [addNewClicked, setAddNewClicked] = useState(false); 
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  useEffect(() => {
  setTimeout(() => {
    
  
    if(productDescID != undefined && finishWidth != undefined && finishLength != undefined && uomID != undefined && finishDrop != undefined && checkDuplicate == 'dddd'){
      console.log(props.dataList)
      console.log()
      var check = false
      if(props.isAdd ==  true){

         check = CheckDuplicate.withCombination(props.dataList,['FinishDrop','FinishLength','FinishWidth'],{
          FinishDrop:finishDrop,
            FinishLength:finishLength,
            FinishWidth:finishWidth,
           })
      }else{
        let array = props.dataList.filter(obj => obj.ProductFinishSizeID !== props.ProductFinishSizeID)
        check = CheckDuplicate.withCombination(array,['FinishDrop','FinishLength','FinishWidth'],{
          FinishDrop:finishDrop,
            FinishLength:finishLength,
            FinishWidth:finishWidth,
           })
      }
     
      // props.dataList,['FinishDrop','FinishLength','FinishWidth'],)
      console.log(check)
      // setCheckDuplicate(check)
      if(!check){
        document.querySelector('#messageGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{

        document.querySelector('#messageGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
        
    }
   }
  }, 500);
  }, [ ]);

  useEffect(() => {
    setTimeout(() => {
      setCheckDuplicate(true)
      if(props.isAdd == true){
        document.querySelector('#messageGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
  
      }
              document.querySelector('#messageGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';

    }, 500);
  
    // getProductFinishSize();
    // getProductCategory();
    // getUOMs();/
    // getProductDesc()
    getEmployee()
    // toEmailsValue = []
  }, []);
  const getEmployee = async (type: string) => {
    
    PIService.getAllEmployee()
      .then((response) => {
        const employeeNames = response.data.recordset.map(employee =>  { return {value: employee.EmployeeID , label: employee.EmployeeName , color:'#28a745'}});
        console.log(employeeNames)
        setToEmails(employeeNames)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProductFinishSize = async (type: string) => {
    ProductService.getProductFinishSizeForList()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        // setProductFinishSize(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProductCategory = async (type: string) => {
    setLoadingProductCategory(true);
    SetupService.getAll('ProductCategory')
      .then((response) => {
        console.log('ProductCategory get response', response.data.recordset);
        setProductCategory(response.data.recordset);
        setLoadingProductCategory(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUOMs = async (type: string) => {
    setLoadingUOMs(true);
    SetupService.getAll('UnitOfMeasurement')
      .then((response) => {
        console.log('uoms get response', response.data.recordset);
        setUOMs(response.data.recordset);
        setLoadingUOMs(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ddlFields: object = { value: 'value' };

  const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };

  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      case 'ProductCategory':
        getProductCategory();
      break;
      case 'ProductDesc':
        // getProductDesc();
      break;
      case 'UnitOfMeasurement':
        getUOMs();
      break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      return (
        <div>
          <SetupAdd type={dialogType} isDialog={true}
          /> 
          {/* <SetupAdd onChange={value => setWord(value)}*/}
        </div>
      );  
    }
    else {
      return (
        <></>
      );
    }
  };


  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }
// const [uomValue, setUomValue] = useState(props.UnitOfMeasurement);
  const uomChange = (e) => {
    setUomValue(e?.itemData?.value ? e?.itemData?.value : '')
    if(e?.e?.srcElement?.id)  {
      console.log('uom change: ', e?.itemData?.id);
        setUOMID(e?.itemData?.id);
      }
    };

    const productCategoryChange = (e) => {
      if(e?.e?.srcElement?.id)  {
        console.log('uom change: ', e?.itemData?.id);
          setProductCategoryID(e?.itemData?.id);
        }
      };
    const productDescChange = (e) => {
        console.log('dfdf' ,e)
        setProductDescValue(e?.itemData?.value  ? e?.itemData?.value :"")
        setProductDescID(0)
      if(e?.e?.srcElement?.id)  {
        console.log('uom change: ', e?.itemData?.id);
          setProductDescID(e?.itemData?.id);
        }
      };


  let productDescRef = useRef(null)
  let uomRef = useRef(null)

  const handleChange = (newValue, actionMeta ,key ) => {
    console.log(key)
    console.log(newValue)
    console.log(actionMeta)
    let values = [...newValue].map(item => item.value);
    // const result = values.join(' ');
    console.log(values);
    setSelectedToEmails(values);
     setToEmailsValue( [...newValue])
    }
  return (
    <div className='product-Finish-size-add-container' onKeyDown={(e)=>{
      console.log(e)
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}>
      <div>
        <div>
          <div className='heading'>
            <h3>Message - {props.isAdd?`Add`:`Edit`}</h3>
          </div>
        </div>
        <div className='App' id='targetElement'>
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>
        <div>
          <div>
            <mat-card>
              <mat-card-content>
                <div className="row">
                <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='Message Date '
                        name='MessageDate'
                        floatLabelType='Auto'
                        value={ moment(messageDate).format('YYYY-MM-DD')}
                        disabled={true}
                        type='date'
                        format="dd-MM-yy"
                        onChange={(e)=>{
                          console.log(e)
                         
                          setMessageDate(e.value)}}
                      />
                    </mat-form-field>
                  </div>
                <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='From '
                        name='EmployeeName'
                        floatLabelType='Auto'
                        value={from}
                        disabled={true}
                        onChange={(e)=>{
                          console.log(e)
                         
                          setFrom(e.value)}}
                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div className="col-sm-12">
                    <mat-form-field>
                    <Select
                      isDisabled={false}
                      isMulti
                      isClearable
                      isSearchable
                      value={toEmailsValue}
                      options={toEmails}
                      onChange={(e,action)=> handleChange(e,action,'TO')}
                      onMenuOpen={()=>setToMenuIsOpen(true)}
                      onMenuClose={()=>setToMenuIsOpen(false)}
                      menuIsOpen={tomenuIsOpen}
                    />
                       <TextBoxComponent
                        className='e-input'
                        placeholder='Message Date '
                        name='ToEmployeeID'
                        floatLabelType='Auto'
                        value={selectedToEmails}
                        disabled={true}
                        type='hidden'
                        
                       
                      />
                    </mat-form-field>
                  </div>

                 
                  <br/>
                  <br/>
                  <div className="col-sm-12">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        type='text'
                        multiline
                        placeholder='Message  Comment'
                        name='MessageComment'
                        floatLabelType='Auto'
                        value={messageComment}
                        onChange={(e)=>{
                          console.log(e)
                          setMessageComment(e.value)}}
                       
                      />
                    </mat-form-field>
                  </div>
                  <br/>
                  
                </div>
                <br />
              </mat-card-content>
              <mat-card-action>
              </mat-card-action>
            </mat-card>
          </div>
        </div>
        
      </div>
{/* <h3 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h3> */}

    </div>
  )
}
