import http from './http-common';

class ReceiveUnAllocatedPaymentService {
  getList() {
    return http.get(`/ReceiveUnAllocatedPayment/list`);
  }
  ApprovePaymentVerificationStatus(id,UserID){
    return http.get(`/ReceiveUnAllocatedPayment/ApprovePaymentVerificationStatus/${id}/${UserID}`)
  }
  UpdateRemarks(value,mainid,crm){
    let data ={
      Value:value,
      MainID:mainid,
      CRM:crm
    }
    return http.post(`/ReceiveUnAllocatedPayment/updateRemarks`,data)
  }

  addReceiveUnAllocatedPayment(data) {
    return http.post(`/ReceiveUnAllocatedPayment/add`,data);
  }
  
  customerDropDown() {
    return http.get(`/ReceiveUnAllocatedPayment/customer/dropdown`);
  }
  getBalanceUnAllocatedAmount(date, currencyID, customerID) {
    return http.get(`/ReceiveUnAllocatedPayment/amount/balanceUnAllocatedAmount/${date}/${currencyID}/${customerID}`);
  }
  ApproveReceiveUnAllocatedPayment(data) {
    return http.put(`/ReceiveUnAllocatedPayment/ApproveReceiveUnAllocatedPayment` , data);
  }
  getEmployeeByUserID(id){
    return http.get(`/ReceiveUnAllocatedPayment/EmployeeByUserID/${id}`);

  }
  }
  export default new ReceiveUnAllocatedPaymentService();
