import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextBoxComponent ,NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { MultiSelectComponent  } from '@syncfusion/ej2-react-dropdowns';
import PIService from '../../services/PI.service';
import EmailEditorService from '../../services/email-editor.service';
import CreatableSelect from 'react-select/creatable';
import { saveAs } from 'file-saver';
// var selectedToEmails = []
//   var selectedCcEmails = []
//   var selectedBccEmails =[]
// var ToEmailsValue = []
//   var CcEmailsValue = []
//   var BccEmailsValue =[]
const EmailEditorPopup = (props) => {
  console.log(props)
  const editor = useRef(null);
  const [content, setContent] = useState(props.BodyText ? props.BodyText :"");
  const [isSendEmail , setIsSendEmail] = useState(false);
  const [smallFiles, setSmallFiles] = useState([]); // Store data of small files
  const [largeFiles, setLargeFiles] = useState([]); // Store data of large files
  const [CcBcc, setCcBcc] = useState([]); // Store data of large files
  const [toEmails, setToEmails] = useState([]); // Store data of large files
  const [isCc, setIsCc] = useState(false);
  const [isBcc, setIsBcc] = useState(false);
  // const [selectedFromEmails, setSelectedFromEmails] = useState(props.isPreview ? props.FromEmailAddress : props.loggedInUser);
  const [selectedFromEmails, setSelectedFromEmails] = useState(`support@myprosource.app`);
  const [subject, setSubject] = useState(props.isPreview == true ? props.EmailSubject : props.PINo);
  const [tomenuIsOpen, setToMenuIsOpen] = useState(false);
  const [ccmenuIsOpen, setCCMenuIsOpen] = useState(false);
  const [bccmenuIsOpen, setBCCMenuIsOpen] = useState(false);
  const [selectedToEmails, setSelectedToEmails] = useState();
  const [selectedCcEmails, setSelectedCcEmails] = useState([]);
  const [selectedBccEmails, setSelectedBccEmails] = useState([]);
  const [ToEmailsValue, setToEmailsValue] = useState([]);
  const [CcEmailsValue, setCcEmailsValue] = useState([]);
  const [BccEmailsValue, setBccEmailsValue] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  

  useEffect(() => {
    console.log(subject)
    console.log(ToEmailsValue)
 

      if(subject){
        setIsSendEmail(true)
      }else{
        setIsSendEmail(false)
    }
  }, [subject ]);
  useEffect(() => {
    console.log(subject)
    console.log(ToEmailsValue)
 

      if(ToEmailsValue.length > 0){
        setIsSendEmail(true)
      }else{
        setIsSendEmail(false)
    }
  }, [ToEmailsValue ]);
  useEffect(() => {
    setSelectedToEmails(props.isPreview ? props.ToEmailAddress : [])
    // selectedBccEmails = []
    // selectedCcEmails = []
    setIsCc(props.isPreview)
    setToEmails([])
    
    if(props.isPreview == true){
      setIsBcc(props.FromEmailAddress == props.loggedInUser)
    setTimeout(() =>  previewFunc(props.isPreview,props.ToEmailAddress,'to' ),100);
    setTimeout(() =>  previewFunc(props.isPreview,props.CCEmailAddress,'cc'), 100);
    setTimeout(() =>  previewFunc(props.isPreview,props.BCCEmailAddress,'bcc'), 100);
    getFiles()
    }else{
      getEmployeeEmail()
    }
    // BCCEmailAddress,
// BodyFormat,
// BodyText,
// CCEmailAddress,
// EmailDate,
// EmailSubject,
// FromEmailAddress,
// HasAttachments,
// PIEmailID,
// ToEmailAddress,
// isPreview,
// loggedInUser,
  }, []);

  const getFiles = () =>{
    try {
      if(props.PIEmailID != undefined){
      console.log(props.PIEmailID)
      EmailEditorService.getEmailFiles(props.PIEmailID).then( (response)=>{         
        console.log(response);       
        setSmallFiles(response.data)
        console.log('files get successful');       

    })
  }
    } catch (error) {
      console.error('Error:', error);
      console.error('files not find');
    }
  }
  const previewFunc = (isPreview,values,key) =>{
    if(isPreview){
      const inputString = values? values :'';

      const dataArray = [];
      let currentEmail = '';
      for (let i = 0; i < inputString.length; i++) {
        const char = inputString[i];
        if (char === ',') {
          dataArray.push({
            value: currentEmail,
            label: currentEmail,
            color: '#28a745'
          });
          currentEmail = '';
        } else {
          currentEmail += char;
        }
      }
      
      // Handle the last email address
      if (currentEmail) {
        dataArray.push({
          value: currentEmail,
          label: currentEmail,
          color: '#28a745'
        });
      }
      if(key == 'to'){

        setToEmailsValue(dataArray)
      }else if ( key == 'cc'){
        setCcEmailsValue(dataArray)
      }else if(key == 'bcc'){
        setBccEmailsValue(dataArray)

      }
      console.log(dataArray);
      
    }
  }
  const getEmployeeEmail = async () => {
    // PIService.getAllEmployee()
    //   .then((response) => {
    //     console.log('productFinishSize get response', response.data.recordset);
    //     const filteredEmployees =  response.data.recordset.filter(employee => employee.DesignationID == 2);
    //     const employeeNames = filteredEmployees.map(employee => { return {value: employee.EmployeeEmail , label: employee.EmployeeEmail , color:'#28a745'}});
    //     console.log(employeeNames)
    //     setToEmails(employeeNames)
    //     const employeeNamesForCcBcc = response.data.recordset.map(employee =>  { return {value: employee.EmployeeEmail , label: employee.EmployeeEmail , color:'#28a745'}});
    //     setCcBcc(employeeNamesForCcBcc)
    //     // { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    //           })
    //   .catch((e) => {
    //     console.log(e);
    //     setToEmails([])
    //   });
    PIService.getToEmails()
      .then((response) => {
        console.log('productFinishSize get response', response.data.recordset);
        // const filteredEmployees =  response.data.recordset.filter(employee => employee.DesignationID == 2);
        const employeeNames = response.data.recordset.map(employee => { return {value: employee.Email , label: employee.Email , color:'#28a745'}});
        console.log(employeeNames)
        setToEmails(employeeNames)
        const employeeNamesForCcBcc = response.data.recordset.map(employee =>  { return {value: employee.Email , label: employee.Email , color:'#28a745'}});
        setCcBcc(employeeNamesForCcBcc)
        // { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
              })
      .catch((e) => {
        console.log(e);
        setToEmails([])
      });
  };
 const config = {
    readonly: props.isPreview ,
    toolbar:!props.isPreview,
    textAlign:'left',
    style:false,
  
    placeholder: 'Start typing...',
    uploader: {
      insertImageAsBase64URI: true,
   
    },
  };

  const onFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'image/jpeg'];
      //  const allowedFileTypes = [];
      const newSmallFiles = [];
      const newLargeFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (allowedFileTypes.includes(file.type)) {
          if (file.size < 501 * 1024 * 1024) {
            newSmallFiles.push(file);
          } else {
            newLargeFiles.push(file);
          }
        }else{
          newLargeFiles.push(file);
        } 
      }

      setSmallFiles([...smallFiles, ...newSmallFiles]);
      setLargeFiles([...largeFiles, ...newLargeFiles]);
    }
  };
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return sizeInBytes + ' B';
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  };
  const deleteTargetList = (index)=>{

    const updatedSmallFiles = [...smallFiles];
      updatedSmallFiles.splice(index, 1);
      setSmallFiles(updatedSmallFiles);
  }
  console.log(smallFiles)
  console.log(largeFiles)
  const handleLargeFileToast = () => {
    const pairs = [];

    for (let i = 0; i < largeFiles.length; i += 2) {
      const pair = largeFiles.slice(i, i + 2);
      pairs.push(pair);
      
    }

    pairs.forEach((pair, index) => {
      setTimeout(() => {
        pair.forEach((file) => {
          toast.error(`Not Supported File Detected: ${file.name}`, { autoClose: 1000 });
        });
      }, index * 2000); // Adjust the time interval between each pair of toasts
    });

  }
  useEffect(() => {
    if (largeFiles.length > 0) {
      handleLargeFileToast();
    }
  }, [largeFiles])



  const isValidEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const sendEmail = (e) => {
 
    console.log(selectedToEmails);
    console.log(selectedCcEmails);
    console.log(selectedBccEmails);
    console.log(content)
    console.log(smallFiles)
      e.preventDefault();
    
    const formData = new FormData();
    formData.append('content', content);
    formData.append('subject', subject);
    formData.append('Cc', selectedCcEmails);
    formData.append('Bcc', selectedBccEmails);
    formData.append('to', selectedToEmails);
    formData.append('from', selectedFromEmails);
    formData.append('PIID', props.PIID);
    
    smallFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    
    try {
      console.log(formData)
      EmailEditorService.emailData(formData).then( (response)=>{         
        console.log('Upload successful');
        setTimeout(() => {
          setShowSuccess(true)
          setTimeout(() => {
            
            // setShowSuccess(fals/e)
          }, 2000);
          // setSelectedToEmails([]);
          // setSelectedCcEmails([]);
          // setSelectedBccEmails([]);
          setContent('')
          setSmallFiles([])     
          // setSmallFiles([])     
          setToEmailsValue('')
          setBccEmailsValue('')
          setCcEmailsValue('')
          
        // setCcEmailsValue([])
        // setBccEmailsValue  ([])
        }, 400);
    })
    } catch (error) {
      console.error('Error:', error);
      console.error('Upload failed');
    }
  
  }
  // console.log(SecurityService.getLoggedInUser())
  // const fields = { text: 'value', value: 'id' };
  
  


  const handleChange = (newValue, actionMeta ,key ) => {
    console.log(key)
    console.log(newValue)
    console.log(actionMeta)
    if(actionMeta.action == 'remove-value'){
      if ( isValidEmail(actionMeta.removedValue.value)) {
        console.log('irtizz')
        console.log(newValue)
        console.log(actionMeta)
        if(key == 'TO'){
          setToEmailsValue([...newValue])
         let values = [...newValue].map(item => item.value);
          const result = values.join(',');
          console.log(result);
          setSelectedToEmails(result)
        }else if(key == 'BCC'){
          setBccEmailsValue([...newValue])
          let values = [...newValue].map(item => item.value);
           const result = values.join(',');
           console.log(result);
           setSelectedBccEmails(result)
        }else if(key == 'CC'){
          setCcEmailsValue([...newValue])
          let values = [...newValue].map(item => item.value);
           const result = values.join(',');
           console.log(result);
           setSelectedCcEmails(result)
        }
        }else{
          console.log('now')
          newValue.pop()
        }
    }else{

    if ( isValidEmail(actionMeta.option.value)) {
      console.log('irtizz')
      console.log(newValue)
      console.log(actionMeta)
      if(key == 'TO'){
        setToEmailsValue([...newValue])
       let values = [...newValue].map(item => item.value);
        const result = values.join(',');
        console.log(result);
        setSelectedToEmails(result)
      }else if(key == 'BCC'){
        setBccEmailsValue([...newValue])
        let values = [...newValue].map(item => item.value);
         const result = values.join(',');
         console.log(result);
         setSelectedBccEmails(result)
      }else if(key == 'CC'){
        setCcEmailsValue([...newValue])
        let values = [...newValue].map(item => item.value);
         const result = values.join(',');
         console.log(result);
         setSelectedCcEmails(result)
      }
      }else{
        console.log('now')
        newValue.pop()
      }
    }
  }
  

  const handleFocusEditor = () => {
    console.log(editor)
    // console.log(editor.current.element)
    if (editor.current ) {
      const joditEditorElement = document.querySelector('.jodit-wysiwyg');
      if (joditEditorElement) {
        joditEditorElement.focus();
      }
    }
  };

  return (
    <div>
 <mat-card>
              <mat-card-content>
                <div className="row">
                <div className="col-sm-1 from-email">
                  From
                  </div>
                <div className="col-sm-6">
                    <mat-form-field>
                      <TextBoxComponent
                        className='e-input'
                        placeholder='PINo'
                        name='PINo'
                        floatLabelType='Never'
                        value={selectedFromEmails}
                        // onChange={textBoxChange}
                        disabled={props.loggedInUser != '' || props.loggedInUser != undefined ? true:false}
                      />
                    </mat-form-field>
                  </div>
                <div className="col-sm-4"></div>
                <div className="col-sm-1 cc-bcc-spans">
                    <mat-form-field>
                      <span className='Cc-span' onClick={(e)=>{setIsCc(!isCc)}}>Cc</span> | <span className='Bcc-span' onClick={(e)=>{setIsBcc(!isBcc)}}>Bcc</span>
                    </mat-form-field>
                  </div>
                  </div>
                  <br/>
                <div className="row">
                <div className="col-sm-1 from-email">
                  To
                  </div>
                <div className="col-sm-11">
                    <mat-form-field>
                    <CreatableSelect
                      isDisabled={props.isPreview}
                      isMulti
                      isClearable
                      isSearchable
                      value={ToEmailsValue}
                      options={toEmails}
                      onChange={(e,action)=> handleChange(e,action,'TO')}
                      onMenuOpen={()=>setToMenuIsOpen(true)}
                      onMenuClose={()=>setToMenuIsOpen(false)}
                      menuIsOpen={tomenuIsOpen}
                    />
                    
                    </mat-form-field>
                  </div>
                  </div>
                  <br/>
                <div className="row">
                <div className="col-sm-1 from-email">
                  Subject
                  </div>
                <div className="col-sm-11">
                    <mat-form-field>
                    <TextBoxComponent
                        className='e-input'
                        placeholder='Subject'
                        name='Subject'
                        floatLabelType='Never'
                        value={subject}
                        disabled={props.isPreview}
                       onChange={(e)=>setSubject(e.value)}
                       onBlur={handleFocusEditor}
                      />
                    </mat-form-field>
                  </div>
                  </div>
                  {isCc?(
                    <>
                    <br/>
                    <div className="row">
                <div className="col-sm-1 from-email">
                  Cc
                  </div>
                <div className="col-sm-11">
                    <mat-form-field>
                    <CreatableSelect
                      isDisabled={props.isPreview}
                      isMulti
                      isClearable
                      isSearchable
                      options={CcBcc}
                      value={CcEmailsValue}
                      onChange={(e,action)=> handleChange(e,action,'CC')}
                      onMenuOpen={()=>setCCMenuIsOpen(true)}
                      onMenuClose={()=>setCCMenuIsOpen(false)}
                      menuIsOpen={ccmenuIsOpen}
                    />
                    </mat-form-field>
                  </div>
                  </div></>
                  ):null}
                  {isBcc ?(
                    <>
                    <br/>
                    <div className="row">
                <div className="col-sm-1 from-email">
                  Bcc
                  </div>
                <div className="col-sm-11">
                    <mat-form-field>
                    <CreatableSelect
                    isDisabled={props.isPreview}
                      isMulti
                      isClearable={true}
                      isSearchable
                      options={CcBcc}
                      value={BccEmailsValue}
                      onChange={(e,action)=> handleChange(e,action,'BCC')}
                      onMenuOpen={()=>setBCCMenuIsOpen(true)}
                      onMenuClose={()=>setBCCMenuIsOpen(false)}
                      menuIsOpen={bccmenuIsOpen}
                      onBlur={handleFocusEditor}
                    />
                    </mat-form-field>
                  </div>
                  </div></>):null}
                  <br/>
                  <div className="row">
                <div className="col-sm-12">
      <JoditEditor
        ref={editor}
        value={content}
        
        config={config}
        style={{ textAlign: 'left' }} // Set text alignment using inline CSS
      
        onBlur={newContent =>{
          const styledContent = newContent.replace(/<strong>(.*?)<\/strong>/g, '<span style="font-weight: bold;">$1</span>');
    setContent(styledContent); 
          // setContent(newContent);
           }}
        onChange={(newContent) => {
          console.log(newContent.includes('<strong>'))

          if(newContent.includes('<strong>')){
          const styledContent = newContent.replace(/<strong>(.*?)<\/strong>/g, '<span style="font-weight: bold;">$1</span>');
    setContent(styledContent); 
          // setContent(newContent);
            // setIsSendEmail(true)
          }
        }}
      />
      </div>
      </div>
              </mat-card-content>
       </mat-card>
      <div className='email-footer'>
        <div className='send'>
        {isSendEmail == true?
        
      <button className='send-email' style={props.isPreview == true? {visibility:'hidden'}:{}}   onClick={sendEmail}>Send Email</button>:(<>...</>)}
      {showSuccess ==  true ?  <p className='showSuccess'>EMAIL SENT SUCCESSFULLY 
      <p>
      <button onClick={()=>{  
        document.querySelector('button.e-dlg-closeicon-btn.e-control.e-btn.e-lib.e-flat.e-icon-btn').click()
      }}>close</button>
      </p>
      </p>:null}
    
         
    
      {/* <ul className='not-supported-file'>
          {largeFiles.map((file, index) => (
            <li key={index}>{file.name } - {formatFileSize(file.size)}</li>
          ))}
        </ul> */}
      
          </div>
      <div className='attech-file'>
        <div className='attech-file-content'>
          <input
           type='file' 
           id='files' 
           multiple onChange={onFileChange}
           accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg" 
           onClick={(e)=>{
            setLargeFiles([])
           }}
           style={props.isPreview ? {display:'none'}:{}}
           />
          <button style={props.isPreview ? {display:'none'}:{}}>Upload File</button>
        </div>
          <ul className='atteched-file'>
          {
            props.isPreview == false?
          smallFiles.map((file, index) =>   (
            <li key={index}>{file.name } - {formatFileSize(file.size)} <span className='plus' onClick={()=>{deleteTargetList(index)}}>
              +</span></li>
         )) :
         smallFiles.map((file, index) =>   (
          <li key={index}>{file.name}  <span>
            <img 
            src={process.env.PUBLIC_URL + '/eye.svg'}
             width={'20px'} 
             style={{top:'40% !important'}} 
             onClick={(e)=>{
              console.log(e)
              console.log(file)
              try {
                // Create a Blob from the file buffer
                const blob = new Blob([new Uint8Array(file.buffer.data)], { type: file.type });
          
                // Create a URL for the Blob
                // const blob = new Blob([fileBuffer], { type: fileType });

                // Save the Blob as a file using FileSaver.js
                saveAs(blob, file.name);
              } catch (error) {
                console.error('Error downloading file:', error);
              }
            }}
             />
            </span></li>
       ))
        }
        </ul>
      </div>
      </div>
        <ToastContainer />
    </div>
  );
};

export default EmailEditorPopup;
