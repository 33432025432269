import React, { useEffect, useState,useRef } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import HardDeleteService from '../../services/hard-delete.service';
import CommonService from '../../services/common.service';
import AuditTable from '../audit-table/audit-table';
// import MultipleInputService from '../country-productSize-productCategory/country-productSize-productCategory.service'



export default function HardDelete(props) {
  


const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
const [dialogWarningVisibility, setDialogWarningVisibility] = useState(false);
const [warningMessage, setWarningMessage] = useState('');
const deleteDialog = useRef();
const warningDialog = useRef();
   

useEffect(() => {
    
    console.log(props)
if(props.SelectedRow){
deleteDialog.current.show();
}else{
  setTimeout(() => {
    
    setWarningMessage('Please select a row first!');
    warningDialog.current.show();
    // props.showResult(true)
  }, 50);
}
}, []);



  const deleteRow = () => {
    if(props?.DeleteSecurity?.length > 0){
 console.log(props.DeleteSecurity.length )
 console.log(props.DeleteSecurity)

 CommonService.delete(props.UserID, props.DeleteSecurity, props.TableName , props.ColumnName , props.RowID , 1)
 .then(async (response) => {
   // setDialogWarningVisibility(false);
   // console.log("Message is about to set to ", response?.data?.recordset[0]?.Message);
   setWarningMessage(response?.data?.recordset[0]?.Message);
   // console.log("Message is set");
   warningDialog.current.show();
   AuditTable.auditDelete(props.TableName,props.RowID,props.SelectedRowData)
   // if (!(response?.data?.recordset[0]?.Message.includes('access denied'))) {
   //   // record was succesfully deleted
   //   const type = getRoute()[1];
   //   await getYarnContracts(type);
   //   // console.log("record was succesfully deleted");
   // }
   // console.log("Dialog Turned on");
 })
 .catch((e) => {
   setWarningMessage('Error: access denied ');
   warningDialog.current.show();
   // setDialogWarningVisibility(true);
   console.log('Error: ', e);
 });;

    }else{
    console.log(props)
    const data = {
      TableName:props.TableName,
      ColumnName:props.ColumnName,
      RowID:props.RowID
    }
    HardDeleteService.hardDelete(data).then(async (response) => {
        // setDialogWarningVisibility(false);
        // console.log("Message is about to set to ", response?.data?.recordset[0]?.Message);
        setWarningMessage(`RECORD(S) DELETED SUCESSFULLY`);
        // console.log("Message is set");
        warningDialog.current.show();
        AuditTable.auditDelete(props.TableName,props.RowID ,props.SelectedRowData)

      
        // if (!(response?.data?.recordset[0]?.Message.includes('access denied'))) {
        //   // record was succesfully deleted
        //   const type = getRoute()[1];
        //   await getYarnContracts(type);
        //   // console.log("record was succesfully deleted");
        // }
        // console.log("Dialog Turned on");
      })
      .catch((e) => {
        // setWarningMessage('Error: ');
        // setDialogWarningVisibility(true);
        console.log('Error: ', e);
        setWarningMessage(`THIS RECORD CANNOT BE DELETED`);
        // console.log("Message is set");
        warningDialog.current.show();
      });
    }
  };

  const deleteButtons = [
    {
      buttonModel: {
        content: 'OK',
        cssClass: 'e-flat',
        isPrimary: true,
      },
      click: (e) => {
        deleteDialog.current.hide();
        console.log(e);
        // const data = {
        //   TableName:props.TableName,
        //   ConditionalColumn: props.ColumnName,
        //   ConditionalSign: '=',
        //   ConditionalValue:props.RowID,
        //   Columns:'*'
        // }
        // MultipleInputService.getMultipleInputByCondition(data).then((res)=>{
        //   console.log(res)
          deleteRow();
        // })
      },
    },
    {
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-flat',
      },
      click: () => {
        deleteDialog.current.hide();
        props.showResult(false)
      },
    },
  ];

  const warningButtons = [
    {
      buttonModel: {
        content: 'OK',
        cssClass: 'e-flat',
        isPrimary: true,
      },
      click: () => {
        warningDialog.current.hide();
        
        if(props.SelectedRow){
          props.showResult(false)
        }else{
          props.showResult(true)
        }
        // console.log('iritza')
        // setTimeout(() => {
        //   if (!(warningMessage.includes('access denied'))) {
        //     // record was succesfully deleted
        //     const type = getRoute()[1];
        //     getYarnContracts(type);
        //     // console.log("record was succesfully deleted");
        //   }
        // }, 500);

      },
    },
  ];

  function deleteDialogClose() {
    setDeleteDialogVisibility(false);
    }

  function warningDialogClose() {
    setDialogWarningVisibility(false);
    if (!(warningMessage.includes('access denied'))) {
      
}
  }


  return(
            <div>
                 <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={deleteDialogVisibility}
                close={deleteDialogClose}
                content={() => (<div>Are you sure you want to delete?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              <DialogComponent
                ref={warningDialog}
                width={400}
                visible={dialogWarningVisibility}
                close={warningDialogClose}
                content={() => (<div>{warningMessage}</div>)}
                isModal={true}
                header='Warning'
                buttons={warningButtons}
              // footerTemplate={footerTemplate}
              />
            </div>
  )



}
