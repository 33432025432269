import http from './http-common';

class UploadSupplierService {
  uploadSupplierData(data) {
    return http.post('/UploadSupplier/upload',data);
  }
  
}

export default new UploadSupplierService();
