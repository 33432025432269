import http from './http-common';

class CustomerLastSale {

  getCustomerLastSaleList(customerId,salesAgentId,countryId) {
    return http.get(`/customerLastSale/list/${customerId}/${salesAgentId}/${countryId}`);
  }

  getCustomerDropdownList() {
    return http.get(`/customerLastSale/customer/dropdown/list`);
  }

  getEmployeeDropdownList() {
    return http.get(`/customerLastSale/employee/dropdown/list`);
  }

  getCountryDropdownList() {
    return http.get(`/customerLastSale/country/dropdown/list`);
  }

}
  export default new CustomerLastSale();
