import React, { useEffect, useRef, useState } from 'react';
import YarnContractService from '../../services/yarn-contract.service';
import './setup-list.scss'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
  Group,
  Freeze,
  ColumnChooser,
  Reorder,
  actionBegin
} from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupService from '../../services/setup.service';
import moment from 'moment';
import SecurityService from '../security/security.service';
import CommonService from '../../services/common.service';

export default function SetupList(props) {
  const [setup, setSetup] = useState([]);
  const [title, setTitle] = useState('');
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const [dialogWarningVisibility, setDialogWarningVisibility] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [deletingID, setDeletingID] = useState(0);
  const deleteDialog = useRef();
  const warningDialog = useRef();  

  let setupGrid: Grid;

  const setHeading = () => {
    const type = setupType();
    let title: string;
    switch (type) {
      case 'Broker':
        title = 'Broker';
        break;
      case 'YarnCount':
        title = 'Yarn Count';
        break;
      case 'YarnQuality':
        title = 'Yarn Quality';
        break;
      case 'YarnType':
        title = 'Yarn Type';
        break;
      case 'YarnBrand':
        title = 'Yarn Brand';
        break;
      case 'YarnGodown':
        console.log('yarngodown');
        title = 'Godown';
        break;
      case 'ImportLC':
        title = 'Import LC';
        break;
      case 'HSCode':
        title = 'HS Code';
        break;
      case 'FabricType':
        title = 'Fabric Type';
        break;
      case 'FabricYarnDescription':
        title = 'Fabric Yarn Description';
        break;
      case 'FabricCategory':
        title = 'Fabric Category';
        break;
      case 'FabricThreadCount':
        title = 'Fabric Thread Count';
        break;
      case 'Vendors':
        title = 'Vendors';
        break;
      case 'CompanyType':
        title = 'Company Type';
        break;
      case 'Currency':
        title = 'Currency';
        break;
      case 'ImportExpenses':
        title = 'Import Expenses';
        break;
      case 'FabricPacking':
        title = 'Fabric Packing';
        break;
      case 'FabricAdjustmentType':
        title = 'Fabric Adjustment Type';
        break;
      case 'PaymentTerms':
        title = 'Payment Terms';
        break;
      case 'Department':
        title = 'Department';
        break;
      case 'DeliveryStatus':
        title = 'Delivery Status';
        break;
      case 'ConversionContractType':
        title = 'Conversion Contract Type';
        break;
      case 'BrokerageType':
        title = 'Brokerage Type';
        break;
      case 'ProcurementType':
        title = 'Procuremen1t Type';
        break;
      case 'AccessoryCategory':
        title = 'Accessory Category';
        break;
      // case 'Country':
      //   title = 'Country';
      //   break;
      case 'StitchingStyle':
        title = 'Stitching Style';
        break;
      case 'UnitOfMeasurement':
        title = 'Unit Of Measurement';
        break;
      case 'Color':
        title = 'Color';
        break;
      case 'FabricDesign':
        title = 'Fabric Design';
        break;
      case 'FabricVersion':
        title = 'Fabric Version';
        break;
      case 'ThirdPartyGodown':
        title = 'Third Party Godown';
        break;
      case 'RejectionSubCategory':
        title = 'Rejection Sub Category';
        break;
      case 'RejectionCategory':
        title = 'Rejection Category';
        break;
      case 'ProductCategory':
        title = 'Product Category';
        break;
      case 'ProductSize':
        title = 'Product Size';
        break;
      case 'ProductCutSize':
        title = 'Product Cut Size';
        break;
      case 'ProductFinishSize':
        title = 'Product Finish Size';
        break;
      case 'ShipmentType':
        title = 'Shipment Type';
        break;
      case 'ProductDesc':
        title = 'Product Description';
        break;
      // VOMS

      case 'Port':
        title = 'Port';
        break;

      case 'PurchaseSource':
        title = 'Purchase Source';
        break;

        case 'PaymentType':
        title = 'Payment Type';
        break;

      case 'TradeBoardLetterStatus':
        title = 'Trade Board Letter Status';
        break;

      case 'ShipmentType':
        title = 'Shipment Type';
        break;

      case 'Designation':
        title = 'Designation';
        break;

      case 'Country':
        title = 'Country';
        break;

      case 'City':
        title = 'City';
        break;

      // VOMS
      default:
        title = '';
        break;
    }
    setTitle(title);
  };

  useEffect(() => {
    const type = setupType();
    setHeading();
    getSetup(type);
  }, []);

  const getSetup = async (type) => {
    SetupService.getForList(type)
      .then((response) => {
        setSetup(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
        setSetup({});
      });
  };

  const setupType = () => {
    return window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/')[1];
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete",
      tooltipText: "Delete",
      id: "Delete",
      disabled: false,
    },
    //  'Delete',
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    //'ColumnChooser',
  ];

  const userId = SecurityService.getLoggedInUserId();
  const databound = () =>
  {
      if (setupGrid) {
        // setupGrid.autoFitColumns();
      }
  }


  const toolbarClick = (args: ClickEventArgs) => {
    console.log('toolbar click: ', args);
    if (setupGrid && args.item.properties.id === 'setupGrid_PDFExport') {
      const heading = setupType().match(/[A-Z][a-z]+/g).join("_");
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_setup_${heading.toLowerCase()}.pdf`
      };
      console.log('clicked on pdf export');
      setupGrid.pdfExport(exportProperties);
    }
    if (setupGrid && args.item.properties.id === 'setupGrid_excelexport') {
      const heading = setupType().match(/[A-Z][a-z]+/g).join("_");
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_setup_${heading.toLowerCase()}.xlsx`
      };
      setupGrid.excelExport(exportProperties);
    }
    // if (setupGrid && args.item.properties.id === 'Delete') {
    //   console.log('toolbar Delete');
    //   if (setupGrid.selectedRowIndex === -1) {
    //     // no rows selected
    //     setDialogWarningVisibility(true);
    //   } else {
    //     setDeleteDialogVisibility(true);
    //   }
    // }    
    if (setupGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (setupGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', setupGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        setWarningMessage('Please select a row first!');
        warningDialog.current.show();
      } else {
        console.log('delete: ', setupGrid.getSelectedRecords()[0]?.id)
        // deleteDialog.current.hide();
        setDeletingID(setupGrid.getSelectedRecords()[0]?.id);
        deleteDialog.current.show();
      }
    }

  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess(`${setupType()}Edit`),
    allowAdding: SecurityService.canUserAccess(`${setupType()}Add`),
    allowDeleting: SecurityService.canUserAccess(`${setupType()}Delete`),

    // allowEditing: true,
    // allowAdding: true,
    // allowDeleting: true,

    mode: 'Normal',
    // showDeleteConfirmDialog: true,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'notequal',
    //     value: 'True'
    //   }
    // ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'id', direction: 'Descending' }]
  }

  const actionBegin = (args)=>{
    console.log(args)
    const type = setupType();
    if(args.rowData?.id == '1'  && type == 'PaymentType'){
      args.cancel = true
    }
  
  }

  const actionComplete = (args: DialogEditEventArgs) => {
    // console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      setupGrid?.autoFitColumns();
    }
    if (args.form) {
      // console.log('data: ', args);
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        console.log('e: ', args.form.ej2_instances[0]);
        /** Add Validation Rules */
        // args.form.ej2_instances[0].addRules('OrderID', { max: 500 });

        // args.form.ej2_instances[0].addRules('OrderDate', {
        //   required: true,
        //   date: true,
        // });
        // args.form.ej2_instances[0].addRules('ContractStartDate', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('ContractEndDate', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('CompanyID', { required: true });
        // args.form.ej2_instances[0].addRules('FullYarnCountID', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('BrandID', { required: true });
        // args.form.ej2_instances[0].addRules('ImportLCID', { required: true });
        // args.form.ej2_instances[0].addRules('OrderBags', { required: true });
        // args.form.ej2_instances[0].addRules('WeightPerBag', { required: true });
        // args.form.ej2_instances[0].addRules('YarnProcurementTypeID', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('CurrencyID', { required: true });
        // args.form.ej2_instances[0].addRules('ForeignCurrencyRate', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('ForeignCurrencyAmount', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('ContractExchangeRate', {
        //   required: true,
        // });
        // args.form.ej2_instances[0].addRules('Rate', { required: true });
        // args.form.ej2_instances[0].addRules('RatePer', { required: true });

        // args.form.ej2_instances[0].addRules('TaxTypeID', { required: true });
        // args.form.ej2_instances[0].addRules('TaxPercent', { required: true });
        // args.form.ej2_instances[0].addRules('PaymentTermsID', {
        //   required: true,
        // });

        // args.form.ej2_instances[0].addRules('DeliveryStatusID', {
        //   required: true,
        // });

        // args.form.ej2_instances[0].addRules('PaymentDays', {
        //   required: true,
        // });
      }

      if (args.requestType === 'save') {
        const type = getRoute()[1];
        if (args?.data?.id > 0) {
          editSetup(type, args?.data);
        }
        else {
          addSetup(type, args?.data)
        }
      }
    }
  };


  const addSetup = (type: string, data: any) => {
    SetupService.insert(type, data).then((response) => {
      console.log('add response: ', response);
      setSetup(response.data.recordset);
      //getSetup(type);        
    });
  };

  const editSetup = (type: string, data: any) => {
    SetupService.update(type, data).then((response) => {
      console.log('edit response: ', response);
      getSetup(type);
    });
  };

  const DELETE_MESSAGE = "DELETED SUCCESSFULLY";
  const CONFLICT_MESSAGE = "THIS RECORD CANNOT BE DELETED";
  const deleteRow = () => {
    const type = getRoute()[1];
    SetupService.delete(type , deletingID)
      .then((response) => {
        console.log(response);
        setWarningMessage(DELETE_MESSAGE);
        warningDialog.current.show();
      })
      .catch((e) => {
        setWarningMessage(CONFLICT_MESSAGE);
        warningDialog.current.show();
      });
  };

  const deleteButtons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: (e) => {
        deleteDialog.current.hide();
        console.log(e);
        deleteRow();
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "e-flat",
      },
      click: () => {
        deleteDialog.current.hide();
      },
    },
  ];

  const warningButtons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        warningDialog.current.hide();
        // setTimeout(() => {
        //   if (!(warningMessage.includes('access denied'))) {
        //     // record was succesfully deleted
        //     const type = getRoute()[1];
        //     getYarnContracts(type);
        //     // console.log("record was succesfully deleted");
        //   }
        // }, 500);
      },
    },
  ];

  function deleteDialogClose() {
    setDeleteDialogVisibility(false);
  }

  function warningDialogClose() {
    setDialogWarningVisibility(false);
    if (warningMessage.includes(DELETE_MESSAGE)) {
      const type = setupType();
      setHeading();
      getSetup(type);
      }
  }



  return (
    <div className="content-wrapper">
      <div>
      <div>
      <DialogComponent
        ref={deleteDialog}
        width={500}
        visible={deleteDialogVisibility}
        close={deleteDialogClose}
        content={() => <div>Are you sure you want to delete?</div>}
        isModal={true}
        header="Confirmation"
        buttons={deleteButtons}
      // footerTemplate={footerTemplate}
      />
      <DialogComponent
        ref={warningDialog}
        width={400}
        visible={dialogWarningVisibility}
        close={warningDialogClose}
        content={() => <div>{warningMessage}</div>}
        isModal={true}
        header="Warning"
        buttons={warningButtons}
      // footerTemplate={footerTemplate}
      />
    </div>
        <div>
          <h3> {title} - List</h3>
          <div></div>
          <DialogComponent
                ref={deleteDialog}
                width={500}
                visible={deleteDialogVisibility}
                close={deleteDialogClose}
                content={() => (<div>Are you sure you want to delete?</div>)}
                isModal={true}
                header='Confirmation'
                buttons={deleteButtons}
              // footerTemplate={footerTemplate}
              />
              <DialogComponent
                ref={warningDialog}
                width={400}
                visible={dialogWarningVisibility}
                close={warningDialogClose}
                content={() => (<div>{warningMessage}</div>)}
                isModal={true}
                header='Warning'
                buttons={warningButtons}
              // footerTemplate={footerTemplate}
              />            
          <GridComponent
            ref={(grid) => (setupGrid = grid)}
            id='setupGrid'
            //height = {550}
            //width = {900}
            dataSource={setup}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 4, pageSizes: true }}
            filterSettings={filterOptions}
            groupSettings={groupOptions}
            actionComplete={actionComplete}
            allowTextWrap='true'
            allowPaging={true}
            allowSorting={true}
            allowPdfExport={true}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            allowFiltering={true}
            allowGrouping={true}
            actionBegin={actionBegin}
            //showColumnChooser={true}
            allowReordering={true}
            allowResizing={true}
            sortSettings={sortingOptions}
            dataBound={databound}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText='ID'
                field='id'
                width='10'
                headerTextAlign='Center'
                textAlign='Right'
                allowEditing={false}
              />
              <ColumnDirective
                headerText='Description'
                field='value'
                width='50'
                headerTextAlign='Center'
                textAlign='left'
              />
              {/* <ColumnDirective
                headerText='Is Deleted'
                field='IsDeleted'
                width='100'
                allowEditing={false}
              /> */}
            </ColumnsDirective>
            <Inject
              services={[
                Edit,
                Toolbar,
                Page,
                Sort,
                PdfExport,
                ExcelExport,
                Filter,
                Resize,
                Group,
                Freeze,
                //ColumnChooser,
                Reorder,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
}