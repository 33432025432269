import http from "./http-common";

class CountryCityPortService {
  
  getCountryCityPortForList() {
    return http.get('/countryCityPort/list');
  }

  addCountryCityPort(data) {
    return http.post('/countryCityPort/add', data);
  }

  delete(CountryCityPortID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/countryCityPort/${CountryCityPortID}`)
  }

  updateCountryCityPort(id,data) {
    return http.put(`/countryCityPort/${id}`,data);
  }

}

export default new CountryCityPortService();
