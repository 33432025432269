// Yarn Count List
import React, { useEffect, useState, useRef } from 'react';
import MessageService from '../../services/message.service';
import './message-button.scss';

import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import MessageAdd from './messages-button-add';
// import YarnCountEdit from './yarn-count-edit';
// import YarnPurchaseContractEdit from './yarn-purchase-contract-edit';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import YarnContractDeliveryScheduleList from '../yarn-contract-delivery-schedule/yarn-contract-delivery-schedule-list';
import PIView from '../pi-view/pi-view';
import moment from 'moment';
import SecurityService from '../security/security.service';
import { useMemo } from 'react';
import AuditTableInput from '../audit-table/audit-table';
import HardDelete from '../hard-delete/hard-delete';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import PIService from '../../services/PI.service'
let selectedRows = []

var printByUserName2 = ''
var printByUserID2 = 0
var piID2 = 0
let messageGrid: Grid;
export default function YarnCountList(props) {
  let piRef = useRef(null)
  console.log(props)
  const [message, setMessage] = useState([]);
  const [loadingPi, setLoadingPi] = useState(false);
  const [pi, setPi] = useState([]);
  const [piValue, setPIValue] = useState(props.PINo);
  const [piID, setPiID] = useState(props.PIID);
  const [printedByUserName, setPrintedByUserName] = useState('');
  const [printedByUserID, setPrintedByUserID] = useState('');

  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();

  const [deletingID, setDeletingID] = useState(0);
  const [showDialogView, setShowDialogView] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [piprops, setPiprops] = useState([]);
  const [piData, setPiData] = useState([]);
  const userId = SecurityService.getLoggedInUserId();

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('args.item.properties :', args.item.properties);
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_PDFExport'
    ) {
      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        // fileName: `${moment().format('YYYYMMDD')}_messages_information.pdf`
        fileName: `messages_information.pdf`
      };
      console.log('clicked on pdf export');
      // fabricQualityGrid.pdfExport(exportProperties);
        setTimeout(() => {
      
      return messageGrid.pdfExport(exportProperties);
    }, 300);
      
    }
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_messages_information.xlsx`
      };
      messageGrid.excelExport(exportProperties);
    }
    if (
      messageGrid &&
      args.item.properties.id === 'messageGrid_columnchooser'
    ) {
    }
    if (messageGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (messageGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', messageGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`YarnCount`,
          ColumnName:`YarnCountID`,
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        // setShowDialogs(true)
        // setWarningMessage("Please select a row first!");
        // warningDialog.current.show();

      } else {
        console.log('delete: ', messageGrid.getSelectedRecords())
        console.log(selectedRows)
        // deleteDialog.current.hide();
        let obj={
          TableName:`YarnCount`,
          ColumnName:`YarnCountID`,
          RowID:messageGrid.getSelectedRecords()[0]['YarnCountID'],
          SelectedRow:true,
          SelectedRowData:{
            YarnCount:  messageGrid.getSelectedRecords()[0].YarnCount,
            WarpWastagePercent: messageGrid.getSelectedRecords()[0].WarpWastagePercent,
            WeftWastagePercent: messageGrid.getSelectedRecords()[0].WeftWastagePercent
          }

          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    } 
    
    // if (
    //   transporterGrid &&
    //   args.item.properties.id === 'transporterGrid_Delivery Schedule'
    // ) {
    //   console.log('clicked delivery');
    // }
  };

  useEffect(() => {
    // getMassage(1);
    setBtnClick(true)
    getPiDropDown();
    SecurityService.getUserAccessList(userId).then((res)=>{
      console.log(res)
      console.log(res.data.recordset[0])
      
      // printByUserName2 = res.data.recordset[0].UserName;
      // printByUserID2 = res.data.recordset[0].UserID;
      printByUserName2 = res.data.recordset[0].UserName;
      setPrintedByUserName(res.data.recordset[0].UserName)
      setPrintedByUserID(res.data.recordset[0].UserID)
      // setPrintedByEmail(res.data.recordset[0].Email)
     });
     MessageService.getEmployeeByUserID(userId).then((res)=>{
        console.log(userId)
        console.log(res)
      printByUserID2 = res.data.recordset[0].EmployeeID;
      getMassage(props.PIID)
      piID2 = props.PIID
     }).catch((e)=>{
      console.log(e)
     })
  }, []);

  
  const getProformaInvoice = async (id) => {
    console.log(id)
    PIService.getList()    
      .then((response) => {
        console.log('ProductFinishSize get response', response.data.recordset);
        let filterData = response.data.recordset.filter(res =>{ if( res.PIID == id){
          return res
        }})
        console.log(filterData)
        setPiData(response.data.recordset)
        setPiprops(filterData[0])
        // setPI(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const getPiDropDown = async () => {
    MessageService.getPIDropdownList()
      .then((response) => {
        console.log('get all pi', response)
        setPi(response.data.recordset);

      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getMassage = async (id) => {
    console.log(printByUserID2)
    MessageService.getMessageList(id , 0)
      .then((response) => {
        console.log('get all yarn count', response)
        setMessage(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addYarnCount = () => {
    props.history.push('/YarnCount/Add');
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    // 'Edit',
    {
      text: "Delete",
      tooltipText: "Delete",
      id: "Delete",
      disabled: !SecurityService.canUserAccess('YarnCountDelete'),
    },
    'Update',
    'Cancel',
    'PDFExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
  ];

  const databound = () =>
  {
      if (messageGrid) {
        messageGrid.autoFitColumns();
      }
  }


  // Grey Area
  const dialogTemplate = (props) => {
    console.log('props: ', props);
    return props.isAdd ?
      (
        <MessageAdd dataList={message} From={printByUserName2} FromID={printByUserID2} {...props} > </MessageAdd>
      )
      :
      (
        <MessageAdd dataList={message} From={printByUserName2} FromID={printByUserID2} {...props} > </MessageAdd>
    
      )
    
  };

  const editSettings: EditSettingsModel = {

    allowEditing: false,
    allowAdding:SecurityService.canUserAccess('PIMessageAdd') && piID != 0 ,
    allowDeleting: SecurityService.canUserAccess('PIMessageDelete'),

    // allowEditing: false,
    // allowAdding: true,
    // allowDeleting: false,
    mode: 'Dialog',
    template: dialogTemplate,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'PIMessageID', direction: 'Descending' }]
  }

  const footerSum = (e: any) => {
    return <span> Sum: {e.Sum}</span>;
  };
  // const header = () => {
  //   return <div>Yarn Contract Delivery Schedule</div>;
  // };

  //   const contentTemplate = () => {
  //     <div>
  //       <YarnContractDeliveryScheduleList
  //         {...selectedRow}
  //       ></YarnContractDeliveryScheduleList>
  //     </div>
  //   );
  // };

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('data: ', args);
    if (args?.requestType === 'columnstate') {
      console.log('adjusting column');
      messageGrid?.autoFitColumns();
    }
    if (args.form) {
      console.log('data: ', args);
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      //   console.log('e: ', args.form.ej2_instances[0]);
      //   /** Add Validation Rules */

      //   args.form.ej2_instances[0].addRules('YarnCount', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('WarpWastagePercent', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('WeftWastagePercent', {
      //     required: true,
      //   });

      // }

      if (args?.requestType === 'save') {
        setTimeout(() => {
          setMessage([])
          getMassage(piID2)
          messageGrid.refresh()
        }, 2000);
      }
      if (args?.requestType === 'save') {
        console.log('Adding ......');
        const data = {
          UserId: userId,
          ...args.data,
          PIID:piID2,
          FromEmployeeID:printByUserID2,
          ToEmployeeID:args.data.ToEmployeeID.includes(',')
          ? args.data.ToEmployeeID.split(',').map(item => item.trim())
          : [args.data.ToEmployeeID]

        };
        console.log('add data: ', data);
        MessageService.insert(data).then((response) => {
          console.log(response)
          // getMassage(piID2)
        })
        // MessageService.create(data).then((response) => {
        //   console.log('add response: ', response);
        //   let AddData = {
        //     YarnCount: args.data.YarnCount,
        //     WarpWastagePercent: args.data.WarpWastagePercent,
        //     WeftWastagePercent: args.data.WeftWastagePercent
        //   }
        //   AuditTableInput.auditAdd(`YarnCount`,`YarnCount`,`YarnCountID`,AddData)
        //   setMessage(response.data.recordset);
        //   // refresh grid to show new record
        //   // const type = getroute()[1];
        //   getYarnCount();


        // });
      } 
      if (args?.action === 'editdddd' && args?.requestType === 'save') {
        console.log('Updating ......');
        const data = {
          UserId: userId,
          ...args.data,
          UserID:userId,
          YarnCountId:args.data.YarnCountID
        };
        console.log('update data: ', data);
        // MessageService.update(data).then((response) => {
        //   console.log('update response: ', response);
        //   let EditData = {
        //     YarnCount: args.data.YarnCount,
        //     WarpWastagePercent: args.data.WarpWastagePercent,
        //     WeftWastagePercent: args.data.WeftWastagePercent
        //   }
        //   let totalColumns = Object.keys(EditData);
        //   console.log(totalColumns)

        //   AuditTableInput.auditEdit(totalColumns,selectedRows,EditData,`YarnCount`,args.data.YarnCountID)
        //   setMessage(response.data.recordset);
        //   // refresh grid to show new record          
        //   getYarnCount();

        // });
      }      
    }

  };


 
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  // const MessageGridComponent =  () => (
    const commands: CommandModel[] = [
      {
        buttonOption: {
          id: 'view-pi',
          content: 'View PI',
          cssClass: 'e-flat',
          isPrimary: true,
        },
      }
    
    ];
    const commandClick = (args: CommandClickEventArgs) => {
      if (args.commandColumn.buttonOption.id === 'view-pi') {
        setShowDialogView(false);
        console.log('command click args: ', args.commandColumn.buttonOption.id);
        setSelectedRow(args.rowData);
        console.log(args.rowData)
        if(
          args.rowData.PIID != undefined  ||
          args.rowData.PIID != undefined 
          ){
        getProformaInvoice(args.rowData.PIID)
          }
        setShowDialogView(true);
      }
    }
    const viewHeader = () => {
      return <div className="dialog-header">{SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList')?'View PI':'VIEW pi'}</div>;
    };
    const getpiid = (e)=>{

      
    }
    const contentViewTemplate = () => {
      if(SecurityService.canUserAccess('YarnPurchaseTransactionsImportExpensesList') || 1==1){
        console.log(piprops)
      return (
        <div>
          <PIView dataList={piData}  userName={printedByUserName} type={"Supervision"} isPreview={true}  isAdd={false}  {...piprops}> </PIView>
          

        </div>
      )
    }else{
   return( 
      <div className='returnlist'>
        {/* <SecureComponent name='YarnPurchaseTransactionsImportExpensesList'/> */}
      </div>
   )
    }
    };
    const viewDialogClose = () => {
      this.buttonInstance.style.display = 'inline-block';
      setShowDialogView(false);
    };
  
    const [btnClick, setBtnClick] = useState(false);
    const btnClicked = (e)=>{
      console.log(e)
      setBtnClick(true)
      console.log(piID)
      if(piID == 0 || piID == undefined){
        getMassage(0);
        // window.alert('PLEASE SELECT PI ')
        // getProformaInvoice(0)
     }else{
        getMassage(piID);
      //  getProformaInvoice(piID)
       // let filterRowByid = emailEditorGrid.dataSource.filter((res)=>{res.PIID ==  piID})
       // console.log(filterRowByid)
       // if(filterRowByid.length > 0){
         
       //   setSelectedRow(filterRowByid);
        //  setShowDialogView(true);
       // }{
       //   window.alert('PLEASE SELECT ANOTHER PI')
       // }
     }
    }
    const MessageGridComponent = useMemo(() =>{
      return () => (
      
    <GridComponent
    ref={(grid) => (messageGrid = grid)}
    id='messageGrid'
    dataSource={message}
    editSettings={editSettings}
    toolbar={toolbarOptions}
    pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 12 }}
    filterSettings={filterOptions}
    groupSettings={groupOptions}
    actionComplete={actionComplete}
    allowTextWrap='true'
    commandClick={commandClick}

    allowPaging={true}
    allowSorting={true}
    // allowPdfExport={true}
    toolbarClick={toolbarClick}
    allowPdfExport={true}
    allowExcelExport={true}
    allowFiltering={true}
    allowGrouping={true}
    showColumnChooser={true}
    allowReordering={true}
    allowResizing={true}
    sortSettings={sortingOptions}
   dataBound={databound}

  //  rowSelected={
  //   (args)=>{
  //     console.log(args.data)
  //     selectedRows = args.data
      
  //               setTimeout(() => {
  //       console.log('ss')
  //     }, 200);
  //   }
  // }
  rowSelected={
    (args)=>{
      console.log(args.data)
      selectedRows = args.data
      // let data = {
      //   PIMessageID:args.data.PIMessageID,
      //   MessageReadDate:new Date()
      // }
      // MessageService.messageReadUpdate(data).then((res)=>{
      //   console.log(res)
      //   setTimeout(() => {
      //     getMassage(piID)
      //   }, 3000);
      //   // messageGrid.refresh()
        
      // })

      
                setTimeout(() => {
                  console.log(args.data)
                  
      }, 3000);
    }
    }

  >
  
    <ColumnsDirective>
    <ColumnDirective
                headerText='Commands'
                width='150'
                commands={commands}
                textAlign='center'
                headerTextAlign='center'
              />
    <ColumnDirective
        headerText='Serial 
        No'
        field='SerialNo'
        width='130'
        headerTextAlign='Center'
        textAlign='Right'
      />
     <ColumnDirective
        headerText='PI NO'
        field='PINo'
        width='140'
        headerTextAlign='Center'
        textAlign='Left'
      />
     <ColumnDirective
        headerText='Date'
        field='MessageDate'
        width='200'
        type='date'
        format={formatOptions}
      //  format={'dd/MM/yy'}
            /> 
      <ColumnDirective
        headerText='Employee Name'
        field='EmployeeName'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
      
      />
      <ColumnDirective
        headerText='To Employees'
        field='ToEmployees'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      <ColumnDirective
        headerText='Message '
        field='MessageComment'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      <ColumnDirective
        headerText='To Employees With Read Date '
        field='ToEmployeesWithReadDate'
        width='160'
        headerTextAlign='Center'
        textAlign='left'
        
      />
      {/* <ColumnDirective
        headerText='Message
        Read 
        Date'
        field='MessageReadDate'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
        type='date'
        format={formatOptions}
        
      />  */}
      {/* <ColumnDirective
        headerText='Message Status '
        field='PIMessageStatus'
        width='100'
        headerTextAlign='Center'
        textAlign='left'
         template={(props) =>{ 
            
            console.log(props)
            return (
            <div className={props.PIMessageStatus == "UNREAD" ? `MessageUnRead` : ''} >
        {props.PIMessageStatus}
            </div>
            // <label className="checkbox-container">

            // <input
            //  type='checkbox'

            //   className={`checkbox ${props.ApprovedProduct ? 'yellow': ''}`}
            //   id={`checkbox_${props.CostingProductRowID}`}
            //    checked={props.ApprovedProduct}
               
            //     onChange={(e) => handleCheckboxChange(e, props)} 
            //   />
            // <span className="checkmark"></span>
            // </label>
          )}} 
        
      />
     */}
    </ColumnsDirective>

          <Inject
      services={[
        Edit,
        Toolbar,
        Page,
        Sort,
        PdfExport,
        ExcelExport,
        Filter,
        Group,
        Aggregate,
        Freeze,
        ColumnChooser,
        Reorder,
        Resize,
        CommandColumn,
      ]}
    />
  </GridComponent>
  )},[message]);

  const ddlFields: object = { value: "value" };
  const piChange = (e) => {
    // setBtnClick(false)
    setPIValue(e?.itemData?.value ? e?.itemData?.value :'');
    if (e?.e?.srcElement?.id) {
      console.log("piChange: ", e?.itemData?.id);
      // setCountryID(e?.itemData?.id);
      setPiID(e?.itemData?.id);
      piID2 = e?.itemData?.id;
      
    }
    else {
      // setMessage([]);
      setPiID(0)
      piID2 = 0
    }
  };


  return (
    (props.isDialog) ? (
      <div>
          <div>
            <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogView}
              close={viewDialogClose}
              className='pi-view'
              //open={openReturnDialog}
              content={contentViewTemplate}
              isModal={true}
              showCloseIcon={true}
              header={viewHeader}
              height={800}
              zIndex={8958}
              minwidth={'950%'}
            />
          </div>
          </div>
        <div className="col-sm-6 pi-div ">
                  {loadingPi ? (
                    <div>... loading PI </div>
                  ) : (
                    <div>
                      <AutoCompleteComponent
                        id="pi"
                        dataSource={pi}
                        placeholder="Select PI"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={piRef}
                        select={piChange}
                        value={piValue}
                        change={piChange}
                        blur={()=>{
                          console.log(piRef.current)
                           if (piID == 0  || piRef.current.value == null || piRef.current.value == '' || piID == undefined ) {
                            piRef.current.element.focus()
                            if(piValue != ''){
                            let resstr = pi.some(e=>e.value ==  piValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("pi").parentElement.parentElement.firstChild)
                              document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }}
                        

                      />
                      <TextBoxComponent
                        name="piID"
                        value={piID}
                        type="hidden"
                      />
                    </div>
                  )}
                    <button onClick={btnClicked}>Messages</button>
                </div>
                <div className='col-sm-8'>
                {btnClick == true   ? <MessageGridComponent />
:null}
</div>
      </div>
    ) :
      (
        <div className="content-">
           <div>
            <div className='App' id='dialog-target'>
            <DialogComponent
              // width='600px'
              target='#dialog-target'
              visible={showDialogView}
              close={viewDialogClose}
              //open={openReturnDialog}
              content={contentViewTemplate}
              isModal={true}
              showCloseIcon={true}
              header={viewHeader}
              height={800}
              zIndex={8958}
              width={1000}
            />
          </div>
          </div>
          <div>
            
          {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
          setShowDialogs(false)
          if(e == false){
        setShowDialogs(false)
        setTimeout(() => {
          getMassage(piID);

        }, 200);
                                      }
              }} 
                ></HardDelete>
                  :null}

            <div>
              <h3>Message - List</h3>
              <div>
              </div>
              {/* <messageGridComponent /> */}
              <div className="col-sm-8  pi-div " >
                  {loadingPi ? (
                    <div>... loading PI </div>
                  ) : (
                    <div className='pi'>
                      <AutoCompleteComponent
                        id="pi"
                        dataSource={pi}
                        placeholder="Select PI"
                        fields={ddlFields}
                        floatLabelType="Auto"
                        highlight={true}
                        ref={piRef}
                        select={piChange}
                        value={piValue}
                        change={piChange}
                        blur={()=>{
                          console.log(piRef.current)
                           if (piID == 0  || piRef.current.value == null || piRef.current.value == '' || piID == undefined ) {
                            piRef.current.element.focus()
                            if(piValue != ''){
                            let resstr = pi.some(e=>e.value ==  piValue);
                            console.log(resstr)
                            if(resstr === false){
                              // setCountryErorr(`${countryValue} Value Not Find ` )
                              console.log( document.getElementById("pi").parentElement.parentElement.firstChild)
                              document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='3px solid red'
                              setTimeout(() => {
                                // setProductItemTypeError('')
                                document.getElementById("pi").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                              }, 1000);
                            }
                          }
                          }
                        }}
                        
                      />
                      <TextBoxComponent
                        name="piID"
                        value={piID}
                        type="hidden"
                      />
                    </div>
                  )}
<button onClick={btnClicked}>Messages</button>
                </div>
                {btnClick == true?
                
                
                <MessageGridComponent />
                :null} 
            </div>
          </div>
        </div>
      )
  );
}
