import http from './http-common';

class EmailEditorService {
  emailData(emailData) {
    return http.post(`/EmailEditor/`,emailData ,{
        headers: {
          'Content-Type': 'multipart/form-data',
        }},
    )
  }
  getEmailEditorList(id ,email) {
    return http.get(`/EmailEditor/list/emails/${id}/${email}`);
  }
  getEmailFiles(id) {
    return http.get(`/EmailEditor/files/${id}`);
  }


}
  export default new EmailEditorService();
