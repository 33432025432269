import React , { useState , useEffect ,useRef} from 'react';
import './upload-pdf-files.scss'
import moment from 'moment'
import * as XLSX from 'xlsx'
import imgSrc from './spinner.gif'
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import PIService  from '../../services/PI.service'

const UploadSupplierList = (props) => {
    const [Files, setFiles] = useState([]);
    const [largeFiles, setLargeFiles] = useState([]);
    const [dataOfFiles, setDataOfFiles] = useState([]);
    
    const [upperButtons, setUpperButtons] = useState(false);


    /// SHOW AND HIDE CODE START
  const Dialog = useRef();
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [Message,  setMessage] = useState('');
  const [dialogHeader,  setDialogHeader] = useState('');
  
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return sizeInBytes + ' B';
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  };
  const LargeFileList = (props) => {
    console.log(largeFiles)
    return (
      <div>
        <p className='large-file-title'>These files cannot be uploaded 
as their size is more than 200 KB
</p>
        <ul className='large-file-list'>
          {props.data.map((fileName, index) => (
            <li key={index}  >{fileName.name} - {formatFileSize(fileName.size) }</li>
          ))}
        </ul>
      </div>
    );
  };
  const OkButtons = [
    {
      buttonModel: {
        content: 'OK',
        cssClass: 'e-flat',
        isPrimary: true,
      },
      click: () => {
        Dialog.current.hide();
        if (largeFiles.length > 0) {
          // handleLargeFileToast();dd
       
          setTimeout(()=> {
              setMessage(<LargeFileList data={largeFiles} />)
              setDialogHeader('')
              Dialog.current.show()
            },500);
            setLargeFiles([]);
       
        }
      },
    },
  ];
  function DialogClose() {
    setDialogVisibility(false);
      
}
  /// SHOW AND HIDE CODE END


    useEffect(()=>{

      getPdfFiles()
    },[])
    

    const getPdfFiles = ()=>{
      try {
      if(props.PIID != undefined){
        PIService.getPIPDFFiles(props.PIID ).then((response)=>{
          console.log(response)
          setFiles(response.data);
          if(response.data.length > 0){

            setUpperButtons(true)
          }
        })
      }
      } catch (error) {
        console.log(error)
      }
    }
    // show toast code start
    const handleLargeFileToast = () => {
        const pairs = [];
    
        for (let i = 0; i < largeFiles.length; i += 2) {
          const pair = largeFiles.slice(i, i + 2);
          pairs.push(pair);
          
        }
    
        pairs.forEach((pair, index) => {
          setTimeout(() => {
            pair.forEach((file) => {
              toast.error(`file Size is too large. Maximum size is 200KB: ${file.name}`, { autoClose: 1500 });
            });
          }, index * 2000); // Adjust the time interval between each pair of toasts
        });
    
      }
      useEffect(() => {
        if (largeFiles.length > 0) {
          // handleLargeFileToast();dd
         
        }
      }, [largeFiles])
    
    // show toast code end

 
    const  showLoader = () => {
        // document.getElementById('loader').style.display = 'block';
        // document.getElementById('successMessage').style.display = 'none';

    }
    
    // Function to hide the loader and show the success message
    const  showSuccessMessage = () => {
      setMessage('FILES UPLOADED SUCCESSFULLY')
      setDialogHeader('ALERT')
      setTimeout(()=> Dialog.current.show(),50);
      
        // document.getElementById('loader').style.display = 'none';
        // document.getElementById('successMessage').style.display = 'block';
        // setTimeout(() => {
        //     document.getElementById('successMessage').style.display = 'none';
        // }, 1000);

    }
    const inputChange = (e)=>{
        document.getElementById('upload-file-div').style.borderColor = '#00335b'
        let files = e.target.files;
        const allowedExtensions = /(\.pdf)$/i; // Only allow PDF files

    // Loop through the selected files
    for (let i = 0; i < files.length; i++) {
        if (!allowedExtensions.exec(files[i].name)) {
          setMessage('ONLY PDF FILES ARE ALLOWED, TRY AGAIN.')
          setTimeout(()=> Dialog.current.show(),50);

            e.target.value = ""; // Clear the input if invalid file is found
            return;
        }
    }
        // console.log(files[0].type)
        
        if(files.length > 0 && files[0].type == 'application/pdf'){
            console.log('files max limit' , )
            const files = e.target.files; // Convert FileList to an array
            const maxFiles = 6 - Files.length;
            const maxSize = 200 * 1024; // 200KB

            let validFiles = [];
            let hasInvalidFile = false;
            let newLargeFiles = [];
            
              // Check each file's size
              for (let i = 0; i < files.length; i++) {
                if (files[i].size > maxSize) {
                //   alert(`File ${files[i].name} is too large. Maximum size is 200KB.`);
                  hasInvalidFile = true;
                  newLargeFiles.push(files[i])
                } else {
                  validFiles.push(files[i]);
                }
            }
            // setLargeFiles((prevs)=>{
            //   return(
            //     [
            //       ...prevs,
            //       ...newLargeFiles
            //     ]
            //   )
            // }
            //   );
            //   console.log(largeFiles)
            // If there were invalid files but some valid ones, upload the valid ones
            if (validFiles.length > maxFiles || maxFiles == 0) {
                // alert(`You can only upload a maximum of ${maxFiles} files.`);
                // toast.error(`You can only upload a maximum of ${maxFiles} files.}`, { autoClose: 4000 });
                setMessage("You can only upload a maximum of 6 files.")
                setTimeout(()=> Dialog.current.show(),50);
                
              if(newLargeFiles.length > 0 && maxFiles != 0){
                setLargeFiles([...largeFiles, ...newLargeFiles]);
              }

              // if(maxFiles != 0){
              if(maxFiles == 0){

                validFiles =[]
              }else{
                validFiles = validFiles.slice(0, maxFiles); 
              }
              }else{
                console.log(largeFiles)
                console.log(newLargeFiles)
                
                if(newLargeFiles.length > 0){
                  
                  setTimeout(()=> {
                    setMessage(<LargeFileList data={newLargeFiles} />)
      
                    Dialog.current.show()
                  },500);
                  setLargeFiles([]);
                }
              }

             
            if (validFiles.length > 0) {
                console.log(validFiles)
                setDataOfFiles(validFiles);
                setFiles(
                  (prevValues) => ([ 
                    ...prevValues,
                    ...validFiles
                ]))

              }
            
              // Clear input value if there were invalid files
              if (hasInvalidFile) {
                e.target.value = '';
              }
    // setFiles(createObject)
        }else{
            setDataOfFiles([])
            document.getElementById('upload-file-div').style.borderColor = 'red'
            document.getElementById('upload-file-div').style.backgroundColor = '#ff90904a'
            setTimeout(() => {
                document.getElementById('upload-file-div').style.borderColor = '#00335b'
                document.getElementById('upload-file-div').style.backgroundColor = 'rgba(129, 148, 170, 0.1803921569)'
            }, 2000);

        }
        
        
    }
    console.log(Files)
   
      const DeleteFile = async (name ,path , id) => {
        console.log(name,path,id)
                if(window.confirm("Are you sure you want to remove file?")){
                  const updatedFiles = [...Files];
                  let  arrayFiles = updatedFiles.filter(item => item.name !== name);
                  console.log(arrayFiles)
                  setFiles(arrayFiles);
                  
                  const updatedDataFiles = [...dataOfFiles];
                  let  arrayDataFiles = updatedDataFiles.filter(item => item.name !== name);
                  console.log(arrayDataFiles)
                  setDataOfFiles(arrayDataFiles);

                  if(id != undefined){
                    let data= {
                      PIPDFID:id,
                      Name:path,
                      YEAR:moment().year()

                    }
                    PIService.deletePDFFilebyPIPDFID(data).then((res)=>{
                      console.log(res)
                    })
                  }
                    // setFiles([]);
                    // setDataOfFiles([]);
                    // document.getElementById('excelFile').value = '';
                }else{
                    // alert('No');
                }
            }

    const formSubmit = (e)=>{
                        e.preventDefault()
if(dataOfFiles.length > 0){
  showLoader()
  console.log(props)
  let data = new FormData()
  
  dataOfFiles.forEach((file, index) => {
    data.append(`file${index}`, file);
  });
  data.append('PIID' , props.PIID )
  data.append('YEAR' ,moment().year())
  PIService.uploadPIPdf(data).then((res)=>{
      console.log(res)
      // document.getElementById('excelFile').value = '';

      showSuccessMessage(); 
       setFiles([])
       setDataOfFiles([])
       getPdfFiles()
  }).catch((e)=>{
      console.log(e)
      
  })
    //   setExcelData(excelData);

                }else{
                    console.log('form submited in else section')
                }

  }


  const DownloadFile  = (buffer ,name) => {
    console.log(buffer);
    if(buffer == undefined){
    alert('PLEASE UPLOAD FILES AND THEN DOWNLOAD')
    }else{
    const blob = new Blob([new Uint8Array(buffer.data)], { type:'application/pdf'});
                  saveAs(blob, name)
    }
  }

  const [downloadAllLoader, setDownloadAllLoader] = useState(false);
  const [deleteAllLoader, setDeleteAllLoader] = useState(false);
  const DownloadAllFiles  = (filesParams) => {
    console.log(filesParams);
    let files = filesParams.filter(item => item.hasOwnProperty('buffer') )
    if(files.length > 0){
    setDownloadAllLoader(true)
    
    files.map((file)=>{
        const blob = new Blob([new Uint8Array(file.buffer.data)], { type:'application/pdf'});
        saveAs(blob, file.name)
        setTimeout(() => { 
      setDownloadAllLoader(false)
    }, 600);
    })
  }else{
    alert('PLEASE UPLOAD FILES AND THEN DOWNLOAD')
  }
  }
  const DeleteAllFiles  = (files) => {
    console.log(files);
    setDeleteAllLoader(true)
    setFiles([])
    setDataOfFiles([])
    PIService.deleteAllPdfByPIID(props.PIID , moment().year()).then((res)=>{

      setTimeout(() => { 
        setDeleteAllLoader(false)
        setUpperButtons(false)

      }, 600);
    })
    
  }
    return (
        <>
           <div className='App' id='dialog-target'>
          <DialogComponent
                ref={Dialog}
                width={500}
                visible={dialogVisibility}
                close={DialogClose}
                content={() => (<div>{Message}</div>)}
                isModal={true}
                header={dialogHeader == ''? 'Warning' :dialogHeader}
                buttons={OkButtons}
              // footerTemplate={footerTemplate}
              />
          </div>
        <div className=' upload-supplier-wrappers'>
            
            <form onSubmit={formSubmit}> 
                <div className='form-upload-wrapper'>
                    <div className='upload-file-main-divs' id='upload-file-div' >
                    <input type="file" id='excelFile' name="excelFile" accept=".pdf" multiple={true} limit={2} onChange={inputChange} 
                    onDragEnter={(e)=>{ document.getElementById('upload-file-div').style.borderColor = '#eee'  }}
                    onDragLeave={(e)=>{ document.getElementById('upload-file-div').style.borderColor = '#00335b'  }}
                    
                    />
                    <span id='excelFile-span'>Drag and drop or <span className="file-link">Choose your files</span></span>
                    </div>
                </div>
                <div className="kb-buttons-box">
                        <button
                        type="submit"
                        className="btn btn-primary form-submit"
                        disabled={dataOfFiles.length > 0 ? false : true}
                        
                        >UPLOAD</button>
                        {/* <img src={imgSrc} id='loader' /> */}
                        {/* <p id='successMessage'> UPLOAD SUCCESSFULLY!!</p> */}
                        {/* <p id='uploadFileAlert'> Upload SuccessFully!</p> */}
                        {upperButtons == true ?<div className="download-delete-toolbar">
                        <button
                        className="btn btn-primary form-submit btn-download-all"
                        disabled={Files.length > 0 ? false : true}
                        onClick={(e)=>(e.preventDefault(),DownloadAllFiles(Files))}
                        >Download All</button>
                        {downloadAllLoader  == false  ? null :<img src={imgSrc} id='downloadAll' />}
                        <button
                        className="btn btn-primary form-submit btn-delete-all"
                        disabled={Files.length > 0 ? false : true}
                        onClick={(e)=>( e.preventDefault(),DeleteAllFiles(props.PIID))}
                        >Delete All</button> 
                        {deleteAllLoader  == false  ? null :<img src={imgSrc} id='downloadAll' />}
                        
                        {/*<p   id='uploadFileAlert' > Upload SuccessFully ! </p>*/}
                </div>
                : null}
                </div>
            </form>
            <hr />
            {Files.length > 0 ?
                                        <div className="kb-attach-box row">
                                            {
                                                Files.map((data, index) => {
                                                    
                                                    console.log(data)
                                                    const { name,   lastModifiedDate, size ,buffer  ,PIPDFID , path} = data;
                                                    // console.log('filename',filename)
                                                    console.log(moment(lastModifiedDate).format('DD/MM/YY'))

                                                    return (
                                                        <div className='col-sm-4'>
                                                        <div className="file-atc-boxs" key={index}>
                                                            
                                                            {
                                                                name.match(/.(pdf)$/i) ?
                                                                <div className="file-images"><i className="far fa-file-alt"></i></div>:
                                                                    <div className="file-images"><i className="far fa-file-alt"></i></div>
                                                            }
                                                            <div className="file-details">
                                                                <h6>{name}</h6>
                                                                <p><span>Size : {formatFileSize(size)}</span></p>
                                                                {/* <span className="ml-3">Modified Date : {moment(lastModifiedDate).format('DD/MM/YY')}</span> */}
                                                                <div className="file-actions">
                                                                    <button className="file-action-btn" 
                                                                    onClick={() => DeleteFile(name ,path , PIPDFID)}
                                                                    >Delete</button>
                                                                    
                                                                
                                                                    <button className="file-action-btn" 
                                                                    onClick={() => DownloadFile(buffer ,name)}
                                                                    >Download</button>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : <></>}      

</div>                                       
<ToastContainer />
 </>

    );
}

export default UploadSupplierList;


