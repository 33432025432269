import http from "./http-common";

class ProductService {
  getProductCutSizeForList() {
    return http.get('/product/productCutSize/list');
  }

  getProductCutSizeForDD() {
    return http.get('/product/productCutSize/dropdown');
  }

  addProductCutSize(data) {
    return http.post('/product/productCutSize/add', data);
  }

  getProductFinishSizeForList() {
    return http.get('/product/productFinishSize/list');
  }

  getProductFinishSizeForDD(id) {
    return http.get(`/product/productFinishSize/dropdown/${id}`);
  }

  addProductFinishSize(data) {
    return http.post('/product/productFinishSize/add', data);
  }

  getProductForList() {
    return http.get('/product/list');
  }

  getProductForDD() {
    return http.get('/product/dropdown');
  }

  getProductSetForDD() {
    return http.get('/product/productSetDropdown');
  }
  addProduct(data) {
    return http.post('/product/add', data);
  }
  addProductTable(data) {
    return http.post('/product/product/add', data);
  }
  updateProductCutSize(id,data) {
    return http.put(`/product/productCutSize/update/${id}`,data);
  }
  addProductDetailItem(data){
    return http.post('/product/list/detail/add',data)
  }

  getProductDetail(){
    return http.get(`/product/list/Detail`)
  }

  updateProduct(ProductID,ProductDetailRowID,data){
    console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/product/update/${ProductID}/${ProductDetailRowID}`,data)
  }
  deleteProductDetail(id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/product/productDetail/delete/${id}`)
  }
  deleteProductRowDetail(id){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/product/productRowDetail/delete/${id}`)
  }
  editProductTable(ProductID,data){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.put(`/product/update/${ProductID}`,data)
  }
  delete(ProductID){
    // console.log('updateProduct args', ProductID,ProductDetailRowID,data)
    return http.delete(`/product/delete/productTable/${ProductID}`)
  }
  updateProductFinshSize(id,data) {
    return http.put(`/product/productFinishSize/update/${id}`,data);
  }

}

export default new ProductService();
