import http from './http-common';

class TransferUnAllocatedPaymentService {
  getList() {
    return http.get(`/TransferUnAllocatedPayment/list`);
  }
  customerDropDown() {
    return http.get(`/TransferUnAllocatedPayment/customer/dropdown`);
  }
  getBalanceUnAllocatedAmount(date, currencyID, customerID) {
    return http.get(`/TransferUnAllocatedPayment/balanceUnAllocatedAmount/${date}/${currencyID}/${customerID}`);
  }
  addTransferUnAllocatedPayment(data) {
    return http.post(`/TransferUnAllocatedPayment/add`,data);
  }
  }
  export default new TransferUnAllocatedPaymentService();
