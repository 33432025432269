import React, { useEffect, useState } from 'react';
import Header from './app/layout/header';
import Menu from './app/layout/menu';
import Content from './app/layout/content';
import Footer from './app/layout/footer';
import './App.scss';
// import './switcher.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SecureComponent from './app/components/security/secure.component';
import SignInSide from './app/components/security/login/signin';
import IdleTimerContainer from './app/components/idleTimerContainer';
import ProtectedRoute from '../src/app/components/security/protected.route';
import CustomerLastSaleList from '../src/app/components/customer-last-sale/customer-last-sale-list'
import AdjustmentCalculatorList from '../src/app/components/adjustment-calculator/adjustment-calculator-list'
import ChangeAccess from './app/components/security/change-access/change-access'
import LedgerList from './app/components/ledger/ledger-list';

import SalesRecordList from './app/components/sales-record/sales-record-list';

function App() {
  // const [ colorTheme, setColorTheme ] = useState('theme-white');

  // useEffect(() => {
  //   const currentThemeColor = localStorage.getItem('theme-color');
  //   if(currentThemeColor){
  //     setColorTheme(currentThemeColor);
  //   }
  // }, []);

  // const changeTheme =(theme) => {
  //   setColorTheme(theme);
  //   localStorage.setItem('theme-color', theme);
  // } 
  return (
    <div>
      {/* className={`App ${colorTheme}`} */}
      <div>
        <IdleTimerContainer
          extraTime={21600000} //6 Hours -- Time in Millisecondds
          standardTime={21600000} //6 Hours -- Time in Millisecondds
          // extraTime={60 * 60 * 1000}
          // standardTime={60 * 60 * 1000}
          // standardTime={500 * 60 * 1000}
        />
        {/* className='theme-options' */}
        {/* <div id='theme-white' onClick={()=>changeTheme('theme-white')} className='active'/>
         <div id='theme-blue' onClick={()=>changeTheme('theme-blue')} className='active'/>
         <div id='theme-orange' onClick={()=>changeTheme('theme-orange')} className='active'/>
         <div id='theme-purple' onClick={()=>changeTheme('theme-purple')} className='active'/>
         <div id='theme-green' onClick={()=>changeTheme('theme-green')} className='active'/>
         <div id='theme-black' onClick={()=>changeTheme('theme-black')} className='active'/> */}
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={SignInSide} />
            <div className='content-box'>
              <Header />
              <Menu />
              <ProtectedRoute exact path='/home' component={Content} />

              <ProtectedRoute exact path='/CommissionDetails' component={SecureComponent}  name='CommissionReportList'/>

              <ProtectedRoute exact path='/Setup/City' component={SecureComponent}  name='CityList'/>

              <ProtectedRoute exact path='/Setup/CountryCityPort' component={SecureComponent} name={'CountryCityPortList'}  />

              <ProtectedRoute exact path='/Setup/Country' component={SecureComponent}  name='CountryList'/>
              
              <ProtectedRoute exact path='/Setup/Currency' component={SecureComponent}  name='CurrencyList'/>

              <ProtectedRoute exact  path='/Setup/Customer' component={SecureComponent} name={'CustomerList'}  />

              <ProtectedRoute exact  path='/Setup/CustomerSaleAgent' component={SecureComponent} name={'CustomerList-SalesAgent'}  />
              
              <ProtectedRoute  exact  path='/Setup/Designation'  component={SecureComponent} name={'DesignationList'} />
              
              <ProtectedRoute  exact   path='/Setup/Employee'   component={SecureComponent} name={'EmployeeList'}  />
              
              <ProtectedRoute  exact  path='/Setup/PaymentType' component={SecureComponent} name={'PaymentTypeList'} />

              <ProtectedRoute  exact  path='/Setup/Port' component={SecureComponent} name={'PortList'} />
              
              <ProtectedRoute  exact  path='/Setup/PurchaseSource' component={SecureComponent} name={'PurchaseSourceList'} />

              <ProtectedRoute  exact  path='/Setup/ShipmentType' component={SecureComponent} name={'ShipmentTypeList'} />

              <ProtectedRoute   exact  path='/Setup/Supplier' component={SecureComponent}  name={'SupplierList'} />
              
              <ProtectedRoute   exact  path='/Setup/TradeBoardLetterStatus' component={SecureComponent}  name={'TradeBoardLetterStatusList'} />
                        
               {/* // CHANGE ACCESS SKIP CODE */}

               <ProtectedRoute exact path='/Users' component={SecureComponent} name='UsersList' />
               
               <ProtectedRoute exact  path='/UserReplicate' component={SecureComponent} name='UserReplicateList' />

               <ProtectedRoute exact  path='/Emails' component={SecureComponent} name='EmailList' />

               <ProtectedRoute  exact  path='/Transactions/PI/CRM'  component={SecureComponent} name='PICRMList' />
               
               <ProtectedRoute  exact  path='/Transactions/PI/SalesAgent'  component={SecureComponent} name='PISalesAgentList' />
               
               <ProtectedRoute  exact  path='/Transactions/PI/Supervisor'  component={SecureComponent} name='PISupervisorList' />
               
               <ProtectedRoute   exact  path='/PI/Duplicate'  component={SecureComponent} name='DuplicatePIList' />

               <ProtectedRoute  exact  path='/PI/Message' component={SecureComponent} name='PIMessageList' />

               <ProtectedRoute  exact  path='/ReceiveUnAllocatedPayment/CRM'  component={SecureComponent} name='ReceiveUnAllocatedPaymentCRMList' />
              
              <ProtectedRoute   exact  path='/ReceiveUnAllocatedPayment/SalesAgent' component={SecureComponent} name='ReceiveUnAllocatedPaymentSalesAgentList' />

              <ProtectedRoute   exact  path='/TransferUnAllocatedPayment' component={SecureComponent}  name='TransferUnAllocatedPaymentList' />

              <ProtectedRoute   exact  path='/UploadSupplier'  component={SecureComponent} name='UploadSupplierList' />

              <ProtectedRoute   exact  path='/CommissionCalculator'  component={SecureComponent} name='CommissionCalculatorPerUnitList' />

              <ProtectedRoute exact  path='/SalesRecord' component={SecureComponent} name={'SaleRecordList'}  />
              <ProtectedRoute exact  path='/SalesSummary' component={SecureComponent} name={'SaleSummaryList'}  />

              <ProtectedRoute exact  path='/DueDate' component={SecureComponent} name={'DueDateReportList'}  />
              {/* <ProtectedRoute exact  path='/Ledger' component={LedgerList} name={'LedgerList'}  /> */}
              
              <ProtectedRoute exact  path='/Ledger' component={SecureComponent} name={'CustomerLedgerList'}  />
              {/* <ProtectedRoute exact  path='/Ledger' component={SecureComponent} name={'LedgerList'}  /> */}

            {/* // ADJUSTMENT Calculator SKIP CODE */}

            <ProtectedRoute
                exact
                path='/AdjustmentCalculator'
                component={AdjustmentCalculatorList}
              />
            <ProtectedRoute
                exact
                path='/CustomerLastSale'
                component={CustomerLastSaleList}
              />

              <Route exact path='/Security/ChangeAccess' component={ChangeAccess} />


            </div>
          </Switch>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
