import http from './http-common';

class UsersService {
  getAll() {
    return http.get('/users');
  }
  getForList() {
    return http.get('/users/getForList');
  }
  add(data) {
    return http.post('/users/add',data);
  }
  edit(data,id) {
    return http.put(`/users/${id}`,data);
  }

  getThreeInputsForList(data) {
    return http.post('/threeInputs/list',data);
  }
  addMultipleInputs(data) {
    return http.post('/threeInputs/add',data);
  }
  editMultipleInputs(data) {
    return http.put('/threeInputs/update',data);
  }
  UserIsDeletedService(data ,id){
    return http.put(`users/changeIsDeleted/${id}`,data)
  }
}

export default new UsersService();
