import React , { useState } from 'react';
import './upload-supplier.scss'
import moment from 'moment'
import * as XLSX from 'xlsx'
import imgSrc from './spinner.gif'
import UploadSupplierService  from '../../services/upload-supplier.service'
const UploadSupplierList = () => {
    const [Files, setFiles] = useState([]);
    const [dataOfFiles, setDataOfFiles] = useState([]);
    const [callApiCheck, setCallApiCheck] = useState(false);
    

    const callApi = (excelData) =>{
        showLoader()
        UploadSupplierService.uploadSupplierData(excelData).then((res)=>{
            console.log(res)
            document.getElementById('excelFile').value = '';
            showSuccessMessage(); 
            setFiles([])
            setDataOfFiles([])
        }).catch((e)=>{
            console.log(e)
            
        })
    }
    const  showLoader = () => {
        document.getElementById('loader').style.display = 'block';
        document.getElementById('successMessage').style.display = 'none';

    }
    
    // Function to hide the loader and show the success message
    const  showSuccessMessage = () => {
        document.getElementById('loader').style.display = 'none';
        document.getElementById('successMessage').style.display = 'block';
        setTimeout(() => {
            document.getElementById('successMessage').style.display = 'none';
        }, 1000);
    }
    const inputChange = (e)=>{
        document.getElementById('upload-file-div').style.borderColor = '#00335b'
        
        let files = e.target.files;
        console.log(files[0].type)
        
        if(files.length > 0 && files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            setDataOfFiles(e.target.files[0])
            for (let i = 0; i < files.length; i++) {
                console.log(files[i]);               
                const reader = new FileReader();
                reader.onload = function(e) {
            // preview.src = e.target.result;
            console.log(e)
            console.log(reader)
            // console.log(JSON.stringify(reader))


            
            let createObject = [{data:files[i] ,fileImage: reader.result }]
            console.log(createObject)
           setFiles(createObject)
            
            };

        reader.readAsArrayBuffer(files[i]);
            }
        }else{
            setDataOfFiles([])
            document.getElementById('upload-file-div').style.borderColor = 'red'
            document.getElementById('upload-file-div').style.backgroundColor = '#ff90904a'
            setTimeout(() => {
                
                document.getElementById('upload-file-div').style.borderColor = '#00335b'
                document.getElementById('upload-file-div').style.backgroundColor = 'rgba(129, 148, 170, 0.1803921569)'
            }, 2000);

        }
        
        
    }
    console.log(Files)
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes < 1024) {
          return sizeInBytes + ' B';
        } else if (sizeInBytes < 1024 * 1024) {
          return (sizeInBytes / 1024).toFixed(2) + ' KB';
        } else {
          return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
        }
      };
      const DeleteFile = async (id) => {
                if(window.confirm("Are you sure you want to remove file?")){
                    
                    setFiles([]);
                    setDataOfFiles([]);
                    document.getElementById('excelFile').value = '';
                }else{
                    // alert('No');
                }
            }

    const formSubmit = (e)=>{
                        e.preventDefault()
                        console.log('form Submit' , dataOfFiles.size)
                if(dataOfFiles != {} && dataOfFiles.size != undefined){
                        const file = dataOfFiles;
                        const reader = new FileReader();
                
                    reader.onload = (event) => {
                        const data = event.target.result;
                        const workbook = XLSX.read(data, { type: 'binary' });
                
                    // Assuming you want to read data from the first sheet
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                
                    // Parse the worksheet data into an array of objects
                        const excelData = XLSX.utils.sheet_to_json(worksheet, { raw: false });

      // Convert the date column to a human-readable format
      for (const row of excelData) {
        console.log(row)
        if (row.ReceiptMonth) {
            setCallApiCheck(true)
            row.ReceiptMonth =  moment(row.ReceiptMonth, "M/D/YY H:mm").format("YYYY-M-D");
        //   const dateValue = parseFloat(row.ReceiptMonth); // Assuming 'date' is the column name
        //   console.log(dateValue)
        //   const jsDate = new Date((dateValue - 25568) * 86400 * 1000); // Convert to JavaScript date
        //   console.log(jsDate)
        //   row.ReceiptMonth = moment(jsDate).format('YYYY-MM-DD');

        //   let jsDate = new Date((dateValue - 25569) * 86400 * 1000); // Convert to JavaScript date
        //   row.ReceiptMonth = moment(jsDate).format('YYYY-MM-DD')
          

        }else{
            setCallApiCheck(false)
            setDataOfFiles([])
            setFiles([])
            document.getElementById('excelFile').value = '';
            document.getElementById('upload-file-div').style.borderColor = 'red'
            document.getElementById('upload-file-div').style.backgroundColor = '#ff90904a'
            setTimeout(() => {
                
                document.getElementById('upload-file-div').style.borderColor = '#00335b'
                document.getElementById('upload-file-div').style.backgroundColor = 'rgba(129, 148, 170, 0.1803921569)'
            }, 2000);
        }
      }

      console.log(excelData);
      if(callApiCheck == true){

          return callApi(excelData)
      }
    //   setExcelData(excelData);


                    };
                
                    reader.readAsBinaryString(file);
                }else{
                    console.log('form submited in else section')
            
                }

  }

    return (
        <>
        <div className='content-wrapper upload-supplier-wrapper'>
            <div className="kb-modal-data-title">
                 <div className="kb-data-title">
                     <h6 className='upload-excel-heading'> Upload Excel File.</h6>
                 </div>
             </div>
            <form onSubmit={formSubmit}> 
                <div className='form-upload-wrapper'>
                    <div className='upload-file-main-div' id='upload-file-div' >
                    <input type="file" id='excelFile' name="excelFile" accept=".xlsx, .xls" multiple={false} onChange={inputChange} 
                    onDragEnter={(e)=>{ document.getElementById('upload-file-div').style.borderColor = '#eee'  }}
                    onDragLeave={(e)=>{ document.getElementById('upload-file-div').style.borderColor = '#00335b'  }}
                    
                    />
                       <span id='excelFile-span'>Drag and drop or <span className="file-link">Choose your files</span></span>
                    </div>
                </div>
                <div className="kb-buttons-box">
                        <button 
                        type="submit" 
                        className="btn btn-primary form-submit"
                        disabled={Files.length > 0 ? false : true}
                        >Upload</button> 
                        <img src={imgSrc} id='loader' />
                        <p id='successMessage'> Upload SuccessFully!</p>
                        {/* <p id='uploadFileAlert'> Upload SuccessFully!</p> */}
                </div>
            </form>
            
            {Files.length > 0 ?
                                        <div className="kb-attach-box">
                                            <hr />
                                            {
                                                Files.map((data, index) => {
                                                    
                                                    console.log(data.data.lastModifiedDate)
                                                    const { name,   lastModifiedDate, size } = data.data;
                                                    // console.log('filename',filename)
                                                    console.log(moment(lastModifiedDate).format('DD/MM/YY'))

                                                    return (
                                                        <div className="file-atc-box" key={index}>
                                                            
                                                            {
                                                                name.match(/.(xlxs|xls)$/i) ?
                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>:
                                                                    <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                            }
                                                            <div className="file-detail">
                                                                <h6>{name}</h6>
                                                                <p><span>Size : {formatFileSize(size)}</span><span className="ml-3">Modified Date : {moment(lastModifiedDate).format('DD/MM/YY')}</span></p>
                                                                <div className="file-actions">
                                                                    <button className="file-action-btn" 
                                                                    onClick={() => DeleteFile()}
                                                                    >Delete</button>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : <></>}      
</div>                                        </>
    );
}

export default UploadSupplierList;




//import React, { useState } from "react";
// // import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
// // import shortid from "https://cdn.skypack.dev/shortid@2.2.16";


// export default function UploadSupplier() {
//     const [selectedfile, SetSelectedFile] = useState([]);
//     const [Files, SetFiles] = useState([]);


//     const filesizes = (bytes, decimals = 2) => {
//         if (bytes === 0) return '0 Bytes';
//         const k = 1024;
//         const dm = decimals < 0 ? 0 : decimals;
//         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//         const i = Math.floor(Math.log(bytes) / Math.log(k));
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
//     }

//     const InputChange = (e) => {
//         // --For Multiple File Input
//         let images = [];1
//         for (let i = 0; i < e.target.files.length; i++) {
//             images.push((e.target.files[i]));
//             let reader = new FileReader();
//             let file = e.target.files[i];
//             reader.onloadend = () => {
//                 SetSelectedFile((preValue ,index) => {
//                     return [
//                         ...preValue,
//                         {
//                             id: index,
//                             filename: e.target.files[i].name,
//                             filetype: e.target.files[i].type,
//                             fileimage: reader.result,
//                             datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
//                             filesize: filesizes(e.target.files[i].size)
//                         }
//                     ]
//                 });
//             }
//             if (e.target.files[i]) {
//                 reader.readAsDataURL(file);
//             }
//         }
//     }


//     const DeleteSelectFile = (id) => {
//         if(window.confirm("Are you sure you want to delete this Image?")){
//             const result = selectedfile.filter((data) => data.id !== id);
//             SetSelectedFile(result);
//         }else{
//             // alert('No');
//         }
        
//     }

//     const FileUploadSubmit = async (e) => {
//         e.preventDefault();

//         // form reset on submit 
//         e.target.reset();
//         if (selectedfile.length > 0) {
//             for (let index = 0; index < selectedfile.length; index++) {
//                 SetFiles((preValue)=>{
//                     return[
//                         ...preValue,
//                         selectedfile[index]
//                     ]   
//                 })
//             }
//             SetSelectedFile([]);
//         } else {
//             alert('Please select file')
//         }
//     }


//     const DeleteFile = async (id) => {
//         if(window.confirm("Are you sure you want to delete this Image?")){
//             const result = Files.filter((data)=>data.id !== id);
//             SetFiles(result);
//         }else{
//             // alert('No');
//         }
//     }
   
//     return(
        
//         <div className="fileupload-view">
//                 <div className="row justify-content-center m-0">
//                     <div className="col-md-6">
//                         <div className="card mt-5">
//                             <div className="card-body">
//                                 <div className="kb-data-box">
//                                     <div className="kb-modal-data-title">
//                                         <div className="kb-data-title">
//                                             <h6> Upload Excel File With Preview</h6>
//                                         </div>
//                                     </div>
//                                     <form onSubmit={FileUploadSubmit}>
//                                         <div className="kb-file-upload">
//                                             <div className="file-upload-box">
//                                                 <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
//                                                 <span>Drag and drop or <span className="file-link">Choose your files</span></span>
//                                             </div>
//                                         </div>
//                                         <div className="kb-attach-box mb-3">
//                                             {
//                                                 selectedfile.map((data, index) => {
//                                                     const { id, filename, filetype, fileimage, datetime, filesize } = data;
//                                                     return (
//                                                         <div className="file-atc-box" key={id}>
//                                                             {
//                                                                 filename.match(/.(xlsx)$/i) ?
//                                                                     <div className="file-image"> <img src={fileimage} alt="" /></div> :
//                                                                     <div className="file-image"><i className="far fa-file-alt"></i></div>
//                                                             }
//                                                             <div className="file-detail">
//                                                                 <h6>{filename}</h6>
//                                                                 <p></p>
//                                                                 <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
//                                                                 <div className="file-actions">
//                                                                     <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     )
//                                                 })
//                                             }
//                                         </div>
//                                         <div className="kb-buttons-box">
//                                             <button type="submit" className="btn btn-primary form-submit">Upload</button>
//                                         </div>
//                                     </form>
//                                     {Files.length > 0 ?
//                                         <div className="kb-attach-box">
//                                             <hr />
//                                             {
//                                                 Files.map((data, index) => {
//                                                     const { id, filename, filetype, fileimage, datetime, filesize } = data;
//                                                     return (
//                                                         <div className="file-atc-box" key={index}>
//                                                             {
//                                                                 filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
//                                                                     <div className="file-image"> <img src={fileimage} alt="" /></div> :
//                                                                     <div className="file-image"><i className="far fa-file-alt"></i></div>
//                                                             }
//                                                             <div className="file-detail">
//                                                                 <h6>{filename}</h6>
//                                                                 <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
//                                                                 <div className="file-actions">
//                                                                     <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
//                                                                     <a href={fileimage}  className="file-action-btn" download={filename}>Download</a>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     )
//                                                 })
//                                             }
//                                         </div>
//                                         : ''}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
       
//     );
// }


// // ReactDOM.render( < App /> , document.getElementById("root"));